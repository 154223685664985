import { ItsyContainer, ItsyColumn } from "@itsy-ui/layout";
import { ItsyLabel } from '@itsy-ui/common';
import { ErrorType } from "../utils/constant";
import { getlocaleText, getUrlParamValue } from "@itsy-ui/utils";
import { StateManagerContext } from "@itsy-ui/core";
import { ItsyToolbar } from "@itsy-ui/navigation";

// const iconSchema = {
//     "displayName": "app_icon",
//     "title": "App_icon",
//     "alignment": "center",
//     "image_type": "base64",
//     "image_value": ICON.error_ICON
// }

const errorTitle = {
    "title": "",
    "headerSize": "h6",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const errorMessage = {
    "title": "",
    "headerSize": "h10",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const detailedErrorMessage = {
    "title": "",
    "headerSize": "h8",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "back_login",
            "displayText": "{{user.okText}}",
            "isPrimary": true,
            "enabled": true
        }
    ],
    "contextParams": ["resetLS"],
    "widgetType": "bound",
    "align": "center",
    "className": "goback-button",
    "typeId": "reset_login"
};

function geterrorMessage(type: string) {
    switch (type) {
        case ErrorType.INVALID_URL:
            errorTitle["title"] = "{{invalidUrl.errorTitle}}";
            errorMessage["title"] = "{{invalidUrl.errorMessage}}";
            const detailedErrMsg = `{{${getUrlParamValue("msg")}}}`;
            detailedErrorMessage["title"] = `${getlocaleText("{{common.error}}")}: ${getlocaleText(detailedErrMsg)}`;
            break;
        case ErrorType.ERROR_403:
            errorTitle["title"] = "{{account.disabled}}";
            errorMessage["title"] = "{{common.accountDeactivatedMessage}}";
            break;
        default:
            break;
    }
}

const ErrorPage = (props) => {
    const type = getUrlParamValue("type");
    geterrorMessage(type);
    return (<ItsyContainer className="ErrorPage-container  w-50">
        <ItsyColumn hAlignment="center" className="apn-error-icon">
            {/* <ItsyImage className="error-icon" schema={iconSchema} /> */}
        </ItsyColumn>
        <ItsyColumn className="apn-error-title">
            <ItsyLabel schema={errorTitle} />
        </ItsyColumn>
        <ItsyColumn>
            <ItsyLabel schema={errorMessage} />
        </ItsyColumn>
        {type === ErrorType.ERROR_403 ? (
            <ItsyColumn className="apn-success-button">
                <StateManagerContext.Provider key="errorAccount" value={{ contextPath: { "id": "reset_login" } }}>
                    <ItsyToolbar schema={toolbarSchema} />
                </StateManagerContext.Provider>
            </ItsyColumn>) : (<ItsyColumn>
                <ItsyLabel schema={detailedErrorMessage} />
            </ItsyColumn>)
        }
    </ItsyContainer>)
}

export default ErrorPage;