import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory } from "@itsy-ui/core";
import { getlocaleText, getItemFromLocalStorage, setItemInLocalStorage } from "@itsy-ui/utils";
import { getFormatedDate } from "../common/helpers";
import { ACTIONS } from "../utils/constant"
import { getScanDateValue, getAgeValue, getWeightValue, getAllSiteIds } from "../utils/helpers"
import moment from 'moment';

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doAnalystFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
        const analystDetails = getItemFromLocalStorage("ANALYST_SEARCH_REQUEST");
        let formData;
        const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-\d{4}$/;

        const analystValidationSchema = {
            ...validationSchema,
            "fileType": Yup.string().required(getlocaleText("{{search.fileType}}")),
            "sharingConsent": Yup.string().required(getlocaleText("{{search.sharingConsent}}")),
            "siteIds": Yup.string().required(getlocaleText("{{search.siteId}}")),
            "classificationIds": Yup.string().required(getlocaleText("{{classification.required}}")),
            "beforeDate": Yup.string().when("scanDateSelector", {
                is: selected_scanDateSelector => {
                  
                    return ((selected_scanDateSelector === "before" ||
                    selected_scanDateSelector === "between") &&
                  selected_scanDateSelector !== "all")
                },
                
                then: Yup.string().required("Date must be in the format mm-dd-yyyy")
                .matches(dateRegex, "Invalid Date, expected mm-dd-yyyy date format"),
                otherwise: Yup.string()
            }),
            "afterDate": Yup.string().when("scanDateSelector", {
                
                is: selected_scanDateSelector => {
                   
                    return (  (selected_scanDateSelector === "after" ||
                    selected_scanDateSelector === "between") &&
                  selected_scanDateSelector !== "all"
                )
               
                },
                
                then: Yup.string().required("Date must be in the format mm-dd-yyyy")
                .matches(dateRegex, "Invalid Date, expected mm-dd-yyyy date format"),
                otherwise: Yup.string()
                
            })
            
                
        };
        if (analystDetails !== undefined && Object.keys(analystDetails).length !== 0) {

            let dateSelector = "all", beforeDate, afterDate;
            if (analystDetails.hasOwnProperty('uploadDate')) {
                dateSelector = getScanDateValue(analystDetails["uploadDate"]);
                beforeDate = analystDetails["uploadDate"]['min'] ? new Date(analystDetails["uploadDate"]['min']) : "";
                afterDate = analystDetails["uploadDate"]['max'] ? new Date(analystDetails["uploadDate"]['max']) : "";
            }
            else if (analystDetails.hasOwnProperty('visitDate')) {
                dateSelector = getScanDateValue(analystDetails["visitDate"]);
                beforeDate = analystDetails["visitDate"]['min'] ? new Date(analystDetails["visitDate"]['min']) : "";
                afterDate = analystDetails["visitDate"]['max'] ? new Date(analystDetails["visitDate"]['max']) : "";
            }

            analystDetails["apn_pet_dicom_available"] = analystDetails.hasOwnProperty('apn_pet_dicom_available') ? analystDetails["apn_pet_dicom_available"].toString() : "";
            analystDetails["pet_dicom_available"] = analystDetails.hasOwnProperty('pet_dicom_available') ? analystDetails["pet_dicom_available"].toString() : "";
            analystDetails["scanDateSelectorFor"] = analystDetails["scanDateSelectorFor"];
            analystDetails["scanDateSelector"] = dateSelector;
            analystDetails["beforeDate"] = beforeDate;
            analystDetails["afterDate"] = afterDate;
            analystDetails["ageSelector"] = analystDetails.hasOwnProperty('age') ? getAgeValue(analystDetails["age"]) : "all"
            analystDetails["ageHigher"] = analystDetails.hasOwnProperty('age') && analystDetails["age"]['max'] ? analystDetails["age"]['max'] : "";
            analystDetails["ageLower"] = analystDetails.hasOwnProperty('age') && analystDetails["age"]['min'] ? analystDetails["age"]['min'] : "";
            analystDetails["weightSelector"] = analystDetails.hasOwnProperty('weight') ? getWeightValue(analystDetails["weight"]) : "all";
            analystDetails["weightLower"] = analystDetails.hasOwnProperty('weight') && analystDetails["weight"]['min'] ? analystDetails["weight"]['min'] : "";
            analystDetails["weightHigher"] = analystDetails.hasOwnProperty('weight') && analystDetails["weight"]['max'] ? analystDetails["weight"]['max'] : "";
            analystDetails["gender"] = analystDetails.hasOwnProperty('gender') ? analystDetails["gender"] : "all";
            analystDetails["sharingConsent"] = analystDetails["sharingConsent"] === null || analystDetails["sharingConsent"] === undefined ? ["true", "false"] : analystDetails["sharingConsent"] ? ["true"] : ["false"];
            const siteIds = await getAllSiteIds();
            let siteIdsArray = siteIds.split(',');
            siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
            const totalSiteIdsLength = siteIdsArray.length;
            analystDetails["siteIds"] = analystDetails.hasOwnProperty('siteIds') ? analystDetails["siteIds"].length === totalSiteIdsLength ? [siteIds] : analystDetails["siteIds"] : [siteIds];
            analystDetails["classificationIds"] = analystDetails.hasOwnProperty('classificationIds') ? analystDetails["classificationIds"] : ["all"]
            formData = {
                ...objectData,
                ...analystDetails
            }
            if (analystDetails !== undefined && analystDetails["scanDateSelector"] !== undefined) {
                formSchema.propertyDefinitions["beforeDate"].readOnly = analystDetails["scanDateSelector"] === "all" ? true : analystDetails["scanDateSelector"] === "between" ? false : analystDetails["scanDateSelector"] !== "before" ? true : false
                formSchema.propertyDefinitions["afterDate"].readOnly = analystDetails["scanDateSelector"] === "all" ? true : analystDetails["scanDateSelector"] === "between" ? false : analystDetails["scanDateSelector"] !== "after" ? true : false
            } if (analystDetails !== undefined && analystDetails["ageSelector"] !== undefined) {
                formSchema.propertyDefinitions["ageLower"].readOnly = analystDetails["ageSelector"] === "all" ? true : analystDetails["ageSelector"] === "between" ? false : analystDetails["ageSelector"] !== "older" ? true : false
                formSchema.propertyDefinitions["ageHigher"].readOnly = analystDetails["ageSelector"] === "all" ? true : analystDetails["ageSelector"] === "between" ? false : analystDetails["ageSelector"] !== "younger" ? true : false
            } if (analystDetails !== undefined && analystDetails["weightSelector"] !== undefined) {
                formSchema.propertyDefinitions["weightLower"].readOnly = analystDetails["weightSelector"] === "all" ? true : analystDetails["weightSelector"] === "between" ? false : analystDetails["weightSelector"] === "greater" ? true : false
                formSchema.propertyDefinitions["weightHigher"].readOnly = analystDetails["weightSelector"] === "all" ? true : analystDetails["weightSelector"] === "between" ? false : analystDetails["weightSelector"] === "less" ? true : false
            } if (analystDetails !== undefined && analystDetails["mmse"] !== undefined && analystDetails["mmse"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.mmse["firstBox"].readOnly = !analystDetails["mmse"].checked
                formSchema.propertyDefinitions.mmse["secondBox"].readOnly = !analystDetails["mmse"].checked
            } if (analystDetails !== undefined && analystDetails["cdr"] !== undefined && analystDetails["cdr"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.cdr["firstBox"].readOnly = !analystDetails["cdr"].checked
                formSchema.propertyDefinitions.cdr["secondBox"].readOnly = !analystDetails["cdr"].checked
            } if (analystDetails !== undefined && analystDetails["adas_cog"] !== undefined && analystDetails["adas_cog"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.adas_cog["firstBox"].readOnly = !analystDetails["adas_cog"].checked
                formSchema.propertyDefinitions.adas_cog["secondBox"].readOnly = !analystDetails["adas_cog"].checked
            } if (analystDetails !== undefined && analystDetails["fab"] !== undefined && analystDetails["fab"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.fab["firstBox"].readOnly = !analystDetails["fab"].checked
                formSchema.propertyDefinitions.fab["secondBox"].readOnly = !analystDetails["fab"].checked
            } if (analystDetails !== undefined && analystDetails["psprs"] !== undefined && analystDetails["psprs"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.psprs["firstBox"].readOnly = !analystDetails["psprs"].checked
                formSchema.propertyDefinitions.psprs["secondBox"].readOnly = !analystDetails["psprs"].checked
            } if (analystDetails !== undefined && analystDetails["updrs"] !== undefined && analystDetails["updrs"].hasOwnProperty("checked")) {
                formSchema.propertyDefinitions.updrs["firstBox"].readOnly = !analystDetails["updrs"].checked
                formSchema.propertyDefinitions.updrs["secondBox"].readOnly = !analystDetails["updrs"].checked
            }
        } else {
            formData = {
                ...objectData,
                "gender": "all",
                "siteIds": [await getAllSiteIds()],
                "scanDateSelectorFor": "visitDate",
                "scanDateSelector": "all",
                "ageSelector": "all",
                "weightSelector": "all",
                "classificationIds": ["all"],
                "pet_dicom_available": { checkBox: false, result: '' },
                "apn_pet_dicom_available": { checkBox: false, result: '' },
                "sharingConsent": ["true"]
            }
        }

        //first conver the locale time to utc an then to iso
        const CurrentDate = new Date();
        const TodayDate = getFormatedDate(CurrentDate);
        formSchema.propertyDefinitions["beforeDate"]["maxDate"] = TodayDate;
        formSchema.propertyDefinitions["afterDate"]["maxDate"] = TodayDate;

        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            objectData: formData,
            formSchema: formSchema,
            validationSchema: analystValidationSchema,
            extraParams: extraParams,
        });
        
    }
}


function doAnalystFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { typeId, formValues, metadata } = getState();
        let currentFormValues;
        let updateFormValues = { ...formValues };
        let updatedValues = { ...value };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        let subjectOption;
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
        if (value !== undefined && value["studyId"] !== undefined && Object.keys(value).toString() === "studyId") {
            const getSUbjectID = await datasource.getAll("subjects_dropdown", { studyId: value["studyId"] });
            subjectOption = [getSUbjectID]
            updatedPropDefs["subjectId"] = metadata.propertyDefinitions["subjectId"];
            updatedPropDefs["subjectId"].metadata.data = subjectOption[0];
            updatedPropDefs["subjectId"].metadata.isLocal = true;
            updatedValues = {
                ...value,
            };
        } if (value !== undefined && value["siteIds"] && Array.isArray(value["siteIds"]) && value["siteIds"].length > 1) {
            let siteIdsValue;
            let allSiteIdsStr = await getAllSiteIds();
            if (value["siteIds"].length > 1 && value["siteIds"][value["siteIds"].length - 1] === allSiteIdsStr) {
                siteIdsValue = [allSiteIdsStr];
            } else {
                siteIdsValue = value["siteIds"].filter(e => e !== allSiteIdsStr)
            }
            updatedValues = {
                "siteIds": siteIdsValue,
            };
        } if (value !== undefined && value["classificationIds"] && Array.isArray(value["classificationIds"]) && value["classificationIds"].length > 1) {
            let classificationIdsValue;
            if (value["classificationIds"].length > 1 && value["classificationIds"][value["classificationIds"].length - 1] === "all") {
                classificationIdsValue = ["all"]
            } else {
                classificationIdsValue = value["classificationIds"].filter(e => e !== "all")
            }
            updatedValues = {
                "classificationIds": classificationIdsValue,
            };
        }
        if (value !== undefined && value["scanDateSelector"] !== undefined && (Object.keys(value).toString() === "scanDateSelector" || value["scanDateSelector"])) {
            updatedPropDefs["beforeDate"].readOnly = value["scanDateSelector"] === "all" ? true : value["scanDateSelector"] === "between" ? false : value["scanDateSelector"] !== "before" ? true : false
            updatedPropDefs["afterDate"].readOnly = value["scanDateSelector"] === "all" ? true : value["scanDateSelector"] === "between" ? false : value["scanDateSelector"] !== "after" ? true : false
            if (value["scanDateSelector"] !== "between") {
                delete formValues["beforeDate"];
                delete formValues["afterDate"];
            }
        } if (value !== undefined && value["ageSelector"] !== undefined && (Object.keys(value).toString() === "ageSelector" || value["ageSelector"])) {
            updatedPropDefs["ageLower"].readOnly = value["ageSelector"] === "all" ? true : value["ageSelector"] === "between" ? false : value["ageSelector"] !== "older" ? true : false
            updatedPropDefs["ageHigher"].readOnly = value["ageSelector"] === "all" ? true : value["ageSelector"] === "between" ? false : value["ageSelector"] !== "younger" ? true : false
            if (value["ageSelector"] !== "between") {
                delete formValues["ageLower"];
                delete formValues["ageHigher"];
            }
        } if (value !== undefined && value["weightSelector"] !== undefined && (Object.keys(value).toString() === "weightSelector" || value["weightSelector"])) {
            updatedPropDefs["weightLower"].readOnly = value["weightSelector"] === "all" ? true : value["weightSelector"] === "between" ? false : value["weightSelector"] === "greater" ? true : false
            updatedPropDefs["weightHigher"].readOnly = value["weightSelector"] === "all" ? true : value["weightSelector"] === "between" ? false : value["weightSelector"] === "less" ? true : false
            if (value["weightSelector"] !== "between") {
                delete formValues["weightLower"];
                delete formValues["weightHigher"];
            }
        } if (value !== undefined && value["mmse"] !== undefined && value["mmse"].hasOwnProperty("checked")) {
            updatedPropDefs.mmse["firstBox"].readOnly = !value["mmse"].checked
            updatedPropDefs.mmse["secondBox"].readOnly = !value["mmse"].checked
        } if (value !== undefined && value["cdr"] !== undefined && value["cdr"].hasOwnProperty("checked")) {
            updatedPropDefs.cdr["firstBox"].readOnly = !value["cdr"].checked
            updatedPropDefs.cdr["secondBox"].readOnly = !value["cdr"].checked
        } if (value !== undefined && value["adas_cog"] !== undefined && value["adas_cog"].hasOwnProperty("checked")) {
            updatedPropDefs.adas_cog["firstBox"].readOnly = !value["adas_cog"].checked
            updatedPropDefs.adas_cog["secondBox"].readOnly = !value["adas_cog"].checked
        } if (value !== undefined && value["fab"] !== undefined && value["fab"].hasOwnProperty("checked")) {
            updatedPropDefs.fab["firstBox"].readOnly = !value["fab"].checked
            updatedPropDefs.fab["secondBox"].readOnly = !value["fab"].checked
        } if (value !== undefined && value["psprs"] !== undefined && value["psprs"].hasOwnProperty("checked")) {
            updatedPropDefs.psprs["firstBox"].readOnly = !value["psprs"].checked
            updatedPropDefs.psprs["secondBox"].readOnly = !value["psprs"].checked
        } if (value !== undefined && value["updrs"] !== undefined && value["updrs"].hasOwnProperty("checked")) {
            updatedPropDefs.updrs["firstBox"].readOnly = !value["updrs"].checked
            updatedPropDefs.updrs["secondBox"].readOnly = !value["updrs"].checked
        }
        if (value != undefined && value["pet_dicom_available"]) {
            if (value["pet_dicom_available"].checkBox) {
                updatedValues["pet_dicom_available"].result = value["pet_dicom_available"].result === '' ? 'false' : value["pet_dicom_available"].result
            }
            else {
                updatedValues["pet_dicom_available"].result = '';
            }
        }
        if (value != undefined && value["apn_pet_dicom_available"]) {
            if (value["apn_pet_dicom_available"].checkBox) {
                updatedValues["apn_pet_dicom_available"].result = value["apn_pet_dicom_available"].result === '' ? 'false' : value["apn_pet_dicom_available"].result
            }
            else {
                updatedValues["apn_pet_dicom_available"].result = '';
            }
        }
        if (value !== undefined && value.hasOwnProperty("fileType") && value["fileType"].length === 0) {
            delete updatedValues["fileType"];
            delete updateFormValues["fileType"];
            currentFormValues = { ...updateFormValues, ...updatedValues };
        } else {
            currentFormValues = { ...formValues, ...updatedValues };
        }
 
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            controlID: "analyst_createForm",
            strict: true,
            mode: 0
        });
      
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            controlID: "analyst_createForm",
            strict: true,
        });
     };
  
}


function doAnalystFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            let allSiteIdsStr = await getAllSiteIds();
            const afterDateValue = localStorage.getItem('afterDate') === 'true';
            if(afterDateValue){

            }
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("analystVisitDataSource");
            if (event.values["pet_dicom_available"] && event.values["pet_dicom_available"]["checkBox"] && event.values["pet_dicom_available"]["result"] !== "") {
                event.values["pet_dicom_available"] = event.values["pet_dicom_available"]["result"] === 'true' ? true : false
            } if (event.values["apn_pet_dicom_available"] && event.values["apn_pet_dicom_available"]["checkBox"] && event.values["apn_pet_dicom_available"]["result"] !== "") {
                event.values["apn_pet_dicom_available"] = event.values["apn_pet_dicom_available"]["result"] === 'true' ? true : false
            }
            if (event.values["classificationIds"] && event.values["classificationIds"][0] === "all") {
                delete event.values["classificationIds"]
            } if (event.values["gender"] && event.values["gender"] === "all") {
                delete event.values["gender"]
            }
            event.values["fileType"] && event.values["fileType"].length === 0 && delete event.values["fileType"]
            if (event.values["sharingConsent"] && (event.values["sharingConsent"].length === 0 || event.values["sharingConsent"].length === 2)) {
                delete event.values["sharingConsent"]
            }
            if (event.values["sharingConsent"] && (event.values["sharingConsent"].length === 1)) {
                event.values["sharingConsent"] = event.values["sharingConsent"][0] === 'true' ? true : false
            }
            const data = await datasource.analystUpsert("search", event.values);
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{search.successMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: "NAVIGATE_URL",
                url: "/analystresult",
            })

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
            transition({
                type: "FORM_REFRESH",
            });
            transition({
                type: "FORM_BEFORE_HANDLE_CHANGE",
                controlID: "analyst_createForm",
                strict: true,
                value: {
                    "gender": "all",
                    "siteIds": [await getAllSiteIds()],
                    "scanDateSelector": "all",
                    "ageSelector": "all",
                    "weightSelector": "all",
                    "classificationIds": ["all"]
                }
            })
        }
    };
}


const AnalystSearchForm: ICustomStateMachineData = {
    name: "AnalystSearchForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onAnalystFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onAnalystFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onAnalystFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onAnalystFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(doAnalystFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onAnalystFormHandleChange: ({ value, formValues }) => dispatch(doAnalystFormHandleChange(value, formValues)),
            onAnalystFormSubmit: (evt) => dispatch(doAnalystFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "search"
}, AnalystSearchForm);
