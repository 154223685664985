import axios from "axios";
import { getHeaders } from "./helpers";
import { getFilteredProps, formGetAllJson, applyPagination, setFilterParam, modifyFilterByRole, getFilterQueryJson, setFilterParam_export } from "./lbHelpers";

export class LBDataSource {

    private headers: any
    private config: any
    private configFunc: any

    constructor(configAPI?: any) {
        this.configFunc = configAPI
    }

    initializeConfig() {
        this.headers = this.headers ? this.headers : getHeaders();
    }

    async getAll(typeId: string, parameters: any, hostUrl: string): Promise<any[]> {
        this.initializeConfig();
        const headers = this.headers;
        try {
            let url = `${hostUrl}/${typeId}`;

            parameters = modifyFilterByRole(typeId, parameters);

            let count = 0;
            let filterQueryJson: any = getFilterQueryJson(parameters);
            if (applyPagination(parameters)) {
                if (parameters.orderBy === undefined) {
                    parameters.orderBy = "lastModifiedDate DESC";
                }
                const pagingProps: any = getFilteredProps(["skipCount", "maxItems", "orderBy"], parameters);
                filterQueryJson = formGetAllJson(pagingProps, filterQueryJson);

                // Getting total objects count
                count = await this.getCount(url, filterQueryJson);
            }

            url = setFilterParam(url, filterQueryJson);

            // Getting objects from server
            let resultData = await axios.get(url, { headers });
            let rowsData = resultData.data;
            if (rowsData && Array.isArray(rowsData)) {
                rowsData["totalRecordsCount"] = count;
                return rowsData;
            }
            if (rowsData) {
                return rowsData;
            }
            return [];
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any, hostUrl: string): Promise<any> {
        this.initializeConfig();
        const headers = this.headers;
        try {
            const resultData = await axios.post(`${hostUrl}/${metadata.id}`, data, {
                headers: {
                    'Content-Type': 'application/json', // Remove
                    ...headers
                }
            });
            return resultData.data;
        } catch (e) {
            throw new Error(e.response.data.error.message);
        }
    }

    async upsert(record: any, formSchema: any, hostUrl: string): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            return await this.update(record, formSchema, hostUrl);
        } else {
            return await this.create(record, formSchema, hostUrl);
        }
    }

    async update(data: {}, metadata: any, hostUrl: string): Promise<any> {
        this.initializeConfig();
        const headers = this.headers;
        const objectId = data["id"];
        if (objectId === undefined || objectId === "") {
            throw new Error(`Failed to update object with ID: ${objectId}, Reason: ID cannot be empty`);
        }
        try {
            const resultData = await axios.put(`${hostUrl}/${metadata.id}/${objectId}`, data, { headers });
            if (!resultData.data && (resultData.status === 204 || resultData.status === 200)) {
                return "updated successfully";
            }
            return resultData.data;
        } catch (e) {
            let reason = e.response.data.error.cause ? e.response.data.error.cause : e.response.data.error.message;
            throw new Error(`Failed to update object with ID: ${objectId}, Reason: ${reason}`);
        }
    }

    async delete(data: any, hostUrl: string): Promise<any> {
        this.initializeConfig();
        const headers = this.headers;
        const objectId = data["id"];
        if (objectId === undefined || objectId === "") {
            throw new Error(`Failed to delete object with ID: ${objectId}, Reason: ID cannot be empty`);
        }
        try {
            const resultData = await axios.delete(`${hostUrl}/${data["objectTypeId"]}/${objectId}`, { headers });
            if (!resultData.data && (resultData.status === 204 || resultData.status === 200)) {
                return "deleted successfully";
            }
            return resultData.data;
        } catch (e) {
            throw new Error(`Failed to delete object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, hostUrl: string, _parameters: any): Promise<any> {
        this.initializeConfig();
        const headers = this.headers;

        const model = typeId.replace(":", "_"); // Remove
        let url = `${hostUrl}/${model}/${objectId}`;
        try {
            if (_parameters && _parameters.filter) {
                const queryJson: any = (JSON.stringify(_parameters.filter));
                if (queryJson) {
                    url = `${hostUrl}/${model}/${objectId}?filter=${queryJson}`;
                }
            }
            const resultData = await axios.get(url, { headers });
            return resultData.data;
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async createRelationship(_record: {}): Promise<any> {
        return null;
    }

    async getRelationshipChildren(_typeId: string): Promise<any> {
        return null;
    }

    async getRelatedRecords(_API_URL, _BAF_URL, _relatedFieldKey: any, _propDefs: {}, _typeId: string, _parameters: any) {
        throw ("not implemented");
    }

    async uploadFile(_typeId: string, _objectId: any, _file: any): Promise<any> {
        throw ("not implemented");
    }

    async downlodFile(hostUrl: any): Promise<any> {
        try {
            const fileName = decodeURIComponent(hostUrl.split("/").pop().split("?")[0]);
            const link = document.createElement('a');
            link.href = hostUrl;
            link.target = "_blank";
            link.download = fileName;
            link.click();
            link.remove();
        } catch (e) {
            throw new Error(e.response.data.error.message);
        }
    }

    async getContentStream(_typeId: string, _objectId: any): Promise<any> {
        throw ("not implemented");
    }

    async getCount(url: string, queryJson: any): Promise<any> {
        const headers = this.headers;
        try {
            url = `${url}/count`;
            if (queryJson && queryJson.where) {
                const encodeJSON = encodeURIComponent(JSON.stringify(queryJson.where));
                url = `${url}?where=${encodeJSON}`;
            }
            let resultData = await axios.get(url, { headers });
            const data = resultData.data;
            return data.count;
        } catch (e) {
            throw new Error(`Failed to get count, Reason: ${e.message}`);
        }
    }

    async getAll_export(typeId: string, parameters: any, hostUrl: string): Promise<any[]> {
        this.initializeConfig();
        const headers = this.headers;
        try {
            let url = `${hostUrl}/${typeId}`;

            parameters = modifyFilterByRole(typeId, parameters);

            let count = 0;
            let filterQueryJson: any = getFilterQueryJson(parameters);
            if (filterQueryJson) {
                filterQueryJson["order"] = parameters.orderBy;
            }
            url = setFilterParam_export(url, filterQueryJson, parameters.requestBody);

            // Getting objects from server
            let resultData = await axios.get(url, { headers });
            let rowsData = resultData.data;
            if (rowsData && Array.isArray(rowsData)) {
                rowsData["totalRecordsCount"] = count;
                return rowsData;
            }
            if (rowsData) {
                return rowsData;
            }
            return [];
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

}