import { WidgetsFactory, CommandOptions, DataLoaderFactory, ICommandManager, IDataSourceLake } from "@itsy-ui/core";
import { getItemFromLocalStorage, getlocaleText } from "@itsy-ui/utils";
import { USER, Roles } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const Actions = {
    State: {
        Drawer: {
            SHOW_DRAWER: "SHOW_DRAWER",
            HIDE_DRAWER: "HIDE_DRAWER",
        },
        Form: {
            FORM_SUBMIT_CLICK: "FORM_SUBMIT_CLICK",
        },
        Grid: {
            GRID_REFRESH: "GRID_REFRESH",
        },
        Popup: {
            SHOW_POPUP: "SHOW_POPUP",
            HIDE_POPUP: "HIDE_POPUP"
        }
    },
};


//USER ADDED COMMANDS

const userAddCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{user.DrawerTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "users",
                    "formSchemaId": "user_create_form",
                    "isModal": true,
                    controlID: "user_create_form"
                },
            },
            onOKTransition: {
                type: Actions.State.Form.FORM_SUBMIT_CLICK,
            },
            onCancelTransition: {
                type: Actions.State.Drawer.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "users",
                    "formSchemaId": "user_create_form",
                },
            },
        };

        transition({
            type: Actions.State.Drawer.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("user_add", {}, userAddCommand);


//USER EDIT COMMANDS

const userEditCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{user.EditDrawerTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "users",
                    "isModal": true,
                    "formSchemaId": "user_edit_form",
                    "record": data,
                    "validateOnBlur": true,
                    controlID: "user_edit_form"
                },
            },
            onOKTransition: {
                type: Actions.State.Form.FORM_SUBMIT_CLICK,
            },
            onCancelTransition: {
                type: Actions.State.Drawer.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "users",
                    "formSchemaId": "user_edit_form",
                },
            },
        };

        transition({
            type: Actions.State.Drawer.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("Edit_User", {}, userEditCommand);


//USER DELETE COMMEND
async function deleteUser(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("datasource");
    data["objectTypeId"] = USER.USERS;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: Actions.State.Grid.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{User.deleteMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const userDeleteCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return data.data && data.data["isActive"] === true ? true : false;;
    },
    execute: async (data, transition) => {
        const popupData = {
            title: getlocaleText("{{common.Alert}}"),
            popupMessage: getlocaleText("{{User.DeleteMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                deleteUser(data, transition)
                transition({
                    type: Actions.State.Popup.HIDE_POPUP,
                });
            },
            onCancel: {
                type: Actions.State.Popup.HIDE_POPUP
            },
        };
        transition({
            type: Actions.State.Popup.SHOW_POPUP,
            event: popupData,
        });
    },
};
commandManager.registerCommand("Delete_User", {}, userDeleteCommand);



//USER ACITIVE

async function reActiveUser(data, transition) {
    const userDataSource: any = dataLoader.getLoader("userDataSource");
    try {
        const updateUser = await userDataSource.reActivate(data, { id: `${USER.USERS}` });
        if (updateUser) {
            transition({
                type: Actions.State.Grid.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{User.Active}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}


const ReactiveCommand: CommandOptions<any> = {
    canExecute: (event: any) => {
        return event.data && event.data["isActive"] === true ? false : true;
    },
    execute: async (data: any, transition: any) => {
        const popupData = {
            title: getlocaleText("{{common.Alert}}"),
            popupMessage: getlocaleText("{{User.ReactiveMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                reActiveUser(data, transition)
                transition({
                    type: Actions.State.Popup.HIDE_POPUP,
                });
            },
            onCancel: {
                type: Actions.State.Popup.HIDE_POPUP
            },
        };
        transition({
            type: Actions.State.Popup.SHOW_POPUP,
            event: popupData,
        });
    },
};

commandManager.registerCommand("re_active_user", {}, ReactiveCommand)

//delete User permantly

async function DeleteCurrentUser(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("userDataSource");
    data["objectTypeId"] = USER.USERS;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: Actions.State.Grid.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{User.deleteMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const DeleteUserCommand: CommandOptions<any> = {
    canExecute: (event: any) => {
        const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
        return selectedRoles && selectedRoles.role_name === Roles.SUPER_ADMIN ? true : false;
    },
    execute: async (data: any, transition: any) => {
        const popupData = {
            title: getlocaleText("{{common.Alert}}"),
            popupMessage: getlocaleText("{{User.DeleteUser}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                DeleteCurrentUser(data, transition)
                transition({
                    type: Actions.State.Popup.HIDE_POPUP,
                });
            },
            onCancel: {
                type: Actions.State.Popup.HIDE_POPUP
            },
        };
        transition({
            type: Actions.State.Popup.SHOW_POPUP,
            event: popupData,
        });
    },
};

commandManager.registerCommand("DeleteUser", {}, DeleteUserCommand)
