import { WidgetsFactory, IDataSourceLake, DataLoaderFactory, StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from "@itsy-ui/navigation";
import { useState, useEffect } from "react";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;

const PageTitle = {
    "title": "{{selectRolesTitle}}",
    "headerSize": "h2",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const PageSubTitle = {
    "title": "{{selectSubRolesTitle}}",
    "headerSize": "h5",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const RolesPage = (props) => {
    const [rolestoolbarSchema, schema] = useState();
    useEffect(() => {
        let currentToolbarSchema: any;
        const userSelectedRole = localStorage.SELECTED_ROLE ? JSON.parse(localStorage.SELECTED_ROLE) : null;
        const userdetails = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
        const dataSource = dataLoader.getLoader<IDataSourceLake>("userDataSource");
        if (userdetails && userdetails.id) {
            dataSource.getObject("users", userdetails.id).then(response => {
                if (response.roleObj && response.roleObj.length > 0) {
                    currentToolbarSchema = {
                        data: [],
                        "widgetType": "bound",
                        "reflowPageId": "selectroles",
                        "align": "right",
                        "className": "selectroles-button",
                        "typeId": "selectroles"
                    };
                    response.roleObj.map((ele, idx) => {
                        if ((userSelectedRole && userSelectedRole.id !== ele.id) || !(userSelectedRole)) {
                            currentToolbarSchema.data.push({
                                "name": "setCurrentRole",
                                "contextParams": ele,
                                "displayText": ele.role_name,
                                "isPrimary": true,
                                "enabled": true
                            })
                        }
                    })
                    schema(currentToolbarSchema);
                }
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }, []);

    return (<ItsyContainer className="apn-selectroles-container w-50">
        <ItsyColumn className="apn-rolepage-title">
            <ItsyLabel schema={PageTitle} />
        </ItsyColumn>
        <br />
        <ItsyColumn className="apn-rolepage-title">
            <ItsyLabel schema={PageSubTitle} />
        </ItsyColumn>
        <ItsyColumn className="apn-roles-form">
            <StateManagerContext.Provider key="selectroles" value={{ contextPath: { "id": "selectroles" } }}>
                {rolestoolbarSchema && <ItsyToolbar className="toolbar" schema={rolestoolbarSchema} />}
            </StateManagerContext.Provider>
        </ItsyColumn>
    </ItsyContainer>)
}

export default RolesPage;