import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");


function doAnalystGridSelectedRows(data: any) {
    return async (getState, dispatch, transition) => {
        transition({ // transition toolbar_refresh
			type: "TOOLBAR_REFRESH",
			items: [],
			params: {
				contextPath: {},
				context: {
					selectedRecords: data["selectedRows"],
				},
			},
		});
        transition({
            type: "GRID_SELECTED_ROWS_DONE",
            selectedRows: data["selectedRows"],
        });
        transition({
            type: "SELECT_RESULT_RECORD",
            record: data["selectedRows"]
        })
    };
}

const analystResultGrid: ICustomStateMachineData = {
    name: "analystResultGrid",
    stateJSON: {
        "states": {
            "gridSelectedRows": {
                "onEntry": [
                    "onAnalystGridSelectedRows"
                ],
                "on": {
                    "GRID_SELECTED_ROWS_DONE": "gridSelectedRowsDone"
                }
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onAnalystGridSelectedRows: (evt) => dispatch(doAnalystGridSelectedRows(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "analyst_result"
}, analystResultGrid);