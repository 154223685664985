import { eventTransition, StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';
import { getlocaleText, getUrlParamValue } from "@itsy-ui/utils";
import { useEffect, useState } from "react";
import { ErrorType } from "../utils/constant";
import { ItsyModal } from "@itsy-ui/navigation";
import { cleanLocalStorage } from "../utils/utils";

const Formschema = {
    typeId: "resetpassword",
    formSchemaId: "resetpassword",
    controlID: "resetpassword",
    submitButtonText: "{{common.done}}"
}

const ResetPasswordPageTitle = {
    "title": "{{resetYourPasswordTitle}}",
    "headerSize": "h6",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const RestPasswordPage = (props) => {
    const id = getUrlParamValue("id");
    const token = getUrlParamValue("token");
    const userInfo = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
    const modalSchema = {
        "width": "md",
        "title": "{{common.warning}}",
        "size": "default",
        "okText": getlocaleText("{{common.logout}}"),
        "showCancelButton": false,
        "onOk": () => {
            cleanLocalStorage();
        },
        "onOKTransition": { type: "HIDE_POPUP" },
        "controlSchema": {
            "name": "label",
            "properties": {
                "ui:widget": "label",
                "title": `${getlocaleText("{{common.sessionInUseMessage1}}")} "${userInfo ? userInfo.username : ""}" ${getlocaleText("{{common.sessionInUseMessage2}}")}`,
            }
        }
    };
    const sessionExistLogoutModal = <ItsyModal className="modal" schema={modalSchema} />
    const [showSessionBlockedModel, setShowSessionBlockedModel] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            let isvalidUrl = false;
            let detailedMessage;
            if (token !== undefined && token !== null && token !== "") {
                const res = JSON.parse(atob(token.split('.')[1]));
                const isxpired = res.exp < new Date().getTime() / 1000;
                isvalidUrl = !isxpired && id === res.id ? true : false;
                detailedMessage = isxpired ? "common.linkExpired" : id !== res.id ? "common.inValidTokenID" : ""
            }
            if (!isvalidUrl) {
                eventTransition({
                    type: "NAVIGATE_URL",
                    url: `/error?type=${ErrorType.INVALID_URL}&msg=${detailedMessage}`,
                });
            } else if (userInfo) {
                if (userInfo.id) {
                    setShowSessionBlockedModel(true);
                }
            }
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    if (showSessionBlockedModel) {
        return sessionExistLogoutModal;
    }
    else {
        return (<ItsyContainer className="apn-reset-container w-50">
            <ItsyColumn className="apn-reset-title">
                <ItsyLabel schema={ResetPasswordPageTitle} />
            </ItsyColumn>
            <ItsyColumn className="apn-reset-form">
                <StateManagerContext.Provider key="resetpassword" value={{ contextPath: { "id": "resetpassword" } }}>
                    <ItsyForm type="form" schema={Formschema} />
                </StateManagerContext.Provider>
            </ItsyColumn>
        </ItsyContainer>)
    }
}

export default RestPasswordPage;