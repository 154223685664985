import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';
import { useState } from 'react';
import { useEffect } from "react";
import { getCurrentUserRole } from "../utils/utils"
import { Roles } from '../utils/constant';

const studyListName = {
    "title": "{{study.studyList}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "study_add",
            "displayText": "{{study.addStudy}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "studies",
    "align": "right",
    "className": "study-list-added",
    "typeId": "study_add"
};

const Gridschema = {
    typeId: "studies",
    gridSchemaId: "studies",
    dataSource: "studyDataSource",
    rowSelectionMode: 1,
    controlID: "studies"
}

const filterSchema = {
    "widgetType": "bound",
    "schema": {
        "id": "study_filter",
        "displayName": "study filter",
        "propertyDefinitions": {
            "siteId": {
                "id": "siteId",
                "displayName": "{{Site.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{User.SiteName_placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_sites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "siteName"
                    ]
                },
                "datasource": "siteDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "siteName"
            },
        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "siteId"
                            }
                        ]
                    }
                ],
            }
        ]
    },
    "operation": {
        "studyId": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "study_filter",
    formSchemaId: "study_filter",
    controlID: "study_filter",
    "align": "left",
};
const StudyList = (props) => {
    const [isShowFilter, filterShow] = useState(false);

    useEffect(() => {
        if (getCurrentUserRole(Roles.SUPER_ADMIN) || getCurrentUserRole(Roles.ADMIN)) {
            filterShow(true)
        }
    }, [])

    return (
        <ItsyContainer className="study-list-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={studyListName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="study" value={{ contextPath: { "id": "study_create_form" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            {isShowFilter && <ItsyRow className="apn-filter-row">
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="study_filter" value={{ contextPath: { "id": "study_filter" } }}>
                        <ItsyFilterBar schema={filterSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>}
            <ItsyRow>
                <StateManagerContext.Provider key="studies" value={{ contextPath: { "id": "studies" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="study-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default StudyList;