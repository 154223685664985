import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { ACTIONS, TYPES, RegExp, LOCALSTORAGE_NAME } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils";
import { getISOFormatedDateTime } from "../common/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doSubjectFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues, metadata } = getState();
        let updatedValues = { ...value };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        const studyDatasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
        if (value !== undefined && value["siteId"] !== undefined) {
            const getStudies = await studyDatasource.getAll("studies_dropdown", { siteId: value["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            updatedPropDefs["studyId"].metadata.readOnly = false;
            updatedValues = {
                ...value,
                studyId: "",
            };
        }
        let currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            mode: 0
        });
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            mode: 0
        });
    }
}

function doSubjectFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {

        const subjectValidationSchema = {
            ...validationSchema,
            "subjectId": Yup.string().matches(RegExp.SUBJECTID, { message: getlocaleText("{{subject.errorMessage}}") }).required(getlocaleText("{{subject.required}}")),
            "studyId": Yup.string().required(getlocaleText("{{studyName.required}}")),
            "gender": Yup.string().required(getlocaleText("{{common.Gender}}")),
        };

        let updatedPropDefs = { ...formSchema.propertyDefinitions };
        objectData = objectData ? objectData : {};
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite && !selectedfilter_csite.includes("all") && !objectData.siteId) {
            objectData["siteId"] = selectedfilter_csite;
        }
        let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        if (selectedfilter_study && !selectedfilter_study.includes("all") && !objectData.studyId) {
            objectData["studyId"] = selectedfilter_study;
        }
        if (objectData !== undefined && objectData["siteId"] !== undefined) {
            const studyDatasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const getStudies = await studyDatasource.getAll("studies_dropdown", { siteId: objectData["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            updatedPropDefs["studyId"].metadata.readOnly = false;
        }

        objectData["thirdPartySharing"] = (objectData["sharingConsent"] === false || objectData["sharingConsent"] == getlocaleText("{{common.notAllowed}}")) ? [] : ["thirdPartySharing"];
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            mode: 0
        });

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: subjectValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doSubjectFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
            const subjectData = {
                studyId: event.values["studyId"],
                subjectId: event.values["subjectId"],
                gender: event.values["gender"],
                birthDate: event.values && event.values["birthDate"] ? getISOFormatedDateTime(event.values["birthDate"]) : undefined,
                sharingConsent: event.values["thirdPartySharing"] && Array.isArray(event.values["thirdPartySharing"]) && event.values["thirdPartySharing"].length == 1 ? true : false
            }
            const data = await datasource.subjectUpsert(subjectData, { id: TYPES.SUBJECT });  //id:url pathaname            
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                    controlID: "subject_create",
                    strict: true,
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.subjectsuccessMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
                transition({
                    type: "FORM_REFRESH",
                    controlID: "upload_form",
                    strict: true,
                })
                transition({
                    type: "FORM_BEFORE_HANDLE_CHANGE",
                    controlID: "upload_form",
                    strict: true,
                    value: {
                        "studyId": event.values["studyId"],
                        "siteId": event.values["siteId"],
                        "subjectId": event.values["subjectId"]
                    }
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const subjectCustome: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onSubjectFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onSubjectFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onSubjectFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doSubjectFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onSubjectFormSubmit: (evt) => dispatch(doSubjectFormSubmit(evt)),
            onSubjectFormHandleChange: ({ value, formValues }) => dispatch(doSubjectFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "subjects",
    "formSchemaId": "subject_create",
}, subjectCustome);


//subject Edit form handler

function doSubjectEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
            const subjectData = {
                id: event.values.id,
                studyId: event.values["studyId"],
                subjectId: event.values["subjectId"],
                gender: event.values["gender"],
                birthDate: event.values && event.values["birthDate"] ? getISOFormatedDateTime(event.values["birthDate"]) : undefined,
                isActive: event.values["isActive"],
                sharingConsent: event.values["thirdPartySharing"] && Array.isArray(event.values["thirdPartySharing"]) && event.values["thirdPartySharing"].length == 1 ? true : false
            }
            const newValue = !JSON.parse(localStorage.getItem('myVariable'));
            localStorage.setItem('myVariable', JSON.stringify(newValue));
            const data = await datasource.subjectUpsert(subjectData, { id: TYPES.SUBJECT });
            if (data !== undefined && data !== null && data !== "") {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.SubjectEdited}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const subjectEditFormOnSubmit: ICustomStateMachineData = {
    name: "subjectEditFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onSubjectFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onSubjectFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onSubjectEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doSubjectFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onSubjectEditFormSubmit: (evt) => dispatch(doSubjectEditFormSubmit(evt)),
            onSubjectFormHandleChange: ({ value, formValues }) => dispatch(doSubjectFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "subjects",
    "formSchemaId": "subject_edit",
}, subjectEditFormOnSubmit);

// batch subjects create form
function doBatchSubjectsFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const SUPPORTED_FORMATS = formSchema.propertyDefinitions.file ? formSchema.propertyDefinitions.file.acceptFileTypes ? formSchema.propertyDefinitions.file.acceptFileTypes : null : null;
        const subjectValidationSchema = {
            ...validationSchema,
            "studyId": Yup.string().required(getlocaleText("{{studyName.required}}")),
            "file": Yup.mixed()
                .required(getlocaleText("{{batchSubjectFile.required}}"))
                .test('fileType', getlocaleText("{{common.unsupportedFileFormat}}"), value => value && SUPPORTED_FORMATS && SUPPORTED_FORMATS.includes("." + value[0].name.split('.').pop()))
        };

        // set default study dropdown to empty
        // let updatedPropDefs = { ...formSchema.propertyDefinitions };
        // updatedPropDefs["studyId"].metadata.data = [];
        // updatedPropDefs["studyId"].metadata.isLocal = true;
        // updatedPropDefs["studyId"].metadata.readOnly = false;

        //to set selected site and study(to populate in text field ) 
        let updatedPropDefs = { ...formSchema.propertyDefinitions };
        objectData = objectData ? objectData : {};
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite && !selectedfilter_csite.includes("all") && !objectData.siteId) {
            objectData["siteId"] = selectedfilter_csite;
        }
        let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        if (selectedfilter_study && !selectedfilter_study.includes("all") && !objectData.studyId) {
            objectData["studyId"] = selectedfilter_study;
        }


        // if siteid has value then set study dropdown options
        if (objectData !== undefined && objectData["siteId"] !== undefined) {
            const studyDatasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const getStudies = await studyDatasource.getAll("studies_dropdown", { siteId: objectData["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            updatedPropDefs["studyId"].metadata.readOnly = false;
        }

        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            mode: 0
        });
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: subjectValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doBatchSubjectsFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
            const batchSubjectsData = {
                siteId: event.values["siteId"],
                studyId: event.values["studyId"],
                file: event.values["file"][0]
            }
            const data = await datasource.uploadFile(batchSubjectsData, { id: TYPES.SUBJECT });
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                if (data.failed && data.failed.length > 0) {
                    const failedErrorMessage = data.failed.map((errorDetails) => {
                        return `Row Number-${errorDetails.rowNumber} : ${errorDetails.reason} `;
                    }).join(', ');
                    transition({
                        type: "SHOW_NOTIFICATION",
                        message: { "text": failedErrorMessage, "type": " alert-danger" },
                        metadata: { "delay": 1500 },
                        action: { "showCloseIcon": false },
                    });
                }
                else {
                    transition({
                        type: "SHOW_NOTIFICATION",
                        message: { "text": "{{common.batchSubjectsuccessMessage}}", "type": " alert-success" },
                        metadata: { "delay": 1500 },
                        action: { "showCloseIcon": false },
                    });
                }
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const batchSubjectsForm: ICustomStateMachineData = {
    name: "batchSubjectsForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onBatchSubjectsFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onSubjectFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onBatchSubjectsFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onBatchSubjectsFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doBatchSubjectsFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onBatchSubjectsFormSubmit: (evt) => dispatch(doBatchSubjectsFormSubmit(evt)),
            onSubjectFormHandleChange: ({ value, formValues }) => dispatch(doSubjectFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "subjects",
    "formSchemaId": "batchSubject_create",
}, batchSubjectsForm);