import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { DROPDOWN, LOCALSTORAGE_NAME, TYPES } from "../../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doSubjectFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        let selectedfilter_classification = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CLASSIFICATION_VALUE);
        let selectedfilter_sharingconsent = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_SHARINGCONSENT_VALUE);
        const customeobjectData = {
            ...objectData,
            "siteId": selectedfilter_csite ? selectedfilter_csite : "all",
            "studyId": selectedfilter_study ? selectedfilter_study : "all",
            "classificationId": selectedfilter_classification ? selectedfilter_classification : "all",
            "sharingConsent": selectedfilter_sharingconsent ? selectedfilter_sharingconsent : "all"
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });
    }
}

function doSubjectFilterFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { typeId, formValues, metadata } = getState();
        const updatedValues = {
            ...value,
        };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (Object.keys(value).toString() === "siteId") {
            let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const getStudies = await datasource.getAll(DROPDOWN.FILTER_STUDIES_DROPDOWN, { siteId: value["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            updatedValues["studyId"] = selectedfilter_study && studyOption[0].map(item => { return item.id }).includes(selectedfilter_study) ? selectedfilter_study : "all";
        }
        const currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
        });
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
        })
        let searchValue = {
            studyId: currentFormValues["studyId"], siteId: currentFormValues["siteId"], classificationId: currentFormValues["classificationId"]
        }
        if (currentFormValues["sharingConsent"] !== "all") {
            searchValue["sharingConsent"] = currentFormValues["sharingConsent"]
        }
        transition({
            controlID: "subjects",
            strict: true,
            type: "GRID_FILTER",
            searchValue: searchValue
        });
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE, searchValue["siteId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE, searchValue["studyId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CLASSIFICATION_VALUE, searchValue["classificationId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_SHARINGCONSENT_VALUE, currentFormValues["sharingConsent"]);
    };
}

const subjectFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onSubjectFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onSubjectFilterFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doSubjectFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onSubjectFilterFormHandleChange: ({ value, formValues }) => dispatch(doSubjectFilterFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "subject_filter"
}, subjectFormHandler);