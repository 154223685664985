import { getCreatedBy, getFormatedDateWithTimezoneOffset, getHeaders, getLoginUserRoleName } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { getServerUrl } from "../utils/utils";
import { TYPES } from "../utils/constant";
import { getlocaleText } from "@itsy-ui/utils";
import { getAllCMCSiteIds, getFilterQuery } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class CompoundMfgSubUsageDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config;
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            if (parameters.filter && (!parameters.filter.cmcSiteId || parameters.filter["cmcSiteId"] === "all")) {
                let allCmcSiteIdsStr = await localStorage.getItem("DROPDOWN_ALL_CMCSITE_IDS");
                let cmcSiteIdsArray = [];
                allCmcSiteIdsStr = allCmcSiteIdsStr ? allCmcSiteIdsStr : await getAllCMCSiteIds();
                if (allCmcSiteIdsStr) {
                    cmcSiteIdsArray = allCmcSiteIdsStr.split(',');
                    cmcSiteIdsArray.splice(cmcSiteIdsArray.indexOf("all"), 1);
                }
                parameters.filter.cmcSiteId = { "inq": cmcSiteIdsArray };
            }
            await getFilterQuery(parameters, undefined);
            let data = await this.lbDatasource.getAll(TYPES.COMPOUND_MANUFACTUREDS, { ...parameters }, this.dataURL);
            data.map((item, i) => {
                data[i]["createdBy"] = getCreatedBy(item);
                data[i]["manufacturingDate"] = getFormatedDateWithTimezoneOffset(item.manufacturingDate);
                data[i]["studyNameTooltip"] = item.principalInvestigator ? `PI : ${item.principalInvestigator}` : null;
                data[i]["subjectIdList"] = (item.subjectList && item.subjectList.length > 0) ? item.subjectList.map((sub) => {
                    return sub.subjectid;
                }).join(', ') : null;
                data[i]["adverseEvent"] = (item.subjectList && item.subjectList.length > 0) && item.subjectList.find(item => item.adverseevent) ? getlocaleText("{{common.yes}}") : getlocaleText("{{common.no}}");

            });
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(TYPES.COMPOUND_MANUFACTUREDS, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }
    async getSignedURL(parameters: any): Promise<any> {
        await this.initializeConfig();
        try {
            if (parameters.filterParams && (!parameters.filterParams.cmcSiteId || parameters.filterParams["cmcSiteId"] === "all")) {
                let allCmcSiteIdsStr = await localStorage.getItem("DROPDOWN_ALL_CMCSITE_IDS");
                let cmcSiteIdsArray = [];
                allCmcSiteIdsStr = allCmcSiteIdsStr ? allCmcSiteIdsStr : await getAllCMCSiteIds();
                if (allCmcSiteIdsStr) {
                    cmcSiteIdsArray = allCmcSiteIdsStr.split(',');
                    cmcSiteIdsArray.splice(cmcSiteIdsArray.indexOf("all"), 1);
                }
                parameters.filterParams.cmcSiteId = { "inq": cmcSiteIdsArray };
            }

            if (parameters?.filterParams?.cmcSiteId === "all") {
                delete parameters.filterParams.cmcSiteId
            }
            if (parameters?.filterParams?.compoundId === "all") {
                delete parameters.filterParams.compoundId
            }
            let params = { "filter": parameters.filterParams };
            await getFilterQuery(params, undefined);
            let data = await this.lbDatasource.getAll_export(`${TYPES.COMPOUND_MANUFACTUREDS}/export?userRole=${getLoginUserRoleName()}`, { ...params, "orderBy": parameters.filterParams.orderBy }, this.dataURL);
            return data;
        } catch (e) {
            throw new Error(`Failed to download the file, Reason: ${e.message}`);
        }
    }

    async downlodFile(url: string): Promise<any> {
        try {
            await this.lbDatasource.downlodFile(url);
        } catch (e) {
            throw new Error(`Failed to download the document`);
        }
    }

}