import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { ItsyButton, ItsyLabel } from '@itsy-ui/common';
import { getlocaleText } from "@itsy-ui/utils";
import { LOCALSTORAGE_NAME } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    if (col.name === "subjectIdList") {
        const subjectIds = cellValue["subjectIdList"];
        if (subjectIds && subjectIds.length > 35) {
            return <>
                <span >{`${subjectIds.substring(0, 35)}`}</span>
                <span className="ellipis" title={subjectIds}>...</span>
            </>
        } else {
            return <span>{`${subjectIds}`}</span>
        }
    }
}

function doCompoundMfgSubUsagesGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        let filterText = {};
        let selectedfilter_msite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE);
        if (selectedfilter_msite) {
            filterText["cmcSiteId"] = selectedfilter_msite;
        }
        let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
        if (selectedfilter_compound && selectedfilter_compound !== "all") {
            filterText["compoundId"] = selectedfilter_compound;
        }
        // to remove compoundid in payload when not needed
        if (filterText["compoundId"] && selectedfilter_compound && selectedfilter_compound === "all") {
            delete filterText["compoundId"];
        }

        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            filterText: filterText,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const compoundMfgSubUsagesGrid: ICustomStateMachineData = {
    name: "compoundMfgUsagesGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onCompoundMfgSubUsagesGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCompoundMfgSubUsagesGridInit: (evt) => dispatch(doCompoundMfgSubUsagesGridInit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "compoundMfgSubUsages"
}, compoundMfgSubUsagesGrid);