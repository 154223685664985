import { getItemFromLocalStorage, verifyJWT, setItemInLocalStorage, getlocaleText } from "@itsy-ui/utils";
import { WidgetsFactory, DataLoaderFactory, IConfigLoader, IDataSourceLake } from "@itsy-ui/core";
import { DocumentType, Roles, UPLOAD_STATUS } from "./constant";
import { getFormatedDate, getFormatedDateOfBirth, getFormatedDICOMAvailableDate } from "../common/helpers";
import axios from "axios";
import { getDefaultLang, getServerUrl, getShowICPLicense } from "./utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;

export const getUrlParamValue = (paramName) => {
	paramName = paramName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
	const results = regex.exec(window.location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export function validateAuthToken() {
	let isAuthenticated = false;
	const lsInfo = getItemFromLocalStorage("FV_TENANT_INFO");
	if (lsInfo) {
		isAuthenticated = verifyJWT(lsInfo.token);
	}
	return isAuthenticated;
}

export const getLocalization = async () => {
	const userInfo = getItemFromLocalStorage("FV_TENANT_INFO");
	const configDataLoader = dataLoader.getLoader<IConfigLoader>("config");
	const cfg = await configDataLoader.getConfig();
	const defaultLang = await getDefaultLang();
	const locale = userInfo.preferredLang ? userInfo.preferredLang : defaultLang ? defaultLang : "en"
	getLanguageJson(locale)
	return locale;
};

export function getLanguageJson(language) {
	const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"];
	const schemaProvider = dataLoader.getLoader("appSchemaProvider");
	const message = schemaProvider.getSchemaSync(`/app/locale/${language}`)
	const localeStateProvider = WidgetsFactory.instance.services["appStateProvider"];
	localeStateProvider.setLocaleData(message);
	return message
}

export async function getLoginUserSites() {
	const userdetails = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
	const userDataSource: any = dataLoader.getLoader<IDataSourceLake>("userDataSource");
	const data = userdetails && userdetails.id ? await userDataSource.getObject("users", userdetails.id) : null;
	const userSites = data && data.site ? data.site : [];
	return userSites;
}
export async function getLoginUserCmcSites() {
	const userdetails = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
	const userDataSource: any = dataLoader.getLoader<IDataSourceLake>("userDataSource");
	const data = userdetails && userdetails.id ? await userDataSource.getObject("users", userdetails.id) : null;
	const userCmcSites = data && data.cmcSiteList ? data.cmcSiteList : [];
	return userCmcSites;
}
export async function getAllSiteIds() {
	let allSiteIdsStr = await localStorage.getItem("DROPDOWN_ALL_SITE_IDS");
	if (allSiteIdsStr) {
		return allSiteIdsStr;
	}
	const siteDataSource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
	const sitesData = await siteDataSource.getAll(`all_sites_dropdown`, {})
	if (sitesData !== undefined && sitesData.length > 0) {
		allSiteIdsStr = sitesData.map((ele, index) => { return ele.id }).join(',');
		return allSiteIdsStr;
	}
	return null;
}
export async function getAllCMCSiteIds() {
	// let allCmcSiteIdsStr = await localStorage.getItem("DROPDOWN_ALL_CMCSITE_IDS");
	// if (allCmcSiteIdsStr) {
	// 	return allCmcSiteIdsStr;
	// }
	const cmcsiteDataSource: any = dataLoader.getLoader<IDataSourceLake>("cmcsiteDataSource");
	const cmcsitesData = await cmcsiteDataSource.getAll(`filter_cmcsites_dropdown`, {})
	if (cmcsitesData !== undefined && cmcsitesData.length > 0) {
		let allCmcSiteIdsStr = cmcsitesData.map((ele, index) => { return ele.id }).join(',');
		// console.log(allCmcSiteIdsStr + " ---->cmcsiteDs cmcsites");
		return allCmcSiteIdsStr;
	}
	return null;
}

export async function getFilterQuery(parameters, siteIdArg) {
	let filterwhereParams = {};
	let siteId;
	if (siteIdArg === "" || siteIdArg === null || siteIdArg === "all") {
		siteId = await getAllSiteIds();
		let siteIdsArray = siteId.split(',');
		siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
		filterwhereParams["siteIds"] = { "inq": siteIdsArray };
	}
	else {
		siteId = siteIdArg;
	}
	if (siteId === "" || siteId === null || siteId === "all") {
		if (siteId.indexOf("all") > -1) {
			siteId = await getAllSiteIds();
			let siteIdsArray = siteId.split(',');
			siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
			filterwhereParams["siteIds"] = { "inq": siteIdsArray };
		} else {
			filterwhereParams["siteIds"] = { "inq": [siteId] };
		}
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("siteId") && parameters.filter["siteId"] && parameters.filter["siteId"] !== "all") {
		if (parameters.filter["siteId"].indexOf("all") > -1) {
			const siteIdsStr = parameters.filter["siteId"];
			let siteIdsArray = siteIdsStr.split(',');
			siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
			filterwhereParams["siteIds"] = { "inq": siteIdsArray };
		} else {
			filterwhereParams["siteIds"] = { "inq": [parameters.filter["siteId"]] };
		}
	}

	if (filterwhereParams["siteIds"] && filterwhereParams["siteIds"].inq && filterwhereParams["siteIds"].inq.length == 0) {
		delete filterwhereParams["siteIds"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("classificationId") && parameters.filter["classificationId"] && parameters.filter["classificationId"] !== "all") {
		filterwhereParams["classificationId"] = parameters.filter["classificationId"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("subjectId") && parameters.filter["subjectId"]) {
		filterwhereParams["subjectId"] = parameters.filter["subjectId"];
		delete filterwhereParams["siteIds"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("studyId") && parameters.filter["studyId"] && parameters.filter["studyId"] !== "all") {
		filterwhereParams["studyId"] = parameters.filter["studyId"];
		delete filterwhereParams["siteIds"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("lastDays") && parameters.filter["lastDays"] && parameters.filter["lastDays"] !== "all") {
		const lastDays = parseInt(parameters.filter["lastDays"]);
		if (!isNaN(lastDays)) {
			filterwhereParams["lastDays"] = lastDays;
		}
		else if (parameters.filter["customDateRange"] && parameters.filter["customDateRange"].startDate && parameters.filter["customDateRange"].endDate) {
			const fromDate = getFormatedDate(parameters.filter["customDateRange"].startDate);
			const toDate = getFormatedDate(parameters.filter["customDateRange"].endDate);
			if (fromDate != 'Invalid date') {
				filterwhereParams["fromDate"] = fromDate;
			}
			if (toDate != 'Invalid date') {
				filterwhereParams["toDate"] = toDate;
			}
		}
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("visitDate") && parameters.filter["visitDate"]) {
		filterwhereParams["visitDate"] = parameters.filter["visitDate"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("cmcSiteId") && parameters.filter["cmcSiteId"] && parameters.filter["cmcSiteId"] !== "all") {
		filterwhereParams["cmcSiteId"] = parameters.filter["cmcSiteId"];
		delete filterwhereParams["siteIds"];
	}

	if (filterwhereParams["cmcSiteId"] && filterwhereParams["cmcSiteId"].inq && filterwhereParams["cmcSiteId"].inq.length == 0) {
		delete filterwhereParams["cmcSiteId"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("batchId") && parameters.filter["batchId"]) {
		filterwhereParams["batchId"] = parameters.filter["batchId"];
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("compoundId") && parameters.filter["compoundId"] && parameters.filter["compoundId"] !== "all") {
		filterwhereParams["compoundId"] = parameters.filter["compoundId"];
	}
	if (parameters.filter && parameters.filter.hasOwnProperty("manufacturingDate") && parameters.filter["manufacturingDate"]) {
		filterwhereParams["manufacturingDate"] = parameters.filter["manufacturingDate"];
	}

	//form and to date settime for mfg date
	const manufacturingFromDate = parameters.filter !== undefined && parameters.filter["manufacturingFromDate"] !== undefined ? `${parameters.filter["manufacturingFromDate"]} 00:00:00` : undefined
	const manufacturingToDate = parameters.filter !== undefined && parameters.filter["manufacturingToDate"] !== undefined ? `${parameters.filter["manufacturingToDate"]} 23:59:59` : undefined
	//from and to date filter for mfg date
	if (parameters.filter !== undefined && manufacturingFromDate !== undefined && manufacturingToDate !== undefined) {
		filterwhereParams["manufacturingDate"] = { "between": [manufacturingFromDate, manufacturingToDate] }
	} else if (parameters.filter !== undefined && manufacturingFromDate && manufacturingToDate === undefined) {
		filterwhereParams["manufacturingDate"] = { "gte": manufacturingFromDate }
	} else if (parameters.filter !== undefined && manufacturingFromDate === undefined && manufacturingToDate !== undefined) {
		filterwhereParams["manufacturingDate"] = { "lte": manufacturingToDate }
	}

	if (parameters.filter && parameters.filter.hasOwnProperty("sharingConsent") && parameters.filter["sharingConsent"] !== "all") {
		filterwhereParams["sharingConsent"] = parameters.filter["sharingConsent"];
	}
	parameters.filter = {
		"where": {
			...filterwhereParams
		}
	}
	return parameters;
}

export function getFilterQueryAll(parameters) {
	if (parameters.filter && parameters.filter.hasOwnProperty("subjectId") && parameters.filterColumns.length > 0 && parameters.filter["subjectId"][0].value !== "all") {
		parameters.filter = {
			"where": {
				"studyId": parameters.filter.subjectId[0].value
			}
		}
	}
	return parameters;

}

export function scoringValue(record) {
	let item = {};
	if (record["mmse"] && record["mmse"].checked) {
		const mmse = {
			"checked": record["mmse"].checked,
			"min": parseInt(record["mmse"].min),
			"max": parseInt(record["mmse"].max)
		}
		item = {
			mmse
		}
	} if (record["cdr"] && record["cdr"].checked) {
		const cdr = {
			"checked": record["cdr"].checked,
			"min": parseInt(record["cdr"].min),
			"max": parseInt(record["cdr"].max)
		}
		item = {
			...item,
			cdr
		}
	} if (record["adas_cog"] && record["adas_cog"].checked) {
		const adas_cog = {
			"checked": record["adas_cog"].checked,
			"min": parseInt(record["adas_cog"].min),
			"max": parseInt(record["adas_cog"].max)
		}
		item = {
			...item,
			adas_cog
		}
	} if (record["updrs"] && record["updrs"].checked) {
		const updrs = {
			"checked": record["updrs"].checked,
			"min": parseInt(record["updrs"].min),
			"max": parseInt(record["updrs"].max)
		}
		item = {
			...item,
			updrs
		}
	} if (record["fab"] && record["fab"].checked) {
		const fab = {
			"checked": record["fab"].checked,
			"min": parseInt(record["fab"].min),
			"max": parseInt(record["fab"].max)
		}
		item = {
			...item,
			fab
		}
	} if (record["psprs"] && record["psprs"].checked) {
		const psprs = {
			"checked": record["psprs"].checked,
			"min": parseInt(record["psprs"].min),
			"max": parseInt(record["psprs"].max)
		}
		item = {
			...item,
			psprs
		}
	}
	return item;
}

export function getVisitInfo(record) {
	let validItem = {};
	let scanDate, age, weight;
	if (record["scanDateSelector"] !== "all") {
		if (record["beforeDate"] !== undefined && record["afterDate"] !== undefined && record["beforeDate"] !== "" && record["afterDate"] !== "") {
			scanDate = {
				"min": getFormatedDate(record["beforeDate"]),
				"max": getFormatedDate(record["afterDate"])
			}
		} else if (record["beforeDate"] !== undefined && record["beforeDate"] !== "" && record["scanDateSelector"] === "before") {
			scanDate = {
				"min": getFormatedDate(record["beforeDate"])
			}
		} else if (record["afterDate"] !== undefined && record["afterDate"] !== "" && record["scanDateSelector"] === "after") {
			scanDate = {
				"max": getFormatedDate(record["afterDate"])
			}
		}
		if (record['scanDateSelectorFor'] == "uploadDate") {
			validItem = {
				"uploadDate": { ...scanDate }
			}
		}
		else if (record['scanDateSelectorFor'] == "visitDate") {
			validItem = {
				"visitDate": { ...scanDate }
			}
		}
	} if (record["weightSelector"] !== "all") {
		if (record["weightLower"] && record["weightHigher"] && record["weightSelector"] === "between") {
			weight = {
				"min": record["weightLower"],
				"max": record["weightHigher"]
			}
		} else if (record["weightLower"] && record["weightSelector"] === "less") {
			weight = {
				"min": record["weightLower"]
			}
		} else if (record["weightHigher"] && record["weightSelector"] === "greater") {
			weight = {
				"max": record["weightHigher"]
			}
		}
		validItem = {
			...validItem,
			weight
		}
	} if (record["ageSelector"] !== "all") {
		if (record["ageLower"] && record["ageHigher"] && record["ageSelector"] === "between") {
			age = {
				"min": record["ageLower"],
				"max": record["ageHigher"]
			}
		} else if (record["ageLower"] && record["ageSelector"] === "older") {
			age = {
				"min": record["ageLower"]
			}
		} else if (record["ageHigher"] && record["ageSelector"] === "younger") {
			age = {
				"max": record["ageHigher"]
			}
		}
		validItem = {
			...validItem,
			age
		}
	}
	return validItem;
}

export function getAnalystItem(record) {
	const scoringInfo = scoringValue(record)
	const visitInfo = getVisitInfo(record)
	//subject part
	delete record["beforeDate"];
	delete record["afterDate"];
	delete record["ageLower"];
	delete record["ageHigher"];
	delete record["weightLower"];
	delete record["weightHigher"];
	delete record["scanDateSelector"];
	delete record["ageSelector"];
	delete record["weightSelector"];
	delete record["uploadDate"]; delete record["visitDate"];
	delete record["age"];
	delete record["weight"];
	//scoring part
	record["mmse"] && (record["mmse"].checked !== true || record["mmse"].checked === "" || record["mmse"].checked === undefined) && delete record["mmse"];
	record["cdr"] && (record["cdr"].checked !== true || record["cdr"].checked === "" || record["cdr"].checked === undefined) && delete record["cdr"];
	record["adas_cog"] && (record["adas_cog"].checked !== true || record["adas_cog"].checked === "" || record["adas_cog"].checked === undefined) && delete record["adas_cog"];
	record["updrs"] && (record["updrs"].checked !== true || record["updrs"].checked === "" || record["updrs"].checked === undefined) && delete record["updrs"];
	record["fab"] && (record["fab"].checked !== true || record["fab"].checked === "" || record["fab"].checked === undefined) && delete record["fab"];
	record["psprs"] && (record["psprs"].checked !== true || record["psprs"].checked === "" || record["psprs"].checked === undefined) && delete record["psprs"]
	//dicom part
	record.hasOwnProperty("apn_pet_dicom_available") && (record["apn_pet_dicom_available"] === "" || record["apn_pet_dicom_available"]["checkBox"] === "" || record["apn_pet_dicom_available"]["checkBox"] === false || record["apn_pet_dicom_available"]["result"] === "") && delete record["apn_pet_dicom_available"];
	record.hasOwnProperty("pet_dicom_available") && (record["pet_dicom_available"] === "" || record["pet_dicom_available"]["checkBox"] === "" || record["pet_dicom_available"]["checkBox"] === false || record["pet_dicom_available"]["result"] === "") && delete record["pet_dicom_available"];
	const recordData = {
		...record,
		...scoringInfo,
		...visitInfo
	}
	if (recordData !== undefined && Object.keys(recordData).length !== 0) {
		setItemInLocalStorage("ANALYST_SEARCH_REQUEST", JSON.stringify(recordData));
	} else if (recordData !== undefined && Object.keys(recordData).length === 0) {
		localStorage.removeItem("ANALYST_SEARCH_REQUEST")
	}
	return recordData;
}

export async function getFilterSearchResult(parameters) {
	let getBody = getItemFromLocalStorage("ANALYST_SEARCH_REQUEST");
	if (getBody.siteIds && getBody.siteIds[0].length > 0) {
		if (getBody.siteIds[0].indexOf("all") > -1) {
			getBody.siteIds[0] = await getAllSiteIds();
			let siteIdsArray = getBody.siteIds[0].split(',');
			// siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
			getBody["siteIds"] = siteIdsArray;
		}
	}
	parameters.filter = {
		"where": {
			"customFilter": {
				...getBody
			}
		}
	}
	return parameters;
}


function str2bytes(str) {
	var bytes = new Uint8Array(str.length);
	for (var i = 0; i < str.length; i++) {
		bytes[i] = str.charCodeAt(i);
	}
	return bytes;
}
export async function downloadResulAttachement(data, filename) {
	const blob = new Blob([str2bytes(data)], { type: "application/zip" });
	const downloadUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.setAttribute("href", downloadUrl);
	link.setAttribute("download", filename);
	link.click();
	return true
}


export function getScanDateValue(record) {
	if (record['min'] && record['max']) {
		return "between"
	} else if (record['min']) {
		return "before"
	} else if (record['max']) {
		return "after"
	} else {
		return "between"
	}
}
export function getWeightValue(record) {
	if (record['min'] && record['max']) {
		return "between"
	} else if (record['min']) {
		return "less"
	} else if (record['max']) {
		return "greater"
	} else {
		return "between"
	}
}
export function getAgeValue(record) {
	if (record['min'] && record['max']) {
		return "between"
	} else if (record['max']) {
		return "younger"
	} else if (record['min']) {
		return "older"
	} else {
		return "between"
	}
}

export function getBatchesUploadList(data) {
	const role = JSON.parse(localStorage.getItem("SELECTED_ROLE"))
	const record = {
		"id": data["id"],
		"cmcSiteId": data["cmcSiteId"] ? data["cmcSiteId"] : "",
		"cmcSiteName": data["cmc_site_name"] ? data["cmc_site_name"] : "",
		"compoundId": data["compoundId"] ? data["compoundId"] : "",
		"compoundName": data["compound_name"] ? data["compound_name"] : "",
		"manufacturingDate": data["manufacturingDate"],
		"batchId": data["batchId"],
		"editDocumentFor": data["editDocumentFor"]
	}
	const analyticRawDataFiles = [];
	const analyticRawDataFilesname = [];
	try {
		data.batchesUploads.map((doc, i) => {
			switch (doc.documentType) {
				case DocumentType.COA_UPLOAD:
					record["CoAfileName"] = doc.contentStreamFileName;
					record["CoAFileDetails"] = {
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType
					};
					break;
				case DocumentType.EXEC_BATCH_REC_UPLOAD:
					record["ExecBatchRecfileName"] = doc.contentStreamFileName;
					record["ExecBatchRecFileDetails"] = {
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType
					};
					break;
				case DocumentType.ANALYTIC_RAW_DATA_UPLOAD:

					// record["AnalyticRawDatafileName"] = doc.contentStreamFileName;
					// record["AnalyticRawDataFileDetails"] = 	{
					// 		id: doc.id,
					// 		status: doc.status,
					// 		documentType: doc.documentType
					// 	 };
					// record["AnalyticRawDataFileDetails"] = 	{
					// 	id: doc.id,
					// 	status: doc.status,
					// 	documentType: doc.documentType
					// };
					// Create the object for AnalyticRawDatafileName
					const analyticRawDataFilesnameObj = {
						id: doc.id,
						fileName: doc.contentStreamFileName
					}
					const analyticRawDataFileObj = {
						fileName: doc.contentStreamFileName,
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType,
					};
					analyticRawDataFilesname.push(analyticRawDataFilesnameObj)
					analyticRawDataFiles.push(analyticRawDataFileObj); // Push to the array

					break;
				default:
					break;
			}
		});
	}
	catch (e) { }
	record["AnalyticRawDatafileName"] = analyticRawDataFilesname
	record["AnalyticRawDataFileDetails"] = analyticRawDataFiles; // Assign the array to the record


	return record;
}

export function getuploadList(data) {
	const userdetails = JSON.parse(localStorage.FV_TENANT_INFO);
	const record = {
		"id": data["id"],
		"siteId": data.subject && data.subject.study && data.subject.study.sites && data.subject.study.sites.length > 0 ? data.subject.study.sites[0].id : "",
		"siteName": data.subject && data.subject.study && data.subject.study.sites && data.subject.study.sites.length > 0 ? data.subject.study.sites[0].siteName : "",  //userdetails["site"]["siteName"],
		"studyId": data.subject ? data.subject.studyId : undefined, //data.subject.study.studyName
		"subjectId": data.subjectId,
		"subject": data.subject,
		"classificationId": data.classificationId,
		"gender": data.subject ? data.subject.gender : undefined,
		"weight": data.weight != getlocaleText("{{common.none}}") && (data.weight != null || data.weight != undefined) ? Number(data.weight) : undefined,
		"bmi": data.bmi != getlocaleText("{{common.none}}") && (data.bmi != null || data.bmi != undefined) ? Number(data.bmi) : undefined,
		"visitDate": getFormatedDate(data.visitDate),
		"onsetAge": data.subject?.birthDate ? getAge(new Date(data.visitDate), new Date(data.subject.birthDate)) : undefined,
		"birthDate": data.subject && data.subject["birthDate"] ? getFormatedDateOfBirth(data.subject["birthDate"]) : undefined,
		"mmse": data.mmse != getlocaleText("{{common.none}}") && (data.mmse != null || data.mmse != undefined) ? Number(data.mmse) : undefined,
		"cdr": data.cdr != getlocaleText("{{common.none}}") && (data.cdr != null || data.cdr != undefined) ? Number(data.cdr) : undefined,
		"adas_cog": data.adas_cog != getlocaleText("{{common.none}}") && (data.adas_cog != null || data.adas_cog != undefined) ? Number(data.adas_cog) : undefined,
		"fab": data.fab != getlocaleText("{{common.none}}") && (data.fab != null || data.fab != undefined) ? Number(data.fab) : undefined,
		"psprs": data.psprs != getlocaleText("{{common.none}}") && (data.psprs != null || data.psprs != undefined) ? Number(data.psprs) : undefined,
		"updrs": data.updrs != getlocaleText("{{common.none}}") && (data.updrs != null || data.updrs != undefined) ? Number(data.updrs) : undefined,
		"pet_dicom_available": data.pet_dicom_available ? (data.pet_dicom_available === "No" || data.pet_dicom_available === "N/A") ? "false" : "true" : data.pet_dicom_available === false ? 'false' : undefined,
		"apn_pet_dicom_available": data.apn_pet_dicom_available ? (data.apn_pet_dicom_available === "No" || data.apn_pet_dicom_available === "N/A") ? "false" : "true" : data.apn_pet_dicom_available === false ? 'false' : undefined,
		"adverse_events": data["adverse_events"] === "Yes" ? ["adverseevent"] : [],
		"rawImage": data["rawImage"] === "Yes" || data["rawImage"] === null || data["rawImage"] === undefined ? ["rawImage"] : [],
		"batchId": data["compound_manufactured_id"],
		"batchIdValue": data.compoundManufactured ? data.compoundManufactured.batchId : "N/A",
		"compoundId": data.compoundManufactured ? data.compoundManufactured.compoundId : "",
		"compoundName": data.compoundManufactured && data.compoundManufactured.compounds ? data.compoundManufactured.compounds.compound_name : "N/A"
	};
	try {
		data.uploads.map((doc, i) => {
			switch (doc.documentType) {
				case DocumentType.CRF:
					record["CRFfileName"] = doc.contentStreamFileName;
					record["CRFFileDetails"] = {
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType
					};
					record["CRFComment"] = doc.comments && doc.comments.length > 0 ? doc.comments[0]["comments"] : "";
					break;
				case DocumentType.MRI:
					record["MRIfileName"] = doc.contentStreamFileName;
					record["MRIFileDetails"] = {
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType
					};
					record["MRIComment"] = doc.comments && doc.comments.length > 0 ? doc.comments[0]["comments"] : "";
					break;
				case DocumentType.PET:
					record["PETfileName"] = doc.contentStreamFileName;
					record["PETFileDetails"] = {
						id: doc.id,
						status: doc.status,
						documentType: doc.documentType
					};
					record["PETComment"] = doc.comments && doc.comments.length > 0 ? doc.comments[0]["comments"] : "";
					break;
				default:
					break;
			}
		});
	}
	catch (e) { }

	return record;
}

export function getColorForDocType(status) {
	let className_color = "link-primary";
	switch (status) {
		case UPLOAD_STATUS.ACCEPTED: className_color = "link-success"; break;
		case UPLOAD_STATUS.REJECTED: className_color = "link-danger"; break;
		case UPLOAD_STATUS.REJECT_CONFIRMED: className_color = "link-danger"; break;
		default: className_color = "link-primary"; break;
	}
	return className_color;
}

export async function getSubjectValue(datasource, typeId, value) {
	const result = await datasource.getObject(typeId, value);
	return result
}

export function setCurrentRole(userInfo) {
	if (!userInfo) {
		userInfo = JSON.parse(localStorage.FV_TENANT_INFO);
	}
	if (userInfo.roles && userInfo.roles.length == 1) {
		localStorage.setItem("SELECTED_ROLE", JSON.stringify(userInfo.roles[0]));
		return userInfo.roles[0];
	}
	else return null;
}

export function getInitialNavigationUrl(roleName) {
	switch (roleName) {
		case Roles.SUPER_ADMIN:
		case Roles.ADMIN: {
			return "/users";
		}
		case Roles.UPLOAD_USER: {
			return "/uploads";
		}
		case Roles.QC_USER: {
			return "/dashboard";
		}
		case Roles.ANALYST: {
			return "/analystsearch";
		}
		case Roles.CMC_UPLOAD_USER: {
			return "/batches";
		}
		case Roles.CMC_ADMIN: {
			return "/cmc-sites"
		}
		default: {
			return "/welcome";
		}
	}
}

export async function getLogo() {
	const tenantURL = await getServerUrl();
	return await axios.get(`${tenantURL}/logo`)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			console.log(error);
			return "";
		});
}

export const isShowICPlicense = async () => {
	const showICPLicense = await getShowICPLicense();
	return showICPLicense !== undefined ? (showICPLicense === 'true' ? true : false) : false;
};

export async function getQCuserRoleId() {
	const rolesDataSource: any = dataLoader.getLoader<IDataSourceLake>("rolesDataSource");
	const result = await rolesDataSource.getAll("roles");
	const qcuserRole = result.find(ele => ele.role_name === Roles.QC_USER)
	return qcuserRole ? qcuserRole.id : null;
}

export async function getAllRoleId() {
	const rolesDataSource: any = dataLoader.getLoader<IDataSourceLake>("rolesDataSource");
	const result = await rolesDataSource.getAll("roles");
	const RoleId = [];
	result.map(ele => {
		RoleId[ele.role_name] = ele.id;
	})
	return RoleId ? RoleId : null;
}

export function getAge(baseDate: Date, birthdate: Date) {
	const year_difference = (baseDate.getFullYear() - birthdate.getFullYear());
	const one_or_zero = (baseDate.getMonth() < birthdate.getMonth()) ||
		(baseDate.getMonth() === birthdate.getMonth() &&
			baseDate.getDate() < birthdate.getDate());
	const age = year_difference - Number(one_or_zero);
	return age;
}

export async function getApiVersion() {
	const rolesDataSource: any = dataLoader.getLoader<IDataSourceLake>("rolesDataSource");
	const result = await rolesDataSource.getAll("version");
	return result ? result.version : "2.0.24";
} 