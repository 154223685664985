import { getHeaders, getFormatedVisitDate, getFormatedDateWithTimezoneOffset } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { getFilterSearchResult, getAnalystItem } from "../utils/helpers";
import { getLocalStorageItem } from "./lbHelpers";
import { getlocaleText, setItemInLocalStorage } from "@itsy-ui/utils";
import axios from "axios";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import streamSaver from "streamsaver";

import streamBrowserify from "stream-browserify";
import { getServerUrl } from "../utils/utils";

const moment = require("moment");
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class AnalystVisitDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        await getFilterSearchResult(parameters);
        try {
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            const analystData = getLocalStorageItem("ANALYST_SEARCH_REQUEST")
            if (analystData !== undefined) {
                if (typeId === "search") {
                    data.map((item, i) => {
                        data[i].pet_dicom_available = item.pet_dicom_available === null ? "N/A" : (item.pet_dicom_available === false ? "No" : "Yes");
                        data[i].apn_pet_dicom_available = item.apn_pet_dicom_available === null ? "N/A" : (item.apn_pet_dicom_available === false ? "No" : "Yes");
                        data[i].visitdate = getFormatedVisitDate(item["visitdate"]);
                        data[i].uploaddate = getFormatedVisitDate(item["uploaddate"]);
                        data[i].weight = item.weight === "-1" || item.weight === null || item.weight === undefined ? getlocaleText("{{common.none}}") : item.weight;
                        data[i].mmse = item.mmse === "-1" || item.mmse === null || item.mmse === undefined ? getlocaleText("{{common.none}}") : item.mmse;
                        data[i].cdr = item.cdr === "-1" || item.cdr === null || item.cdr === undefined ? getlocaleText("{{common.none}}") : item.cdr;
                        data[i].adas_cog = item.adas_cog === "-1" || item.adas_cog === null || item.adas_cog === undefined ? getlocaleText("{{common.none}}") : item.adas_cog;
                        data[i].fab = item.fab === "-1" || item.fab === null || item.fab === undefined ? getlocaleText("{{common.none}}") : item.fab;
                        data[i].psprs = item.psprs === "-1" || item.psprs === null || item.psprs === undefined ? getlocaleText("{{common.none}}") : item.psprs;
                        data[i].updrs = item.updrs === "-1" || item.updrs === null || item.updrs === undefined ? getlocaleText("{{common.none}}") : item.updrs;
                        data[i]["sharingConsent"] = item.sharingconsent ? getlocaleText("{{common.allowed}}") : getlocaleText("{{common.notAllowed}}");
                    });
                }
                const analystRecord = getLocalStorageItem("ANALYST_SEARCH_REQUEST")
                const getAnalystItem = {
                    ...analystRecord,
                    "count": data.totalRecordsCount,
                }
                setItemInLocalStorage("ANALYST_SEARCH_REQUEST", JSON.stringify(getAnalystItem));
                return data
            }
            return [];
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data.record, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async analystUpsert(typeId: any, recordArg: any,): Promise<any> {
        try {
            let recordData = getAnalystItem(recordArg);
            recordData = this.updateRecordWithSiteIds(recordArg);

            const data = {
                "maxItems": 10,
                "filter": {
                    "where": {
                        "customFilter": {
                            ...recordData
                        }
                    }
                }
            }
            return await this.getAll(typeId, data,);
        } catch (e) {
            throw new Error(`Failed to Search:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async downlodFilesSignedUrls(typeId: any, recordArg: any): Promise<any> {
        this.hideProgressBar();

        let record = this.updateRecordWithSiteIds(recordArg);
        eventTransition({
            type: "SHOW_INDICATOR",
            loadingMessage: "{{Download.loading_message}}"
        })

        let url;
        try {
            const data = {
                "customFilter": {
                    ...record
                }
            }
            if (data) {
                const queryJson: any = (JSON.stringify(data));
                if (queryJson) {
                    url = `${this.dataURL}/${typeId}/?where=${queryJson}`;
                }
            }
            const headers = this.headers;
            this.showAndUpdateProgressBar(1);
            const fileurls = await axios.get(url, { headers });
            this.showAndUpdateProgressBar(10);

            // Get all signed urls 
            let urls = [];
            const fileurlsData = fileurls.data;
            if (fileurlsData && fileurlsData.signedUrls.length > 0) {
                for (let i = 0; i < fileurlsData.signedUrls.length; i++) {
                    urls.push({ signedUrl: fileurlsData.signedUrls[i]["signedURL"], fileName: fileurlsData.signedUrls[i]["fileName"] });
                }
            }

            // Get pdf signed url
            if (fileurlsData && fileurlsData.pdfid) {
                let pdfSignedUrl;
                while (true) {
                    if (pdfSignedUrl !== undefined) {
                        const url = { signedUrl: pdfSignedUrl, fileName: "SearchResults.PDF" };
                        urls.push(url);
                        break;
                    } else {
                        pdfSignedUrl = await this.getSignedURLpdf(fileurls.data);
                    }
                }
            } else {
                console.error(`PDF id is empty - ${fileurls.data}`);
                this.hideProgressBar();
            }

            // download all files from signed urls
            if (urls && urls.length > 0) {
                var zip = new JSZip()
                const fileName = `SearchResult_${moment(new Date()).format("YYYY-MM-DD_HH-mm-ss")}_${Math.floor(100000 + Math.random() * 900000)}.zip`;
                let writeStream = streamSaver.createWriteStream(fileName).getWriter();
                let eachFileTotalPercentage = Math.round(80 / urls.length);
                let totalCompletedPercentage = 10;
                for (let index = 0; index < urls.length; index++) {
                    await this.fetchFileUsingSignedUrl(zip, urls[index], index, eachFileTotalPercentage, totalCompletedPercentage);
                    totalCompletedPercentage += eachFileTotalPercentage;
                }

                // zip.generateAsync({ type: 'blob' }).then(zipFile => {
                //     this.showAndUpdateProgressBar(100);
                //     setTimeout(() => {
                //         this.hideProgressBar();
                //     }, 1000 * 3);
                //     return saveAs(zipFile, fileName);
                // });

                zip.generateInternalStream({
                    type: "blob", compression: "DEFLATE",
                    compressionOptions: {
                        // 1: Best speed
                        // ...
                        // 9: Best compression
                        level: 5,
                    },
                    streamFiles: true,
                })
                    .on('data', (data, metadata) => {
                        writeStream.write(data);
                    })
                    .on('error', (err) => {
                        writeStream.abort(err);
                        console.error(err);
                        this.hideProgressBar();
                    })
                    .on('end', () => {
                        writeStream.close();
                        this.showAndUpdateProgressBar(100);
                        setTimeout(() => {
                            this.hideProgressBar();
                        }, 1000 * 3);
                    })
                    .resume()
            }
        } catch (e) {
            this.hideProgressBar();
            throw new Error(`Failed to Download Result:, Reason: ${e.message}`);
        }
    }

    async fetchFileUsingSignedUrl(zip, url, index, eachFileTotalPercentage, totalCompletedPercentage) {
        let receivedLength = 0; // received that many bytes at the moment
        let progressValue = 0;
        let contentLength, fileNameWithSize;
        const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let p = fetch(url.signedUrl).then(res => {
            contentLength = +res.headers.get('Content-Length');
            let fSize = contentLength;
            let i = 0;
            while (fSize > 900) { fSize /= 1024; i++; }
            const fileSize = (Math.round(fSize * 100) / 100) + ' ' + fSExt[i];
            fileNameWithSize = url.fileName ? `${url.fileName} (${fileSize})` : "";
            return res.body.getReader();
        })
        let rs = streamBrowserify.Readable();
        rs._read = () => {
            p.then(reader => reader.read().then(({ value, done }) => {
                if (!done) {
                    receivedLength += value.length;
                    const currentFileProgress = Math.round((receivedLength / contentLength) * (eachFileTotalPercentage));
                    progressValue = totalCompletedPercentage + currentFileProgress;
                    localStorage.setItem("fileName", fileNameWithSize);
                    this.showAndUpdateProgressBar(progressValue);
                }
                return rs.push(done ? null : Buffer.from(value));
            })
            )
        }
        zip.file(url.fileName, rs);
    }

    async getSignedURLpdf(fileurlsData: any): Promise<any> {
        try {
            const headers = this.headers;
            const pdf_url = `${this.dataURL}/analyst-pdf/${fileurlsData.pdfid}`;
            const resultData = await axios.get(pdf_url, { headers });
            if (resultData.status === 200) {
                const data = resultData.data;
                if (data.signedUrl && (data.signedUrl !== null || data.signedUrl !== undefined)) {
                    const pdfSignedUrl = data.signedUrl;
                    return pdfSignedUrl;
                }
            } else {
                this.hideProgressBar();
                throw new Error(`Failed to download, Reason: ${JSON.stringify(resultData)}`);
            }
        } catch (e) {
            this.hideProgressBar();
            throw new Error(`Failed to get Signed URL:, Reason: ${e.message}`);
        }
    }

    updateRecordWithSiteIds(recordData: any) {
        if (recordData && recordData["siteIds"] && recordData["siteIds"].length === 1 && recordData["siteIds"][0].indexOf("all") > -1) {
            let siteIdsArray = recordData["siteIds"][0].split(',');
            // siteIdsArray.splice(siteIdsArray.indexOf("all"), 1);
            recordData["siteIds"] = siteIdsArray;
        }
        return recordData;
    }

    showAndUpdateProgressBar(progressValue) {
        eventTransition({
            type: "UPDATE_INDICATOR",
            loadingMessage: "{{fileDownloading.msg}}",
            showProgressBar: true,
            progressValue: progressValue,
        });
        localStorage.setItem("progressValue", progressValue.toString());
    }

    hideProgressBar() {
        eventTransition({
            type: "HIDE_INDICATOR",
        });
        localStorage.removeItem("progressValue");
        localStorage.removeItem("fileName");
    }

}