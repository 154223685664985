import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions } from "@itsy-ui/core";
import { getInitialNavigationUrl } from "../utils/helpers";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const setCurrentRoleCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    localStorage.removeItem("DROPDOWN_ALL_SITE_IDS"); // reset the site ids on roles change
    //add/update selected role to local storage
    localStorage.setItem("SELECTED_ROLE", JSON.stringify(data.currentObject.contextParams));
    // initialNavigation
    const url = getInitialNavigationUrl(data.currentObject.contextParams.role_name);
    transition({
      type: "NAVIGATE_URL",
      url: url,
      persistCurrentUrl: false,
      shouldReplace: true
    });
    window.location.reload();
  },
};

commandManager.registerCommand("setCurrentRole", {}, setCurrentRoleCommand);

const currentRoleCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    const url = getInitialNavigationUrl(data.role_name);
    transition({
      type: "NAVIGATE_URL",
      url: url,
      persistCurrentUrl: false,
      shouldReplace: true
    });
    // window.location.reload();
  },
};

commandManager.registerCommand("currentRole_defaultpage", {}, currentRoleCommand);