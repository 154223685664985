import React from 'react';
import ReactDOM from 'react-dom';
import "@itsy-ui/core";
import "@itsy-ui/common";
import "@itsy-ui/auth";
import "@itsy-ui/app";
import "@itsy-ui/data";
import "@itsy-ui/feedback";
import "@itsy-ui/form";
import "@itsy-ui/layout";
import "@itsy-ui/navigation";
import "@itsy-ui/common";
import "@itsy-ui/freshui-theme";
import "@itsy-ui/freshui-theme/dist/es/itsy-ui-freshui-theme.esm.css";
import "@itsy-ui/freshui-theme/dist/styles/fonts/icons.css";
import './index.css';
import "./style/apn-app.css";
import "./style/themeing.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ItsyLoadingOverlay as LoadingOverlay } from '@itsy-ui/feedback';
import { ItsyPopup as Popup } from '@itsy-ui/navigation';
import { ItsyNotification as Notification } from '@itsy-ui/feedback';
import { ItsyDrawer as Drawer } from '@itsy-ui/navigation';
import "@apn/components";
import axios from 'axios';
import { eventTransition } from '@itsy-ui/core';
import { ErrorType } from './utils/constant';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      if (error.response.data.error.message.includes("Invalid")) {
        throw error;
      }
      eventTransition({
        type: "HIDE_POPUP",
      });
      eventTransition({
        type: "HIDE_DRAWER",
      });
      eventTransition({
        type: "NAVIGATE_URL",
        url: `/error?type=${ErrorType.ERROR_403}`,
      });
      window.location.reload();
    }
    else if (error.response.status === 401) {
      // Unauthorized or already logged out
      window.location.reload();
    }
    else {
      throw error;
    }
  }
);

ReactDOM.render(
  <React.StrictMode>
    <LoadingOverlay key="app-loading-overlay" />
    <App />
    <Popup key="app-popup" />
    <Notification key="app-notification" />
    <Drawer key="app-drawer" />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
