import { eventTransition, StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';
import { getUrlParamValue } from "@itsy-ui/utils";
import { useEffect } from "react";
import { ErrorType } from "../utils/constant";

const Formschema = {
    typeId: "enroll",
    formSchemaId: "enroll",
    controlID: "enroll",
    submitButtonText: "{{common.Submit}}"
}

const AppEnrollTitle = {
    "title": "{{enroll_form_title}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}

const EnrollPage = (props) => {
    const id = getUrlParamValue("id");
    useEffect(() => {
        const timer = setTimeout(() => {
            if (id === null || id === undefined || id === "") {
                eventTransition({
                    type: "NAVIGATE_URL",
                    url: `/error?type=${ErrorType.INVALID_URL}`,
                });
            }
        }, 100);
        return () => clearTimeout(timer);
    }, []);
    return (<ItsyContainer className="apn-enroll-container">
        <ItsyColumn className="apn-enroll-title">
            <ItsyLabel schema={AppEnrollTitle} />
        </ItsyColumn>
        <ItsyColumn className="apn-enroll-form">
            <StateManagerContext.Provider key="enroll" value={{ contextPath: { "id": "enroll" } }}>
                <ItsyForm type="form" schema={Formschema} />
            </StateManagerContext.Provider>
        </ItsyColumn>
    </ItsyContainer>)
}

export default EnrollPage;