import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory, eventTransition } from "@itsy-ui/core";
import { DataLoaderFactory, ICommandManager } from "@itsy-ui/core";
import * as React from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Row, Col } from "react-bootstrap";
import { getlocaleText } from "@itsy-ui/utils";

class MultipleLinkControl extends React.Component<IWidgetControlProps, {}> {

	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}

	onLinkClick(id: any) {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
		const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");
		//	return async (_, dispatch: any, transition: any) => {
            const batchUploadData = {
                "id" : id
			}
		let cmd = commandManager.getCommand(fieldSchema.commandName, {});
		try {
			cmd!.execute(batchUploadData, eventTransition);
		} catch (e) {
			console.log("Command execution error: ", e);
		}
		//}
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const { title, displayName, commandName, contextParams, alignText, propertyType } = fieldSchema;
		const alignstyle = alignText !== undefined && alignText === "right" ? "flex-end" : alignText === "center" ? "center" : "flex-start";
		const customClass = fieldSchema.className ? fieldSchema.className : "";
		const customStyle = fieldSchema.style ? fieldSchema.style : {};
		if (displayName && controlProps.value) { //dynamic link
			return (
				<Form.Group className={customClass} style={customStyle} controlId={fieldSchema.id}>
					<Form.Label className="text-label">{displayName}</Form.Label>
					<div style={{ "justifyContent": alignstyle, ...customStyle }} className={`input-textbox link-container ${customClass}`}>
						<span>{fieldSchema.prefixText ? getlocaleText(fieldSchema.prefixText) : null}</span>
						<Card.Link onClick={this.onLinkClick.bind(this)}>
							{controlProps.value ? controlProps.value : null}
						</Card.Link>
					</div>
				</Form.Group>
			);
		}
		else if (title) { //to have some static link click
			
			return (
				<div style={{ "justifyContent": alignstyle, ...customStyle }} className={`link-container ${customClass}`}>
					<span>{fieldSchema.prefixText ? getlocaleText(fieldSchema.prefixText) : null}</span>
					<Card.Link onClick={this.onLinkClick.bind(this)}>
						{getlocaleText(title)}
					</Card.Link>
				</div>
			);
		}
		else if (controlProps.value) {
            // const modifiedFileNames = controlProps.value.map((fileName) => "File: " + fileName);

			return (
               
            <>
                {controlProps.value .map((modifiedFileName, index) => (
					
              <div style={{ "justifyContent": alignstyle, ...customStyle }} className={`link-container ${customClass}`}>
					<span>{fieldSchema.prefixText ? getlocaleText(fieldSchema.prefixText) : null}</span>
					<Card.Link onClick={() =>this.onLinkClick(modifiedFileName.id)}>
						{modifiedFileName.fileName}<br></br>
					</Card.Link>
				</div>
                ))}
                </>
			
			);
		}
		else return null;
	}
}

MultipleLinkControl['displayName'] = 'MultipleLinkControl';

WidgetsFactory.instance.registerFactory(MultipleLinkControl);
WidgetsFactory.instance.registerControls({
	multiplelinkedText: 'MultipleLinkControl'
});