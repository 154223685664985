import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { DROPDOWN, LOCALSTORAGE_NAME, TYPES } from "../../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doCmsuFilterbarBeforeChange(value: {}, formValues: {}) {
    return async (getState, dispatch, transition) => {
        const { formValues, metadata } = getState();
        let currentFormValues;
        const updatedValues = {
            ...value,
        };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (Object.keys(value).toString() === "cmcSiteId") {
            let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
            if (!value["cmcSiteId"].includes("all")) {
                const compoundDataSource: any = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
                const getCompounds = await compoundDataSource.getAll(DROPDOWN.ALL_CMC_SITES_COMPOUNDS, { cmcSiteId: value["cmcSiteId"] });
                const compoundsOption = [getCompounds];
                updatedPropDefs["compoundId"].metadata.data = compoundsOption[0];
                updatedPropDefs["compoundId"].metadata.isLocal = true;
            }
            else if (value["cmcSiteId"].includes("all")) {
                const compoundsOption = [{
                    "compound_name": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }];
                updatedPropDefs["compoundId"].metadata.data = compoundsOption;
                updatedPropDefs["compoundId"].metadata.isLocal = true;
            }
            const compoundId = selectedfilter_compound && updatedPropDefs["compoundId"].metadata.data.map(item => { return item.id }).includes(selectedfilter_compound) ? selectedfilter_compound : "all";
            currentFormValues = { ...formValues, ...updatedValues, compoundId };
        } else {
            currentFormValues = { ...formValues, ...updatedValues };
        }
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            controlID: "compoundMfgSubUsage_filter",
            strict: true,
        });
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            controlID: "compoundMfgSubUsage_filter",
            strict: true,
        });
        let searchValue = {
            cmcSiteId: currentFormValues["cmcSiteId"], manufacturingFromDate: currentFormValues["manufacturingFromDate"],
            manufacturingToDate: currentFormValues["manufacturingToDate"], compoundId: currentFormValues["compoundId"]
        }
        transition({
            controlID: "compoundMfgSubUsages",
            strict: true,
            type: "GRID_FILTER",
            searchValue: searchValue
        });
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE, currentFormValues["cmcSiteId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE, currentFormValues["compoundId"]);
    }
}

function doCmsuFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_msite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE);
        let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
        const customeobjectData = {
            ...objectData,
            "cmcSiteId": selectedfilter_msite ? selectedfilter_msite : "all",
            "compoundId": selectedfilter_compound ? selectedfilter_compound : "all",
            "manufacturingFromDate": undefined,
            "manufacturingToDate": undefined
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });
    }
}


const subjectFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onCmsuFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onCmsuFilterbarBeforeChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCmsuFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doCmsuFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onCmsuFilterbarBeforeChange: ({ value, formValues }) => dispatch(doCmsuFilterbarBeforeChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "compoundMfgSubUsage_filter"
}, subjectFormHandler);