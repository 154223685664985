import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions, IDataSourceLake } from "@itsy-ui/core";
import { ACTIONS } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils"
import { getItemFromLocalStorage, setItemInLocalStorage } from "@itsy-ui/utils";
import { TYPES, Roles } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

//subject Create
const SubjectCreateCommands: CommandOptions<any> = {

   
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data, transition) => {
    
        let uploadFormData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "upload_form",
            formSchemaId: "upload_form",
            typeId: "upload",
            onData: (formData) => {
                uploadFormData = formData["formValues"];
            },
        });
        const siteId = uploadFormData ? uploadFormData["siteId"] : "";
        const studyId = uploadFormData ? uploadFormData["studyId"] : "";
        const subjectId = uploadFormData && uploadFormData["subjectId"] ? uploadFormData["subjectId"]["invalidSubjectId"] : "";
       
       
         const drawerData = {
            "title": "{{subject.DrawerTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "35%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "subjects",
                    "formSchemaId": "subject_create",
                    "record": { "siteId": siteId, "studyId": studyId, "subjectId": subjectId },
                    controlID: "subject_create",
                    "isModal": true,
                },
            },
            onOk: () => {
                transition({
                    type: "FORM_SUBMIT_CLICK",
                    controlID: "subject_create",
                    strict: true,
                });
            },
            onCancel: () => {
                transition({
                    type: "HIDE_DRAWER",
                    controlID: "subject_create",
                });
            },
            customState: {
                contextPath: {
                    "typeId": "subjects",
                    "formSchemaId": "subject_create",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};

commandManager.registerCommand("subject_added", {}, SubjectCreateCommands);

//Subject Edit
const SubjectEditCommands: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{subject.EditDrawerTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "35%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "subjects",
                    "formSchemaId": "subject_edit",
                     "isModal": true,
                    "record": data,
                    controlID: "subject_edit",
                },
            },
            onOk: () => {
                transition({
                    type: "FORM_SUBMIT_CLICK",
                    controlID: "subject_edit",
                    strict: true,
                });
            },
            onCancel: () => {
                transition({
                    type: "HIDE_DRAWER",
                    controlID: "subject_edit",
                });
            },
            customState: {
                contextPath: {
                    "typeId": "subjects",
                    "formSchemaId": "subject_edit",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};

commandManager.registerCommand("Edit_Subject", {}, SubjectEditCommands);

//batch subject Create
const BatchSubjectCreateCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data, transition) => {
        let uploadFormData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "upload_form",
            formSchemaId: "upload_form",
            typeId: "upload",
            onData: (formData) => {
                uploadFormData = formData["formValues"];
            },
        });
        const siteId = uploadFormData ? uploadFormData["siteId"] : "";
        const studyId = uploadFormData ? uploadFormData["studyId"] : "";
        const subjectId = uploadFormData && uploadFormData["subjectId"] ? uploadFormData["subjectId"]["invalidSubjectId"] : "";
      
        const drawerData = {
            "title": "{{batchSubject.DrawerTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "subjects",
                    "formSchemaId": "batchSubject_create",
                    "record": { "siteId": siteId, "studyId": studyId, "subjectId": subjectId },
                  
                    controlID: "batchSubject_create",
                    "isModal": true,
                },
            },
            onOk: () => {
                transition({
                    type: "FORM_SUBMIT_CLICK",
                    controlID: "batchSubject_create",
                    strict: true,
                });
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "subjects",
                    "formSchemaId": "batchSubject_create",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};

commandManager.registerCommand("batchSubject_create", {}, BatchSubjectCreateCommand);

//Particular Subject Complete Delete

async function deleteSubjectData(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
    data["objectTypeId"] = TYPES.SUBJECT;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: ACTIONS.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{subject.deleteSuccessMsg}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const subjectRemovedCommand: CommandOptions<any> = {
    canExecute: (event: any) => {
        const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
        return selectedRoles && selectedRoles.role_name === Roles.SUPER_ADMIN ? true : false;
    },
    execute: async (data, transition) => {
        const popupData = {
            title: getlocaleText("{{subject.deleteTitle}}"),
            popupMessage: getlocaleText("{{subject.deleteMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                deleteSubjectData(data, transition)
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
            onCancel: {
                type: ACTIONS.HIDE_POPUP
            },
        };
        transition({
            type: ACTIONS.SHOW_POPUP,
            event: popupData,
        });
    },
};
commandManager.registerCommand("DeleteSubject", {}, subjectRemovedCommand);

//get upload details by subject id
const subjectGetUploadCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{Dashboard.DrawerTittle}}",
            "okText": getlocaleText("{{common.close}}"),
            "showCancel": false,
            width: "60%",
            "controlSchema": {
                "name": "SubjectUpload Data",
                "properties": {
                    "ui:widget": "itsy:page",
                    "pageId": "viewSubjectUploadsPage",
                    "queryParams": {
                        "subjectId": data.id
                    },
                }
            },
            onOKTransition: {
                type: ACTIONS.HIDE_DRAWER,
            }
        }

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("subject_getupload", {}, subjectGetUploadCommand);

//download subjectBatchTemplate command
const downloadSubjectBatchTemplate: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
        try {
            const file = await datasource.downlodSubjectBatchTemplate();
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    },
};

commandManager.registerCommand("download_subjectBatchTemplate", {}, downloadSubjectBatchTemplate);

//export xlsx file command
const subjectexportFileXlsx: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
        try {

            transition({
                type: "SHOW_INDICATOR",
                loadingMessage: "{{Download.loading_message}}",
            });
            let filterParams, defaultVisibleColumnsParams;
            transition({
                type: "FORM_GET_STATE",
                strict: true,
                controlID: "subject_filter",
                onData: (formData) => {
                    filterParams = formData["formValues"];
                },
            });
            transition({
                type: "GRID_GET_STATE",
                strict: true,
                controlID: "subjects",
                onData: (data) => {
                    filterParams["orderBy"] = data.viewAttributes.orderBy;
                    defaultVisibleColumnsParams = data.viewAttributes.defaultVisibleColumns;
                },
            });
            // to getSignedURL for xls file to export
            const data = await datasource.getSignedURL({ filterParams }, defaultVisibleColumnsParams);
            if (data && data["signedUrl"]) {
                await datasource.downlodFile(data["signedUrl"]);
                transition({
                    type: "HIDE_INDICATOR",
                });
            } else {
                transition({
                    type: "HIDE_INDICATOR",
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
                    metadata: { "delay": 5 * 1000 },
                    action: { "showCloseIcon": false },
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage) {
                errorMessage = "Error in network call";
            }
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
                metadata: { "delay": 5 * 1000 },
                action: { "showCloseIcon": false },
            });
        }
    },
};

commandManager.registerCommand("subjectexport_xlsx", {}, subjectexportFileXlsx);
