import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions } from "@itsy-ui/core";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const forgotpasswordCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    transition({
      type: "NAVIGATE_URL",
      url: "/forgotpassword",
    });
  },
};

commandManager.registerCommand("forgotpassword", {}, forgotpasswordCommand);

//Go back to Login page
const backToLoginCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    if (data && data.contextParams && data.contextParams.indexOf("resetLS") !== -1) {
      const recordsperpage = localStorage.getItem("recordsperpage");
      const selectedColumsToShow = localStorage.getItem("selectedColumsToShow");
      localStorage.clear();
      if (recordsperpage) {
        localStorage.setItem("recordsperpage", recordsperpage);
      }
      if (selectedColumsToShow) {
        localStorage.setItem("selectedColumsToShow", selectedColumsToShow);
      }

    }
    transition({
      type: "NAVIGATE_URL",
      url: "/login",
    });
    // window.location.reload(); - sometimes loading prev page instead of login
  },
};

commandManager.registerCommand("back_login", {}, backToLoginCommand);

//Forgot password form submit command
const forgotPasswordSubmitCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    transition({
      type: "FORM_SUBMIT_CLICK",
    });
  },
};

commandManager.registerCommand("forgot_submit", {}, forgotPasswordSubmitCommand);
