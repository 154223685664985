import { CommandOptions, DataLoaderFactory, ICommandManager, WidgetsFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, LOCALSTORAGE_NAME, TYPES } from "../utils/constant";
import { getBatchesUploadList } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

//Add New batch upload command

const NewBatchUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		let selectedfilter_msite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE);
		let record = {};
		if (selectedfilter_msite && selectedfilter_msite !== "all") {
			record["cmcSiteId"] = selectedfilter_msite;
		}
		const drawerData = {
			"title": "{{batch.newBatch}}",
			"okText": getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '40%',
			className: "batch-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "batches",
					"formSchemaId": "batches_form",
					"isModal": true,
					"record": record,
					controlID: "batches_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "batches_form",
					strict: true,
				});
			},
			onCancel: () => {
				transition({
					type: "HIDE_DRAWER",
					controlID: "batches_form",
				});
			},
			customState: {
				contextPath: {
					formSchemaId: "batches_form",
					typeId: "batches"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("batch_added", {}, NewBatchUploadCommand);

//edit batch upload command

const EditBatchUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getBatchesUploadList(data);
		const drawerData = {
			"title": "{{batch.editBatch}}",
			"okText": getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '40%',
			className: "batch-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "batches",
					"formSchemaId": "batches_edit_form",
					"isModal": true,
					"record": record,
					controlID: "batches_edit_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "batches_edit_form",
					strict: true,
				});
			},
			onCancel: () => {
				transition({
					type: "HIDE_DRAWER",
					controlID: "batches_edit_form",
				});
			},
			customState: {
				contextPath: {
					formSchemaId: "batches_edit_form",
					typeId: "batches"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("editBatchUploaded", {}, EditBatchUploadCommand);

// add More BatchUpload

const AddMoreBatchUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getBatchesUploadList(data);
		const drawerData = {
			"title": "{{batch.addMoreBatch}}",
			"okText": getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '40%',
			className: "batch-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "batches",
					"formSchemaId": "batches_uploadMore_form",
					"isModal": true,
					"record": record,
					controlID: "batches_uploadMore_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "batches_uploadMore_form",
					strict: true,
				});
			},
			onCancel: () => {
				transition({
					type: "HIDE_DRAWER",
					controlID: "batches_uploadMore_form",
				});
			},
			customState: {
				contextPath: {
					formSchemaId: "batches_uploadMore_form",
					typeId: "batches"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("addMoreBatchUpload", {}, AddMoreBatchUploadCommand);

//download file command
const downloadFile: CommandOptions<any> = {
	canExecute: (batchUploadData: any) => {
		return true;
	},
	execute: async (batchUploadData: any, transition: any) => {
		const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");

	//	 console.log(datasource , "datasource to getSignedURl" ,batchUploadData )
		try {
			const data = await datasource.getSignedURL({ id: batchUploadData.id }, { id: TYPES.BATCHES_UPLOADS });
			if (data && data["signedUrl"]) {
				await datasource.downlodFile(data["signedUrl"]);
			} else {
				transition({
					type: "SHOW_NOTIFICATION",
					message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
					metadata: { "delay": 5 * 1000 },
					action: { "showCloseIcon": false },
				});
			}
		} catch (e) {
			const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
			let errorMessage = response ? response.message || response.errorMessage : null;
			if (!errorMessage) {
				errorMessage = "Error in network call";
			}
			transition({
				type: "SHOW_NOTIFICATION",
				message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
				metadata: { "delay": 5 * 1000 },
				action: { "showCloseIcon": false },
			});
		}
	},
};

commandManager.registerCommand("batch_dowload_file", {}, downloadFile);