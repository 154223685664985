import { WidgetsFactory, CommandOptions, DataLoaderFactory, ICommandManager, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText, getItemFromLocalStorage } from "@itsy-ui/utils"
import { ACTIONS, TYPES, Roles } from "../utils/constant"
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const compundAddCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{compound.addprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "compounds",
                    "formSchemaId": "compound_create_form",
                    "isModal": true,
                    controlID: "compound_create_form"
                },
            },
            onOKTransition: {
                type: "FORM_SUBMIT_CLICK",
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "compounds",
                    "formSchemaId": "compound_create_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("compound_add", {}, compundAddCommand);


//Compound EDIT COMMANDS

const compoundEditCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{compound.editprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "compounds",
                    "isModal": true,
                    "formSchemaId": "compound_edit_form",
                    "record": data,
                    "validateOnBlur": true,
                    controlID: "compound_edit_form"
                },
            },
            onOKTransition: {
                type: "FORM_SUBMIT_CLICK",
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "compounds",
                    "formSchemaId": "compound_edit_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("compound_edit", {}, compoundEditCommand);


//Particular compound Complete Delete

async function deleteCompoundData(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
    data["objectTypeId"] = TYPES.COMPOUNDS;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: ACTIONS.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{compound.deleteSuccessMsg}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const compoundRemoveCommand: CommandOptions<any> = {
    canExecute: (event: any) => {
        const userInfo = getItemFromLocalStorage("FV_TENANT_INFO")
        return userInfo && userInfo.roles.length > 0 && userInfo.roles[0].role_name === Roles.SUPER_ADMIN ? true : false;
    },
    execute: async (data, transition) => {
        const popupData = {
            title: getlocaleText("{{compound.deleteTitle}}"),
            popupMessage: getlocaleText("{{compound.deleteMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                deleteCompoundData(data, transition)
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
            onCancel: {
                type: ACTIONS.HIDE_POPUP
            },
        };
        transition({
            type: ACTIONS.SHOW_POPUP,
            event: popupData,
        });
    },
};
commandManager.registerCommand("DeleteCompound", {}, compoundRemoveCommand);