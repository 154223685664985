import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import { getlocaleText } from "@itsy-ui/utils";
import { Row, Col } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getFormLabelClassName } from "../../helper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const moment = require("moment");
const DateFormat = "MM-DD-YYYY";

class InputDateRange extends React.Component {
    constructor(props) {
        super(props);
        const controlProps = this.getControlSchemaProperties();
        this.state = {
            ipDate: controlProps.value
        }
    }
    getControlSchemaProperties() {
        const registry = getDefaultRegistry();
        const { definitions } = registry;
        const schema = retrieveSchema(this.props.schema, definitions);
        return schema;
    }

    isDateinRange(date, minDate, maxDate) {
        let isAfterDate = true, isBeforeDate = true;
        if (minDate) {
            isAfterDate = moment(date).isSameOrAfter(minDate);
        }
        if (maxDate) {
            isBeforeDate = moment(date).isSameOrBefore(maxDate);
        }
        if (isAfterDate && isBeforeDate) {
            return true
        }
        return false;
    }

    pad(x) {
        return x < 10 ? `0${x}` : x;
    }
    padYear(y) {
        return y.toLocaleString('en-US', {
            minimumIntegerDigits: 4,
            useGrouping: false
        })
    }

    getISODate(value) {
        const date = new Date(value);
        if (date.toString() !== "Invalid Date") {
            return this.padYear(date.getFullYear()) +
                '-' + this.pad(date.getMonth() + 1) +
                '-' + this.pad(date.getDate());
        }
        else {
            return '';
        }
    }

    generateDate = (userDate, operation) => {
        const controlProps = this.getControlSchemaProperties();
        const { customMinDate, customMaxDate, dateSpan } = controlProps.fieldSchema;
        let currentDate = new Date();
        const checkValidDate = new Date(userDate);
        if (checkValidDate.toString() === "Invalid Date") {
            switch (userDate) {
                case "tomorrow":
                    currentDate.setDate(currentDate.getDate() + 1);
                    break;
                case "yesterday":
                    currentDate.setDate(currentDate.getDate() - 1);
                    break;
                case "custom": //both max & min have custom date
                    if (operation === "MIN") {
                        currentDate = new Date(customMinDate);
                    } else {
                        currentDate = new Date(customMaxDate);
                    }
                    break;
                case "dateSpan":
                    currentDate.setDate(currentDate.getDate() + dateSpan);
                    break;
                default:
                    break;
            }
        } else {
            return this.getISODate(userDate);
        }
        return currentDate;
    }

    getMinMaxDate = () => {
        const controlProps = this.getControlSchemaProperties();
        const { minDate, maxDate } = controlProps.fieldSchema;
        const generatedMinDate = this.generateDate(minDate, "MIN");
        const generatedMaxDate = this.generateDate(maxDate, "MAX");
        return { minDate: generatedMinDate, maxDate: generatedMaxDate };
    }

    handleRangeDate = (event, picker) => {
        const controlProps = this.getControlSchemaProperties();
        if (picker.chosenLabel === "All") {
            picker.element.val("");
            controlProps.handleChange(undefined, null);
        }
        else {
            picker.element.val(
                picker.startDate.format(DateFormat) +
                " - " +
                picker.endDate.format(DateFormat)
            );
            const DateRanger = {
                startDate: picker.startDate,
                endDate: picker.endDate,
            }
            const { fieldSchema } = controlProps;
            controlProps.handleChange(undefined, DateRanger);
        }
    }

    handleCancelRangeDate = (event, picker) => {
        picker.element.val("");
        // const DateRanger = {
        //     startDate: 'null',
        //     endDate: 'null',
        // }
        const controlProps = this.getControlSchemaProperties();
        controlProps.handleChange(undefined, null);
    };

    handleRangeCallback = (start, end) => {
        // const DateRanger = {
        //     startDate: start,
        //     endDate: end,
        // }
        // const controlProps = this.getControlSchemaProperties();
        // const { fieldSchema } = controlProps;
        // controlProps.handleChange(undefined, DateRanger);
    }

    renderDateRange = (controlProps, fieldSchema, className, style) => {
        const { _minDate, _maxDate } = this.getMinMaxDate();
        const minDate = fieldSchema.minDate && (typeof fieldSchema.minDate !== "object") ? fieldSchema.minDate.replaceAll("-", "/") : fieldSchema.minDate;
        const maxDate = fieldSchema.maxDate && (typeof fieldSchema.maxDate !== "object") ? fieldSchema.maxDate.replaceAll("-", "/") : fieldSchema.maxDate;
        const isRequiredClassName = fieldSchema.required ? "required-field" : "";
        return <>{
            fieldSchema.readOnly ? <div className={className} style={style}><label className={fieldSchema.readOnly ? "text-label label-disabled" : "text-label"} tabIndex={0} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</label>
                <Form.Control
                    id={fieldSchema.id}
                    key={fieldSchema.id}
                    tabIndex={0}
                    aria-label={controlProps.value ? controlProps.value : " "}
                    value={controlProps.value ? controlProps.value : " "}
                    disabled={fieldSchema.readOnly}
                /></div>
                : <Form.Group className="date-time-contianer date-range" controlId={fieldSchema.id}>
                    <Form.Label className={getFormLabelClassName(fieldSchema)} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</Form.Label>
                    <div className="date-component">
                        <DateRangePicker
                            key={fieldSchema.id}
                            initialSettings={{
                                autoUpdateInput: false,
                                locale: {
                                    format: fieldSchema.dateFormat ? fieldSchema.dateFormat.toUpperCase() : "MM/DD/YYYY",
                                    cancelLabel: "Clear",
                                },
                                // ranges: {
                                //     "All": '',
                                //     "Last 7 Days": [
                                //         moment().subtract(6, "days").toDate(),
                                //         moment().toDate(),
                                //     ],
                                //     "Last 15 Days": [
                                //         moment().subtract(14, "days").toDate(),
                                //         moment().toDate(),
                                //     ],
                                //     "Last 30 Days": [
                                //         moment().subtract(29, "days").toDate(),
                                //         moment().toDate(),
                                //     ],
                                // },
                                minDate: minDate ? new Date(minDate) : _minDate,
                                maxDate: maxDate ? new Date(maxDate) : _maxDate,
                                //  startDate: controlProps.value && controlProps.value.startDate ? this.getISODate(controlProps.value.startDate) : '',
                                //   endDate: controlProps.value && controlProps.value.endDate ? this.getISODate(controlProps.value.endDate) : '',
                            }}
                            onApply={this.handleRangeDate.bind(this)}
                            onCancel={this.handleCancelRangeDate.bind(this)}
                            onCallback={this.handleRangeCallback.bind(this)}
                        >
                            <Form.Control type="text" className={`form-control ${isRequiredClassName}`} placeholder={"Please select custom range here"} />
                        </DateRangePicker>
                        {fieldSchema.helptext && <Form.Text aria-label={getlocaleText(fieldSchema.helptext)}>{getlocaleText(fieldSchema.helptext)}</Form.Text>}
                    </div>
                </Form.Group>
        }
        </>;

    }

    render() {
        const controlProps = this.getControlSchemaProperties();
        const { fieldSchema } = controlProps;
        const customClass = fieldSchema.className ? fieldSchema.className : "";
        const customStyle = fieldSchema.style ? fieldSchema.style : {};
        let formatedDateValue;
        if (controlProps.value && new Date(controlProps.value) && !(controlProps.value === "")) {
            formatedDateValue = moment(new Date(controlProps.value)).format(DateFormat)
        }

        if (controlProps.isReadonly || fieldSchema.readOnly) {
            return (
                <Form.Group className={`date-time-contianer ${customClass}`} style={customStyle} controlId={fieldSchema.id}>
                    <label tabIndex={0} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</label>
                    <div className="date-component">
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder={"Please select custom range here"}
                                aria-label={formatedDateValue}
                                disabled={true}
                                tabIndex={0} />
                        </div>
                    </div>
                </Form.Group>
            );
        }
        if (fieldSchema.visibility === undefined || fieldSchema.visibility) {
            if (fieldSchema.dateRange) {
                return this.renderDateRange(controlProps, fieldSchema, customClass, customStyle);
            }
            else { return null; }
        } else { return null; }
    }
}

InputDateRange['displayName'] = 'InputDateRangeControl';

WidgetsFactory.instance.registerFactory(InputDateRange);
WidgetsFactory.instance.registerControls({
    apn_date_range: 'InputDateRangeControl'
});