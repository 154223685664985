import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyLabel } from '@itsy-ui/common';

const PageName = {
    "headerSize": "h10",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const PageTitle = {
    "title": "Welcome to the Aprinoia Therapeutics Clinical Archiving System",
    "headerSize": "h10",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const WelcomePage = (props) => {
    return (
        <ItsyContainer className="welcome-page">
            <ItsyRow>
                <ItsyColumn>
                    <ItsyLabel schema={PageName} />
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow>
                <ItsyColumn>
                    <ItsyLabel schema={PageTitle} />
                </ItsyColumn>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default WelcomePage;