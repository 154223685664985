import { getHeaders, getCreatedBy, getFormatedDateTimeWithTimezoneOffset, getFormatedVisitDate } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { getLoginUserSites, getFilterQuery } from "../utils/helpers";
import { getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class QCDashboardDataSource {

    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    // not in use currently 
    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        const sites = await getLoginUserSites()
        const siteId = sites && sites.length > 0 ? sites[0]["id"] : null;
        await getFilterQuery(parameters, siteId)
        try {
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            for (var i = 0; i < data.length; i++) {
                const classificationData = data[i]["visit"] ? data[i]["visit"]["subject"] ? data[i]["visit"]["subject"]["classification"] ? data[i]["visit"]["subject"]["classification"] : null : null : null;
                data[i]["subjectId"] = data[i]["visit"] ? data[i]["visit"]["subject"] ? data[i]["visit"]["subject"]["subjectId"] : null : null;
                data[i]["visitDate"] = data[i]["visit"] ? getFormatedVisitDate(data[i]["visit"]["visitDate"]) : null;
                data[i]["uploadDate"] = getFormatedDateTimeWithTimezoneOffset(data[i]["createdDate"]);
                data[i]["uploadedBy"] = getCreatedBy(data[i]);
                data[i]["classification"] = classificationData ? classificationData["name"] : null;
            };
            return data

        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }

    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }
    // not in use currently 
    async upsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            return await this.update(record, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

    // not in use currently 
    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data.record, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }
}