import { ICustomStateMachineData, ICustomStateMachineProvider, WidgetsFactory } from "@itsy-ui/core";
import { DataLoaderFactory, IAppSchemaProvider } from "@itsy-ui/core";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function doSubjectsUploadGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchema: data.gridSchema,
            gridSchemaId: data.gridSchemaId,
            filterText: { "subjectId": data.queryParams.subjectId },
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            rowSelectionMode: 1,
            customDatasource: data.customDatasource
        });
    };
}

const uploadsDataGrid: ICustomStateMachineData = {
    name: "uploadsDataGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onSubjectsUploadGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectsUploadGridInit: (evt) => dispatch(doSubjectsUploadGridInit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    "typeId": "uploads",
    "gridSchemaId": "viewSubjectUploads",
    "pageId": "viewSubjectUploadsPage"
}, uploadsDataGrid);