import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory } from "@itsy-ui/core";
import { USER, ACTIONS, RegExp } from "../utils/constant"
import { getItemFromLocalStorage, getlocaleText, setItemInLocalStorage } from "@itsy-ui/utils"

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");


function doProfileFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        // to handle intermitent issue we are adding this in local storage 
        var myBooleanValue = true; // Replace with your boolean value
        localStorage.setItem("changePassword", JSON.stringify(myBooleanValue));

        const formValidationSchema = {
            ...validationSchema,
            "oldPassword": Yup.string().required(getlocaleText("{{password.required}}")),
            "newPassword": Yup.string().matches(RegExp.PASSWORD, { message: getlocaleText("{{password.errorMessage}}") })
                .required(getlocaleText("{{password.required}}"))
                .notOneOf([Yup.ref('oldPassword'), null], getlocaleText("{{password.repeated}}")),
            "confirmPassword": Yup.string().required(getlocaleText("{{password.required}}"))
                .oneOf([Yup.ref('newPassword'), null], getlocaleText("{{password.mustmatch}}"))
        };
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: formValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}


function doProfileFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
            const tenantInfo = getItemFromLocalStorage("FV_TENANT_INFO");
            delete event.values["confirmPassword"];
            const getvalues: any = {
                id: tenantInfo.id,
                ...event.values,

            }
            const data = await dataSource.post(getvalues, { id: "users/change-password" });  //id:url pathaname
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": data.message, "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const ProfileFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onProfileFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onProfileFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onProfileFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doProfileFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onProfileFormSubmit: (evt) => dispatch(doProfileFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "changedPassword",
    "formSchemaId": "changedPassword",
}, ProfileFormOnSubmit);



//profile edit form handler

function doProfileEditFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        // to handle intermitent issue we are adding this in local storage 
        var myBooleanValue = true; // Replace with your boolean value
        localStorage.setItem("editProfile", JSON.stringify(myBooleanValue));
        
        const formValidationSchema = {
            ...validationSchema,
            "name": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{name.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "email": Yup.string().matches(RegExp.EMAIL, { message: getlocaleText("{{emailId.errorMessage}}") }).required(getlocaleText("{{emailId.required}}")),
            "phoneNumber": Yup.string().matches(RegExp.PHONENUMBER, { message: getlocaleText("{{userPhoneNumber.errormessage}}") })
                .required(getlocaleText("{{userphonNumber.Required}}")),
        };
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: formValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}






function doProfileEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const tenantInfo = getItemFromLocalStorage("FV_TENANT_INFO");
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
            const userData = {
                id: event.values.id,
                name: event.values.name,
                email: event.values.email,
                phoneNumber: event.values.phoneNumber,
                preferredLang: event.values.preferredLang
            }
                const data = await datasource.update(userData, { id: USER.USERS });
                if (data) {
                    transition({
                        type: ACTIONS.HIDE_INDICATOR,
                    });
                    transition({
                        type: "FORM_AFTER_SUBMIT",
                    });
                    transition({
                        type: "LANGUAGE_CHANGE",
                        language: event.values["preferredLang"]
                    })
                    const setLocalItem = { ...tenantInfo, preferredLang: event.values["preferredLang"] }
                    setItemInLocalStorage("FV_TENANT_INFO", JSON.stringify(setLocalItem))
                    transition({
                        type: ACTIONS.HIDE_DRAWER,
                    });
                    transition({
                        type: ACTIONS.HIDE_DRAWER,
                    });
                    transition({
                        type: "SHOW_NOTIFICATION",
                        message: { "text": data.message, "type": " alert-success" },
                        metadata: { "delay": 1500 },
                        action: { "showCloseIcon": false },
                    });
                    window.location.reload();
                }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const ProfileEditFormOnSubmit: ICustomStateMachineData = {
    name: "ProfileEditFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onProfileEditFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onProfileEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onProfileEditFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doProfileEditFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onProfileEditFormSubmit: (evt) => dispatch(doProfileEditFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "profileEdit",
    typeId: "userProfile",
    controlID: "profileEdit",
}, ProfileEditFormOnSubmit);