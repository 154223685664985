import { WidgetsFactory, CommandOptions, DataLoaderFactory, ICommandManager, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText, getItemFromLocalStorage } from "@itsy-ui/utils"
import { ACTIONS, TYPES, Roles } from "../utils/constant"
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const siteAddCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{site.addprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "sites",
                    "formSchemaId": "site_create_form",
                    "isModal": true,
                    controlID: "site_create_form"
                },
            },
            onOKTransition: {
                type: "FORM_SUBMIT_CLICK",
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "sites",
                    "formSchemaId": "site_create_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("site_add", {}, siteAddCommand);


//SITE EDIT COMMANDS

const siteEditCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{site.editprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "sites",
                    "isModal": true,
                    "formSchemaId": "site_edit_form",
                    "record": data,
                    "validateOnBlur": true,
                    controlID: "site_edit_form"
                },
            },
            onOKTransition: {
                type: "FORM_SUBMIT_CLICK",
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "sites",
                    "formSchemaId": "site_edit_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("site_edit", {}, siteEditCommand);


//Particular Site Complete Delete

async function deleteSiteData(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
    data["objectTypeId"] = TYPES.SITES;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: ACTIONS.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{site.deleteSuccessMsg}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const siteRemovedCommand: CommandOptions<any> = {
    canExecute: (event: any) => {
        const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
        return selectedRoles && selectedRoles.role_name === Roles.SUPER_ADMIN ? true : false;
    },
    execute: async (data, transition) => {
        const popupData = {
            title: getlocaleText("{{site.deleteTitle}}"),
            popupMessage: getlocaleText("{{site.deleteMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                deleteSiteData(data, transition)
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
            onCancel: {
                type: ACTIONS.HIDE_POPUP
            },
        };
        transition({
            type: ACTIONS.SHOW_POPUP,
            event: popupData,
        });
    },
};
commandManager.registerCommand("DeleteSite", {}, siteRemovedCommand);