import { getItemFromLocalStorage } from "@itsy-ui/utils";
import { validateAuthToken } from "../utils/helpers";
import { DateTimeFormat, DateFormat } from '../utils/constant'
import { cleanLocalStorage } from "../utils/utils";

const moment = require("moment")

export function getHeaders() {
    const lsInfo = getItemFromLocalStorage("FV_TENANT_INFO");
    if (lsInfo) {
        if (lsInfo.token) {
            const isValidAuthToken = validateAuthToken();
            if (isValidAuthToken) {
                return {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${lsInfo.token}`,
                };
            } else {
                cleanLocalStorage();
            }
        }
    }
    return {
        "Content-Type": "application/json"
    };
}

export function getUploadHeaders() {
    const lsInfo = getItemFromLocalStorage("FV_TENANT_INFO");
    if (lsInfo) {
        if (lsInfo.token) {
            const isValidAuthToken = validateAuthToken();
            if (isValidAuthToken) {
                return {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${lsInfo.token}`,
                };
            } else {
                cleanLocalStorage();
            }
        }
    }
    return {
        "Content-Type": "multipart/form-data"
    };
}

export function getLoginUserObjectId() {
    const lsInfo = getItemFromLocalStorage("FV_TENANT_INFO");
    return lsInfo.id;
}

export function getLoginUserRoleName() {
    const currentRole = getItemFromLocalStorage("SELECTED_ROLE");
    return currentRole ? currentRole.role_name : "";
    // return lsInfo.roles[0].role_name;
}

export function getLoginUserAdminObjectId() {
    const lsInfo = getItemFromLocalStorage("FV_TENANT_INFO");
    return lsInfo.createdBy;
}

export function getCreatedBy(item: any) {
    return item["createdUser"] ? item["createdUser"]["name"] : null;
}

export function getModifiedBy(item: any) {
    return item["modifiedUser"] ? item["modifiedUser"]["name"] : null;
}

export function getFormatedDateTime(date: any) {
    if (date) {
        return date ? moment(new Date(date)).format(DateTimeFormat) : null
    }
}

export function getFormatedDate(date: any) {
    if (date) {
        return date ? moment(new Date(date)).format(DateFormat) : null
    }
}

export function getFormatedDateTimeWithTimezoneOffset(date: any) {
    if (date) {
        return date ? moment(new Date(date)).local().format(DateTimeFormat) : null;
    }
}

export function getFormatedDateWithTimezoneOffset(date: any) {
    if (date) {
        return date ? moment(new Date(date)).local().format(DateFormat) : null;
    }
}

export function getFormatedDateOfBirth(date) {
    return getFormatedDateWithTimezone(date);
}

export function getFormatedVisitDate(date) {
    return getFormatedDateWithTimezone(date);
}

export function getFormatedDICOMAvailableDate(date) {
    return getFormatedDateWithTimezone(date);
}

function getFormatedDateWithTimezone(date) {
    if (date) {
        const inputDate = new Date(date);
        const timezoneOffset = inputDate.getTimezoneOffset();
        return moment(new Date(inputDate.getTime() + (timezoneOffset * 60 * 1000))).format(DateFormat);
    }
}

export function getISOFormatedDate(date: any) {
    if (date) {
        return date ? moment(new Date(date)).format("YYYY-MM-DD") : null;
    }
}

export function getISOFormatedDateTime(date: any) {
    if (date) {
        return date ? moment(new Date(date)).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]") : null;
    }
}