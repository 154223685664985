import { StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyModal, ItsyToolbar } from '@itsy-ui/navigation';
import { isShowICPlicense } from "../utils/helpers";
import { useEffect, useState } from "react";
import { getlocaleText } from "@itsy-ui/utils";
import { cleanLocalStorage } from "../utils/utils";

const Formschema = {
    typeId: "login",
    formSchemaId: "login",
    controlID: "login",
    submitButtonText: "{{common.login}}"
}

const LoginTitle = {
    "title": "{{app_title}}",
    "headerSize": "h4",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const AppWelcomeTitle = {
    "title": "{{app_welcomeTitle}}",
    "headerSize": "h4",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "forgotpassword",
            "displayText": "{{Forgot.Password}}",
            "isPrimary": true,
            "roles": []
        },
    ],
    "align": "right",
    "span": null
};

const LoginPage = (props) => {
    const userInfo = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
    const modalSchema = {
        "width": "md",
        "title": "{{common.warning}}",
        "size": "default",
        "okText": getlocaleText("{{common.logout}}"),
        "showCancelButton": false,
        "onOk": () => {
            cleanLocalStorage();
        },
        "onOKTransition": { type: "HIDE_POPUP" },
        "controlSchema": {
            "name": "label",
            "properties": {
                "ui:widget": "label",
                "title": `${getlocaleText("{{common.sessionInUseMessage1}}")} "${userInfo ? userInfo.username : ""}" ${getlocaleText("{{common.sessionInUseMessage2}}")}`,
            }
        }
    };
    const sessionExistLogoutModal = <ItsyModal className="modal" schema={modalSchema} />

    const [showSessionBlockedModel, setShowSessionBlockedModel] = useState(false);
    const [showICPlicense, setShowICPlicense] = useState(false);
    useEffect(() => {
        if (userInfo) {
            if (userInfo.id) {
                setShowSessionBlockedModel(true);
            }
        }

        isShowICPlicense().then(response => {
            setShowICPlicense(response);
        })
            .catch(error => {
                console.log(error);
            });
    }, []);
    if (showSessionBlockedModel) {
        return sessionExistLogoutModal;
    } else {
        return (<ItsyContainer className="apn-login-container w-50">
            <ItsyColumn hAlignment="center" className="apn-login-title">
                <ItsyLabel schema={AppWelcomeTitle} />
            </ItsyColumn>
            <ItsyColumn hAlignment="center" className="apn-login-title">
                <ItsyLabel schema={LoginTitle} />
            </ItsyColumn>
            <ItsyColumn className="apn-login-form">
                <StateManagerContext.Provider key="login" value={{ contextPath: { "id": "login" } }}>
                    <ItsyForm type="form" schema={Formschema} />
                </StateManagerContext.Provider>
            </ItsyColumn>
            <ItsyColumn className="apn-login-forgot-psw">
                <StateManagerContext.Provider key="login" value={{ contextPath: { "id": "forgotpassword" } }}>
                    <ItsyToolbar schema={toolbarSchema} />
                </StateManagerContext.Provider>
            </ItsyColumn>
            <footer className="bg-light text-center text-lg-start apn-footer">
                    {showICPlicense &&
                        <a className="text-center p-3 apn-footer-link-container" target="_blank" href="https://beian.miit.gov.cn/">备案号： 苏ICP备2022027663号</a>
                    }
                    <span className="float-right">Version: 2.0.47</span>
            </footer>
        </ItsyContainer>)
    }
}

export default LoginPage;