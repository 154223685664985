import { getDefaultRegistry, getLocaleString, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import { Form } from 'react-bootstrap';
import { getlocaleText } from "@itsy-ui/utils";

class RadioBoxScoringControl extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			checkBox: false
		}
	}


	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}

	checkboxOnClick(e, value, controlProps) {
		const currentCheckBoxValue = controlProps.value !== undefined && controlProps.value !== "" ? (controlProps.value === "true" || controlProps.value === "false") ? controlProps.value : controlProps.value["checkBox"] ? controlProps.value["checkBox"] : this.state.checkBox : this.state.checkBox
		this.setState({
			checkBox: !currentCheckBoxValue,
		})
		const defaultValue = {
			"checkBox": !currentCheckBoxValue,
			"result": controlProps.value && controlProps.value.hasOwnProperty('result') ? controlProps.value["result"] : (controlProps.value === "true" || controlProps.value === "false") ? controlProps.value : "",
		}
		controlProps.handleChange(e, defaultValue)
	}

	RadioHandlechange(e, value, controlProps) {
		this.setState({
			checkBox: true
		})
		const defaultValue = {
			"checkBox": "true",
			"result": value
		}
		controlProps.handleChange(e, defaultValue)
	}

	getRadioOptions = (options, controlProps, defaultValue) => {
		let result = [];
		if (options) {
			const { handleChange } = controlProps;
			result = options.map(t => {
				if (Object.prototype.toString.call(t) === "[object String]") {
					const selected = controlProps.value && ((controlProps.value || defaultValue) === t) ? true : false;
					return <Form.Check
						type="radio"
						name={controlProps.fieldSchema.id}
						id={`${controlProps.fieldSchema.id}-${t}`}
						key={t}
						checked={selected}
						value={t}
						label={t}
						onChange={e => this.RadioHandlechange && this.RadioHandlechange(e, t, controlProps)}
					/>;
				} else {
					const selected = controlProps.value && ((controlProps.value["result"] || defaultValue) === t.key) ? true : false;
					// expecting as key value object pair
					return <Form.Check
						type="radio"
						name={controlProps.fieldSchema.id}
						id={`${controlProps.fieldSchema.id}-${t.key}`}
						key={t.key}
						value={t.key}
						label={t.value}
						disabled={controlProps.value !== undefined && controlProps.value !== "" ? controlProps.value["checkBox"] === false ? true : false : true}
						checked={selected}
						onChange={e => this.RadioHandlechange && this.RadioHandlechange(e, t.key, controlProps)}
					/>;
				}
			});
		}
		return result;
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const defaultValue = controlProps.value !== undefined && (controlProps.value["result"] === undefined || controlProps.value["result"] === "") && controlProps.value ? controlProps.value : ""
		return (
			<div>
				<div className={`input-textbox apn-scoring-control radio-control ${fieldSchema.customClass}`} style={fieldSchema.customStyle}>
					<Form.Check
						type="checkbox"
						className="apn-scoring-checkbox"
						name={fieldSchema.id}
						checked={(controlProps.value === "true" || controlProps.value === "false") || this.state.checkBox === true ? (controlProps.value && controlProps.value["checkBox"] === false ? false : true) : false}
						id={`${fieldSchema.id}`}
						key={fieldSchema.key}
						onChange={e => this.checkboxOnClick && this.checkboxOnClick(e, e.target.value, controlProps)}
					/>
					<div className="apn-scoring-radio-checkbox">
						<Form.Label className={fieldSchema.readOnly ? "text-label label-disabled" : "text-label"} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</Form.Label>
						<Form.Group controlId={fieldSchema.id}>
							<div className={controlProps.fieldSchema.mode === "vertical" ? "freshui-radio-option" : "freshui-radio-horizontal"} >
								{this.getRadioOptions(fieldSchema.value, controlProps, defaultValue)}
							</div>
							{/* {controlProps.value && (controlProps.value["result"] === "" || controlProps.value["result"] === undefined) && this.state.checkBox === true && <Form.Text key={fieldSchema.id}>{getlocaleText("{{search.radioButton}}")}</Form.Text>} */}
							{/* {controlProps.value && controlProps.value["result"] !== undefined && controlProps.value["result"] !== "" && this.state.checkBox === false && <Form.Text key={fieldSchema.id}>{getlocaleText("{{search.checkBox}}")}</Form.Text>} */}
						</Form.Group>
					</div>
				</div>
			</div>
		);
	}
}

RadioBoxScoringControl['displayName'] = 'RadioBoxScoringControl';

WidgetsFactory.instance.registerFactory(RadioBoxScoringControl);
WidgetsFactory.instance.registerControls({
	"apn_radiobox_scoring_control": 'RadioBoxScoringControl',
});