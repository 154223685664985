import { WidgetsFactory } from "@itsy-ui/core";
import { DataLoaderFactory, IAuthResult, IAuthService, IConfigLoader } from "@itsy-ui/core";
import { setItemInLocalStorage, getItemFromLocalStorage, parseJWT, verifyJWT } from "@itsy-ui/utils";
import { cleanLocalStorage, getServerUrl, refreshToken } from "../utils/utils";
import axios from "axios";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;

class AuthService implements IAuthService {
    async authenticate(username: string, password: string): Promise<IAuthResult> {
        const isOffline = window.navigator.onLine !== undefined && !window.navigator.onLine ? true : false;
        if (isOffline) {
            // allow user to continue
            return ({
                isAuthenticated: true,
                data: null,
            });
        }

        // handle online scenario 
        const configData = dataLoader.getLoader<IConfigLoader>("config");
        const cfg = await configData.getConfig();
        const tenantURL = await getServerUrl();
        // check if password is a token
        const JWT_TOKEN = parseJWT(password);
        if (JWT_TOKEN == null) {
            const body = {
                "username": username,
                "password": password,
            };
            try {
                const result = await axios.post(`${tenantURL}/users/login`, body);
                if (result.data && result.data !== null && result.status === 200) {
                    const userBTOA = btoa(`${username}:${result.data.jwt_token}`);
                    setItemInLocalStorage("USER_LOCAL", userBTOA);
                    if (!result.data.repository) {
                        result.data.repository = []
                    }
                    if (!result.data.token) {
                        result.data.token = result.data.jwt_token;
                    }
                    setItemInLocalStorage("FV_TENANT_INFO", JSON.stringify(result.data));
                    return ({
                        isAuthenticated: true,
                        data: null,
                    });
                } else {
                    return ({
                        isAuthenticated: false,
                        data: null,
                    });
                }
            } catch (e) {
                let errorMessage = e.response.data.error.message
                if (e.response.data.error.statusCode === 422) {
                    const path = e.response.data.error.details[0].path;
                    errorMessage = `${path.substring(1).charAt(0).toUpperCase()}${path.substring(2)} ${e.response.data.error.details[0].message}`
                }
                e.response.data = {
                    ...e.response.data,
                    "errorMessage": errorMessage
                }
                return ({
                    isAuthenticated: false,
                    data: e.response.data,
                });
            }
        } else {
            let isAuthenticated = verifyJWT(password);
            if (!isAuthenticated) {
                let tenantInfo = getItemFromLocalStorage("FV_TENANT_INFO");
                // verify refresh token expiry and get the new token
                // if refreshToken is expired then user has to login
                if (Object.keys(tenantInfo).length > 0 && "refresh_token" in tenantInfo) {
                    await refreshToken(tenantInfo, tenantURL);
                    // verify the token again so its not expired
                    tenantInfo = getItemFromLocalStorage("FV_TENANT_INFO");
                    isAuthenticated = verifyJWT(tenantInfo.token);
                }
            }
            return ({
                isAuthenticated: isAuthenticated,
                data: null,
            });
        }
    }

    logout(): any {
        cleanLocalStorage();
    }
}

dataLoader.registerLoader({
    "auth": new AuthService(),
});
