import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions, IDataSourceLake } from "@itsy-ui/core";
import { getItemFromLocalStorage, removeInLocalStorage } from "@itsy-ui/utils";
import { TYPES } from "../utils/constant";
import { getLocalStorageItem } from "../common/lbHelpers"
import { getAllSiteIds } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

//ResetForm
const analystRestCommands: CommandOptions<any> = {    
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
     //removing localstorage data to reset the checkbox and also result page  
  const localStorageKey = "ANALYST_SEARCH_REQUEST"; 

  localStorage.removeItem(localStorageKey)
    transition({
      type: "FORM_REFRESH",
    });
    transition({
      type: "FORM_BEFORE_HANDLE_CHANGE",
      controlID: "analyst_createForm",
      strict: true,
      value: {
        "gender": "all",
        "siteIds": [await getAllSiteIds()],
        "scanDateSelectorFor": "visitDate",
        "scanDateSelector": "all",
        "ageSelector": "all",
        "weightSelector": "all",
        "classificationIds": ["all"],
        "mmse": { checked: false },
        "cdr": { checked: false },
        "adas_cog": { checked: false },
        "fab": { checked: false },
        "psprs": { checked: false },
        "updrs": { checked: false },
        "pet_dicom_available": { checkBox: false, result: '' },
        "apn_pet_dicom_available": { checkBox: false, result: '' },
        "sharingConsent": ["true"]
      }
    })
  },
};

commandManager.registerCommand("Analyst_Reset", {}, analystRestCommands);


//searchForm
const analystSearchCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    return true;
  },
  execute: async (data: any, transition: any) => {
    transition({
      type: "FORM_SUBMIT_CLICK",
    });
  },
};

commandManager.registerCommand("Analyst_Search", {}, analystSearchCommand);

//result download

const resultDownloadCommand: CommandOptions<any> = {
  canExecute: (data: any) => {
    const bodyData = getItemFromLocalStorage("ANALYST_SEARCH_REQUEST");
    return bodyData !== undefined && Object.keys(bodyData).length === 0 ? false : bodyData.count === 0 ? false : true;
  },
  execute: async (data: any, transition: any) => {
    const datasource: any = dataLoader.getLoader<IDataSourceLake>("analystVisitDataSource");
    const bodyData = getItemFromLocalStorage("ANALYST_SEARCH_REQUEST");
    try {
      // await datasource.downlodFile(`${TYPES.SEARCH}/${data.typeId}`, bodyData);
      const typeId = data.typeId == "download-files" ? "fileurls" : data.typeId;
      await datasource.downlodFilesSignedUrls(`${TYPES.SEARCH}/${typeId}`, bodyData);
    } catch (e) {
      const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
      let errorMessage = response ? response.message || response.errorMessage : null;
      if (!errorMessage)
        errorMessage = "Error in network call";
      transition({
        strict: true,
        type: "FORM_ERROR",
        errorMessage: errorMessage,
      });
    }
  },
};

commandManager.registerCommand("download_result", {}, resultDownloadCommand);

//select record download

const selectRecordDownloadCommand: CommandOptions<any> = {
  canExecute: (item: any) => {
    return item !== undefined && Array.isArray(item.data["selectedRecords"]) && item.data["selectedRecords"].length > 0 ? true : false;
  },
  execute: async (data: any, transition: any) => {
    let filterId;
    try {
      transition({
        type: "RESULT_GET_STATE",
        onResultData: (data) => {
          const ids = data.resultRecord.map(e => { return e.id })
          filterId = ids
        }
      })
      const datasource: any = dataLoader.getLoader<IDataSourceLake>("analystVisitDataSource");
      const searchReq = getItemFromLocalStorage("ANALYST_SEARCH_REQUEST");
      // await datasource.downlodFile(`${TYPES.SEARCH}/${data.typeId}`, { ...searchReq, "uploadIds": filterId });
      const typeId = data.typeId == "download-files" ? "fileurls" : data.typeId;
      await datasource.downlodFilesSignedUrls(`${TYPES.SEARCH}/${typeId}`, { ...searchReq, "uploadIds": filterId });
    } catch (e) {
      const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
      let errorMessage = response ? response.message || response.errorMessage : null;
      if (!errorMessage)
        errorMessage = "Error in network call";
      transition({
        strict: true,
        type: "FORM_ERROR",
        errorMessage: errorMessage,
      });
    }
  },
};

commandManager.registerCommand("select_download_record", {}, selectRecordDownloadCommand);