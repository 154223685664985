import { WidgetsFactory, IDataSourceLake, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { getItemFromLocalStorage, getlocaleText, setItemInLocalStorage } from "@itsy-ui/utils";
import { getInitialNavigationUrl, getUrlParamValue, setCurrentRole } from "../utils/helpers";
import { SuccessType, ErrorType, RegExp } from "../utils/constant";
const Yup = require("yup");

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function doTermsAcceptFormSubmit(evt) {
    return async (_, dispatch, transition) => {
        try {
            const userdata = JSON.parse(localStorage.FV_TENANT_INFO);
            const userId = userdata["id"];
            const dataSource: any = dataLoader.getLoader<IDataSourceLake>("userDataSource");
            const termsdata = {
                id: userId,
                "accept_termsofuse": true
            }
            const userdetails = await dataSource.acceptTermsofuse(termsdata, { id: "accept_terms" });
            //update local storage
            const tenantInfo = getItemFromLocalStorage("FV_TENANT_INFO");
            const setLocalItem = { ...tenantInfo, accept_termsofuse: true };
            setItemInLocalStorage("FV_TENANT_INFO", JSON.stringify(setLocalItem));

            if (userdetails) {
                transition({
                    type: "HIDE_INDICATOR",
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: "HIDE_DRAWER",
                });
                const userSelectedRole = localStorage.SELECTED_ROLE ? JSON.parse(localStorage.SELECTED_ROLE) : setCurrentRole(userdata);
                if (userSelectedRole) {
                    // initialNavigation
                    const url = getInitialNavigationUrl(userSelectedRole.role_name);
                    transition({
                        type: "NAVIGATE_URL",
                        url: url, //after login page url
                        persistCurrentUrl: false,
                        shouldReplace: true
                    });
                }
                else {
                    transition({
                        type: "NAVIGATE_URL",
                        url: "/roles",
                        persistCurrentUrl: false,
                        shouldReplace: true
                    });
                }
            }
        } catch (e) {
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "HIDE_DRAWER",
            });
            const errorMsg = e.message.split(",");
            let errorMessage = errorMsg[2] ? errorMsg[2] : e.message;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    }
}

const termsFormOnSubmit: ICustomStateMachineData = {
    name: "termsFormOnSubmit",
    stateJSON: {
        "states": {
            "formSubmit": {
                "onEntry": [
                    "onTermsAcceptFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onTermsAcceptFormSubmit: (evt) => dispatch(doTermsAcceptFormSubmit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "termsofuse"
}, termsFormOnSubmit);