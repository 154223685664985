import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText, getUrlParamValue } from "@itsy-ui/utils";
import { SuccessType, ErrorType, RegExp } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doResetFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const formValidationSchema = {
            ...validationSchema,
            "newPassword": Yup.string().matches(RegExp.PASSWORD, { message: getlocaleText("{{password.errorMessage}}") }).required(getlocaleText("{{password.required}}")),
            "confirmPassword": Yup.string().required(getlocaleText("{{password.required}}"))
                .oneOf([Yup.ref('newPassword'), null], getlocaleText("{{password.mustmatch}}"))
        };
        const dataObject = {
            "id": getUrlParamValue("id"),
            "token": getUrlParamValue("token")
        };

        const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
        let userdetails;
        try {
            userdetails = dataObject.id ? await dataSource.getObject("users", dataObject.id) : null;
            if (userdetails === null) {
                throw new Error("User.InvalidUserId");
            }
            else if (userdetails && !userdetails.isActive) {
                throw new Error("resetpassword.UserDeactivated");
            }
        } catch (e) {
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: formValidationSchema,
                objectData: dataObject,
                typeId: typeId,
            });
            transition({
                type: "NAVIGATE_URL",
                url: `/error?type=${ErrorType.INVALID_URL}&msg=${e.message}`
            });
        }

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: formValidationSchema,
            objectData: dataObject,
            typeId: typeId,
        });
    }
}

function doResetFormSubmit(evt) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            var object = {
                "id": evt.values.id ? evt.values.id : getUrlParamValue("id"),
                "token": evt.values.token ? evt.values.token : getUrlParamValue("token"),
                "password": evt.values.confirmPassword
            }
            const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
            const data = await dataSource.post(object, { id: "users/reset-password" });
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "USER_AUTHENTICATED",
                isAuthenticated: false,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: "NAVIGATE_URL",
                url: `/success?type=${SuccessType.RESET_PASSWORD}`,
            });
        } catch (e) {
            transition({
                type: "HIDE_INDICATOR",
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage) errorMessage = getlocaleText("{{network_Error}}");
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const createFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onResetFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onResetFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onResetFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doResetFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onResetFormSubmit: (evt) => dispatch(doResetFormSubmit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "resetpassword"
}, createFormOnSubmit);