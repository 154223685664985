import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';

const UserPageName = {
    "title": "{{user.pageTittle}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "user_add",
            "displayText": "{{common.addUser}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "users",
    "align": "right",
    "className": "user-list-added",
    "typeId": "user_add"
};

const Gridschema = {
    typeId: "users",
    gridSchemaId: "users",
    dataSource: "dataSource",
    rowSelectionMode: 1,
    controlID: "users"
}

const UserPage = (props) => {
    return (
        <ItsyContainer className="user-management-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={UserPageName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="users" value={{ contextPath: { "id": "user_create_form" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="users" value={{ contextPath: { "id": "users" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="user-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default UserPage;