import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';

const compoundMfgSubUsageName = {
    "title": "{{sidebar.compoundMfgSubUsage}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "export_compoundMfgSubUsage_csv",
            "displayText": "{{common.export}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "download",
            "className": "export-button",
            "roles": ["Super Admin",
                "CMC Admin",
                "CMC Upload User"]
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "compoundMfgSubUsages",
    "align": "right",
    "className": "batches-button",
    "typeId": "compoundMfgSubUsages"
};

const filterschema = {
    "widgetType": "bound",
    "schema": {
        "id": "compoundMfgSubUsage_filter",
        "displayName": "compoundMfgSubUsage filter",
        "propertyDefinitions": {
            "cmcSiteId": {
                "id": "cmcSiteId",
                "displayName": "{{cmcsite.selectTitle}}",
                "propertyType": "dropdown",
                "placeholderText": "{{cmcsite.SiteName_placeholder}}",
                "ui:widget": "itsy:dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "filter_cmcsites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "cmc_site_name"
                    ]
                },
                "datasource": "cmcsiteDataSource",
                "isLocal": false,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "cmc_site_name"
            },
            "compoundId": {
                "id": "compoundId",
                "displayName": "{{compound.displayName}}",
                "propertyType": "dropdown",
                "placeholderText": "{{compound.namedropdown_placeHolder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_cmcsites_compound_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "compound_name"
                    ]
                },
                "datasource": "compoundDataSource",
                "isLocal": false,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "compound_name",
            },
            "manufacturingFromDate": {
                "id": "manufacturingFromDate",
                "displayName": "{{compound.manufacturingFromDate}}",
                "propertyType": "string",
                "ui:widget": "date"
            },
            "manufacturingToDate": {
                "id": "manufacturingToDate",
                "displayName": "{{compound.manufacturingToDate}}",
                "propertyType": "string",
                "ui:widget": "date"
            },
        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "cmcSiteId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "compoundId"
                            }
                        ]
                    },
                    {
                        "className": "date-filter-cl-align",
                        "cell": [
                            {
                                "widget": "manufacturingFromDate"
                            }
                        ]
                    },
                    {
                        "className": "date-filter-cl-align",
                        "cell": [
                            {
                                "widget": "manufacturingToDate"
                            }
                        ]
                    },
                ],
            }
        ]
    },
    "operation": {
        "cmcSiteId": "eq",
        "compoundId": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "compoundMfgSubUsage_filter",
    controlID: "compoundMfgSubUsage_filter",
    formSchemaId: "compoundMfgSubUsage_filter",
    "align": "left",
}

const Gridschema = {
    typeId: "compoundMfgSubUsages",
    gridSchemaId: "compoundMfgSubUsages",
    dataSource: "compoundMfgSubUsageDataSource",
    rowSelectionMode: 1,
    controlID: "compoundMfgSubUsages",
    primaryColumn: "id"
}

const CompoundMfgSubUsage = (props) => {
    return (
        <ItsyContainer className="upload-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={compoundMfgSubUsageName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="export_compoundMfgSubUsage_csv" value={{ contextPath: { "id": "export_compoundMfgSubUsage_csv" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="compoundMfgSubUsage_filter" value={{ contextPath: { "id": "compoundMfgSubUsage_filter" } }}>
                    <ItsyFilterBar schema={filterschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="compoundMfgSubUsages" value={{ contextPath: { "id": "compoundMfgSubUsages" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="uploads-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default CompoundMfgSubUsage;
