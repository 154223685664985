import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, SuccessType, RegExp } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doForgetpasswordFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const checkyValidationSchema = {
            ...validationSchema,
            "email": Yup.string().matches(RegExp.EMAIL, { message: getlocaleText("{{emailId.errorMessage}}") })
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: checkyValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doForgotFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
            const body = {
                email: event.values["email"],
                hostUrl: window.location.origin
            }
            const data = await dataSource.post(body, { id: "users/forgot-password" });
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: "NAVIGATE_URL",
                url: `/success?type=${SuccessType.FORGOT_PASSWORD}`,
            });
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const createFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onForgetpasswordFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onForgotFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onForgetpasswordFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doForgetpasswordFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onForgotFormSubmit: (evt) => dispatch(doForgotFormSubmit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "forgotpassword"
}, createFormOnSubmit);