import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions, IDataSourceLake, eventTransition } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { getFormatedDate } from "../common/helpers";
import { ACTIONS, Roles, TYPES, UPLOAD_STATUS } from "../utils/constant";
import { getCurrentUserRole } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const qcAcceptCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        let qcformData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "qcDataForm",
            onData: (formData) => {
                qcformData = formData["formValues"];
            },
        });
        try {
            let commentsData = {
                uploadId: qcformData.id
            }, createcomment;
            if (qcformData.newQcComment) {
                commentsData["comments"] = qcformData.newQcComment;
                createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });
            }
            //add an comment accepted
            commentsData["comments"] = UPLOAD_STATUS.ACCEPTED;
            createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });

            //update upload status
            const uploadData = {
                id: qcformData.id,
                status: UPLOAD_STATUS.ACCEPTED,
                documentType: qcformData.type
            }
            const updateData = await datasource.update(uploadData, { id: TYPES.UPLOADS });
            if (updateData) {
                transition({
                    type: ACTIONS.HIDE_DRAWER
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{qc.acceptedMsg}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: "GRID_REFRESH",
                    controlID: "qcDashboardNewUploads",
                    strict: true,
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    },
};

commandManager.registerCommand("qc_accept", {}, qcAcceptCommand);

const qcUpdateCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource = dataLoader.getLoader<IDataSourceLake>("commentsDataSource");
        let qcformData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "qcDataForm",
            onData: (formData) => {
                qcformData = formData["formValues"];
            },
        });
        try {
            if (qcformData.newQcComment) {
                const commentsData = {
                    uploadId: qcformData.id,
                    comments: qcformData.newQcComment
                };
                const createData = await datasource.create(commentsData, { id: TYPES.COMMENTS });
                if (createData) {
                    transition({
                        type: ACTIONS.HIDE_DRAWER
                    });
                    transition({
                        type: "SHOW_NOTIFICATION",
                        message: { "text": "{{qc.commentedMsg}}", "type": " alert-success" },
                        metadata: { "delay": 1500 },
                        action: { "showCloseIcon": false },
                    });
                    transition({
                        type: "GRID_REFRESH",
                        controlID: "qcDashboardNewUploads",
                        strict: true,
                    });
                }
            }
            else {
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{qc.commentRequired}}", "type": " alert-danger drawer-z-index" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    },
};

commandManager.registerCommand("qc_update", {}, qcUpdateCommand);

const qcCancelCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        transition({
            type: ACTIONS.HIDE_DRAWER
        });
    },
};

commandManager.registerCommand("qc_cancel", {}, qcCancelCommand);

const qcRejectCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        let qcformData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "qcDataForm",
            onData: (formData) => {
                qcformData = formData["formValues"];
            },
        });
        try {
            let commentsData = {
                uploadId: qcformData.id
            }, createcomment;
            if (qcformData.newQcComment) {
                commentsData["comments"] = qcformData.newQcComment;
                createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });
            }
            //add an comment rejected
            commentsData["comments"] = UPLOAD_STATUS.REJECTED;
            createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });

            //update upload status
            const uploadData = {
                id: qcformData.id,
                status: UPLOAD_STATUS.REJECTED,
                documentType: qcformData.type
            }
            const updateData = await datasource.update(uploadData, { id: TYPES.UPLOADS });
            if (updateData) {
                transition({
                    type: ACTIONS.HIDE_DRAWER
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{qc.rejectedMsg}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: "GRID_REFRESH",
                    controlID: "qcDashboardNewUploads",
                    strict: true,
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    },
};

commandManager.registerCommand("qc_reject", {}, qcRejectCommand);

const getQcDataCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        //update the upload record with open status
        const datasource = dataLoader.getLoader<IDataSourceLake>("commentsDataSource");
        // change the status from new to open only for qc user
        if (getCurrentUserRole(Roles.QC_USER) && data["status"] && data["status"] === UPLOAD_STATUS.NEW) {
            const uploadData = {
                id: data.id,
                status: UPLOAD_STATUS.OPEN,
                documentType: data["documentType"]
            };
            const updateData = await datasource.update(uploadData, { id: TYPES.UPLOADS });
            if (updateData) {
                const commentsData = {
                    uploadId: data.id,
                    comments: UPLOAD_STATUS.OPEN
                };
                await datasource.create(commentsData, { id: TYPES.COMMENTS });
                console.log(`upload record updated successfully with "OPEN" status`);
            }
            transition({
                type: "GRID_REFRESH",
                controlID: "qcDashboardNewUploads",
                strict: true,
            });
        }
        const drawerData = {
            "title": "{{Dashboard.DrawerTittle}}",
            "showOK": false,
            "showCancel": false,
            width: "50%",
            "controlSchema": {
                "name": "Upload Data",
                "properties": {
                    "ui:widget": "itsy:page",
                    "pageId": "qcData",
                    "queryParams": {
                        "id": data.id,
                        "uploadComment": data.comments && data.comments.length > 0 ? data.comments[0].comments : ""
                    },
                }
            }
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};

commandManager.registerCommand("getQcData", {}, getQcDataCommand);

//download file command
const downloadFile: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
        let qcformData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "qcDataForm",
            onData: (formData) => {
                qcformData = formData["formValues"];
            },
        });
        try {
            const data = await datasource.getSignedURL({ id: qcformData.id }, { id: TYPES.UPLOADS });
            if (data && data["signedUrl"]) {
                await datasource.downlodFile(data["signedUrl"]);
            } else {
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
                    metadata: { "delay": 5 * 1000 },
                    action: { "showCloseIcon": false },
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage) {
                errorMessage = "Error in network call";
            }
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
                metadata: { "delay": 5 * 1000 },
                action: { "showCloseIcon": false },
            });
        }
    },
};

commandManager.registerCommand("dowload_file", {}, downloadFile);

//reject confirm
const qcRejectConfirmCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        transition({
            type: "SHOW_INDICATOR",
            loadingMessage: "{{rejectConfirm.loading_message}}"
        });
        const datasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        let qcformData;
        transition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "qcDataForm",
            onData: (formData) => {
                qcformData = formData["formValues"];
            },
        });
        try {
            //update upload status
            const uploadData = {
                id: qcformData.id,
                status: UPLOAD_STATUS.REJECT_CONFIRMED,
                documentType: qcformData.type
            }
            const updateData = await datasource.update(uploadData, { id: TYPES.UPLOADS });

            let commentsData = {
                uploadId: qcformData.id
            }, createcomment;
            if (qcformData.newQcComment) {
                commentsData["comments"] = qcformData.newQcComment;
                createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });
            }
            //add an comment reject confirmed
            commentsData["comments"] = UPLOAD_STATUS.REJECT_CONFIRMED;
            createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });

            if (createcomment && updateData) {
                transition({
                    type: "HIDE_INDICATOR"
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{uploaduser.rejectedConfirmMsg}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: "GRID_REFRESH",
                    controlID: "uploads",
                    strict: true,
                });
            }
        } catch (e) {
            transition({
                type: "HIDE_INDICATOR"
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                type: ACTIONS.HIDE_DRAWER
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    },
};

commandManager.registerCommand("qc_rejectConfirm", {}, qcRejectConfirmCommand);

//re-open
async function ReopenCRFUpload(data, transition) {
    transition({
        type: "SHOW_INDICATOR",
        loadingMessage: "{{rejectConfirm.loading_message}}"
    });
    const datasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
    let qcformData;
    transition({
        type: "FORM_GET_STATE",
        strict: true,
        controlID: "qcDataForm",
        onData: (formData) => {
            qcformData = formData["formValues"];
        },
    });
    try {
        //update upload status
        const uploadData = {
            id: qcformData.id,
            status: UPLOAD_STATUS.NEW,
            documentType: qcformData.type
        }
        const updateData = await datasource.update(uploadData, { id: TYPES.UPLOADS });

        let commentsData = {
            uploadId: qcformData.id
        }, createcomment;
        if (qcformData.newQcComment) {
            commentsData["comments"] = qcformData.newQcComment;
            createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });
        }
        //add an comment reopen
        commentsData["comments"] = "Reopen";
        createcomment = await datasource.create(commentsData, { id: TYPES.COMMENTS });

        if (createcomment && updateData) {
            transition({
                type: "HIDE_INDICATOR"
            });
            transition({
                type: ACTIONS.HIDE_DRAWER
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{uploaduser.reopenSuccessMsg}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: "GRID_REFRESH",
                controlID: "uploads",
                strict: true,
            });
            const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
            const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");
            let cmd = commandManager.getCommand("crf_edit", {});
            try {
                const queryParam = {
                    filter: {
                        subjectId: qcformData["subjectObjId"],
                        visitDate: getFormatedDate(qcformData["visitDate"])
                    }
                };
                const visitDatasource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
                const prevVisitData = await visitDatasource.getAll(TYPES.VISITS, queryParam);
                cmd!.execute(prevVisitData ? prevVisitData[0] : {}, eventTransition);
            } catch (e) {
                console.log("Command execution error: ", e);
            }
        }
    } catch (e) {
        transition({
            type: "HIDE_INDICATOR"
        });
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            type: ACTIONS.HIDE_DRAWER
        });
        transition({
            type: "SHOW_NOTIFICATION",
            message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
            metadata: { "delay": 1500 },
            action: { "showCloseIcon": false },
        });
    }
}

const qcReopenCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const popupData = {
            title: getlocaleText("{{common.Alert}}"),
            popupMessage: getlocaleText("{{uploaduser.ReopenMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                ReopenCRFUpload(data, transition)
                transition({
                    type: "HIDE_POPUP",
                });
            },
            onCancel: {
                type: "HIDE_POPUP"
            },
        };
        transition({
            type: "SHOW_POPUP",
            event: popupData,
        });
    },
};

commandManager.registerCommand("qc_reOpen", {}, qcReopenCommand);