import { WidgetsFactory, IDataSourceLake, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { getFormatedDateTimeWithTimezoneOffset, getFormatedDateWithTimezoneOffset, getFormatedDICOMAvailableDate, getFormatedVisitDate } from "../common/helpers";
import { TYPES, UPLOAD_STATUS, DocumentType } from "../utils/constant";
import { Roles } from "../utils/constant";
import { getCurrentUserRole } from "../utils/utils";

const moment = require("moment");
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

const getRenderRule = (propertyDefinitions, widget) => {
    const schema = {};
    if (propertyDefinitions) {
        schema["mmse"] = schema["cdr"] = schema["adas_cog"] = schema["fab"] = schema["psprs"] = schema["updrs"] =
            schema["pet_dicom_available"] = schema["apn_pet_dicom_available"] =
            schema["weight"] = schema["bmi"] = (values) => {
                if (values && values["type"] === DocumentType.CRF) {
                    return false;
                }
                return true;
            };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("uploadComment")) {
        schema["uploadComment"] = (values) => {
            if (values && values["uploadComment"]) {
                return false;
            }
            return true;
        };
    }
    return schema;
}
function doQcDataFormSchemaBeforeLoad(event: any) {
    return async (_, dispatch, transition) => {
        const isUploadUser = getCurrentUserRole(Roles.UPLOAD_USER);
        const dataSource: any = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        const { typeId, formSchemaId, objectData, formSchema, validationSchema, queryParams } = event;
        let items = [];
        try {
            const uploadId = queryParams["id"];
            const uploadData = uploadId ? await dataSource.getObject(TYPES.UPLOADS, uploadId) : null;
            const visitData = uploadData["visit"] ? uploadData["visit"] : null;
            const subjectData = visitData ? visitData["subject"] : null;
            const uploadedDate = uploadData ? uploadData["createdDate"] : null;
            const studyData = subjectData ? subjectData["study"] : null;
            const siteData = studyData ? studyData["sites"][0] : null;
            const classificationData = visitData && visitData["classificationId"] ? await dataSource.getObject(TYPES.CLASSIFICATION, visitData["classificationId"]) : null;
            const data = {
                id: uploadId,
                siteId: siteData ? siteData["id"] : null,
                siteName: siteData ? siteData["siteName"] : null,
                studyId: subjectData ? subjectData["studyId"] : null,
                studyName: studyData ? studyData["studyName"] : null,
                subjectId: subjectData ? subjectData["subjectId"] : null,
                subjectObjId: subjectData ? subjectData["id"] : null,
                visitDate: getFormatedVisitDate(visitData.visitDate),
                uploadTime: getFormatedDateTimeWithTimezoneOffset(uploadedDate),
                visitLastUpdate: getFormatedDateWithTimezoneOffset(visitData.lastModifiedDate),
                gender: subjectData ? subjectData["gender"] : null,
                classificationId: visitData ? visitData["classificationId"] : null,
                classificationName: classificationData ? classificationData["name"] : null,
                type: uploadData ? uploadData["documentType"] ? uploadData["documentType"] : null : null,
                status: uploadData ? uploadData["status"] ? uploadData["status"] : null : null,
                fileName: uploadData ? uploadData.contentStreamFileName : null,
                weight: visitData && visitData.weight && visitData.weight != "-1" && (visitData.weight != null || visitData.weight != undefined) ? Number(visitData.weight) : getlocaleText("{{common.none}}"),
                bmi: visitData && visitData.bmi && visitData.bmi != "-1" && (visitData.bmi != null || visitData.bmi != undefined) ? Number(visitData.bmi) : getlocaleText("{{common.none}}"),
                mmse: visitData && visitData.mmse && visitData.mmse != "-1" && (visitData.mmse != null || visitData.mmse != undefined) ? Number(visitData.mmse) : getlocaleText("{{common.none}}"),
                cdr: visitData && visitData.cdr && visitData.cdr != "-1" && (visitData.cdr != null || visitData.cdr != undefined) ? Number(visitData.cdr) : getlocaleText("{{common.none}}"),
                adas_cog: visitData && visitData.adas_cog && visitData.adas_cog != "-1" && (visitData.adas_cog != null || visitData.adas_cog != undefined) ? Number(visitData.adas_cog) : getlocaleText("{{common.none}}"),
                fab: visitData && visitData.fab && visitData.fab != "-1" && (visitData.fab != null || visitData.fab != undefined) ? Number(visitData.fab) : getlocaleText("{{common.none}}"),
                psprs: visitData && visitData.psprs && visitData.psprs != "-1" && (visitData.psprs != null || visitData.psprs != undefined) ? Number(visitData.psprs) : getlocaleText("{{common.none}}"),
                updrs: visitData && visitData.updrs && visitData.updrs != "-1" && (visitData.updrs != null || visitData.updrs != undefined) ? Number(visitData.updrs) : getlocaleText("{{common.none}}"),
                pet_dicom_available: visitData && visitData.pet_dicom_available ? getlocaleText("{{common.yes}}") : (visitData.pet_dicom_available === false) ? getlocaleText("{{common.no}}") : getlocaleText("{{common.none}}"),
                apn_pet_dicom_available: visitData && visitData.apn_pet_dicom_available ? getlocaleText("{{common.yes}}") : (visitData.apn_pet_dicom_available === false) ? getlocaleText("{{common.no}}") : getlocaleText("{{common.none}}"),
                adverse_events: visitData && visitData.adverseEvent ? getlocaleText("{{common.yes}}") : getlocaleText("{{common.no}}"),
                rawImage: visitData && visitData.raw ? getlocaleText("{{common.yes}}") : getlocaleText("{{common.no}}"),
                batchIdValue: visitData && visitData.compoundManufactured ? visitData.compoundManufactured.batchId : getlocaleText("{{common.none}}"),
                compoundName: visitData && visitData.compoundManufactured && visitData.compoundManufactured.cmcSiteCompound && visitData.compoundManufactured.cmcSiteCompound.compounds ? visitData.compoundManufactured.cmcSiteCompound.compounds.compound_name : getlocaleText("{{common.none}}"),
                uploadComment: queryParams["uploadComment"]
            }

            formSchema.propertyDefinitions["status"]["className"] = "statusField";
            if (data.status === UPLOAD_STATUS.REJECT_CONFIRMED || data.status === UPLOAD_STATUS.REJECTED) {
                formSchema.propertyDefinitions["status"]["className"] = "rejectStatusField";
            }

            if (getCurrentUserRole(Roles.SUPER_ADMIN)) {
                formSchema.propertyDefinitions["newQcComment"]["style"] = { "display": "none" };
                items.push({
                    "name": "upload_delete",
                    "displayText": "{{Common.Delete}}",
                    "isPrimary": true,
                })
            }
            else {
                if (data.status && data.status === UPLOAD_STATUS.REJECTED) {
                    formSchema.propertyDefinitions["newQcComment"]["style"] = { "display": "none" };

                    isUploadUser && items.push({
                        "name": "qc_rejectConfirm",
                        "displayText": "{{RejectConfirm.ButtonText}}",
                        "isPrimary": true,
                    });
                }
                if (data.status && (data.status === UPLOAD_STATUS.NEW || data.status === UPLOAD_STATUS.OPEN)) {
                    items.push({
                        "name": "qc_update",
                        "displayText": "{{Update.ButtonText}}",
                        "isPrimary": true,
                    })
                    !isUploadUser && items.push(
                        {
                            "name": "qc_accept",
                            "displayText": "{{Accept.ButtonText}}",
                            "isPrimary": true,
                        },
                        {
                            "name": "qc_reject",
                            "displayText": "{{Reject.ButtonText}}",
                            "isPrimary": true,
                        });
                    formSchema.propertyDefinitions["newQcComment"]["style"] = { "display": "block" };
                }
                if (data.status && (data.status === UPLOAD_STATUS.ACCEPTED || data.status === UPLOAD_STATUS.REJECT_CONFIRMED)) {
                    formSchema.propertyDefinitions["newQcComment"]["style"] = { "display": "none" };

                    if (data.status === UPLOAD_STATUS.ACCEPTED && data.type === DocumentType.CRF) {
                        isUploadUser && items.push({
                            "name": "qc_reOpen",
                            "displayText": "{{ReOpen.ButtonText}}",
                            "isPrimary": true,
                        });
                    }
                }
            }

            transition({
                type: "TOOLBAR_REFRESH",
                controlID: "qcData_toolbar",
                strict: true,
                items: items,
                typeId: "qcData",
                params: {},
            });
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                objectData: data,
                typeId: typeId,
                displayRuleSchema: getRenderRule(formSchema.propertyDefinitions, typeId),
            });
        } catch (e) {
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                typeId: typeId,
                displayRuleSchema: getRenderRule(formSchema.propertyDefinitions, typeId),
            });
        }
    }
}

const qcDataForm: ICustomStateMachineData = {
    name: "qcDataForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onQcDataFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                }
            }
        }
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onQcDataFormSchemaBeforeLoad: (event) => dispatch(doQcDataFormSchemaBeforeLoad(event)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "qc",
    "formSchemaId": "qcDataForm",
    "pageId": "qcData"
}, qcDataForm);