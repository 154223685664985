import { ItsyContainer, ItsyColumn } from "@itsy-ui/layout";
import { ItsyImage, ItsyLabel } from '@itsy-ui/common';
import { SuccessType } from "../utils/constant";
import { StateManagerContext } from "@itsy-ui/core";
import { ItsyToolbar } from "@itsy-ui/navigation";
import { getUrlParamValue } from "@itsy-ui/utils";
import SUCCESS_ICON from "../style/image/sucessfulImage.png";

const iconSchema = {
    "displayName": "app_icon",
    "title": "App_icon",
    "alignment": "center",
    "image_type": "base64",
    "image_value": SUCCESS_ICON
}
const toolbarSchema = {
    data: [
        {
            "name": "back_login",
            "displayText": "{{common.login}}",
            "isPrimary": true,
            "enabled": true
        }
    ],
    "widgetType": "bound",
    "align": "center",
    "className": "goback-button",
    "typeId": "back_login"
};
const successTitle = {
    "title": "",
    "headerSize": "h6",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const successMessage = {
    "title": "",
    "headerSize": "h10",
    "alignText": "center",
    "style": { "text-align": "center" }
}
function getSuccessMessage(type: string) {
    switch (type) {
        case SuccessType.FORGOT_PASSWORD:
            successTitle["title"] = "{{password.successTitle}}";
            successMessage["title"] = "{{password.successMessage}}";
            break;
        case SuccessType.RESET_PASSWORD:
            successTitle["title"] = "{{resetpassword.successTitle}}";
            successMessage["title"] = "{{resetpassword.successMessage}}";
            break;
        case SuccessType.ENROLL:
            successTitle["title"] = "{{enroll.successTitle}}";
            successMessage["title"] = "{{enroll.successMessage}}";
            break;
        default:
            break;
    }
}

const SuccessPage = (props) => {
    const type = getUrlParamValue("type");
    getSuccessMessage(type);
    return (<ItsyContainer className="successpage-container w-50">
        <ItsyColumn hAlignment="center" className="apn-success-icon">
            <ItsyImage className="success-icon" schema={iconSchema} />
        </ItsyColumn>
        <ItsyColumn className="apn-success-title">
            <ItsyLabel schema={successTitle} />
        </ItsyColumn>
        <ItsyColumn>
            <ItsyLabel schema={successMessage} />
        </ItsyColumn>
        {type !== SuccessType.FORGOT_PASSWORD &&
            <ItsyColumn className="apn-success-button">
                <StateManagerContext.Provider key="success" value={{ contextPath: { "id": "back_login" } }}>
                    <ItsyToolbar schema={toolbarSchema} />
                </StateManagerContext.Provider>
            </ItsyColumn>
        }
    </ItsyContainer>)
}

export default SuccessPage;