import { WidgetsFactory, IDataSourceLake, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { USER } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function doQcDashboardFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
        try {
            const userdetails = JSON.parse(localStorage.FV_TENANT_INFO);

            const data = {
                siteId: userdetails ? userdetails.siteId ? userdetails.siteId : null : null,
                siteName: userdetails ? userdetails.site ? userdetails.site.siteName ? userdetails.site.siteName : null : null : null
            }
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                objectData: data,
                typeId: typeId,
            });
        } catch (e) {
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                typeId: typeId,
            });
        }
    }
}

const qcDashboardFilterForm: ICustomStateMachineData = {
    name: "qcDashboardFilterForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onQcDashboardFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                }
            }
        }
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onQcDashboardFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doQcDashboardFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "qcDashboard_filters"
}, qcDashboardFilterForm);