import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { RegExp } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doLoginFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const checkyValidationSchema = {
            ...validationSchema,
            "userName": Yup.string().required(getlocaleText("{{userName.required}}")),
            "password": Yup.string().required(getlocaleText("{{password.required}}")),
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: checkyValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doLoginFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        const { userName, password } = event.values;
        try {
            //Handle authorization
            const authService = dataLoader.getLoader<IAuthService>("auth");
            transition({
                type: "SHOW_INDICATOR",
                loadingMessage: "{{login.loginLoading}}",
            });
            authService.authenticate(userName, password)
                .then(authResult => {
                    if (authResult.isAuthenticated) {
                        const authSuccess = { fromLogin: true, ...authResult };

                        transition({
                            type: "FORM_AFTER_SUBMIT",
                        });
                        transition({
                            type: "USER_AUTHENTICATED",
                            isAuthenticated: authSuccess.isAuthenticated,
                        });
                        transition({
                            type: "HIDE_INDICATOR",
                        });
                    } else {
                        transition({
                            type: "TENANT_ERROR",
                            data: authResult.data.errorMessage,
                            errorCode: authResult.data.errorCode
                                ? authResult.data.errorCode
                                : authResult.data.status === 401
                                    ? authResult.data.status
                                    : undefined,
                        });
                        transition({
                            strict: true,
                            type: "FORM_ERROR",
                            errorMessage: authResult.data.errorMessage,
                        });
                    }
                })
                .catch(e => {
                    transition({
                        type: "TENANT_ERROR",
                        data: e.message,
                    });
                    transition({
                        type: "HIDE_INDICATOR",
                    });
                    const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
                    let errorMessage = response ? response.message || response.errorMessage : null;
                    if (!errorMessage)
                        errorMessage = getlocaleText("{{network_Error}}");
                    transition({
                        strict: true,
                        type: "FORM_ERROR",
                        errorMessage: errorMessage,
                    });
                });
        } catch (e) {
            console.error(e);
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "FORM_ERROR",
            });
        }
    };
}

const createFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onLoginFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onLoginFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onLoginFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doLoginFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onLoginFormSubmit: (evt) => dispatch(doLoginFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "id": "login"
}, createFormOnSubmit);