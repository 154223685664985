import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { ItsyButton } from '@itsy-ui/common';
import { getColorForDocType } from "../utils/helpers";
import { getItemFromLocalStorage, getlocaleText } from "@itsy-ui/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function renderUploadTypeColumn(type, cellValue) {
    const data = cellValue.uploads.find(obj => { return obj.documentType === type });
    if (data) {
        const title = toCamelCase(data.status.toString());
        const className = getColorForDocType(data.status);
        const userInfo = getItemFromLocalStorage("FV_TENANT_INFO")
        if (userInfo && userInfo.usersUploadTypes && userInfo.usersUploadTypes.length > 0 &&
            userInfo.usersUploadTypes.find(uploadType => { return uploadType.type === type })) {
            return <ItsyButton
                title={`${title}`}
                className={`${className} apn-link-button`}
                commandName="getQcData"
                onContext={() => { return data }}
            />
        }
        else return <span>{`${title}`}</span>
    } else {
        return <span>{getlocaleText("{{common.none}}")}</span>
    }
}

function toCamelCase(str) {
    return str.split('_').map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    if (cellValue.hasOwnProperty("visitDate") && col.name === "visitDate") {
        return <div className="apn-custom-action-button">
            <span title={cellValue["visitDate"]}>{cellValue["visitDate"]}</span>
        </div>
    } else if (col.name === "CRF") {
        const type = "CRF";
        return <div className="apn-custom-action-button">
            {renderUploadTypeColumn(type, cellValue)}
        </div>
    } else if (col.name === "MRI") {
        const type = "MRI";
        return <div className="apn-custom-action-button">
            {renderUploadTypeColumn(type, cellValue)}
        </div>
    } else if (col.name === "PET") {
        const type = "PET";
        return <div className="apn-custom-action-button">
            {renderUploadTypeColumn(type, cellValue)}
        </div>
    } else {
        return null;
    }
}

function doQcDashboardGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const qcDashboardGrid: ICustomStateMachineData = {
    name: "qcDashboardGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onQcDashboardGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onQcDashboardGridInit: (evt) => dispatch(doQcDashboardGridInit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "qcDashboardNewUploads"
}, qcDashboardGrid);