import { ItsyContainer, ItsyRow } from "@itsy-ui/layout";
import { ItsyNavbar } from "@itsy-ui/navigation";
import Enroll from "./enrollPage";
import Success from "./successPage";
import Error from "./errorPage";
import { getLogo } from "../utils/helpers";
import { useEffect, useState } from "react";
import ForgotPage from "./forgotPage";
import RestPage from "./resetPasswordPage";
import Login from "./login";

/**
 * Navbar schema to render the top bar
 */

const PublicpageWrapper = (props: any) => {
    const [navBarSchema, setNavBarSchema] = useState();
    useEffect(() => {
        getLogo().then(response => {
            let currentImageSchema: any = {
                data: {
                    items: [
                        {
                            id: "back_home",
                            appIcon: response
                        }
                    ]
                }
            };
            setNavBarSchema(currentImageSchema);
        })
            .catch(error => {
                console.log(error);
            });
    }, []);
    const { schema } = props
    return <ItsyContainer className="apn-app-rootlevel-container">
        {navBarSchema && <ItsyRow className="apn-navbar">
            <ItsyNavbar schema={navBarSchema} />
        </ItsyRow>}
        <div className="apn-public-page-container">
            <div className="apn-public-page-wrapper container mw-100 h-100 d-flex">
                <ItsyRow hAlignment="center" padding="15px">
                    {schema.pageId === "enroll" && <Enroll />}
                    {schema.pageId === "success" && <Success />}
                    {schema.pageId === "error" && <Error />}
                    {schema.pageId === "forgotpassword" && <ForgotPage />}
                    {schema.pageId === "resetpassword" && <RestPage />}
                    {schema.pageId === "login" && <Login />}
                    {/* {props.children} */}
                </ItsyRow>
            </div>
        </div>
    </ItsyContainer>
}

export default PublicpageWrapper;