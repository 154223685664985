import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { ACTIONS, TYPES, RegExp } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doCMCSiteFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const checkyValidationSchema = {
            ...validationSchema,
            "cmc_site_name": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{cmcsiteName.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "cmc_site_contact": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{cmcsiteContact.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}"))
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: checkyValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doCMCSiteCreateOrUpdateFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("cmcsiteDataSource");
            const data = await datasource.siteUpsert(event.values, { id: TYPES.CMCSITES });
            let textMessage = (event.values["id"] !== undefined && event.values["id"] !== null && event.values["id"] !== "") ? "{{cmcsite.successUpdateMessage}}" : "{{cmcsite.successCreateMessage}}";
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: ACTIONS.HIDE_DRAWER,
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": textMessage, "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: ACTIONS.GRID_REFRESH
            })

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const CMCSiteCreateOrUpdateFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onCMCSiteFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onCMCSiteCreateOrUpdateFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCMCSiteFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doCMCSiteFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onCMCSiteCreateOrUpdateFormSubmit: (evt) => dispatch(doCMCSiteCreateOrUpdateFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "cmc-sites",
    "formSchemaId": "cmcsite_create_form",
}, CMCSiteCreateOrUpdateFormOnSubmit);



function doCMCSiteEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("cmcsiteDataSource");
            const data = await datasource.siteUpsert(event.values, { id: TYPES.CMCSITES });
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.UserEdited}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const CMCSiteEditFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSubmit": {
                "onEntry": [
                    "onCMCSiteEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCMCSiteEditFormSubmit: (evt) => dispatch(doCMCSiteEditFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "cmc-sites",
    "formSchemaId": "cmcsite_edit_form",
}, CMCSiteEditFormOnSubmit);