import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { DROPDOWN, LOCALSTORAGE_NAME, TYPES } from "../../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doStudyFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        const customeobjectData = {
            "siteId": selectedfilter_csite ? selectedfilter_csite : "all"
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });
    }
}

function doStudyFilterFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        transition({
            type: "FORM_VALUE_UPDATE",
            values: value,
        });
        transition({
            controlID: "studies",
            strict: true,
            type: "GRID_FILTER",
            searchValue: { siteId: value["siteId"] }
        });
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE, value["siteId"]);
    };
}

const subjectFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onStudyFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onStudyFilterFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onStudyFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doStudyFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onStudyFilterFormHandleChange: ({ value, formValues }) => dispatch(doStudyFilterFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "study_filter"
}, subjectFormHandler);