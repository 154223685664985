import { CommandOptions, DataLoaderFactory, ICommandManager, WidgetsFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, LOCALSTORAGE_NAME, TYPES, UPLOAD_STATUS } from "../utils/constant";
import { getuploadList } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

//Go back to Upload Grid View page
const backToUploadListCommand: CommandOptions<any> = {
	canExecute: (data: any) => {
		return true;
	},
	execute: async (data: any, transition: any) => {
		transition({
			type: "NAVIGATE_URL",
			url: "/uploads",
		});
	},
};

commandManager.registerCommand("back_uploadList", {}, backToUploadListCommand);

//Add New Upload command

const NewUploadCommands: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		// const userdetails = JSON.parse(localStorage.FV_TENANT_INFO);
		let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
		let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
		let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
		let record = {};
		if (selectedfilter_csite && selectedfilter_csite !== "all" && !selectedfilter_csite.includes("all")) {
			record["siteId"] = selectedfilter_csite;
		}
		if (selectedfilter_study && selectedfilter_study !== "all" && !selectedfilter_study.includes("all")) {
			record["studyId"] = selectedfilter_study;
		}
		if (selectedfilter_compound && selectedfilter_compound !== "all" && !selectedfilter_compound.includes("all")) {
			record["compoundId"] = selectedfilter_compound;
		}
		if (selectedfilter_compound && selectedfilter_compound !== "all" && !selectedfilter_compound.includes("all")) {
			
			const batchDataSource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
			const getBatchs = await batchDataSource.getAll(TYPES.BATCHES, { compoundId: selectedfilter_compound["compoundId"] ,cmcSiteId: selectedfilter_csite["cmcSiteId"]});
   let targetIds
  let filteredObjects
				// targetIds =  selectedfilter_compound?.map(obj => obj.id);
				 filteredObjects = getBatchs?.filter(obj => selectedfilter_compound.includes(obj.compoundId));
				 const filteredIds = filteredObjects.map(obj => obj.id)
  // record["batchId"] = filteredIds;
  	}

		const drawerData = {
			"title": "{{upload.newUpload}}",
			"okText": getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '60%',
			className: "upload-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "upload",
					"formSchemaId": "upload_form",
					"isModal": true,
					"record": record,
					controlID: "upload_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "upload_form",
					strict: true,
				});
			},
			onCancel: () => {
				transition({
					type: "HIDE_DRAWER",
					controlID: "upload_form",
				});
			},
			customState: {
				contextPath: {
					formSchemaId: "upload_form",
					typeId: "upload"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("upload_add", {}, NewUploadCommands);

//Edit Upload and resubmit command
const EditUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getuploadList(data);

		const drawerData = {
			"title": "{{upload.editUpload}}",
			"okText": data.status && data.status === UPLOAD_STATUS.REJECT_CONFIRMED ? getlocaleText("{{common.ReUpload}}") : getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '60%',
			className: "upload-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "upload",
					"formSchemaId": "uploadEdit_form",
					"isModal": true,
					"record": record,
					controlID: "uploadEdit_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "uploadEdit_form",
					strict: true,
				});
			},
			onCancelTransition: {
				type: ACTIONS.HIDE_DRAWER,
			},
			customState: {
				contextPath: {
					formSchemaId: "uploadEdit_form",
					typeId: "upload"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("upload_edit", {}, EditUploadCommand);

//CRF
const CrfEditUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getuploadList(data);
		const status = record["CRFFileDetails"] ? record["CRFFileDetails"].status : "DRAFT";
		const drawerData = {
			"title": "{{upload.CRFEdit}}",
			"okText": status === UPLOAD_STATUS.REJECT_CONFIRMED ? getlocaleText("{{common.ReUpload}}") : getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '60%',
			className: "upload-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "upload",
					"formSchemaId": "crfUpload_form",
					"isModal": true,
					"record": record,
					controlID: "crfUpload_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "crfUpload_form",
					strict: true,
				});
			},
			onCancelTransition: {
				type: ACTIONS.HIDE_DRAWER,
			},
			customState: {
				contextPath: {
					formSchemaId: "crfUpload_form",
					typeId: "upload"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("crf_edit", {}, CrfEditUploadCommand);

//PET
const PetEditUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getuploadList(data);
		const status = record["PETFileDetails"] ? record["PETFileDetails"].status : "DRAFT";
		const drawerData = {
			"title": "{{upload.PetEdit}}",
			"okText": status === UPLOAD_STATUS.REJECT_CONFIRMED ? getlocaleText("{{common.ReUpload}}") : getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '60%',
			className: "upload-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "upload",
					"formSchemaId": "petUpload_form",
					"isModal": true,
					"record": record,
					controlID: "petUpload_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "petUpload_form",
					strict: true,
				});
			},
			onCancelTransition: {
				type: ACTIONS.HIDE_DRAWER,
			},
			customState: {
				contextPath: {
					formSchemaId: "petUpload_form",
					typeId: "upload"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("pet_edit", {}, PetEditUploadCommand);

//MRI
const MriEditUploadCommand: CommandOptions<any> = {
	canExecute: (data) => {
		return true;
	},
	execute: async (data, transition) => {
		const record = getuploadList(data);
		const status = record["MRIFileDetails"] ? record["MRIFileDetails"].status : "DRAFT";
		const drawerData = {
			"title": "{{upload.MRIEdit}}",
			"okText": status === UPLOAD_STATUS.REJECT_CONFIRMED ? getlocaleText("{{common.ReUpload}}") : getlocaleText("{{common.Submit}}"),
			"cancelText": getlocaleText("{{user.cancel}}"),
			width: '60%',
			className: "upload-drawer",
			controlSchema: {
				name: "form",
				properties: {
					"ui:widget": "form",
					"typeId": "upload",
					"formSchemaId": "mriUpload_form",
					"isModal": true,
					"record": record,
					controlID: "mriUpload_form",
				},
			},
			onOk: () => {
				transition({
					type: "FORM_SUBMIT_CLICK",
					controlID: "mriUpload_form",
					strict: true,
				});
			},
			onCancelTransition: {
				type: ACTIONS.HIDE_DRAWER,
			},
			customState: {
				contextPath: {
					formSchemaId: "mriUpload_form",
					typeId: "upload"
				},
			},
		};

		transition({
			type: ACTIONS.SHOW_DRAWER,
			event: drawerData,
		});
	},
};
commandManager.registerCommand("mri_edit", {}, MriEditUploadCommand);

//download mri file command
const downloadMRIFile: CommandOptions<any> = {
	canExecute: (data: any) => {
		return true;
	},
	execute: async (data: any, transition: any) => {
		const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
		let uploadformData;
		transition({
			type: "FORM_GET_STATE",
			strict: true,
			controlID: "mriUpload_form",
			onData: (formData) => {
				uploadformData = formData["formValues"];
			},
		});
		if (!uploadformData) {
			transition({
				type: "FORM_GET_STATE",
				strict: true,
				controlID: "upload_form",
				onData: (formData) => {
					uploadformData = formData["formValues"];
				},
			});
		}
		try {
			const data = await datasource.getSignedURL({ id: uploadformData["MRIFileDetails"].id }, { id: TYPES.UPLOADS });
			if (data && data["signedUrl"]) {
				await datasource.downlodFile(data["signedUrl"]);
			} else {
				transition({
					type: "SHOW_NOTIFICATION",
					message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
					metadata: { "delay": 5 * 1000 },
					action: { "showCloseIcon": false },
				});
			}
		} catch (e) {
			const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
			let errorMessage = response ? response.message || response.errorMessage : null;
			if (!errorMessage) {
				errorMessage = "Error in network call";
			}
			transition({
				type: "SHOW_NOTIFICATION",
				message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
				metadata: { "delay": 5 * 1000 },
				action: { "showCloseIcon": false },
			});
		}
	},
};

commandManager.registerCommand("dowload_MRIfile", {}, downloadMRIFile);


//download crf file command
const downloadCRFFile: CommandOptions<any> = {
	canExecute: (data: any) => {
		return true;
	},
	execute: async (data: any, transition: any) => {
		const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
		let uploadformData;
		transition({
			type: "FORM_GET_STATE",
			strict: true,
			controlID: "crfUpload_form",
			onData: (formData) => {
				uploadformData = formData["formValues"];
			},
		});
		if (!uploadformData) {
			transition({
				type: "FORM_GET_STATE",
				strict: true,
				controlID: "upload_form",
				onData: (formData) => {
					uploadformData = formData["formValues"];
				},
			});
		}
		try {
			const data = await datasource.getSignedURL({ id: uploadformData["CRFFileDetails"].id }, { id: TYPES.UPLOADS });
			if (data && data["signedUrl"]) {
				await datasource.downlodFile(data["signedUrl"]);
			} else {
				transition({
					type: "SHOW_NOTIFICATION",
					message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
					metadata: { "delay": 5 * 1000 },
					action: { "showCloseIcon": false },
				});
			}
		} catch (e) {
			const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
			let errorMessage = response ? response.message || response.errorMessage : null;
			if (!errorMessage) {
				errorMessage = "Error in network call";
			}
			transition({
				type: "SHOW_NOTIFICATION",
				message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
				metadata: { "delay": 5 * 1000 },
				action: { "showCloseIcon": false },
			});
		}
	},
};

commandManager.registerCommand("dowload_CRFfile", {}, downloadCRFFile);

//download pet file command
const downloadPETFile: CommandOptions<any> = {
	canExecute: (data: any) => {
		return true;
	},
	execute: async (data: any, transition: any) => {
		const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
		let uploadformData;
		transition({
			type: "FORM_GET_STATE",
			strict: true,
			controlID: "petUpload_form",
			onData: (formData) => {
				uploadformData = formData["formValues"];
			},
		});
		if (!uploadformData) {
			transition({
				type: "FORM_GET_STATE",
				strict: true,
				controlID: "upload_form",
				onData: (formData) => {
					uploadformData = formData["formValues"];
				},
			});
		}
		try {
			const data = await datasource.getSignedURL({ id: uploadformData["PETFileDetails"].id }, { id: TYPES.UPLOADS });
			if (data && data["signedUrl"]) {
				await datasource.downlodFile(data["signedUrl"]);
			} else {
				transition({
					type: "SHOW_NOTIFICATION",
					message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
					metadata: { "delay": 5 * 1000 },
					action: { "showCloseIcon": false },
				});
			}
		} catch (e) {
			const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
			let errorMessage = response ? response.message || response.errorMessage : null;
			if (!errorMessage) {
				errorMessage = "Error in network call";
			}
			transition({
				type: "SHOW_NOTIFICATION",
				message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
				metadata: { "delay": 5 * 1000 },
				action: { "showCloseIcon": false },
			});
		}
	},
};

commandManager.registerCommand("dowload_PETfile", {}, downloadPETFile);

//export xlsx file command
const exportFileXlsx: CommandOptions<any> = {
	canExecute: (data: any) => {
		return true;
	},
	execute: async (data: any, transition: any) => {
		const datasource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
		try {

			transition({
				type: "SHOW_INDICATOR",
				loadingMessage: "{{Download.loading_message}}",
			});
			let filterParams, defaultVisibleColumnsParams;
			transition({
				type: "FORM_GET_STATE",
				strict: true,
				controlID: "upload_filter",
				onData: (formData) => {
					filterParams = formData["formValues"];
				},
			});
			transition({
				type: "GRID_GET_STATE",
				strict: true,
				controlID: "uploads",
				onData: (data) => {
					filterParams["orderBy"] = data.viewAttributes.orderBy;
					defaultVisibleColumnsParams = data.viewAttributes.defaultVisibleColumns;
				},
			});
			// to getSignedURL for xls file to export
			const data = await datasource.getSignedURL({ filterParams }, defaultVisibleColumnsParams);
			if (data && data["signedUrl"]) {
				await datasource.downlodFile(data["signedUrl"]);
				transition({
					type: "HIDE_INDICATOR",
				});
			} else {
				transition({
					type: "HIDE_INDICATOR",
				});
				transition({
					type: "SHOW_NOTIFICATION",
					message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
					metadata: { "delay": 5 * 1000 },
					action: { "showCloseIcon": false },
				});
			}
		} catch (e) {
			const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
			let errorMessage = response ? response.message || response.errorMessage : null;
			if (!errorMessage) {
				errorMessage = "Error in network call";
			}
			transition({
				type: "HIDE_INDICATOR",
			});
			transition({
				type: "SHOW_NOTIFICATION",
				message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
				metadata: { "delay": 5 * 1000 },
				action: { "showCloseIcon": false },
			});
		}
	},
};

commandManager.registerCommand("export_xlsx", {}, exportFileXlsx);
