import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { ItsyButton } from '@itsy-ui/common';
import { getlocaleText } from "@itsy-ui/utils"
import { LOCALSTORAGE_NAME, Roles } from "../utils/constant";
import { getCurrentUserRole } from "../utils/utils";
import { FaCircle } from "react-icons/fa";
import { getLoginUserObjectId } from "../common/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function renderSubjectIdControlByRole(cellValue) {
    const isSuperAdmin = getCurrentUserRole(Roles.SUPER_ADMIN);
    if (isSuperAdmin) {
        return <ItsyButton
            title={cellValue["subjectId"]}
            className="link-primary apn-link-button"
            commandName="subject_getupload"
            onContext={() => { return cellValue }}
        />
    }
    else
        return <span title={cellValue["subjectId"]}>{cellValue["subjectId"]}</span>
}

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    const displayName = cellValue["isActive"] === true ? getlocaleText("{{common.active}}") : getlocaleText("{{common.inactive}}")
    const class_active = cellValue["isActive"] === true ? "active-circle" : "inactive-circle"
    if (col.name === "isActive") {
        return <FaCircle className={class_active} title={displayName} />
    }
    const isSuperAdmin = getCurrentUserRole(Roles.SUPER_ADMIN);
    const isUploadUser = getCurrentUserRole(Roles.UPLOAD_USER);
    const loginUserId = getLoginUserObjectId();
    if (col.name === "customAction") {
        return <div className="apn-custom-action-button">
            {isSuperAdmin && <ItsyButton
                title={getlocaleText("{{Common.Delete}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="delete"
                commandName="DeleteSubject"
                onContext={() => { return cellValue }}
            />}
            {isUploadUser && loginUserId == cellValue["createdUser"].id && <ItsyButton
                title={getlocaleText("{{Common.Edit}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="edit"
                commandName="Edit_Subject"
                onContext={() => { return cellValue }}
            />}
        </div>
    }
    if (col.name === "subjectId") {
        return <div className="apn-custom-action-button">
            {renderSubjectIdControlByRole(cellValue)}
        </div>

    }
}

function doSubjectGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        let filterText = {};
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite) {
            filterText["siteId"] = selectedfilter_csite;
        }
        let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        if (selectedfilter_study) {
            filterText["studyId"] = selectedfilter_study;
        }
        let selectedfilter_classification = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CLASSIFICATION_VALUE);
        if (selectedfilter_classification) {
            filterText["classificationId"] = selectedfilter_classification;
        }
        let selectedfilter_sharingconsent = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_SHARINGCONSENT_VALUE);
        if (selectedfilter_sharingconsent && selectedfilter_sharingconsent !== "all") {
            filterText["sharingConsent"] = selectedfilter_sharingconsent;
        }
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            filterText: filterText,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const subjectGrid: ICustomStateMachineData = {
    name: "subjectGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onSubjectGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectGridInit: (evt) => dispatch(doSubjectGridInit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "subjects"
}, subjectGrid);