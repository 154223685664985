import { StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from "@itsy-ui/navigation";
import { useState, useEffect } from "react";
import { ItsyForm } from "@itsy-ui/form";

const title = {
    "title": "Terms of Use for APRINOIA Imaging System",
    "headerSize": "h5",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_scope = {
    "title": "Scope",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_agreement = {
    "title": "Agreement to Terms of Use",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_intellectualpropertyrights = {
    "title": "Intellectual Property Rights",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_idpasswordsecurity = {
    "title": "ID, Password and Security",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_useofsystem = {
    "title": "Use of the System",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_disclaimers = {
    "title": "Disclaimers",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_violation = {
    "title": "Violation of These Terms of Use",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}
const subTitle_miscellaneous = {
    "title": "Miscellaneous",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "left" }
}

const TermsofUsePage = (props) => {
    const [toolbarSchema, ToolbarSchema] = useState();
    const [termsFormSchema, TermsFormSchema] = useState();
    useEffect(() => {
        let currentToolbarSchema: any;
        let currentTermsFormSchema: any;
        const isAuthenticated = localStorage.getItem("FV_TENANT_INFO") !== null;
        if (!isAuthenticated) {
            currentToolbarSchema = {
                data: [
                    {
                        "name": "back_login",
                        "displayText": "{{common.back}}",
                        "isPrimary": true,
                        "enabled": true
                    }
                ],
                "widgetType": "bound",
                "reflowPageId": "termsofuse",
                "align": "right",
                "className": "termsofuse-button",
                "typeId": "termsofuse"
            };
        }
        else {
            const formSchema = {
                "id": "termsofuse_form",
                "displayName": "Termsofuse Form",
                "typeId": "termsofuse",
                "propertyDefinitions": {
                    "acceptTermsOfUse": {
                        "id": "acceptTermsOfUse",
                        "displayName": "",
                        "className": "termsofuseCheckbox",
                        "propertyType": "string",
                        "ui:widget": "checkbox",
                        "required": true,
                        "value": [
                            {
                                "key": "acceptTermsOfUse",
                                "value": "By ticking this box, you are confirming that you have read, understood, and agree to the Terms of Use"
                            }
                        ]
                    }
                }
            }
            currentTermsFormSchema = {
                typeId: "termsofuse_form",
                formSchema: formSchema,
                controlID: "termsofuse",
                submitButtonText: "{{common.continue}}"
            };
        }
        ToolbarSchema(currentToolbarSchema);
        TermsFormSchema(currentTermsFormSchema);
    }, []);

    return (<ItsyContainer className="">
        <br />
        <ItsyColumn className="">
            <ItsyLabel schema={title} />
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_scope} />
        </ItsyColumn>
        <ItsyColumn>
            <p>These Terms of Use apply to the websites, applications, software, and services under the APRINOIA Imaging System (https://dataarchive.aprinoia.com) (hereinafter the “System”), intended for the limited authorized use to you as agreed upon by the agreement executed between APRINOIA corporate group (APRINOIA Therapeutics Limited, APRINOIA Therapeutics Inc., APRINOIA Therapeutics, LLC and/or Suzhou APRINOIA Therapeutics Co, Ltd, hereinafter “APRINOIA”) and you (or the company or institute you are working for)(hereinafter the “Agreement”). If you are not an authorized person under any such an agreement, please DO NOT use or attempt to login to the System.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_agreement} />
        </ItsyColumn>
        <ItsyColumn>
            <p>The System is the sole property of APRINOIA. By using the System, you agree to these Terms of Use, including the amendment of these Terms of Use modified by APRINOIA at its sole discretion from time to time. Any amendment will be announced to you in your login process. You are required to read these Terms of Use and the amendments carefully. Your continued use of the System following the posting of amendments constitutes your acceptance and agreement to the amendments.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_intellectualpropertyrights} />
        </ItsyColumn>
        <ItsyColumn>
            <p>All text, images, graphics, user interfaces, trademarks, logos, tools, computer code or other forms of data (collectively, "Content") in the System is owned or licensed to APRINOIA, and is protected by copyright, patent and trademark laws and other intellectual property rights laws. You are authorized to use the Content only to the extent in accordance with the Agreement. You acknowledge the Content may include APRINOIA’s or third parties’ intellectual property rights and undertake that you will not use the Content in any way unlawfully or potentially invading the intellectual property rights.In the event you are authorized to upload certain Content to the System, you are responsible to ensure the uploading of such Content will not invade any third party’s the intellectual property rights.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_idpasswordsecurity} />
        </ItsyColumn>
        <ItsyColumn>
            <p>You are assigned an ID and a corresponding password to login to the System. You shall be responsible for keeping the ID and password in confidential, i.e.,preventing from intentionally or negligently disclosing the ID and password to any third party. You shall be responsible for any and all activities that occur under the login by your ID and password as a result of your failing to keep your ID and password secure and confidential. You need to notify APRINOIA immediately of any unauthorized use, suspected unauthorized use or any security issue which are or seems arising from a login by your ID and password.</p>
            <p>You shall not use any ID and password other than your ID and password to login to the system. Upon the expiration or termination of the Agreement or upon the termination of your employment or delegation relationship with your company or institute, as the case may be, you shall cease to use the System immediately.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_useofsystem} />
        </ItsyColumn>
        <ItsyColumn>
            <p>You agree to use the System and the Content in accordance with the guidance, instructions and documents provided by APRINOIA, either in the System or in other forms. You undertake the System and the Content will be used solely for the purpose set forth in the Agreement and will be in accordance with the terms and conditions set forth therein. You shall not use or attempt to use any portion of the System and/or the Content that was not definitely authorized to you. You shall be responsible for maintaining the security of your device, either a computer, a handhold device or others, for using in connecting the System to prevent from any unauthorized use of the System and/or the Content by any unauthorized person. For clarity, downloading the entire database is strictly prohibited.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_disclaimers} />
        </ItsyColumn>
        <ItsyColumn>
            <p>The System and All the Content are provided on an "as-is" and "as-available" basis. APRINOIA does not promise that the System or any content will be error-free or uninterrupted. APRINOIA disclaims all warranties, express or implied, including any warranties of accuracy, non-infringement, merchantability and fitness for a particular purpose. APRINOIA disclaims any and all liability for the acts, omissions and conduct of any third party in connection with or related to your use of the System or the Content. You assume total responsibility for your use of the System and the Content.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_violation} />
        </ItsyColumn>
        <ItsyColumn>
            <p>Any violation of these Terms of Use will constitute a material breach of the Agreement and will cause you and your company or institute be liable for such breach event. If the violation of these Terms of Use result in any demands, loss, liability, claims or expenses (including attorneys’ fees) made by any third party against APRINOIA due to or arising out of or in connection with such violation, you agree to indemnify and hold APRINOIA, its shareholders, directors, officers, employees, agents and consultants, harmless from such demands, loss, liability, claims and expenses.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn>
            <ItsyLabel schema={subTitle_miscellaneous} />
        </ItsyColumn>
        <ItsyColumn>
            <p>Except for the terms specifically stipulated herein, the Agreement shall govern the use of the System and the Content in all other respects, including the liability, the governing law and jurisdiction, and the dispute resolution.</p>
        </ItsyColumn>
        <br />
        <ItsyColumn className="">
            <StateManagerContext.Provider key="termsofuse" value={{ contextPath: { "id": "termsofuse" } }}>
                {termsFormSchema && <ItsyForm type="form" schema={termsFormSchema} />}
            </StateManagerContext.Provider>
        </ItsyColumn>
        <ItsyColumn className="">
            {toolbarSchema && <ItsyToolbar className="toolbar" schema={toolbarSchema} />}
        </ItsyColumn>
    </ItsyContainer>)
}

export default TermsofUsePage;