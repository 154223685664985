import { Switch, useHistory, Route, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTransition, WidgetsFactory, DataLoaderFactory, IAppSchemaProvider, eventTransition } from "@itsy-ui/core";
import { ItsyProvider } from "@itsy-ui/app";
import { PageWrapper, PublicpageWrapper, Login, ForgotPage, RestPage, Enroll, Success, Error, TermsofUsePage } from "./components";
import { AnalystVisitDataSource } from "./common/AnalystVisitDataSource";
import { UserDataSource } from "./common/UserDataSource";
import { SiteDataSource } from "./common/SiteDataSource";
import { StudyDataSource } from "./common/StudyDataSource";
import { QCDashboardDataSource } from "./common/QCDashboardDataSource";
import { CommentsDataSource } from "./common/CommentsDataSource";
import { SubjectDataSource } from "./common/SubjectDataSource";
import { UploadDataSource } from "./common/UploadDataSource";
import { VisitDataSource } from "./common/VisitDataSource";
import { ActivitiesDataSoruce } from "./common/ActivitiesDateSource";
import { RolesDataSource } from "./common/RolesDataSource";
import { publicUrls, Roles } from "./utils/constant";
import { ItsyColumn } from "@itsy-ui/layout";
import { ItsyImage } from '@itsy-ui/common';
import { CmcSiteDataSource } from "./common/CmcSiteDatasource";
import { CompoundDataSource } from "./common/CompoundDataSource";
import { BatchDataSource } from "./common/BatchDataSource";
import { UploadTypesDataSource } from "./common/UploadTypesDataSource";
import { CompoundMfgSubUsageDataSource } from "./common/CompoundMfgSubUsageDataSource";
import { getLocalization, getLanguageJson, getInitialNavigationUrl, setCurrentRole, getLogo } from "./utils/helpers";

const stateJSON = {
    "initial": "onLoaded",
    "states": {
        "onLoaded": {
            "on": {
                "INITIALIZE": "init",
                "NAVIGATE_URL": "navigateUrl",
                "USER_AUTHENTICATED": "userAuthenticate",
                "LANGUAGE_CHANGE": "languageChange"
            },
        },
        "init": {
            "onEntry": [
                "onInit",
            ],
            "on": {
                "INITIALIZE_DONE": "onLoaded",
            },
        },
        "navigateUrl": {
            "onEntry": [
                "onNavigateUrl",
            ],
            "on": {
                "NAVIGATION_DONE": "onLoaded",
            },
        },
        "userAuthenticate": {
            "onEntry": [
                "onAuthenticate",
            ],
            "on": {
                "AUTH_COMPLETE": "onLoaded",
            },
        },
        "languageChange": {
            "onEntry": [
                "onLanguageChange"
            ],
            "on": {
                "LANGUAGE_CHANGE_DONE": "onLoaded",
            }
        }
    },
};

// Define constants to access the states
const Actions = {
    State: {
        NAVIGATE_URL: "NAVIGATE_URL",
        NAVIGATION_DONE: "NAVIGATION_DONE",
        INITIALIZE_DONE: "INITIALIZE_DONE",
        INITIALIZE: "INITIALIZE",
        USER_AUTHENTICATE: "USER_AUTHENTICATE",
        AUTH_COMPLETE: "AUTH_COMPLETE",
        LANGUAGE_CHANGE: "LANGUAGE_CHANGE"
    },
    INIT: "Actions.INIT",
    AUTHENTICATE: "Actions.AUTHENTICATE",
    NAVIGATE: "Actions.NAVIGATE",
    UPDATALANGUAGE: "Actions.UPDATALANGUAGE"
};

// Reducer state to maintain in application state
const initialState = {
    history: null,
    isAuthenticated: false,
    initialLocation: {},
    language: "",
    message: null
};

/**
 * Function that maintains reducer state
 * @param state - application state in the reducer, this is an immutable state.
 * @param action - dispatcher action that triggers from action methods.
 * @returns 
 */
function reducer(state: any, action: any) {
    switch (action.type) {
        case Actions.INIT:
            return {
                history: action.history,
                isAuthenticated: action.isAuthenticated,
                initialLocation: action.initialLocation,
                language: action.language,
                message: action.message
            }
        case Actions.AUTHENTICATE:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated
            }
        case Actions.NAVIGATE:
            return {
                ...state,
                currentURL: action.url
            };
        case Actions.UPDATALANGUAGE:
            return {
                ...state,
                language: action.language,
                message: action.message
            }
        default:
            return state === undefined ? initialState :
                Object.keys(state).length === 0 ? initialState : state;
    }
}

/**
 * This action method initializes the application state and registers all the command.
 * It also uses a simple way to verify if user is authenticated and navigates to the route accordingly.
 * @param event - Event object for the INIT transition
 * @returns void
 */

async function initializeDatasource() {
    const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
    dataLoader.registerLoader({
        analystVisitDataSource: new AnalystVisitDataSource(),
        datasource: new UserDataSource(),
        userDataSource: new UserDataSource(),
        siteDataSource: new SiteDataSource(),
        studyDataSource: new StudyDataSource(),
        qcDashboardDataSource: new QCDashboardDataSource(),
        commentsDataSource: new CommentsDataSource(),
        subjectDataSource: new SubjectDataSource(),
        uploadDataSource: new UploadDataSource(),
        visitDataSource: new VisitDataSource(),
        activitiesDataSource: new ActivitiesDataSoruce(),
        rolesDataSource: new RolesDataSource(),
        cmcsiteDataSource: new CmcSiteDataSource(),
        compoundDataSource: new CompoundDataSource(),
        batchDataSource: new BatchDataSource(),
        uploadTypesDataSource: new UploadTypesDataSource(),
        compoundMfgSubUsageDataSource: new CompoundMfgSubUsageDataSource()
    });
    return true;
}

function initialNavigation(transition: any, dispatch: any) {
    const uri = new URL(`${window.location}`);
    const userInfo = JSON.parse(localStorage.FV_TENANT_INFO);
    const userSelectedRole = localStorage.SELECTED_ROLE ? JSON.parse(localStorage.SELECTED_ROLE) : setCurrentRole(userInfo);
    if (uri.pathname === "/enroll" || uri.pathname === "/forgotpassword" || uri.pathname === "/resetpassword" ||
        uri.pathname === "/success" || uri.pathname === "/error") {
        transition({
            type: Actions.State.NAVIGATE_URL,
            url: `${uri.pathname}${uri.search}`,
            persistCurrentUrl: false,
            shouldReplace: true
        });
    }
    else if (uri.pathname === "/login" || uri.pathname === "/termsofuse") {
        if (!userInfo.accept_termsofuse) {
            transition({
                type: "NAVIGATE_URL",
                url: "/termsofuse",//if terms of use not accepted 
                persistCurrentUrl: false,
                shouldReplace: true
            });
            return;
        }
        dispatch({
            type: Actions.UPDATALANGUAGE,
            language: userInfo.preferredLang,
            message: getLanguageJson(userInfo.preferredLang)
        });
        if (userSelectedRole) {
            // initialNavigation
            const url = getInitialNavigationUrl(userSelectedRole.role_name);
            transition({
                type: "NAVIGATE_URL",
                url: url, //after login page url
                persistCurrentUrl: false,
                shouldReplace: true
            });
        }
        else {
            transition({
                type: Actions.State.NAVIGATE_URL,
                url: "/roles",
                persistCurrentUrl: false,
                shouldReplace: true
            });
        }
    }
    else {
        if (!userInfo.accept_termsofuse) {
            transition({
                type: "NAVIGATE_URL",
                url: "/termsofuse",//if terms of use not accepted 
                persistCurrentUrl: false,
                shouldReplace: true
            });
            return;
        }
        if (!userSelectedRole) {
            transition({
                type: Actions.State.NAVIGATE_URL,
                url: "/roles",
                persistCurrentUrl: false,
                shouldReplace: true
            });
            return;
        }
        transition({
            type: Actions.State.NAVIGATE_URL,
            url: `${uri.pathname}${uri.search}`,
            persistCurrentUrl: false, //temporary changes
            shouldReplace: true
        });
    }
}

function doInit(event: any) {
    return async (_: any, dispatch: any, transition: any) => {
        const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
        const commandLoader: any = dataLoader.getLoader("commandLoader");
        const schemaProvider = dataLoader.getLoader<IAppSchemaProvider>("appSchemaProvider");
        await initializeDatasource();
        const customCommand = await schemaProvider.getSchema(`/app/command/data`);
        // initialize all commands
        commandLoader.generateCommand(customCommand);
        const isAuthenticated = localStorage.getItem("FV_TENANT_INFO") !== null;
        const userInfo = isAuthenticated && JSON.parse(localStorage.FV_TENANT_INFO);
        dispatch({
            type: Actions.INIT,
            history: event.history,
            isAuthenticated: isAuthenticated,
            initialLocation: event.location,
            language: userInfo ? userInfo.preferredLang : event.language,
            message: userInfo ? getLanguageJson(userInfo.preferredLang) : getLanguageJson(event.language)
        });
        transition({
            type: Actions.State.INITIALIZE_DONE,
        });
        if (!isAuthenticated) {
            const uri = new URL(`${window.location}`);
            if (publicUrls.includes(uri.pathname.substring(1))) {
                transition({
                    type: Actions.State.NAVIGATE_URL,
                    url: `${uri.pathname}${uri.search}`,
                    history: event.history
                });
            }
            else {
                transition({
                    type: Actions.State.NAVIGATE_URL,
                    url: `/login`, //signup
                    history: event.history
                });
            }
        } else {
            initialNavigation(transition, dispatch);
        }
    };
}

/**
 * This action method implements the navigation routing using transition API.
 * In the below code, it navigates to LOGIN page,
 * 
 * transition({
 *  type: "NAVIGATE_URL",
 *  url: "/login"
 * });
 * @param event - Event object with URL to navigate to. Only during the INIT phase the history object
 * is passed in the event.
 * @returns void
 */

function doNavigateUrl(event: any) {
    return (getState: any, dispatch: any, transition: any) => {
        const { url, persistCurrentUrl, shouldReplace } = event
        // replace history only if its different
        const { history, initialLocation } = getState();
        if (persistCurrentUrl && initialLocation && history && initialLocation.pathname !== "/" && initialLocation.pathname !== "/login") {
            // if page is refreshed on the same url
            if (initialLocation.pathname !== history.location.pathname && initialLocation.search !== history.location.search) {
                history.replace(initialLocation);
            }
        } else {
            const uri = new URL(`${window.location.origin}${url}`);
            if (history && (history.location.pathname !== uri.pathname || (history.location.pathname === uri.pathname && history.location.search !== uri.search))) {
                if (shouldReplace !== undefined && shouldReplace) {
                    history.replace(url);
                } else {
                    history.push(url);
                }
                dispatch({
                    type: Actions.NAVIGATE,
                    url
                });
            }
        }
        transition({
            type: Actions.State.NAVIGATION_DONE,
        });
    };
}

/**
 * This action method checks for authentication and navigates accordingly.
 * @param event - Provides the state if the user is authenticated
 * @returns void
 */
function doAuthenticate(event: any) {
    return (getState: any, dispatch: any, transition: any) => {
        dispatch({
            type: Actions.AUTHENTICATE,
            isAuthenticated: event.isAuthenticated
        });
        transition({
            type: Actions.State.AUTH_COMPLETE,
        });
        if (event.isAuthenticated) {
            initialNavigation(transition, dispatch);
        } else {
            transition({
                type: Actions.State.NAVIGATE_URL,
                url: `/login` //signup 
            });
        }
    };
}


function doLanguageChange(language: string) {
    return async (getState, dispatch: any, transition: any) => {
        if (language) {
            dispatch({
                type: Actions.UPDATALANGUAGE,
                language: language,
                message: getLanguageJson(language)
            });
            transition({
                type: "LANGUAGE_CHANGE_DONE",
            });
        }
    };
}

/**
 * MapDispatchToProps is a set of functions that are bound to the State JSON transition events.
 * This method is also available thru props.
 * @param dispatch - Dispatcher object that triggers the action methods
 * @returns Object
 */
const mapDispatchToProps = (dispatch: any) => {
    return {
        onNavigateUrl: (event: any) => dispatch(doNavigateUrl(event)),
        onInit: (event: any) => dispatch(doInit(event)),
        onAuthenticate: (event: any) => dispatch(doAuthenticate(event)),
        onLanguageChange: (event) => dispatch(doLanguageChange(event.language))
    };
};

const PublicRoutes = () => {
    const [imageSchema, setImageSchema] = useState();
    useEffect(() => {
        getLogo().then(response => {
            let currentImageSchema: any = {
                "displayName": "app_logo",
                "title": "App_logo",
                "alignment": "center",
                "image_type": "base64",
                "image_value":
                    response
            };
            setImageSchema(currentImageSchema);
        })
            .catch(error => {
                console.log(error);
            });
    }, []);
    return (<div className="apn-public-page-container">
        {imageSchema && <ItsyColumn hAlignment="left" className="apn-login-logo">
            <ItsyImage className="logo-image" schema={imageSchema} />
        </ItsyColumn>}
        <div className="apn-login-page container mw-100 h-100 d-flex">
            <Switch>
                <Route exact path="/login" render={() => <Login key="login" schema={{
                    pageId: "login"
                }} />} />
                <Route exact path="/forgotpassword" render={() => <ForgotPage key="forgotpassword" schema={{
                    pageId: "forgotpassword"
                }} />} />
                <Route exact path="/resetpassword" render={() => <RestPage key="resetpassword" schema={{
                    pageId: "resetpassword"
                }} />} />
                <Route exact path="/enroll" render={() => <Enroll key="enroll" schema={{
                    pageId: "enroll"
                }} />} />
                <Route exact path="/success" render={() => <Success key="success" schema={{
                    pageId: "success"
                }} />} />
                <Route exact path="/error" render={() => <Error key="error" schema={{
                    pageId: "error"
                }} />} />
                <Route exact path="/termsofuse" render={() => <TermsofUsePage key="termsofuse" schema={{
                    pageId: "termsofuse"
                }} />} />
                <Route exact path="/" render={() => <Redirect to="/login" />} />
            </Switch>
        </div>
    </div>
    );
};

const PrivateRoutes = () => {
    return <>
        <Switch>
            <Route exact path="/users" render={() => <PageWrapper key="users" schema={{
                pageId: "users"
            }} />} />
            <Route exact path="/welcome" render={() => <PageWrapper key="welcome" schema={{
                pageId: "welcome"
            }} />} />
            <Route exact path="/cmc-sites" render={() => <PageWrapper key="cmc-sites" schema={{
                pageId: "cmc-sites"
            }} />} />
            <Route exact path="/sites" render={() => <PageWrapper key="sites" schema={{
                pageId: "sites"
            }} />} />
            <Route exact path="/enroll" render={() => <PublicpageWrapper key="enroll" schema={{
                pageId: "enroll"
            }} />} />
            <Route exact path="/success" render={() => <PublicpageWrapper key="success" schema={{
                pageId: "success"
            }} />} />
            <Route exact path="/error" render={() => <PublicpageWrapper key="error" schema={{
                pageId: "error"
            }} />} />
            <Route exact path="/studies" render={() => <PageWrapper key="studies" schema={{
                pageId: "studies"
            }} />} />
            <Route exact path="/subjects" render={() => <PageWrapper key="subjects" schema={{
                pageId: "subjects"
            }} />} />
            <Route exact path="/dashboard" render={() => <PageWrapper key="dashboard" schema={{
                pageId: "dashboard"
            }} />} />
            <Route exact path="/uploads" render={() => <PageWrapper key="uploads" schema={{
                pageId: "uploads"
            }} />} />
            <Route exact path="/upload" render={() => <PageWrapper key="upload" schema={{
                pageId: "upload"
            }} />} />
            <Route exact path="/analystsearch" render={() => <PageWrapper key="analystsearch" schema={{
                pageId: "analystsearch"
            }} />} />
            <Route exact path="/analystresult" render={() => <PageWrapper key="analystresult" schema={{
                pageId: "analystresult"
            }} />} />
            <Route exact path="/activities" render={() => <PageWrapper key="activities" schema={{
                pageId: "activities"
            }} />} />
            <Route exact path="/compounds" render={() => <PageWrapper key="compounds" schema={{
                pageId: "compounds"
            }} />} />
            <Route exact path="/roles" render={() => <PageWrapper key="roles" schema={{
                pageId: "roles"
            }} />} />
            <Route exact path="/termsofuse" render={() => <PageWrapper key="termsofuse" schema={{
                pageId: "termsofuse"
            }} />} />
            <Route exact path="/forgotpassword" render={() => <PublicpageWrapper key="forgotpassword" schema={{
                pageId: "forgotpassword"
            }} />} />
            <Route exact path="/resetpassword" render={() => <PublicpageWrapper key="resetpassword" schema={{
                pageId: "resetpassword"
            }} />} />
            <Route exact path="/login" render={() => <PublicpageWrapper key="login" schema={{
                pageId: "login"
            }} />} />
            <Route exact path="/batches" render={() => <PageWrapper key="batches" schema={{
                pageId: "batches"
            }} />} />
            <Route exact path="/uploadSummary" render={() => <PageWrapper key="uploadSummary" schema={{
                pageId: "uploadSummary"
            }} />} />
            <Route exact path="/compoundMfgSubUsages" render={() => <PageWrapper key="compoundMfgSubUsages" schema={{
                pageId: "compoundMfgSubUsages"
            }} />} />
            <Route exact path="/" render={() => <Redirect to="/users" />} />
        </Switch>
    </>;
};

export const Main: React.FC = (props: any) => {
    const history = useHistory();
    const [state, transition]: any[] = useTransition("Main", reducer, mapDispatchToProps, stateJSON);
    useEffect(() => {
        document.addEventListener("keyup", event => {
            if (event && event.keyCode === 27) {
                eventTransition({
                    type: "HIDE_DRAWER",
                });
            }
        });
        async function fetchLanguage() {
            const lang = await getLocalization()
            if (!state.isAuthenticated && lang) {
                transition({
                    type: "LANGUAGE_CHANGE",
                    language: lang,
                })
            }
            transition({
                type: Actions.State.INITIALIZE,
                location: {
                    pathname: history.location.pathname !== undefined ? history.location.pathname : "",
                    search: history.location.search !== undefined ? history.location.search : ""
                },
                history,
                language: lang
            });
        }
        fetchLanguage()
    }, []);
    return (
        <ItsyProvider locale={state.language} messages={state.message}>
            {!state.isAuthenticated ? <PublicRoutes /> :
                <PrivateRoutes />
            }
        </ItsyProvider>
    );
};
