import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { LOCALSTORAGE_NAME } from "../../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doBatchFilterbarBeforeChange(value: {}, formValues: {}) {
    return async (getState, dispatch, transition) => {
        const { formValues, metadata } = getState();
        let currentFormValues;
        const updatedValues = {
            ...value,
        }
        currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            controlID: "batch_filter",
            strict: true,
        });
        transition({
            controlID: "batches",
            strict: true,
            type: "GRID_FILTER",
            searchValue: {
                cmcSiteId: currentFormValues["cmcSiteId"], lastDays: currentFormValues["lastDays"]
            }
        });
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE, currentFormValues["cmcSiteId"]);
    }
}

function doBatchFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_msite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE);
        const customeobjectData = {
            ...objectData,
            "cmcSiteId": selectedfilter_msite ? selectedfilter_msite : "all",
            "lastDays": "all"
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });
    }
}


const batchFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onBatchFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onBatchFilterbarBeforeChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onBatchFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doBatchFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onBatchFilterbarBeforeChange: ({ value, formValues }) => dispatch(doBatchFilterbarBeforeChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "batch_filter"
}, batchFormHandler);