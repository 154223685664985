import { getFormatedDateTime, getHeaders } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class ActivitiesDataSoruce {

    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config;
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
       //form and to date settime
        const fromDate = parameters.filter !== undefined && parameters.filter["from_date"] !== undefined ? `${parameters.filter["from_date"]} 00:00:00` : undefined
        const toDate = parameters.filter !== undefined && parameters.filter["to_date"] !== undefined ? `${parameters.filter["to_date"]} 23:59:59` : undefined
       //from and to date filter
        if (parameters.filter !== undefined && fromDate !== undefined && toDate !== undefined) {
            parameters.filter = {
                "where": {
                    "createdDate": { "between": [fromDate, toDate] }
                }
            }
        } else if (parameters.filter !== undefined && fromDate && toDate === undefined) {
            parameters.filter = {
                "where": {
                    "createdDate": { "gte": [fromDate] }
                }
            }
        } else if (parameters.filter !== undefined && fromDate === undefined && toDate !== undefined) {
            parameters.filter = {
                "where": {
                    "createdDate": { "lte": [toDate] }
                }
            }
        }
        try {
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            data.map((item, i) => {
                data[i]["createdDate"] = item["createdDate"] ? getFormatedDateTime(item["createdDate"]) : null;
            })
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

}