import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import { Row, Col } from "react-bootstrap";
import { getlocaleText } from "@itsy-ui/utils";
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import { getFormLabelClassName } from '../../helper';

class InputPasswordTextBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showPassword: false,
		}
		this.visbileIconClick = this.visbileIconClick.bind(this);
	}

	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}
	visbileIconClick() {
		this.setState({
			showPassword: !this.state.showPassword,
		});
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema, gridStyle } = controlProps;
		const type = fieldSchema.type !== undefined ? fieldSchema.type : "text";
		const customClass = fieldSchema.className ? fieldSchema.className : "";
		const customStyle = fieldSchema.style ? fieldSchema.style : {};
		if (controlProps.isReadonly) {
			return (
				<Form.Group className={customClass} style={customStyle}>
					<Row>
						<Col xs={6} sm={6} md={6} lg={6}>
							<Form.Label >{`${fieldSchema.displayName}:`}</Form.Label>
						</Col>
						<Col xs={6} md={6} lg={6}>
							<Form.Label>{controlProps.value}</Form.Label>
						</Col>
					</Row>
				</Form.Group>
			);
		}
		return (
			(fieldSchema.visibility === undefined || fieldSchema.visibility) &&
			<>
				<Form.Group className={customClass} style={customStyle} controlId={fieldSchema.id}>
					<Form.Label className={getFormLabelClassName(fieldSchema)}>{fieldSchema.displayName}</Form.Label>
					<div className="input-textbox">
						<InputGroup>
							<Form.Control
								type={type === "password" ? this.state.showPassword ? "text" : "password" : type}
								required={fieldSchema.required}
								disabled={fieldSchema.readOnly}
								placeholder={fieldSchema.placeholderText}
								value={controlProps.value !== undefined ? controlProps.value : ""}
								maxLength={fieldSchema.maxLength}
								className={controlProps.error ? 'form-control error' : 'form-control'}
								onBlur={(v) => {
									controlProps.handleBlur &&
										controlProps.handleBlur(v, v.target.value);
								}}
								onChange={e => controlProps.handleChange && controlProps.handleChange(e, e.target.value)}
							/>
							<InputGroup.Append>
								<InputGroup.Text>
									{this.state.showPassword ? <BsEyeFill className="show-password" onClick={this.visbileIconClick} /> : <BsEyeSlashFill className="hide-password" onClick={this.visbileIconClick} />}
								</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
						{fieldSchema.helptext && <Form.Text className="helping-text">{getlocaleText(fieldSchema.helptext)}</Form.Text>}
						{controlProps.error &&
							<Form.Text id="component-error-text">{controlProps.error}</Form.Text>
						}
					</div>
				</Form.Group>
			</>
		);
	}
}

InputPasswordTextBox['displayName'] = 'InputPasswordTextBox';

WidgetsFactory.instance.registerFactory(InputPasswordTextBox);
WidgetsFactory.instance.registerControls({
	password_text: "InputPasswordTextBox"
});