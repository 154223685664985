import { getHeaders, getCreatedBy } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { DROPDOWN, TYPES, Roles } from "../utils/constant";
import { getlocaleText } from "@itsy-ui/utils";
import { getCurrentUserRole, getServerUrl } from "../utils/utils"
import { getAllSiteIds } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class CompoundDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            const dropdownId = typeId;
            if (dropdownId === DROPDOWN.COMPOUNDS) {
                typeId = TYPES.COMPOUNDS;
                parameters.filter = {
                    ...parameters.filter,
                    "order": "compound_name ASC",
                    "where": {
                        "isActive": true,
                    },
                    fields: { id: true, compound_name: true }
                }
            }
            if (dropdownId === DROPDOWN.CMC_SITES_COMPOUNDS) {
                typeId = TYPES.CMC_SITES_COMPOUNDS;
                let cmcSiteId = parameters.cmcSiteId ? parameters.cmcSiteId : "";
                parameters.filter = {
                    ...parameters.filter,
                    // "order": "compound_name ASC",
                    "where": {
                        "isActive": true,
                        "cmcSiteId": cmcSiteId
                    },
                    // fields: { id: true, compound_name: true }
                }
            }
            if (dropdownId === DROPDOWN.ALL_CMC_SITES_COMPOUNDS) {
                typeId = TYPES.CMC_SITES_COMPOUNDS;
                let cmcSiteId = parameters.cmcSiteId ? parameters.cmcSiteId : "";
                if (!cmcSiteId) {
                    cmcSiteId = await getAllSiteIds();
                    let cmcSiteIdsArray = cmcSiteId.split(',');
                    cmcSiteIdsArray.splice(cmcSiteIdsArray.indexOf("all"), 1);
                    cmcSiteId = { "inq": cmcSiteIdsArray }
                }
                parameters.filter = {
                    ...parameters.filter,
                    "where": {
                        "isActive": true,
                        "cmcSiteId": cmcSiteId
                    },
                }
            }
            let data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            data.map((item, i) => {
                data[i]["createdBy"] = getCreatedBy(item);
            });

            if (dropdownId === DROPDOWN.CMC_SITES_COMPOUNDS || dropdownId === DROPDOWN.ALL_CMC_SITES_COMPOUNDS) {
                let compoundData = [];
                data.map((item, i) => {
                    compoundData.push({ id: item["compounds"].id, compound_name: item["compounds"].compound_name });
                });
                data = compoundData;
            }
            if (dropdownId.includes("all")) {
                data.unshift({
                    "compound_name": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                });
            }
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async compoundUpsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let object = {
                "id": record["id"],
                "compound_name": record["compound_name"],
                "note": record["note"],
                "isActive": record["isActive"]
            }
            return await this.update(object, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

}