import { StateManagerContext, IDataSourceLake, WidgetsFactory, DataLoaderFactory, useTransition } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid } from "@itsy-ui/data";
import { ItsyToolbar } from "@itsy-ui/navigation"
import { getLocalStorageItem } from "../common/lbHelpers";
import { getlocaleText } from "@itsy-ui/utils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
const Gridschema = {
    typeId: "search",
    gridSchemaId: "analyst",
    dataSource: "analystVisitDataSource",
    rowSelectionMode: 2,
    controlID: "analyst_result",
    primaryColumn: "id",
}

const toolbarSchema = {
    data: [
        {
            "name": "download_result",
            "displayText": "{{result.download}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "cloud_download",
            "roles": []
        },
        {
            "name": "select_download_record",
            "displayText": "{{result.selectdownload}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "cloud_download",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "result",
    "align": "right",
    "className": "result-download-button",
    "typeId": "download-files"
};

const criteriaDisplay = (searchCriteria, siteNames, classification) => {
    const displayText = [];
    //filetype is required and thus always listed, so as long as it is not undefined list filetypes
    searchCriteria.fileType !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.fileType}}")} ${searchCriteria.fileType.join(', ')}`)
    //if siteId is listed, display siteId, otherwise display "Sites: All"
    siteNames === undefined || siteNames === false || siteNames.length === 0 ? displayText.push(`${getlocaleText("{{searchCriteria.sitesAll}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.sites}}")} ${siteNames.join(', ')}`)
    //if gender is undefined, All was selected, otherwise Male or Female was selected
    searchCriteria.gender === undefined ? displayText.push(`${getlocaleText("{{searchCriteria.genderAll}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.gender}}")} ${searchCriteria.gender === "male" ? getlocaleText("{{gender.male}}") : getlocaleText("{{gender.female}}")}`)
    //if classification is defined, then list, otherwise do not list
    classification === undefined || classification === false || classification.length === 0 ? displayText.push(`${getlocaleText("{{searchCriteria.classificationAll}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.classification}}")} ${classification.join(', ')}`)
    //if scandate is undefined, is all, otherwise display between dates
    if (searchCriteria.uploadDate === undefined && searchCriteria.visitDate === undefined) {
        if (searchCriteria.scanDateSelectorFor === "uploadDate")
            displayText.push(`${getlocaleText("{{searchCriteria.uploadDateAll}}")}`)
        if (searchCriteria.scanDateSelectorFor === "visitDate")
            displayText.push(`${getlocaleText("{{searchCriteria.visitDateAll}}")}`)
    }
    if (searchCriteria.uploadDate) {
        if (searchCriteria.uploadDate.min !== undefined && searchCriteria.uploadDate.max !== undefined && searchCriteria.uploadDate.min.length !== 0 && searchCriteria.uploadDate.max.length !== 0) { //between
            displayText.push(`${getlocaleText("{{searchCriteria.uploadDateBetween}}")} ${searchCriteria.uploadDate.min.substring(0, 10)} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.uploadDate.max.substring(0, 10)}`)
        } else if (searchCriteria.uploadDate.max !== undefined && searchCriteria.uploadDate.max.length !== 0) { //min is 0, so after
            displayText.push(`${getlocaleText("{{searchCriteria.uploadDateAfter}}")} ${searchCriteria.uploadDate.max.substring(0, 10)}`)
        } else if (searchCriteria.uploadDate.min !== undefined && searchCriteria.uploadDate.min.length !== 0) { //max is 0, so before
            displayText.push(`${getlocaleText("{{searchCriteria.uploadDateBefore}}")} ${searchCriteria.uploadDate.min.substring(0, 10)}`)
        }
    }
    else if (searchCriteria.visitDate) {
        if (searchCriteria.visitDate.min !== undefined && searchCriteria.visitDate.max !== undefined && searchCriteria.visitDate.min.length !== 0 && searchCriteria.visitDate.max.length !== 0) { //between
            displayText.push(`${getlocaleText("{{searchCriteria.visitDateBetween}}")} ${searchCriteria.visitDate.min.substring(0, 10)} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.visitDate.max.substring(0, 10)}`)
        } else if (searchCriteria.visitDate.max !== undefined && searchCriteria.visitDate.max.length !== 0) { //min is 0, so after
            displayText.push(`${getlocaleText("{{searchCriteria.visitDateAfter}}")} ${searchCriteria.visitDate.max.substring(0, 10)}`)
        } else if (searchCriteria.visitDate.min !== undefined && searchCriteria.visitDate.min.length !== 0) { //max is 0, so before
            displayText.push(`${getlocaleText("{{searchCriteria.visitDateBefore}}")} ${searchCriteria.visitDate.min.substring(0, 10)}`)
        }
    }

    //if subjectId is defined, then list, otherwise do not list
    if (searchCriteria.subjectId !== undefined && searchCriteria.subjectId.length !== 0) {
        displayText.push(`${getlocaleText("{{searchCriteria.subjectId}}")}${searchCriteria.subjectId}`)
    }
    //based on age criteria, display:
    if (searchCriteria.age !== undefined && searchCriteria.age.min === undefined && searchCriteria.age.max !== undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.age}}")} ${searchCriteria.age.max} ${getlocaleText("{{searchCriteria.yearsYounger}}")}`);
    } else if (searchCriteria.age !== undefined && searchCriteria.age.min !== undefined && searchCriteria.age.max === undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.ageAtLeast}}")} ${searchCriteria.age.min} ${getlocaleText("{{searchCriteria.ageYears}}")}`);
    } else if (searchCriteria.age !== undefined && searchCriteria.age.min !== undefined && searchCriteria.age.max !== undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.ageBetween}}")} ${searchCriteria.age.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.age.max}${getlocaleText("{{searchCriteria.ageYears}}")}`);
    } else {
        displayText.push(getlocaleText("{{searchCriteria.yearsAny}}"))
    }
    //based on weight criteria, display:
    if (searchCriteria.weight !== undefined && searchCriteria.weight.min !== undefined && searchCriteria.weight.max === undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.weightAtLeast}}")} ${searchCriteria.weight.min}kg`);
    } else if (searchCriteria.weight !== undefined && searchCriteria.weight.min === undefined && searchCriteria.weight.max !== undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.weightLessThan}}")} ${searchCriteria.weight.max}kg`);
    } else if (searchCriteria.weight !== undefined && searchCriteria.weight.min !== undefined && searchCriteria.weight.max !== undefined) {
        displayText.push(`${getlocaleText("{{searchCriteria.weightBetween}}")} ${searchCriteria.weight.min}kg ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.weight.max}kg`);
    } else {
        displayText.push(getlocaleText("{{searchCriteria.weightAny}}"))
    }
    //neuro criteria
    searchCriteria.mmse !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.MMSE}}")} ${searchCriteria.mmse.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.mmse.max}`)
    searchCriteria.cdr !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.CDR}}")} ${searchCriteria.cdr.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.cdr.max}`)
    searchCriteria.adas_cog !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.ADASCOG}}")} ${searchCriteria.adas_cog.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.adas_cog.max}`)
    searchCriteria.fab !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.FAB}}")} ${searchCriteria.fab.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.fab.max}`)
    searchCriteria.psprs !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.PSPRS}}")} ${searchCriteria.psprs.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.psprs.max}`)
    searchCriteria.updrs !== undefined && displayText.push(`${getlocaleText("{{searchCriteria.UPDRS}}")} ${searchCriteria.updrs.min} ${getlocaleText("{{searchCriteria.and}}")} ${searchCriteria.updrs.max}`)
    //dicom criteria
    searchCriteria.pet_dicom_available !== undefined ? searchCriteria.pet_dicom_available && searchCriteria.pet_dicom_available !== "false" ? displayText.push(`${getlocaleText("{{searchCriteria.PETDicom}}")} ${getlocaleText("{{common.yes}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.PETDicom}}")} ${getlocaleText("{{common.no}}")}`) : displayText.push();
    searchCriteria.apn_pet_dicom_available !== undefined ? searchCriteria.apn_pet_dicom_available && searchCriteria.apn_pet_dicom_available !== "false" ? displayText.push(`${getlocaleText("{{searchCriteria.APNDicom}}")} ${getlocaleText("{{common.yes}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.APNDicom}}")} ${getlocaleText("{{common.no}}")}`) : displayText.push()
    searchCriteria.sharingConsent === null || searchCriteria.sharingConsent === undefined ? displayText.push(`${getlocaleText("{{searchCriteria.sharingConsent}}")} ${getlocaleText("{{common.allowed}}")}, ${getlocaleText("{{common.notAllowed}}")}`) : searchCriteria.sharingConsent ? displayText.push(`${getlocaleText("{{searchCriteria.sharingConsent}}")} ${getlocaleText("{{common.allowed}}")}`) : displayText.push(`${getlocaleText("{{searchCriteria.sharingConsent}}")} ${getlocaleText("{{common.notAllowed}}")}`)
    return displayText;
}

const stateJSON = {
    "initial": "onLoaded",
    "states": {
        "onLoaded": {
            "on": {
                "SELECT_RESULT_RECORD": "resultRecord",
                "RESULT_GET_STATE": "resultGetSate"
            },
        },
        "resultRecord": {
            "onEntry": [
                "onresultRecord",
            ],
            "on": {
                "resultRecord": "onLoaded",
            },
        },
        "resultGetSate": {
            "onEntry": [
                "onResultGetState"
            ],
            "on": {
                "RESULT_STATE": "onLoaded"
            }
        }
    },
};

function doresultRecord(event) {
    return (getState: any, dispatch: any, transition: any) => {
        dispatch({
            type: "UPDARE_RECORD",
            record: event.record
        });
        transition({
            type: "resultRecord"
        })
    }
}

export function doResultGetState(onResultData: any) {
    return async (getState, _dispatch: any, transition: any) => {
        transition({
            type: "RESULT_STATE",
        });
        const resultState = getState();
        onResultData.call(null, resultState)
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onresultRecord: (event: any) => dispatch(doresultRecord(event)),
        onResultGetState: (event: any) => dispatch(doResultGetState(event.onResultData))
    };
};

const initialState = {
    resultRecord: null
};

function reducer(state: any, action: any) {
    switch (action.type) {
        case "UPDARE_RECORD":
            return {
                resultRecord: action.record,
            }
        default:
            return state === undefined ? initialState :
                Object.keys(state).length === 0 ? initialState : state;
    }
}

const AnalystResult = (props) => {
    const [state, transition]: any[] = useTransition("AnalystResult", reducer, mapDispatchToProps, stateJSON);
    const [siteItem, setSiteIds] = useState([])
    const [classItem, setClassIds] = useState([])
    const searchCriteria = getLocalStorageItem("ANALYST_SEARCH_REQUEST");
    const displayText = searchCriteria && criteriaDisplay(searchCriteria, siteItem, classItem);
    const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
    const datasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
    const subjectDatasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
    let  analystDetails = localStorage.getItem("ANALYST_SEARCH_REQUEST")
    let history = useHistory();
    const  navigating =()=>{
    if(analystDetails == null){
    history.push("/analystsearch")
}
}
    useEffect(() => {
    navigating()
      async function updateData() {
            const siteIds = [];
            const classIds = [];
            const sitesData = await datasource.getAll(`all_sites_dropdown`, {});
            if (searchCriteria !== undefined) {
                const selectedSiteIds = searchCriteria["siteIds"];
                if (selectedSiteIds !== undefined && selectedSiteIds.length > 0) {
                    if (selectedSiteIds[0].indexOf("all") > -1) {
                        siteIds.push("All");
                    } else {
                        selectedSiteIds.filter(item => {
                            sitesData.map(e => {
                                if (e.id === item) {
                                    siteIds.push(e.siteName);
                                }
                            })
                        })
                    }
                } else {
                    siteIds.push("All");
                }
            }

            const classificationData = await subjectDatasource.getAll(`all_classifications_dropdown`, {});
            if (classificationData !== undefined && classificationData.length > 0 && searchCriteria !== undefined && searchCriteria["classificationIds"] !== undefined) {
                searchCriteria["classificationIds"].filter(id => {
                    classificationData.map(e => {
                        if (e.id === id) {
                            classIds.push(e.name);
                        }
                    })
                })
            } else if (searchCriteria !== undefined && searchCriteria["classificationIds"] === undefined) {
                classIds.push("All")
            }
            setSiteIds(siteIds);
            setClassIds(classIds);
        }
        updateData()
    }, [])

    return (
        <ItsyContainer className="upload-page">
            <ItsyRow>
                {searchCriteria === undefined ? <span>{getlocaleText("{{common.noRecord}}")}</span> : siteItem !== undefined && siteItem.length > 0 && displayText ? <>
                    <span><b>{searchCriteria.count} {getlocaleText("{{search.resultText}}")}</b> {getlocaleText("{{search.matchcriteri}}")}</span>
                    <span>&nbsp;{displayText.join(', ') + '.'}</span></> : <span>{getlocaleText("{{search.loadingMessage}}")}</span>
                }
            </ItsyRow>
            <ItsyRow>
                <ItsyColumn>
                    <StateManagerContext.Provider key="result" value={{ contextPath: { "id": "result_download" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow >
                {siteItem !== undefined && siteItem.length > 0 ?
                    <StateManagerContext.Provider key="analyst_result" value={{ contextPath: { "id": "analyst_result" } }}>
                        <ItsyGrid style={{ "width": "100%" }} className="analyst_result_table" schema={Gridschema} />
                    </StateManagerContext.Provider> : <span>{getlocaleText("{{search.resultdataMessage}}")}</span>
                }
            </ItsyRow>
        </ItsyContainer>
    )
}

export default AnalystResult;
