import { getHeaders, getCreatedBy } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { DROPDOWN, TYPES, Roles } from "../utils/constant";
import { getlocaleText } from "@itsy-ui/utils";
import { getLoginUserSites } from "../utils/helpers";
import { getCurrentUserRole, getServerUrl } from "../utils/utils"

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class SiteDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            const dropdownId = typeId;
            if (dropdownId.includes("dropdown")) {
                typeId = TYPES.SITES;
                parameters.filter = {
                    ...parameters.filter,
                    "order": "siteName ASC",
                    "where": {
                        "isActive": true
                    },
                    fields: { id: true, siteName: true }
                }
            }
            let data = [];
            if (!getCurrentUserRole(Roles.UPLOAD_USER)) {
                data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            }
            if (dropdownId.includes("dropdown") && (getCurrentUserRole(Roles.ADMIN) || getCurrentUserRole(Roles.QC_USER) || getCurrentUserRole(Roles.UPLOAD_USER))) {
                let sites = await getLoginUserSites();
                if (sites && sites.length > 0) {
                    sites.map((site) => {
                        if (data.length > 0) {
                            let flag = false;
                            data.some((ele) => {
                                if (ele.id === site["id"]) {
                                    flag = false;
                                    return true;
                                }
                                else {
                                    flag = true;
                                    return false;
                                }
                            })
                            if (flag) {
                                data.unshift({
                                    "id": site["id"],
                                    "siteName": site["siteName"]
                                });
                            }

                        } else {
                            data.unshift({
                                "id": site["id"],
                                "siteName": site["siteName"]
                            });
                        }
                    })
                    data.sort((a, b) => a.siteName.localeCompare(b.siteName));
                }
            }
            if (dropdownId.includes("all")) {
                let siteIdArray = ["all"];
                data.map((item, i) => {
                    siteIdArray.push(data[i]["id"]);
                });
                const siteIdsStr = siteIdArray.toString();
                data.unshift({
                    "siteName": getlocaleText("{{dropdown.option_all}}"),
                    "id": siteIdsStr,
                });
                localStorage.setItem("DROPDOWN_ALL_SITE_IDS", siteIdsStr);
            }
            if (dropdownId === TYPES.SITES) {
                data.map((item, i) => {
                    data[i]["createdBy"] = getCreatedBy(item);
                    data[i]["cmc_site_name"] = item["cmc_site"] !== undefined ? item["cmc_site"]["cmc_site_name"] : undefined;
                });
            }
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async siteUpsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let object = {
                "id": record["id"],
                "siteName": record["siteName"],
                "siteManagerName": record["siteManagerName"],
                "adminComments": record["adminComments"] !== undefined && record["adminComments"] ? record["adminComments"] : "",
                "isActive": record["isActive"],
                "cmcSiteId": record["cmcSiteId"] ? record["cmcSiteId"] : ""
            }
            return await this.update(object, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

}