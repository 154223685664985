import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import { getlocaleText } from "@itsy-ui/utils";

class CustomeLabelControl extends React.Component<IWidgetControlProps, {}> {

	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const { displayName, headerTag, headerSize, style, alignText, className } = fieldSchema;
		const CustomTag = headerTag ? headerTag : headerSize;
		const alignstyle = alignText !== undefined && alignText === "right" ? "flex-end" : alignText === "center" ? "center" : "flex-start";
		const customStyle = style ? { justifyContent: alignstyle, display: "flex", ...style } : { justifyContent: alignstyle, display: "flex" };
		const customClass = className ? `${className} label-root-contain` : "label-root-contain";

		if (CustomTag) {
			return (
				<div style={customStyle} className={fieldSchema.isRequired? `${customClass} ${'required-field'}` :customClass} >
					<CustomTag tabIndex={0} aria-label={displayName}>{displayName}</CustomTag>
				</div>
			);
		} else {
			return (
				<label style={customStyle} className={customClass} tabIndex={0} aria-label={displayName}>
					{displayName}
				</label>
			);
		}
	}
}

CustomeLabelControl["displayName"] = "CustomeLabelControl";

WidgetsFactory.instance.registerFactory(CustomeLabelControl);
WidgetsFactory.instance.registerControls({
	apn_custome_label_control: "CustomeLabelControl",
});
