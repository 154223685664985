import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';


const CMCSiteListName = {
    "title": "{{cmcsite.cmcsiteList}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "cmcsite_add",
            "displayText": "{{cmcsite.addCmcSite}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "cmc-sites",
    "align": "right",
    "className": "cmcsite-list-added",
    "typeId": "cmcsite_add"
};

const Gridschema = {
    typeId: "cmc-sites",
    gridSchemaId: "cmc-sites",
    dataSource: "cmcsiteDataSource",
    rowSelectionMode: 1,
    controlID: "cmc-sites"
}

const CMCSiteList = (props) => {
    return (
        <ItsyContainer className="cmcsite-management-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={CMCSiteListName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="cmc-site" value={{ contextPath: { "id": "cmcsite_create_form" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="cmc-sites" value={{ contextPath: { "id": "cmc-sites" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="cmcsite-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default CMCSiteList;