import { getHeaders, getCreatedBy } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { DROPDOWN, TYPES, Roles } from "../utils/constant";
import { getlocaleText } from "@itsy-ui/utils";
import { getFilterQuery, getLoginUserCmcSites } from "../utils/helpers";
import { getCurrentUserRole, getServerUrl } from "../utils/utils"

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class CmcSiteDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        await getFilterQuery(parameters, undefined);
        try {
            const dropdownId = typeId;
            if (dropdownId === DROPDOWN.CMCSITES || dropdownId === DROPDOWN.FILTER_CMCSITES_DROPDOWN) {
                typeId = TYPES.CMCSITES;
                parameters.filter = {
                    ...parameters.filter,
                    "order": "cmc_site_name ASC",
                    "where": {
                        "isActive": true
                    },
                    fields: { id: true, cmc_site_name: true }
                }
            }
            let data = [];
            if (!getCurrentUserRole(Roles.CMC_UPLOAD_USER)) {
                data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            }
            if (dropdownId.includes("dropdown") || dropdownId.includes("showDataText") && (getCurrentUserRole(Roles.CMC_UPLOAD_USER) || getCurrentUserRole(Roles.ADMIN) || getCurrentUserRole(Roles.CMC_ADMIN))) {
                let cmcSites = await getLoginUserCmcSites();
                if (cmcSites && cmcSites.length > 0) {
                    cmcSites.map((msite) => {
                        if (msite && msite["isActive"]) {
                            if (data.length > 0) {
                                let flag = false;
                                data.some((ele) => {
                                    if (ele.id === msite["id"]) {
                                        flag = false;
                                        return true;
                                    }
                                    else {
                                        flag = true;
                                        return false;
                                    }
                                })
                                if (flag) {
                                    data.unshift({
                                        "id": msite["id"],
                                        "cmc_site_name": msite["cmc_site_name"]

                                    });
                                }

                            } else {
                                data.unshift({
                                    "id": msite["id"],
                                    "cmc_site_name": msite["cmc_site_name"]
                                });
                            }
                        }
                    })
                    data.sort((a, b) => a.cmc_site_name.localeCompare(b.cmc_site_name));
                }
            }

            data.map((item, i) => {
                data[i]["createdBy"] = getCreatedBy(item);
            });
            if (dropdownId === DROPDOWN.FILTER_CMCSITES_DROPDOWN) {
                let cmcSiteIdArray = ["all"];
                data.map((item, i) => {
                    cmcSiteIdArray.push(data[i]["id"]);
                });
                const cmcSiteIdsStr = cmcSiteIdArray.toString();
                localStorage.setItem("DROPDOWN_ALL_CMCSITE_IDS", cmcSiteIdsStr);
                const extraOption = [{
                    "cmc_site_name": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }]
                const item = extraOption.concat(data);
                return item;
            }
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async siteUpsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let object = {
                "id": record["id"],
                "cmc_site_name": record["cmc_site_name"],
                "cmc_site_contact": record["cmc_site_contact"],
                "isActive": record["isActive"]
            }
            return await this.update(object, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

}