import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ItsyContainer, ItsyRow } from "@itsy-ui/layout";
import { ItsyModal, ItsyNavbar, ItsySidebar } from "@itsy-ui/navigation";
import User from "./userPage";
import Welcome from "./welcomePage";
import Site from "./siteList";
import Study from "./studyList";
import Subject from "./subjectPage";
import AnalystImage from "./analystImage";
import QcDashboard from "./qcDashboard";
import AnalystResult from "./analystResult";
import { cleanLocalStorage, getRoleSidebarItem } from "../utils/utils"
import Upload from "./uploadList";
import { ACTIONS } from '../utils/constant';
import { getlocaleText } from "@itsy-ui/utils"
import Activites from './activities'
import { eventTransition } from "@itsy-ui/core";
import CMCSite from "./cmcsiteList";
import Compounds from "./compoundList";
import UserRoles from "./rolesPage";
import TermsofUsePage from "./termsofUse";
import { getLogo } from '../utils/helpers';
import Batches from './batches';
import UploadSummary from "./uploadSummary";
import { ItsyButton } from '@itsy-ui/common';
import CompoundMfgSubUsage from "./compoundMfgSubUsage";

/**
 * Navbar schema to render the top bar
 */

export const Sidebarschema = {
    "layout": "layout_type_b",
    "className": "apn-sidebar",
    "data": {
        "default": [
            {
                "id": "UserManagement",
                "title": "{{sidebar.userTitle}}",
                "childUrl": [
                    "/users"
                ],
                "url": "/users",
                "orderId": 1,
                "roles": [
                    "Super Admin",
                    "Admin",
                ]
            },
            {
                "id": "CMC SiteManagement",
                "title": "{{sidebar.cmcSiteTitle}}",
                "childUrl": [
                    "/cmc-sites"
                ],
                "url": "/cmc-sites",
                "orderId": 2,
                "roles": [
                    "Super Admin",
                    "CMC Admin",
                ]
            },
            {
                "id": "SiteManagement",
                "title": "{{sidebar.siteTitle}}",
                "childUrl": [
                    "/sites"
                ],
                "url": "/sites",
                "orderId": 3,
                "roles": [
                    "Super Admin",
                    "Admin",
                ]
            },
            {
                "id": "StudyManagement",
                "title": "{{sidebar.studyTittle}}",
                "childUrl": [
                    "/studies"
                ],
                "url": "/studies",
                "orderId": 4,
                "roles": [
                    "Super Admin",
                    "Admin",
                ]
            },
            {
                "id": "Uploads",
                "title": "{{sidebar.uploads}}",
                "childUrl": [
                    "/uploads"
                ],
                "url": "/uploads",
                "orderId": 5,
                "roles": ["Upload User"]
            },
            {
                "id": "QcDashboard",
                "title": "{{sidebar.Dashboard}}",
                "childUrl": [
                    "/dashboard"
                ],
                "url": "/dashboard",
                "orderId": 6,
                "roles": ["QC User"]
            },
            {
                "id": "analystsearch",
                "title": "{{analyst.Search}}",
                "childUrl": [
                    "/analystsearch"
                ],
                "url": "/analystsearch",
                "orderId": 7,
                "roles": ["Analyst"]

            },
            {
                "id": "result",
                "title": "{{analyst.Result}}",
                "childUrl": [
                    "/analystresult"
                ],
                "url": "/analystresult",
                "orderId": 8,
                "roles": ["Analyst"]

            },
            {
                "id": "Subjects",
                "title": "{{sidebar.subjects}}",
                "childUrl": [
                    "/subjects"
                ],
                "url": "/subjects",
                "orderId": 9,
                "roles": ["Super Admin", "Upload User", "Analyst"]
            },
            {
                "id": "Activites",
                "title": "{{sidebar.Activites}}",
                "childUrl": [
                    "/activities"
                ],
                "url": "/activities",
                "orderId": 10,
                "roles": ["Super Admin"]
            },
            {
                "id": "CompoundsManagement",
                "title": "{{sidebar.compoundsTitle}}",
                "childUrl": [
                    "/compounds"
                ],
                "url": "/compounds",
                "orderId": 11,
                "roles": [
                    "Super Admin",
                    "CMC Admin",
                ]
            },
            {
                "id": "Batches",
                "title": "{{sidebar.batches}}",
                "childUrl": [
                    "/batches"
                ],
                "url": "/batches",
                "orderId": 12,
                "roles": [
                    "CMC Upload User"
                ]
            },
            {
                "id": "UploadSummary",
                "title": "{{sidebar.uploadSummary}}",
                "childUrl": [
                    "/uploadSummary"
                ],
                "url": "/uploadSummary",
                "orderId": 13,
                "roles": [
                    "Super Admin",
                ]
            },
            {
                "id": "CompoundMfgSubUsages",
                "title": "{{sidebar.compoundMfgSubUsage}}",
                "childUrl": [
                    "/compoundMfgSubUsages"
                ],
                "url": "/compoundMfgSubUsages",
                "orderId": 14,
                "roles": ["Super Admin",
                    "CMC Admin",
                    "CMC Upload User"]
            },
        ]
    }
}

const PageWrapper = (props: any) => {
    let sidebarData = Sidebarschema.data.default
    if (["welcome", "roles", "termsofuse"].includes(props.schema.pageId)) {
        sidebarData = null;
    }
    const [sidebarItem, sidebar] = useState();
    const [isActive, setIsActive] = useState(true);
    const [navBarSchema, setNavBarSchema] = useState();

    const handleOnIdle = event => {
        eventTransition({
            type: "HIDE_DRAWER",
        });
        cleanLocalStorage();
    }

    useIdleTimer({
        //1000ms x 60 seconds x minutes
        timeout: 1000 * 60 * 45,
        onIdle: handleOnIdle,
        debounce: 500
    })

    useEffect(() => {
        let currentSidebar: any = sidebarData ? getRoleSidebarItem(sidebarData) : null;
        if (currentSidebar) {
            currentSidebar = {
                "layout": Sidebarschema.layout,
                "className": Sidebarschema.className,
                "data": {
                    "default": [
                        ...currentSidebar
                    ]
                }
            }
        }
        sidebar(currentSidebar)

        getLogo().then(response => {
            let currentNavBarSchema: any = {
                data: {
                    items: [
                        {
                            id: "back_home",
                            appIcon: response
                        }
                    ],
                    rightItems: [{
                        "id": "profile",
                        "title": "{{common.profile}}"
                    },
                    {
                        "id": "changePassword",
                        "title": "{{common.changePassword}}"
                    },
                    {
                        "id": "about",
                        "title": "{{common.about}}"
                    },
                    {
                        "id": "logout",
                        "title": "{{common.logout}}",
                    },
                    ]
                }
            };
            const switchRoleRtItem = {
                "id": "switchRole",
                "title": "{{common.switchRole}}"
            };
            const userInfo = localStorage.FV_TENANT_INFO ? JSON.parse(localStorage.FV_TENANT_INFO) : null;
            if (userInfo && userInfo.roles && userInfo.roles.length > 1) {
                currentNavBarSchema.data.rightItems.splice(1, 0, switchRoleRtItem);
            }
            if (userInfo && !userInfo.accept_termsofuse) {
                currentNavBarSchema.data.rightItems = [{
                    "id": "logout",
                    "title": "{{common.logout}}",
                }]
            }
            setNavBarSchema(currentNavBarSchema);
        })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const { schema } = props;
    const userSelectedRole = localStorage.SELECTED_ROLE ? JSON.parse(localStorage.SELECTED_ROLE) : null;
    const currentRole = `${getlocaleText("{{User.Role}}")}: ${userSelectedRole ? userSelectedRole.role_name : null}`;
    const CurrentRoleSchema = {
        "title": currentRole,
        "headerSize": "h6",
        "alignText": "left",
        "className": "navbarText",
        "commandName": "currentRole_defaultpage",
        "onContext": () => { return { role_name: userSelectedRole.role_name } }
    }

    const defaultContent = <ItsyContainer className="apn-app-rootlevel-container">
        {navBarSchema && <ItsyRow className="apn-navbar">
            <ItsyNavbar schema={navBarSchema} />
            {userSelectedRole && <ItsyButton schema={CurrentRoleSchema} />}
        </ItsyRow>}
        {sidebarItem && <ItsyRow className="sidebar-row">
            <ItsySidebar schema={sidebarItem} />
        </ItsyRow>}
        <ItsyRow hAlignment="center" padding="15px">
            {schema.pageId === "users" && <User />}
            {schema.pageId === "welcome" && <Welcome />}
            {schema.pageId === "sites" && <Site />}
            {schema.pageId === "studies" && <Study />}
            {schema.pageId === "subjects" && <Subject />}
            {schema.pageId === "dashboard" && <QcDashboard />}
            {schema.pageId === "uploads" && <Upload />}
            {schema.pageId === "analystsearch" && <AnalystImage />}
            {schema.pageId === "analystresult" && <AnalystResult />}
            {schema.pageId === "activities" && <Activites />}
            {schema.pageId === "cmc-sites" && <CMCSite />}
            {schema.pageId === "compounds" && <Compounds />}
            {schema.pageId === "roles" && <UserRoles />}
            {schema.pageId === "termsofuse" && <TermsofUsePage />}
            {schema.pageId === "batches" && <Batches />}
            {schema.pageId === "uploadSummary" && <UploadSummary />}
            {schema.pageId === "compoundMfgSubUsages" && <CompoundMfgSubUsage />}

        </ItsyRow>
    </ItsyContainer>;

    const modalSchema = {
        "width": "md",
        "title": "{{common.timedout}}",
        "size": "default",
        "okText": getlocaleText("{{common.login}}"),
        "showCancelButton": false,
        "onOk": () => {
            cleanLocalStorage();
        },
        "onOKTransition": { type: ACTIONS.HIDE_POPUP },
        "controlSchema": {
            "name": "label",
            "properties": {
                "ui:widget": "label",
                "title": "{{common.timedoutMessage}}",
            }
        }
    }

    const expiryModal = <ItsyModal className="modal" schema={modalSchema} />
    return (
        <>
            {isActive ? defaultContent : expiryModal}
        </>
    )
}

export default PageWrapper;