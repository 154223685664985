import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions } from "@itsy-ui/core";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const acitivitesRestCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        transition({
            type: "FORM_REFRESH",
        });
        transition({
            type: "FORM_BEFORE_HANDLE_CHANGE",
            controlID: "activities_filter",
            strict: true,
            value: {
                from_date: "",
                to_date: ""
            }
        })
    },
};

commandManager.registerCommand("activities_reset", {}, acitivitesRestCommand);