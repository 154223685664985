import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, TYPES, RegExp, LOCALSTORAGE_NAME } from "../utils/constant"
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");


function doStudyFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        const checkyValidationSchema = {
            ...validationSchema,
            "studyName": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{studyName.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "principalInvestigator": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{pi.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "siteId": Yup.string().required(getlocaleText("{{common.SiteIdInvalidError}}")),
            "protocolName": Yup.string().when("imageProtocolUpload", {
                is: file => {
                    return (file && file.length > 0)
                },
                then: Yup.string().required(getlocaleText("{{imageProtocolName.required}}")),
                otherwise: Yup.string()
            }),
        };
        const formData = objectData ? objectData : {};
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite && selectedfilter_csite.indexOf("all") === -1 && !formData.siteId) {
            formData["siteId"] = selectedfilter_csite;
        }

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: updateFormSchema,
            validationSchema: checkyValidationSchema,
            objectData: formData,
            typeId: typeId,
        });
    }
}

function doStudyFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { typeId, formValues, metadata } = getState();
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (value["approvalDate"]) {
            var tomorrow = new Date(value["approvalDate"]);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const afterDate = tomorrow.toISOString().split("T")[0]
            updatedPropDefs["completionDate"]["minDate"] = afterDate
        }
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...value },
            controlID: metadata.id === "study_edit_form" ? "study_edit_form" : "study_create_form",
            strict: true
        });
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            controlID: metadata.id === "study_edit_form" ? "study_edit_form" : "study_create_form",
            strict: true,
            mode: 1
        });
    }
}


function doStudyCreateOrUpdateFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const data = await datasource.studyUpsert(event.values, { id: TYPES.STUDY });
            if (data) {
                let textMessage = (event.values["id"] !== undefined && event.values["id"] !== null && event.values["id"] !== "") ? "{{study.successUpdateMessage}}" : "{{study.successCreateMessage}}";
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                if (event.values["imageProtocolUpload"] && event.values["imageProtocolUpload"].length > 0) {
                    let imageProtocolUploadFile = event.values["imageProtocolUpload"][0];
                    if (data["imageProtocolRelation"]) {
                        await datasource.uploadFileToCloud(data.imageProtocolRelation.signedUrl, imageProtocolUploadFile);
                    }
                }
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": textMessage, "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const StudyCreateOrUpdateFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onStudyFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onStudyFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onStudyCreateOrUpdateFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onStudyFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doStudyFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onStudyCreateOrUpdateFormSubmit: (evt) => dispatch(doStudyCreateOrUpdateFormSubmit(evt)),
            onStudyFormHandleChange: ({ value, formValues }) => dispatch(doStudyFormHandleChange(value, formValues))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "studies",
    "formSchemaId": "study_create_form",
}, StudyCreateOrUpdateFormOnSubmit);


//Edit form handler

const getRenderRule = (propertyDefinitions, widget) => {
    const schema = {};
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("imageProtocolfileName")) {
        schema["imageProtocolfileName"] = (values) => {
            if (values && values["imageProtocolfileName"]) {
                return false;
            }
            return true;
        };
    }
    return schema;
}

function onStudyEditFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        const checkyValidationSchema = {
            ...validationSchema,
            "studyName": Yup.string().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{studyName.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "principalInvestigator": Yup.string().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{pi.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "siteId": Yup.string().required(getlocaleText("{{common.SiteIdInvalidError}}")),
            "protocolName": Yup.string().when("imageProtocolUpload", {
                is: file => {
                    return (file && file.length > 0)
                },
                then: Yup.string().required(getlocaleText("{{imageProtocolName.required}}")),
                otherwise: Yup.string()
            }),
        };

        if (objectData["approvalDate"]) {
            var tomorrow = new Date(objectData["approvalDate"]);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const afterDate = tomorrow.toISOString().split("T")[0]
            updateFormSchema.propertyDefinitions["completionDate"]["minDate"] = afterDate
        }

        if (objectData["approvalDate"] === null)
            objectData["approvalDate"] = ""
        if (objectData["completionDate"] === null)
            objectData["completionDate"] = ""
        if (objectData["imageProtocolRelation"] && objectData["imageProtocolRelation"].length > 0) {
            objectData["imageProtocolfileName"] = objectData["imageProtocolRelation"][0]["contentStreamFileName"];
        }
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: updateFormSchema,
            validationSchema: checkyValidationSchema,
            objectData: objectData,
            typeId: typeId,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, typeId),
        });
    }
}



function doStudyEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const data = await datasource.studyUpsert(event.values, { id: TYPES.STUDY });
            if (data) {
                const textMessage = (event.values["id"] !== undefined && event.values["id"] !== null && event.values["id"] !== "") ? "{{study.successUpdateMessage}}" : "{{study.successCreateMessage}}";
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                if (event.values["imageProtocolUpload"] && event.values["imageProtocolUpload"].length > 0) {
                    let imageProtocolUploadFile = event.values["imageProtocolUpload"][0], result;
                    if (data["imageProtocolRelation"]) {
                        await datasource.uploadFileToCloud(data.imageProtocolRelation.signedUrl, imageProtocolUploadFile);
                    }
                }
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": textMessage, "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const studyEditFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onStudyEditFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onStudyFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onStudyEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onStudyEditFormSubmit: (evt) => dispatch(doStudyEditFormSubmit(evt)),
            onStudyEditFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(onStudyEditFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onStudyFormHandleChange: ({ value, formValues }) => dispatch(doStudyFormHandleChange(value, formValues))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "studies",
    "formSchemaId": "study_edit_form",
}, studyEditFormOnSubmit);