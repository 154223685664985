import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';


const ActivitesListName = {
    "title": "{{activities.activitesList}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const filterschema = {
    "widgetType": "bound",
    "schema": {
        "id": "activities_filter",
        "displayName": "Activities filter",
        "propertyDefinitions": {
            "from_date": {
                "id": "from_date",
                "displayName": "{{activities.fromDate}}",
                "propertyType": "string",
                "ui:widget": "date"
            },
            "to_date": {
                "id": "to_date",
                "displayName": "{{activities.toDate}}",
                "propertyType": "string",
                "ui:widget": "date"
            },
        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "from_date"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "to_date"
                            }
                        ]
                    }
                ],
            }
        ]
    },
    "operation": {
        "studyId": "eq",
        "lastDays": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "activities_filter",
    controlID: "activities_filter",
    formSchemaId: "activities_filter",
    "align": "left",
}

const Gridschema = {
    typeId: "activities",
    gridSchemaId: "activities",
    dataSource: "activitiesDataSource",
    rowSelectionMode: 1,
    controlID: "activities"
}

const toolbarSchema = {
    data: [
        {
            "name": "activities_reset",
            "displayText": "{{analyst.Reset}}",
            "isPrimary": true,
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "users",
    "align": "right",
    "className": "user-list-added",
    "typeId": "activities_filter"
};


const Activites = (props) => {
    return (
        <ItsyContainer className="activities-management-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={ActivitesListName} />
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow>
                <ItsyColumn>
                    <StateManagerContext.Provider key="users" value={{ contextPath: { "id": "activities_filter" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="activities_filter" value={{ contextPath: { "id": "activities_filter" } }}>
                    <ItsyFilterBar schema={filterschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="activities" value={{ contextPath: { "id": "activities" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="activities-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default Activites;