import { CommandOptions, DataLoaderFactory, ICommandManager, WidgetsFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, TYPES } from "../utils/constant";
import { getBatchesUploadList } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

//export csv file command
const exportFileCSV: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("compoundMfgSubUsageDataSource");
        try {

            transition({
                type: "SHOW_INDICATOR",
                loadingMessage: "{{Download.loading_message}}",
            });
            let filterParams;
            transition({
                type: "FORM_GET_STATE",
                strict: true,
                controlID: "compoundMfgSubUsage_filter",
                onData: (formData) => {
                    filterParams = formData["formValues"];
                },
            });
            transition({
                type: "GRID_GET_STATE",
                strict: true,
                controlID: "compoundMfgSubUsages",
                onData: (data) => {
                    filterParams["orderBy"] = data.viewAttributes.orderBy;
                },
            });
            // to getSignedURL for xls file to export
            const data = await datasource.getSignedURL({ filterParams });
            if (data && data["signedUrl"]) {
                await datasource.downlodFile(data["signedUrl"]);
                transition({
                    type: "HIDE_INDICATOR",
                });
            } else {
                transition({
                    type: "HIDE_INDICATOR",
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
                    metadata: { "delay": 5 * 1000 },
                    action: { "showCloseIcon": false },
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage) {
                errorMessage = "Error in network call";
            }
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
                metadata: { "delay": 5 * 1000 },
                action: { "showCloseIcon": false },
            });
        }
    },
};

commandManager.registerCommand("export_compoundMfgSubUsage_csv", {}, exportFileCSV);