import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyToolbar } from '@itsy-ui/navigation';
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';

const Formschema = {
    typeId: "search",
    formSchemaId: "analyst_createForm",
    controlID: "analyst_createForm",
    showSubmitButton: false
}

const toolbarSchema = {
    data: [
        {
            "name": "Analyst_Reset",
            "displayText": "{{analyst.Reset}}",
            "isPrimary": true,
            "enabled": true
        },
        {
            "name": "Analyst_Search",
            "displayText": "{{analyst.Search}}",
            "isPrimary": true,
            "enabled": true
        },
    ],
    "widgetType": "bound",
    "reflowPageId": "forgotpassword",
    "align": "right",
    "className": "search-button",
    "typeId": "analyst"
};

const AnalystImage = (props) => {
    return (
        <ItsyContainer className="analyst-page">
            <ItsyColumn>
                <StateManagerContext.Provider key="search" value={{ contextPath: { "id": "search" } }}>
                    <ItsyForm className="analyst-form" schema={Formschema} />
                    <ItsyToolbar className="toolbar" schema={toolbarSchema} />
                </StateManagerContext.Provider>
            </ItsyColumn>
        </ItsyContainer>
    )
}

export default AnalystImage;
