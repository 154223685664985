import { WidgetsFactory } from "@itsy-ui/core";
// retrieve the DataLoaderFactory singleton
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"];
// retrieve the custom state machine provider
const schemaProvider = dataLoader.getLoader("appSchemaProvider");

// Registers all the app schema into the schema provider
schemaProvider.putSchema("/config/data", require("./config"));
schemaProvider.appendSchemaSync("/app/locale/en", require("./locale/en.json"));
schemaProvider.appendSchemaSync("/app/locale/zh", require("./locale/zh.json"));
schemaProvider.putSchema("/app/command/data", require("./command.json"));
schemaProvider.putSchema("/app/login/form/login/data", require("./app/login/form/login.json"));
schemaProvider.putSchema("/app/forgotpassword/form/forgotpassword/data", require("./app/forgotPassword/form/forgotpassword.json"));
schemaProvider.putSchema("/app/resetpassword/form/resetpassword/data", require("./app/resetPassword/form/resetpassword.json"));
schemaProvider.putSchema("/app/enroll/form/enroll/data", require("./app/enroll/form/enroll.json"));
schemaProvider.putSchema("/app/changedPassword/form/changedPassword/data", require("./app/changedPassword/form/changedPassword.json"));

//userlist
const userListJSON = require("./app/users/form/user_create_form.json");
schemaProvider.putSchema("/app/users/form/user_create_form/data", userListJSON);
const userEditListJSON = require("./app/users/form/user_edit_form.json");
schemaProvider.putSchema("/app/users/form/user_edit_form/data", userEditListJSON);

const userListGridJSON = require("./app/users/grid/users.json");
schemaProvider.putSchema("/app/users/grid/users/data", userListGridJSON);

//profile
schemaProvider.putSchema("/app/userProfile/card/userProfile/data", require("./app/userProfile/card/userProfile.json"));
schemaProvider.putSchema("/app/userProfile/card/adminUserProfile/data", require("./app/userProfile/card/adminUserProfile.json"));

const ProfileEditJSON = require("./app/userProfile/form/profileEdit.json");
schemaProvider.putSchema("/app/userProfile/form/profileEdit/data", ProfileEditJSON);

//sitelist
const siteAddJSON = require("./app/sites/form/site_create_form.json");
schemaProvider.putSchema("/app/sites/form/site_create_form/data", siteAddJSON);
const siteEditJSON = require("./app/sites/form/site_edit_form.json");
schemaProvider.putSchema("/app/sites/form/site_edit_form/data", siteEditJSON);

const siteListGridJSON = require("./app/sites/grid/sites.json");
schemaProvider.putSchema("/app/sites/grid/sites/data", siteListGridJSON);

//study List
const studyAddJSON = require("./app/study/form/study_create_form.json");
schemaProvider.putSchema("/app/studies/form/study_create_form/data", studyAddJSON);
const studyEditJSON = require("./app/study/form/study_edit_form.json");
schemaProvider.putSchema("/app/studies/form/study_edit_form/data", studyEditJSON);

const studyListGridJSON = require("./app/study/grid/study.json");
schemaProvider.putSchema("/app/studies/grid/studies/data", studyListGridJSON);

//qcdashboard
schemaProvider.putSchema("/app/uploads/form/qcDashboardFilters/data", require("./app/qc/form/qcDashboardFilters.json"));
schemaProvider.putSchema("/app/uploads/grid/qcDashboardNewUploads/data", require("./app/qc/grid/qcDashboardNewUploads.json"));

//qcData
schemaProvider.putSchema("/app/qc/form/qcDataForm/data", require("./app/qc/form/qcDataForm.json"));
schemaProvider.putSchema("/app/comments/grid/qcComments/data", require("./app/qc/grid/qcComments.json"));

//Subject 
const subjectAddJSON = require("./app/subjects/form/subject_create.json");
schemaProvider.putSchema("/app/subjects/form/subject_create/data", subjectAddJSON);
const subjectEditJSON = require("./app/subjects/form/subject_edit.json");
schemaProvider.putSchema("/app/subjects/form/subject_edit/data", subjectEditJSON);
schemaProvider.putSchema("/app/subjects/form/batchSubject_create/data", require("./app/subjects/form/batchSubject_create.json"));

const subjectListGridJSON = require("./app/subjects/grid/subjects.json");
schemaProvider.putSchema("/app/subjects/grid/subjects/data", subjectListGridJSON);

schemaProvider.putSchema("/app/subjects/grid/viewSubjects/data", require("./app/subjects/grid/viewSubjects.json"));
schemaProvider.putSchema("/app/subjects/form/viewSubject_data/data", require("./app/subjects/form/viewSubject_data.json"));
schemaProvider.putSchema("/app/uploads/grid/viewSubjectUploads/data", require("./app/upload/grid/viewSubjectUploads.json"));
schemaProvider.putSchema("/app/pages/viewSubjectUploadsPage/data", require("./pages/viewSubjectUploadsPage.json"));

//Upload page
schemaProvider.putSchema("/app/upload/form/upload_form/data", require("./app/upload/form/upload_form.json"));
schemaProvider.putSchema("/app/visits/grid/uploads/data", require("./app/upload/grid/uploads.json"));
schemaProvider.putSchema("/app/upload/form/uploadEdit_form/data", require("./app/upload/form/uploadEdit_form.json"));
schemaProvider.putSchema("/app/visits/grid/uploadSummary/data", require("./app/upload/grid/uploadSummary.json"));

schemaProvider.putSchema("/app/compoundMfgSubUsages/grid/compoundMfgSubUsages/data", require("./app/compoundMfgSubUsages/grid/compoundMfgSubUsages.json"));

//CRF
schemaProvider.putSchema("/app/upload/form/crfUpload_form/data", require("./app/upload/form/crfUpload_form.json"));
//PET
schemaProvider.putSchema("/app/upload/form/petUpload_form/data", require("./app/upload/form/petUpload_form.json"));
//MRI
schemaProvider.putSchema("/app/upload/form/mriUpload_form/data", require("./app/upload/form/mriUpload_form.json"));


schemaProvider.putSchema("/app/pages/qcData/data", require("./pages/qcData.json"));

//analystImage
schemaProvider.putSchema("/app/search/form/analyst_createForm/data", require("./app/search/form/analyst_createForm.json"));
schemaProvider.putSchema("/app/search/grid/analyst/data", require("./app/search/grid/analyst.json"));

//activites
schemaProvider.putSchema("/app/activities/grid/activities/data", require("./app/activities/grid/activities.json"));

//cmcsitelist
const cmcsiteAddJSON = require("./app/cmcSites/form/cmcsite_create_form.json");
schemaProvider.putSchema("/app/cmc-sites/form/cmcsite_create_form/data", cmcsiteAddJSON);
const cmcsiteEditJSON = require("./app/cmcSites/form/cmcsite_edit_form.json");
schemaProvider.putSchema("/app/cmc-sites/form/cmcsite_edit_form/data", cmcsiteEditJSON);

const cmcsiteListGridJSON = require("./app/cmcSites/grid/cmcsites.json");
schemaProvider.putSchema("/app/cmc-sites/grid/cmc-sites/data", cmcsiteListGridJSON);

//compounds
const compoundAddJSON = require("./app/compounds/form/compound_create_form.json");
schemaProvider.putSchema("/app/compounds/form/compound_create_form/data", compoundAddJSON);
const compoundEditJSON = require("./app/compounds/form/compound_edit_form.json");
schemaProvider.putSchema("/app/compounds/form/compound_edit_form/data", compoundEditJSON);

const compoundtGridJSON = require("./app/compounds/grid/compounds.json");
schemaProvider.putSchema("/app/compounds/grid/compounds/data", compoundtGridJSON);

//batches
const batchesFormJSON = require("./app/batches/form/batches_form.json");
schemaProvider.putSchema("/app/batches/form/batches_form/data", batchesFormJSON);

const batchesEditFormJSON = require("./app/batches/form/batches_edit_form.json");
schemaProvider.putSchema("/app/batches/form/batches_edit_form/data", batchesEditFormJSON);

const batchesAddMoreFormJSON = require("./app/batches/form/batches_uploadMore_form.json");
schemaProvider.putSchema("/app/batches/form/batches_uploadMore_form/data", batchesAddMoreFormJSON);

const batchesGridJSON = require("./app/batches/grid/batches.json");
schemaProvider.putSchema("/app/batches/grid/batches/data", batchesGridJSON);
//about
schemaProvider.putSchema("/app/about/card/about/data", require("./app/about/card/about.json"));
