import { Roles, TYPES } from "../utils/constant";
import { getLoginUserAdminObjectId, getLoginUserObjectId, getLoginUserRoleName } from "./helpers";

export function getHeaders(useBearer) {
	const token = localStorage.getItem("USER_LOCAL");
	if (!token)
		return "";
	else {
		const authorization = useBearer ? `Bearer ${token}` : ``;
		return {
			"Content-Type": "application/json",
			"Authorization": authorization,
		};
	}
}

export function getLocalStorageItem(storageKey, paramsName?) {
	const storedValue = localStorage.getItem(storageKey)
	if (storedValue) {
		const data = JSON.parse(storedValue)
		if (paramsName && data.hasOwnProperty(paramsName)) {
			return data[paramsName];
		}
		return data;
	}
}

export const formGetAllJson = (props, filter) => {
	const queryJSON = {
		"offset": 0,
		"limit": props.limit,
		"skip": props.skip,
		"order": props.order,
		"where": {
			...filter.where
		},
		"fields": {}
	}
	return queryJSON;
}

export const getFilteredProps = (props: string[], obj: {}) => {
	const val = {};
	const recordsperpage = localStorage.getItem("recordsperpage");
	val["limit"] = recordsperpage ? Number(recordsperpage) : 10;
	val["skip"] = 0;
	for (const key in obj) {
		if (props.indexOf(key) > -1) {
			if (key.includes("maxItems")) {
				//if recordsperpage exist then use its value
				val["limit"] = recordsperpage ? Number(recordsperpage) : obj[key];
			} else if (key.includes("skipCount")) {
				val["skip"] = obj[key];
			} else if (key.includes("orderBy")) {
				val["order"] = obj[key];
			} else {
				val[key] = obj[key];
			}
		}
	}
	return val;
}

export const getFilterQueryJson = (parameters: any) => {
	let filterQueryJson: any = {};
	let allFilters = {};

	if (parameters.defaultFilter && Object.keys(parameters.defaultFilter).length !== 0) {
		allFilters = {
			...parameters.defaultFilter
		};
	}

	if (parameters.filter && Object.keys(parameters.filter).length !== 0) {
		allFilters = {
			...allFilters,
			...parameters.filter
		};
	}

	if (allFilters && Object.keys(allFilters).length !== 0) {
		for (let itemKey in allFilters) {
			if (allFilters.hasOwnProperty(itemKey)) {
				const itemValue = allFilters[itemKey];
				if (Array.isArray(itemValue) && itemValue.length > 0) {
					itemValue.forEach((item) => {
						let filter: any = {};
						const operation: any = item.operation;
						const value: any = item.value;
						filter = getlb4FilterString(operation, value);
						filterQueryJson[itemKey] = filter;
					});
				} else {
					filterQueryJson[itemKey] = itemValue;
				}
			}
		}
	}

	return filterQueryJson;
}

export const getlb4FilterString = (operation: any, value: any) => {
	let filter: any = {};
	let lb4Operation, lb4Value;
	switch (operation) {
		case "eq":
			lb4Operation = "eq";
			lb4Value = value;
			break;
		case "contains":
			lb4Operation = "regexp";
			lb4Value = `/${value}/`;
			break;
		case "gt":
			lb4Operation = "gt";
			lb4Value = value;
			break;
		case "gte":
			lb4Operation = "gte";
			lb4Value = value;
			break;
		case "lt":
			lb4Operation = "lt";
			lb4Value = value;
			break;
		case "lte":
			lb4Operation = "lte";
			lb4Value = value;
			break;
		case "between":
			lb4Operation = "between";
			lb4Value = value;
			break;
		case "ilike":
			lb4Operation = "ilike";
			lb4Value = value;
			break;
		case "nilike":
			lb4Operation = "nilike";
			lb4Value = value;
			break;
		case "like":
			lb4Operation = "like";
			lb4Value = value;
			break;
		case "nlike":
			lb4Operation = "nlike";
			lb4Value = value;
			break;
		case "inq":
			lb4Operation = "inq";
			lb4Value = value;
			break;

		default:
			break;
	}

	if (lb4Operation && lb4Value) {
		filter[lb4Operation] = lb4Value;
	}

	return filter;
}

export const applyPagination = (parameters: {}) => {
	if (parameters && parameters["maxItems"]) {
		return true;
	}
	return false;
}

export const setQueryParams = (url: string, parameters: {}) => {

}

export const modifyFilterByRole = (modelName: string, parameters: any) => {
	// if parameters is an empty object to avoid undefined expection for filter.where
	if (parameters && Object.getPrototypeOf(parameters) === Object.prototype &&
		Object.keys(parameters).length == 0) {
		parameters.filter = {};
	}
	const userRoleName = getLoginUserRoleName();
	modelName = modelName.split("?")[0];
	if (userRoleName === Roles.SUPER_ADMIN) {
		//
	} else if (userRoleName === Roles.ADMIN) {
		if (modelName !== TYPES.STUDY) {
			parameters.filter.where = {
				...parameters.filter.where,
				createdBy: getLoginUserObjectId()
			}
		}
	} else if (userRoleName === Roles.ANALYST) {
		//
	} else if (userRoleName === Roles.QC_USER) {
		// get his ceated siteids
		if (modelName === TYPES.SITES) {
			parameters.filter.where = {
				...parameters.filter.where,
				createdBy: getLoginUserAdminObjectId()
			}
		}
	} else if (userRoleName === Roles.UPLOAD_USER) {
		if (modelName === TYPES.SITES) {
			parameters.filter.where = {
				...parameters.filter.where,
				createdBy: getLoginUserAdminObjectId()
			}
			/* } else if (modelName === TYPES.VISITS) {
				parameters.filter.where = {
					...parameters.filter.where,
					createdBy: getLoginUserObjectId()
				} */
		}
	}
	else if (userRoleName === Roles.CMC_ADMIN) {
		if (modelName === TYPES.CMCSITES || modelName === TYPES.COMPOUNDS) {
			parameters.filter.where = {
				...parameters.filter.where,
				createdBy: getLoginUserObjectId()
			}
		}
	}

	return parameters;
}

export const setFilterParam = (url: string, queryJson: {}) => {
	if (Object.keys(queryJson).length > 0) {
		const encodeJSON: any = encodeURIComponent(JSON.stringify(queryJson));
		if (url.indexOf("?") > -1) {
			url = `${url}&filter=${encodeJSON}`;
		} else {
			url = `${url}?filter=${encodeJSON}`;
		}
	}
	return url;
}


export const setFilterParam_export = (url: string, queryJson: {}, requestBodyJson) => {
	if (Object.keys(queryJson).length > 0) {
		const encodeJSON: any = encodeURIComponent(JSON.stringify(queryJson));
		if (url.indexOf("?") > -1) {
			url = `${url}&filter=${encodeJSON}`;
		} else {
			url = `${url}?filter=${encodeJSON}`;
		}
	}
	if (requestBodyJson && Object.keys(requestBodyJson).length > 0) {
		const otherQueryJson = { ...requestBodyJson };
		const selectedColumnsJson = JSON.stringify(otherQueryJson);
		const encoderequestBodyJSON: any = encodeURIComponent(selectedColumnsJson);
		if (url.indexOf("?") > -1) {
			url = `${url}&selectedColumns=${encoderequestBodyJSON}`;
		} else {
			url = `${url}?selectedColumns=${encoderequestBodyJSON}`;
		}
	}
	return url;
}