import { setItemInLocalStorage, getItemFromLocalStorage, getlocaleText } from "@itsy-ui/utils";
import axios from "axios";
import { Roles } from "./constant"

export async function refreshToken(tenantInfo, tenantURL) {
    try {
        const result = await axios.get(`${tenantURL}/refreshToken?refreshToken=${tenantInfo.refresh_token}`);
        if (result) {
            tenantInfo["token"] = result.data;
            const userPass = atob(getItemFromLocalStorage("USER_LOCAL", false)).split(":");
            const userBTOA = btoa(`${userPass[0]}:${result.data}`);
            setItemInLocalStorage("USER_LOCAL", userBTOA);
            setItemInLocalStorage("FV_TENANT_INFO", JSON.stringify(tenantInfo));
        }
    } catch (e) {
        throw e;
    }
};


export function getValidationError(event) {
    if (event.values["roleId"] === undefined) {
        return getlocaleText("{{common.RoleIdInvalidError}}")
    } else if (event.values["siteId"] === undefined) {
        return getlocaleText("{{common.SiteIdInvalidError}}")
    } else {
        return getlocaleText("{{common.PreferredLangValidError}}")
    }
}

export function getCurrentUserRole(role) {
    const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
    return selectedRoles && selectedRoles.role_name === role ? true : false;
}

export function getRoleSidebarItem(sidebarData) {
    const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
    const getRoles = selectedRoles ? selectedRoles.role_name : null;
    const filterData = [];
    sidebarData.map((e) => {
        const visible = getRoles !== null && e.roles.some(role => getRoles === role)
        if (e.id === "Subjects") {
            e.title = getRoleSubjectTitle();
        }
        if (visible === true) {
            filterData.push(e)
        }
    })
    return filterData.length > 0 ? filterData : null;
}

export function getRoleToolbarItem(toolbarData) {
    const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
    const getRoles = selectedRoles ? selectedRoles.role_name : null;
    const filterData = [];
    toolbarData.map((e) => {
        const visible = getRoles !== null && e.roles && e.roles.some(role => getRoles === role)
        if (visible === true) {
            filterData.push(e)
        }
    })
    return filterData;
}

export function getRoleSubjectTitle() {
    let title = "{{sidebar.subjects}}";
    const selectedRoles = getItemFromLocalStorage("SELECTED_ROLE");
    const getRoles = selectedRoles ? selectedRoles.role_name : null;
    if (getRoles) {
        if (getRoles === Roles.SUPER_ADMIN) {
            title = "{{sidebar.subjectsTitle_admin}}"
        }
        if (getRoles === Roles.ANALYST) {
            title = "{{sidebar.subjectsTitle_analyst}}"
        }
    }
    return title;
}

export function getServerUrl() {
    const value = process.env.REACT_APP_SERVER_URL;
    if (value) {
        return value;
    } else {
        throw new Error("REACT_APP_SERVER_URL variable is missing.");
    }
}

export function getDefaultLang() {
    const value = process.env.REACT_APP_DEFAULT_LANG;
    if (value) {
        return value;
    } else {
        throw new Error("REACT_APP_DEFAULT_LANG variable is missing.");
    }
}

export function getShowICPLicense() {
    const value = process.env.REACT_APP_SHOW_ICP_LICENSE;
    if (value) {
        return value;
    } else {
        throw new Error("REACT_APP_SHOW_ICP_LICENSE variable is missing.");
    }
}

export function cleanLocalStorage() {
    const recordsperpage = localStorage.getItem("recordsperpage");
    const selectedColumsToShow = localStorage.getItem("selectedColumsToShow");
    localStorage.clear();
    window.location.reload();
    if (recordsperpage) {
        localStorage.setItem("recordsperpage", recordsperpage);
    }
    if (selectedColumsToShow) {
        localStorage.setItem("selectedColumsToShow", selectedColumsToShow);
    }
}