import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { ACTIONS, TYPES, RegExp, DocumentType } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils";
import { getFormatedDate } from "../common/helpers";
import { getBatchesUploadList } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");


const getRenderRule = (propertyDefinitions, widget) => {
    const schema = {};
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("CoAfileName")) {
        schema["CoAfileName"] = (values) => {
            if (values && values["CoA"]) {
                return true;
            }
            return false;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("ExecBatchRecfileName")) {
        schema["ExecBatchRecfileName"] = (values) => {
            if (values && values["ExecBatchRec"]) {
                return true;
            }
            return false;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("AnalyticRawDatafileName")) {
        schema["AnalyticRawDatafileName"] = (values) => {
            if (values && values["AnalyticRawData"]) {
                return true;//it will display 
            }
            return false;//it will hide 
        };
    }
    return schema;
};

function doBatchFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {

        const batchValidationSchema = {
            ...validationSchema,
            "batchId": Yup.string().matches(RegExp.BATCHID, { message: getlocaleText("{{batchId.errorMessage}}") }).required(getlocaleText("{{batchId.required}}")),
            "cmcSiteId": Yup.string().required(getlocaleText("{{cmcsiteName.required}}")),
            "manufacturingDate": Yup.string().required(getlocaleText("{{manufacturingDate.required}}")),
            "compoundId": Yup.string().required(getlocaleText("{{compoundName.required}}")),
        };
        const updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        let updatedPropDefs = { ...updateFormSchema.propertyDefinitions };
        updatedPropDefs["CoA"]["className"] = updatedPropDefs["ExecBatchRec"]["className"] = updatedPropDefs["AnalyticRawData"]["className"] = "btn-enable";
        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchema: updateFormSchema,
            validationSchema: batchValidationSchema,
            objectData: objectData,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, typeId),
        });
    }
}

function doBatchFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const bactchData = {
                id: event.values["id"],
                cmcSiteId: event.values["cmcSiteId"],
                batchId: event.values["batchId"],
                compoundId: event.values["compoundId"],
                manufacturingDate: event.values && event.values["manufacturingDate"] ? new Date(event.values["manufacturingDate"]) : undefined,
            }
            const data = await batchDatasource.batchUpsert(bactchData, { id: TYPES.BATCHES });
            if (data) {
                console.log("batch created successfully");
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                if (event.values["CoA"] && event.values["CoA"].length > 0) {
                    let coaFile = event.values["CoA"][0];
                    const uploadvalues: any = {
                        batchId: data.id ? data.id : event.values["id"],
                        documentType: "coaUpload",
                        contentStreamFileName: coaFile.name,
                        contentSteamLength: coaFile.size + "",
                        contentStreamMimeType: coaFile.type,
                    }
                    let result;
                    if (data["CoAFileDetails"]) {
                        uploadvalues["id"] = data["CoAFileDetails"]["id"];
                        result = await datasource.update(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                        await datasource.uploadFileToCloud(result.signedUrl, coaFile);

                    } else {
                        result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                        await datasource.uploadFileToCloud(result.signedUrl, coaFile);
                    }
                }
                if (event.values["ExecBatchRec"] && event.values["ExecBatchRec"].length > 0) {
                    let execBatchRecFile = event.values["ExecBatchRec"][0];
                    const uploadvalues: any = {
                        batchId: data.id ? data.id : event.values["id"],
                        documentType: "execBatchRecUpload",
                        contentStreamFileName: execBatchRecFile.name,
                        contentSteamLength: execBatchRecFile.size + "",
                        contentStreamMimeType: execBatchRecFile.type,
                    }
                    let result;
                    if (data["ExecBatchRecFileDetails"]) {
                        uploadvalues["id"] = data["ExecBatchRecFileDetails"]["id"];
                        result = await datasource.update(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                        await datasource.uploadFileToCloud(result.signedUrl, execBatchRecFile);

                    } else {
                        result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                        await datasource.uploadFileToCloud(result.signedUrl, execBatchRecFile);
                    }
                }
                if (event.values["AnalyticRawData"] && event.values["AnalyticRawData"].length > 0) {
                    event.values["AnalyticRawData"].map(async (analyticRawDataFile) => {
                        const uploadvalues: any = {
                            batchId: data.id ? data.id : event.values["id"],
                            documentType: "analyticRawDataUpload",
                            contentStreamFileName: analyticRawDataFile.name,
                            contentSteamLength: analyticRawDataFile.size + "",
                            contentStreamMimeType: analyticRawDataFile.type,
                        }
                        let result;
                        if (data["AnalyticRawDataFileDetails"]) {
                            uploadvalues["id"] = data["AnalyticRawDataFileDetails"]["id"];
                            result = await datasource.update(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                            await datasource.uploadFileToCloud(result.signedUrl, analyticRawDataFile);

                        } else {
                            result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                            await datasource.uploadFileToCloud(result.signedUrl, analyticRawDataFile);
                        }
                    })
                }
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                    controlID: "batches_form",
                    strict: true,
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.batchSuccessMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

function doBatchFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues, metadata } = getState();
        let updatedValues = { ...value };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        let existingValues = {};
        let currentFormValues = { ...formValues, ...updatedValues };
        // check previous batch details for existing  upload
        if (currentFormValues["cmcSiteId"] && currentFormValues["batchId"] && currentFormValues["compoundId"] &&
            currentFormValues["manufacturingDate"] && currentFormValues["manufacturingDate"] !== "") {
            const queryParam = {
                filter: {
                    cmcSiteId: currentFormValues["cmcSiteId"],
                    batchId: currentFormValues["batchId"],
                    compoundId: currentFormValues["compoundId"],
                    manufacturingDate: getFormatedDate(currentFormValues["manufacturingDate"])
                }
            };
            transition({
                type: "SHOW_INDICATOR",
                loadingMessage: "{{batches.find}}",
            });
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const prevBatchtData = await batchDatasource.getAll(TYPES.BATCHES, queryParam);

            existingValues = prevBatchtData && prevBatchtData.length > 0 && getBatchesUploadList(prevBatchtData[0]);
        }
        if (existingValues) {
            //coa button
            if (existingValues["CoAfileName"]) {
                updatedPropDefs["CoA"]["readOnly"] = true;
                updatedPropDefs["CoA"]["className"] = "btn-disable";
            }
            else {
                updatedPropDefs["CoA"]["readOnly"] = false;
                updatedPropDefs["CoA"]["className"] = "btn-enable";
            }
            //exec batch rec button
            if (existingValues["ExecBatchRecfileName"]) {
                updatedPropDefs["ExecBatchRec"]["readOnly"] = true;
                updatedPropDefs["ExecBatchRec"]["className"] = "btn-disable";
            }
            else {
                updatedPropDefs["ExecBatchRec"]["readOnly"] = false;
                updatedPropDefs["ExecBatchRec"]["className"] = "btn-enable";
            }
            //analyticRawData button
            if (existingValues["AnalyticRawDatafileName"]?.length > 0) {
                updatedPropDefs["AnalyticRawData"]["readOnly"] = true;
                updatedPropDefs["AnalyticRawData"]["className"] = "btn-disable";
            }
            else {
                updatedPropDefs["AnalyticRawData"]["readOnly"] = false;
                updatedPropDefs["AnalyticRawData"]["className"] = "btn-enable";
            }

            updatedPropDefs["CoA"]["showFile"] = false;
            updatedPropDefs["ExecBatchRec"]["showFile"] = false;
            updatedPropDefs["AnalyticRawData"]["showFile"] = false;
        }
        else {
            updatedPropDefs["CoA"]["readOnly"] = updatedPropDefs["ExecBatchRec"]["readOnly"] = updatedPropDefs["AnalyticRawData"]["readOnly"] = false;
            updatedPropDefs["CoA"]["className"] = updatedPropDefs["ExecBatchRec"]["className"] = updatedPropDefs["AnalyticRawData"]["className"] = "btn-enable";
        }

        //set currentFormValues based on search data
        currentFormValues["id"] = existingValues["id"];
        currentFormValues["CoAfileName"] = existingValues["CoAfileName"]; currentFormValues["CoAFileDetails"] = existingValues["CoAFileDetails"];
        currentFormValues["ExecBatchRecfileName"] = existingValues["ExecBatchRecfileName"]; currentFormValues["ExecBatchRecFileDetails"] = existingValues["ExecBatchRecFileDetails"];
        currentFormValues["AnalyticRawDatafileName"] = existingValues["AnalyticRawDatafileName"]; currentFormValues["AnalyticRawDataFileDetails"] = existingValues["AnalyticRawDataFileDetails"];

        transition({
            type: "HIDE_INDICATOR"
        })

        if (updatedPropDefs["CoA"]["showFile"] !== true && value["CoA"] !== undefined) {
            updatedPropDefs["CoA"]["showFile"] = true;
        } else if (updatedPropDefs["ExecBatchRec"]["showFile"] !== true && value["ExecBatchRec"] !== undefined) {
            updatedPropDefs["ExecBatchRec"]["showFile"] = true;
        } else if (updatedPropDefs["AnalyticRawData"]["showFile"] !== true && value["AnalyticRawData"] !== undefined) {
            updatedPropDefs["AnalyticRawData"]["showFile"] = true;
        }

        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
        });
    };
}

const batchCustome: ICustomStateMachineData = {
    name: "batchFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onBatchFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onBatchFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onBatchFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onBatchFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doBatchFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onBatchFormSubmit: (evt) => dispatch(doBatchFormSubmit(evt)),
            onBatchFormHandleChange: ({ value, formValues }) => dispatch(doBatchFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "batches_form",
    typeId: "batches"
}, batchCustome);

//batches_edit_form 

function doEditBatchFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        const batchValidationSchema = {
            ...validationSchema,
            "batchId": Yup.string().matches(RegExp.BATCHID, { message: getlocaleText("{{batchId.errorMessage}}") }).required(getlocaleText("{{batchId.required}}")),
            "cmcSiteId": Yup.string().required(getlocaleText("{{cmcsiteName.required}}")),
            "manufacturingDate": Yup.string().required(getlocaleText("{{manufacturingDate.required}}")),
            "compoundId": Yup.string().required(getlocaleText("{{compoundName.required}}")),
        };

        if (objectData && objectData["CoAFileDetails"]) {
            updateFormSchema.propertyDefinitions["CoA"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["CoA"]["className"] = "btn-enable";
        }
        if (objectData && objectData["ExecBatchRecFileDetails"]) {
            updateFormSchema.propertyDefinitions["ExecBatchRec"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["ExecBatchRec"]["className"] = "btn-enable";
        }
        if (objectData && objectData["AnalyticRawDataFileDetails"]) {
            updateFormSchema.propertyDefinitions["AnalyticRawData"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["AnalyticRawData"]["className"] = "btn-enable";
        }
        updateFormSchema.propertyDefinitions["CoA"]["className"] = updateFormSchema.propertyDefinitions["ExecBatchRec"]["className"] = updateFormSchema.propertyDefinitions["AnalyticRawData"]["className"] = "display-none";
        updateFormSchema.propertyDefinitions["CoAfileName"]["className"] = updateFormSchema.propertyDefinitions["ExecBatchRecfileName"]["className"] = updateFormSchema.propertyDefinitions["AnalyticRawDatafileName"]["className"] = "display-none";
        if (objectData) {
            switch (objectData.editDocumentFor) {
                case DocumentType.COA_UPLOAD: {
                    updateFormSchema.propertyDefinitions["CoA"]["className"] = "display-block";
                    updateFormSchema.propertyDefinitions["CoAfileName"]["className"] = "display-block";
                    break;
                }
                case DocumentType.EXEC_BATCH_REC_UPLOAD: {
                    updateFormSchema.propertyDefinitions["ExecBatchRec"]["className"] = "display-block";
                    updateFormSchema.propertyDefinitions["ExecBatchRecfileName"]["className"] = "display-block";
                    break;
                }
                case DocumentType.ANALYTIC_RAW_DATA_UPLOAD: {
                    updateFormSchema.propertyDefinitions["AnalyticRawData"]["className"] = "display-block";
                    updateFormSchema.propertyDefinitions["AnalyticRawDatafileName"]["className"] = "display-block";
                    break;
                }
            }
        }

        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            formSchema: updateFormSchema,
            validationSchema: batchValidationSchema,
            objectData: objectData,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, typeId),
            extraParams: extraParams,

        });
    }
}

function doEditBatchFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const bactchData = {
                id: event.values["id"],
                cmcSiteId: event.values["cmcSiteId"],
                compoundId: event.values["compoundId"],
                manufacturingDate: event.values && event.values["manufacturingDate"] ? new Date(event.values["manufacturingDate"]) : undefined,
            }
            const data = await batchDatasource.update(bactchData, { id: TYPES.BATCHES });
            if (data) {
                console.log("batch updated successfully");
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                if (event.values["CoA"] && event.values["CoA"].length > 0) {
                    let coaFile = event.values["CoA"][0];
                    const uploadvalues: any = {
                        batchId: event.values["id"],
                        documentType: "coaUpload",
                        contentStreamFileName: coaFile.name,
                        contentSteamLength: coaFile.size + "",
                        contentStreamMimeType: coaFile.type,
                    }
                    let result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, coaFile);
                }
                if (event.values["ExecBatchRec"] && event.values["ExecBatchRec"].length > 0) {
                    let execBatchRecFile = event.values["ExecBatchRec"][0];
                    const uploadvalues: any = {
                        batchId: event.values["id"],
                        documentType: "execBatchRecUpload",
                        contentStreamFileName: execBatchRecFile.name,
                        contentSteamLength: execBatchRecFile.size + "",
                        contentStreamMimeType: execBatchRecFile.type,
                    }
                    let result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, execBatchRecFile);
                }
                if (event.values["AnalyticRawData"] && event.values["AnalyticRawData"].length > 0) {
                    event.values["AnalyticRawData"].map(async (analyticRawDataFile) => {
                        const uploadvalues: any = {
                            batchId: event.values["id"],
                            documentType: "analyticRawDataUpload",
                            contentStreamFileName: analyticRawDataFile.name,
                            contentSteamLength: analyticRawDataFile.size + "",
                            contentStreamMimeType: analyticRawDataFile.type,
                        }
                        let result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                        await datasource.uploadFileToCloud(result.signedUrl, analyticRawDataFile);
                    })
                }
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                    controlID: "batches_form",
                    strict: true,
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.batchSuccessMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const editBatchCustomState: ICustomStateMachineData = {
    name: "EditBatchFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onEditBatchFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onEditBatchFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onEditBatchFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(doEditBatchFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onEditBatchFormSubmit: (evt) => dispatch(doEditBatchFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "batches_edit_form",
    typeId: "batches"
}, editBatchCustomState);

// add more batches

function doAddMoreBatchFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const bactchData = {
                id: event.values["id"],
                cmcSiteId: event.values["cmcSiteId"],
                compoundId: event.values["compoundId"],
                manufacturingDate: event.values && event.values["manufacturingDate"] ? new Date(event.values["manufacturingDate"]) : undefined,
            }

            const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
            if (event.values["AnalyticRawData"] && event.values["AnalyticRawData"].length > 0) {
                event.values["AnalyticRawData"].map(async (analyticRawDataFile) => {
                    const uploadvalues: any = {
                        batchId: event.values["id"],
                        documentType: "analyticRawDataUpload",
                        contentStreamFileName: analyticRawDataFile.name,
                        contentSteamLength: analyticRawDataFile.size + "",
                        contentStreamMimeType: analyticRawDataFile.type,
                    };
                    let result = await datasource.postData(uploadvalues, { id: TYPES.BATCHES_UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, analyticRawDataFile);
                })
            }
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
                controlID: "batches_form",
                strict: true,
            });
            transition({
                type: ACTIONS.HIDE_DRAWER,
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{common.batchSuccessMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: ACTIONS.GRID_REFRESH
            });

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const addMoreBatchCustomState: ICustomStateMachineData = {
    name: "AddMoreBatchFormOnSubmit",
    stateJSON: {
        "states": {
            "formSubmit": {
                "onEntry": [
                    "onAddMoreBatchFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            // onEditBatchFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(doEditBatchFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onAddMoreBatchFormSubmit: (evt) => dispatch(doAddMoreBatchFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "batches_uploadMore_form",
    typeId: "batches"
}, addMoreBatchCustomState);
