import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { DROPDOWN, LOCALSTORAGE_NAME, TYPES } from "../../utils/constant";
import { getFormatedDate } from "../../common/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doUploadFilterbarBeforeChange(value: {}, formValues: {}) {
    return async (getState, dispatch, transition) => {
        const { formValues, metadata } = getState();
        let currentFormValues;
        let updatedValues = {
            ...value,
        };
        
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (Object.keys(value).toString() === "siteId") {
            let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
            let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
            value["siteId"] = value["siteId"] && metadata.propertyDefinitions.siteId.options.map(item => { return item.key }).includes(value["siteId"]) ? value["siteId"] : "all";
            updatedValues["siteId"] = value["siteId"];
            let studyId = value["studyId"] ? value["studyId"] : formValues["studyId"];
            let compoundId = value["compoundId"] ? value["compoundId"] : formValues["compoundId"];
            let batchId
            if (!value["siteId"].includes("all")) {
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
              
                transition({
                    type: "SHOW_INDICATOR",
                    loadingMessage: ""
                });
                const getStudies = await datasource.getAll(DROPDOWN.FILTER_STUDIES_DROPDOWN, { siteId: value["siteId"] });
                transition({
                    type: "HIDE_INDICATOR",
                });
                const studyOption = [getStudies]
                updatedPropDefs["studyId"].metadata.data = studyOption[0];
                updatedPropDefs["studyId"].metadata.isLocal = true;
                studyId = selectedfilter_study && studyOption[0].map(item => { return item.id }).includes(selectedfilter_study) ? selectedfilter_study : "all";

                const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
                transition({
                    type: "SHOW_INDICATOR",
                    loadingMessage: ""
                });
                const siteData = await siteDatasource.getObject(TYPES.SITES, value["siteId"]);
                transition({
                    type: "HIDE_INDICATOR",
                });
                const compoundDataSource: any = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
                transition({
                    type: "SHOW_INDICATOR",
                    loadingMessage: ""
                });
                const getCompounds = await compoundDataSource.getAll(DROPDOWN.ALL_CMC_SITES_COMPOUNDS, { cmcSiteId: siteData["cmcSiteId"] });
                transition({
                    type: "HIDE_INDICATOR",
                });
                const compoundsOption = [getCompounds];
                updatedPropDefs["compoundId"].metadata.data = compoundsOption[0];
                updatedPropDefs["compoundId"].metadata.isLocal = true;
                compoundId = selectedfilter_compound && compoundsOption[0].map(item => { return item.id }).includes(selectedfilter_compound) ? selectedfilter_compound : "all";
                const batchDataSource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
                const getBatchs = await batchDataSource.getAll(TYPES.BATCHES, { compoundId: compoundId["compoundId"] ,cmcSiteId: siteData["cmcSiteId"]});
                 let targetIds
                 let filteredObjects
                if(compoundsOption?.length>= 0){
                    targetIds =  compoundsOption?.map(obj => obj.id);
                     filteredObjects = getBatchs?.filter(obj => compoundId.includes(obj.compoundId));
                     
       }     
            }
            else if (value["siteId"].includes("all")) {
                studyId = "all"; compoundId = "all";
                const compoundsOption = [{
                    "compound_name": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }];
                updatedPropDefs["compoundId"].metadata.data = compoundsOption;
                updatedPropDefs["compoundId"].metadata.isLocal = true;

                const studyOption = {
                    "studyName": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }
                updatedPropDefs["studyId"].metadata.data = studyOption;
                updatedPropDefs["studyId"].metadata.isLocal = true;
            }

            const siteId = value["siteId"];
            currentFormValues = { ...formValues, ...updatedValues, siteId, studyId, compoundId };
        }
        else if (value["lastDays"] && value["lastDays"] == "customDate") {
            const customDateRange = "";
            currentFormValues = { ...formValues, ...updatedValues, customDateRange };
        }
        else {
            currentFormValues = { ...formValues, ...updatedValues };
        }

        if (currentFormValues && currentFormValues["lastDays"] && currentFormValues["lastDays"] == "customDate") {
            updatedPropDefs["customDateRange"].readOnly = false;
        }
        else {
            updatedPropDefs["customDateRange"].readOnly = true;
        }

        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            controlID: "upload_filter",
            strict: true,
        });
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            controlID: "upload_filter",
            strict: true,
        });
        if (currentFormValues["lastDays"] && ((currentFormValues["lastDays"] == "customDate" && currentFormValues["customDateRange"]) || currentFormValues["lastDays"] != "customDate")) {
            let searchValue = {
                siteId: currentFormValues["siteId"], studyId: currentFormValues["studyId"],
                lastDays: currentFormValues["lastDays"], compoundId: currentFormValues["compoundId"],
                customDateRange: currentFormValues["customDateRange"]
            };
            localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE, searchValue["siteId"]);
            localStorage.setItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE, searchValue["studyId"]);
            localStorage.setItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE, searchValue["compoundId"]);

            transition({
                controlID: "uploads",
                strict: true,
                type: "GRID_FILTER",
                searchValue: searchValue
            });
        }
    }
}

function doUploadFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        let selectedfilter_study = selectedfilter_csite?.includes("all") ? "all" : localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        let selectedfilter_compound = selectedfilter_csite?.includes("all") ? "all" : localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
        const customeobjectData = {
            ...objectData,
            "siteId": selectedfilter_csite ? selectedfilter_csite : "all",
            "studyId": selectedfilter_study ? selectedfilter_study : "all",
            "lastDays": "all",
            "compoundId": selectedfilter_compound ? selectedfilter_compound : "all",
            "customDateRange": ""
        };
        const CurrentDate = new Date();
        const TodayDate = getFormatedDate(CurrentDate);
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        updateFormSchema.propertyDefinitions["customDateRange"]["maxDate"] = TodayDate;

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: updateFormSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });


    }
}


const subjectFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onUploadFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onUploadFilterbarBeforeChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onUploadFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doUploadFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onUploadFilterbarBeforeChange: ({ value, formValues }) => dispatch(doUploadFilterbarBeforeChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "upload_filter"
}, subjectFormHandler); let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);