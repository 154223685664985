export const ACTIONS = {
    HIDE_POPUP: "HIDE_POPUP",
    SHOW_POPUP: "SHOW_POPUP",
    SHOW_DRAWER: "SHOW_DRAWER",
    HIDE_DRAWER: "HIDE_DRAWER",
    GRID_REFRESH: "GRID_REFRESH",
    SHOW_INDICATOR: "SHOW_INDICATOR",
    HIDE_INDICATOR: "HIDE_INDICATOR",
    Form: {
        FORM_SUBMIT_CLICK: "FORM_SUBMIT_CLICK"
    }
}

export const USER = {
    ADDNEW: "users/addnew",
    USERS: "users"
}

export const publicUrls = ["forgotpassword", "resetpassword", "enroll", "success", "error"];

export const Roles = {
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    UPLOAD_USER: "Upload User",
    ANALYST: "Analyst",
    QC_USER: "QC User",
    CMC_UPLOAD_USER: "CMC Upload User",
    CMC_ADMIN: "CMC Admin"
}

export const TYPES = {
    SITES: "sites",
    STUDY: "studies",
    SUBJECT: "subjects",
    VISITS: "visits",
    UPLOADS: "uploads",
    COMMENTS: "comments",
    CLASSIFICATION: "classifications",
    SEARCH: "search",
    CMCSITES: "cmc-sites",
    COMPOUNDS: "compounds",
    BATCHES: "batches",
    BATCHES_UPLOADS: "batches-uploads",
    IMAGE_PROTOCOL_UPLOADS: "image-protocols",
    CMC_SITES_COMPOUNDS: "cmc-site-compounds",
    COMPOUND_MANUFACTUREDS: "compound-manufactureds"
}

export const DocumentType = {
    CRF: "CRF",
    MRI: "MRI",
    PET: "PET",
    COA_UPLOAD: "coaUpload",
    EXEC_BATCH_REC_UPLOAD: "execBatchRecUpload",
    ANALYTIC_RAW_DATA_UPLOAD: "analyticRawDataUpload"
}

export const DateTimeFormat = "MM-DD-YYYY HH:mm:ss";
export const DateFormat = "MM-DD-YYYY";

export const RegExp = {
    USERNAME: /^([a-zA-Z0-9]){3,20}$/,
    PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
    EMAIL: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    PHONENUMBER: /^(?=.*[0-9])[0-9+\- \(\)]{6,20}$/,
    NOTEMAIL: /^(?!^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$).*$/i,
    SUBJECTID: /^[a-zA-Z0-9]{1,25}$/,
    BATCHID: /^[a-zA-Z0-9-]{1,8}$/
};

export const SuccessType = {
    ENROLL: "enroll",
    FORGOT_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword"
}

export const ErrorType = {
    INVALID_URL: "invalidUrl",
    ERROR_403: "error_403"
}

export const DROPDOWN = {
    SITES: "sites_dropdown",
    STUDIES: "studies_dropdown",
    SUBJECT: "subjects_dropdown",
    FILTER_STUDIES_DROPDOWN: "filter_studies_dropdown",
    ALL_SITES: "all_sites_dropdown",
    ALL_ClASSIFICATIONS: "all_classifications_dropdown",
    CMCSITES: "cmcsites_dropdown",
    COMPOUNDS: "compound_dropdown",
    ALL_CMC_SITES_COMPOUNDS: "all_cmcsites_compound_dropdown",
    CMC_SITES_COMPOUNDS: "cmcsites_compound_dropdown", // cmc-site-compounds compound_dropdown
    FILTER_CMCSITES_DROPDOWN: "filter_cmcsites_dropdown",
    BATCHES: "batch_dropdown",
}

export const UPLOAD_STATUS = {
    NEW: "NEW",
    OPEN: "OPEN",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    REJECT_CONFIRMED: "REJECT_CONFIRMED"
}

export const LOCALSTORAGE_NAME = {
    FILTER_MSITE_VALUE: "FILTER_MSITE_VALUE",
    FILTER_CSITE_VALUE: "FILTER_CSITE_VALUE",
    FILTER_STUDY_VALUE: "FILTER_STUDY_VALUE",
    FILTER_CLASSIFICATION_VALUE: "FILTER_CLASSIFICATION_VALUE",
    FILTER_COMPOUND_VALUE: "FILTER_COMPOUND_VALUE",
    FILTER_SHARINGCONSENT_VALUE: "FILTER_SHARINGCONSENT_VALUE"
}