import { getUploadHeaders, getCreatedBy, getModifiedBy, getFormatedDateWithTimezoneOffset, getFormatedVisitDate, getHeaders, getLoginUserRoleName } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { UPLOAD_STATUS } from "../utils/constant"
import axios from "axios";
import { getFilterQuery } from "../utils/helpers"
import { getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class UploadDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config;
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            await getFilterQuery(parameters, "")
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            const getItem = data.map((item, i) => {
                return {
                    ...item,
                    fileName: item.contentStreamFileName,
                    classification: item.visit && item.visit.classification ? item.visit.classification.name : null,
                    lastModifiedDate: getFormatedDateWithTimezoneOffset(item.lastModifiedDate),
                    lastModifiedBy: getModifiedBy(item),
                    uploadedBy: getCreatedBy(item),
                    uploadDate: item.uploadDate ? getFormatedDateWithTimezoneOffset(item.uploadDate) : getFormatedDateWithTimezoneOffset(item.lastModifiedDate),
                    visitDate: item.visit ? getFormatedVisitDate(item.visit.visitDate) : null,
                    studyNameTooltip: item.subject && item.subject.study ? `PI : ${item.subject.study.principalInvestigator}` : null
                }
            })
            getItem["totalRecordsCount"] = data["totalRecordsCount"];
            return getItem
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(formData: {}, metadata: any, uploadId: any = null): Promise<any> {
        await this.initializeConfig();
        const headers = this.headers;
        try {
            // api to create a new upload file
            let url = `${this.dataURL}/${metadata.id}`;
            if (uploadId) {
                // api to replace existing upload file
                url = `${this.dataURL}/${metadata.id}/${uploadId}/upload`;
            }
            const resultData = await axios.post(url, formData, {
                headers: {
                    Connection: "keep-alive",
                    "Content-Type": `multipart/form-data; boundary=${formData["_boundary"]}`,
                    ...headers
                }
            });
            return resultData.data;
        } catch (e) {
            throw new Error(e.response.data.error.message);
        }
    }

    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }
    async postData(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }
    async uploadFile(data: {}, metadata: any): Promise<any> {
        try {
            var formData = new FormData();
            var res;
            formData.append(data["documentType"], data["document"]);
            formData.append("documentType", data["documentType"]);
            formData.append("visitId", data["visitId"]);
            formData.append("status", data["status"] ? data["status"] : UPLOAD_STATUS.NEW);
            if (data["uploadId"]) {
                res = await this.create(formData, metadata, data["uploadId"]);
            }
            else {
                res = await this.create(formData, metadata);
            }


            if (res) {
                console.log(`upload file successfully with document type : ${data["documentType"]}`);
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    async getSignedURL(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            const headers = this.headers;
            const url = `${this.dataURL}/${metadata.id}/${data["id"]}/signedURL?userRole=${getLoginUserRoleName()}`;
            const resultData = await axios.get(url, { headers });
            return resultData.data;
        } catch (e) {
            throw new Error(`Failed to download the file, Reason: ${e.message}`);
        }
    }

    async downlodFile(url: string): Promise<any> {
        try {
            await this.lbDatasource.downlodFile(url);
        } catch (e) {
            throw new Error(`Failed to download the document`);
        }
    }

    async uploadFileToCloud(signedUrl: string, file: any, uploadDetails: any): Promise<any> {
        try {
            const sizeinbytes = file.size;
            let fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
            let fSize = sizeinbytes;
            let i = 0;
            while (fSize > 900) { fSize /= 1024; i++; }
            const fileSize = (Math.round(fSize * 100) / 100) + ' ' + fSExt[i];
            const fileName = file.name ? `${file.name} (${fileSize})` : "";
            eventTransition({
                type: "UPDATE_INDICATOR",
                loadingMessage: "{{fileUploading.msg}}",
                showProgressBar: true,
                progressValue: 0,
                fileName: fileName,
            });
            console.log(`uploading file - ${file.name}`);
            localStorage.setItem("fileName", fileName);
            await axios.request({
                method: "put",
                url: signedUrl,
                headers: {
                    'Content-Type': file.type,
                },
                data: file,
                onUploadProgress: (p) => {
                    let fileprogress = (p.loaded / p.total) * 100;
                    let percentCompleted = Math.round(fileprogress);
                    localStorage.setItem("progressValue", percentCompleted.toString());
                    console.log(`upload progress ${percentCompleted}`);
                }
            }).then(data => {
                localStorage.removeItem("progressValue");
                localStorage.removeItem("fileName");
                eventTransition({
                    type: "UPDATE_INDICATOR",
                    loadingMessage: "{{form.submitIndicator}}",
                    showProgressBar: false,
                    progressValue: 100
                });
                if (uploadDetails) {
                    uploadDetails["uploadStatus"] = "SUCCESS";
                    this.update(uploadDetails, { id: "uploads" });
                }
            })
        } catch (e) {
            localStorage.removeItem("progressValue");
            localStorage.removeItem("fileName");
            eventTransition({ type: "HIDE_INDICATOR" });
            uploadDetails["uploadStatus"] = "FAILED";
            this.update(uploadDetails, { id: "uploads" });
            throw new Error(`Failed to download the document`);
        }
    }

}