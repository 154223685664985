import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import { getlocaleText } from "@itsy-ui/utils";
import { Row, Col } from "react-bootstrap";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getFormLabelClassName } from "../../helper";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const moment = require("moment");
const DateFormat = "MM-DD-YYYY";

const CustomInput = React.forwardRef((props, ref) => {
    let inputDateValue = props.state.ipDate !== undefined ? props.state.ipDate : props.value;
    if (props.controlProps && props.controlProps.value === "") {
        inputDateValue = "";
    }
    return (
        <div class="input-group mb-3">
           <input
                type="text"
                class="form-control"
                placeholder={props.fieldSchema.placeholderText ? props.fieldSchema.placeholderText : "mm-dd-yyyy"}
                aria-label={inputDateValue}
                aria-describedby="calendar-svg"
                onChange={(e) => props.handleIpChange(e.target.value, props)}
                value={inputDateValue} />
            <div class="input-group-append">
                <span class="input-group-text" id="calendar-svg" onClick={props.onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                    </svg>
                </span>
            </div>
        </div>
    );
});

class InputDate extends React.Component {
    constructor(props) {
        super(props);
        const controlProps = this.getControlSchemaProperties();
        this.state = {
            ipDate: controlProps.value
        }
    }
    getControlSchemaProperties() {
        const registry = getDefaultRegistry();
        const { definitions } = registry;
        const schema = retrieveSchema(this.props.schema, definitions);
        return schema;
    }

    handleDateChange(date) {
        const controlProps = this.getControlSchemaProperties();
        const { fieldSchema } = controlProps;
        let value = date !== undefined ? date : controlProps.value;
        value = moment(new Date(value)).format(DateFormat);
        controlProps.handleChange(undefined, value);
        if (value && new Date(value)) {
            this.setState({ ipDate: moment(new Date(value)).format(DateFormat) });
        }
    }

    handleIpChange(val, props) {
        let test = moment(val, DateFormat, true).isValid();
        if (test) {
            if (this.isDateinRange(val, props.fieldSchema.minDate, props.fieldSchema.maxDate)) {
                let inputDate = val && (typeof val !== "object") ? val.replaceAll("-", "/") : val; // to fix new Date(value) - invalid date in fire fox
                this.handleDateChange(new Date(inputDate));
            }
        }
        if ((props.controlProps && props.controlProps.value === "") || (val === "") || !test) {
            props.controlProps.handleChange(undefined, undefined);
        }
        this.setState({ ipDate: val });
    }

    isDateinRange(date, minDate, maxDate) {
        let isAfterDate = true, isBeforeDate = true;
        if (minDate) {
            isAfterDate = moment(date).isSameOrAfter(minDate);
        }
        if (maxDate) {
            isBeforeDate = moment(date).isSameOrBefore(maxDate);
        }
        if (isAfterDate && isBeforeDate) {
            return true
        }
        return false;
    }

    renderDate(controlProps, fieldSchema, className, style) {
        const disabledClass = fieldSchema.readOnly ? "borderDisable" : ""
        const dateValue = this.state.ipDate;
        if (dateValue && !moment(dateValue, DateFormat, true).isValid()) {
            controlProps.error = getlocaleText("{{common.invalidDateError}}");
        }
        else if (dateValue && !this.isDateinRange(dateValue, fieldSchema.minDate, fieldSchema.maxDate)) {
            controlProps.error = getlocaleText("{{common.notInRangeDateError}}");
        }
        const minDate = fieldSchema.minDate && (typeof fieldSchema.minDate !== "object") ? fieldSchema.minDate.replaceAll("-", "/") : fieldSchema.minDate;
        const maxDate = fieldSchema.maxDate && (typeof fieldSchema.maxDate !== "object") ? fieldSchema.maxDate.replaceAll("-", "/") : fieldSchema.maxDate;
       
      
        const customClass = fieldSchema.className ? fieldSchema.className : "";
        const customStyle = fieldSchema.style ? fieldSchema.style : {};
        return (
            <Form.Group className={`date-time-contianer ${className}`} style={{width:"100% !important" }} controlId={fieldSchema.id}>
                <label className={getFormLabelClassName(fieldSchema)} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</label>
              <div className="date-component" style={{width:"100% !important" }}>
                  <DatePicker
                        id={'apnDatePicker'}
                        selected={controlProps.value ? new Date(controlProps.value) : undefined}
                        onChange={(date) => {
                            this.handleDateChange(date);
                        }}
                        className="form-control"
                        minDate={new Date(minDate)}
                        maxDate={new Date(maxDate)}
                        dateFormat={"MM-dd-yyyy"}
                        shouldCloseOnSelect={true}
                        customInput={<CustomInput
                            state={this.state}
                            fieldSchema={fieldSchema}
                            controlProps={controlProps}
                            handleIpChange={(val, props) => this.handleIpChange(val, props)} />}
                    />
                    {fieldSchema.helptext && <Form.Text aria-label={getlocaleText(fieldSchema.helptext)}>{getlocaleText(fieldSchema.helptext)}</Form.Text>}
                    {controlProps.error && <Form.Text aria-label={controlProps.error}>{controlProps.error}</Form.Text>}
                </div>
            </Form.Group>
        );
    }

    render() {
        const controlProps = this.getControlSchemaProperties();
        const { fieldSchema } = controlProps;
        const customClass = fieldSchema.className ? fieldSchema.className : "";
        const customStyle = fieldSchema.style ? fieldSchema.style : {};
        let formatedDateValue;
        if (controlProps.value && new Date(controlProps.value) && !(controlProps.value === "")) {
            formatedDateValue = moment(new Date(controlProps.value)).format(DateFormat)
        }

        if (controlProps.isReadonly || fieldSchema.readOnly) {
            return (
                <Form.Group className={`date-time-contianer ${customClass}`} style={customStyle} controlId={fieldSchema.id}>
                    <label className="text-label label-disabled form-label" tabIndex={0} aria-label={fieldSchema.displayName}>{fieldSchema.displayName}</label>
                    <div className="date-component">
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder={fieldSchema.placeholderText ? fieldSchema.placeholderText : "mm-dd-yyyy"}
                                aria-label={formatedDateValue}
                                disabled={true}
                                tabIndex={0}
                                value={formatedDateValue} />
                        </div>
                    </div>
                </Form.Group>
            );
        }
        if (fieldSchema.visibility === undefined || fieldSchema.visibility) {
            return this.renderDate(controlProps, fieldSchema, customClass, customStyle)
        } else { return null; }
    }
}

InputDate['displayName'] = 'InputDateControl';

WidgetsFactory.instance.registerFactory(InputDate);
WidgetsFactory.instance.registerControls({
    apn_date: 'InputDateControl'
});