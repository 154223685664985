import {
  getDefaultRegistry,
  IWidgetControlProps,
  retrieveSchema,
  WidgetsFactory,
  eventTransition,
} from "@itsy-ui/core";
import { DataLoaderFactory, ICommandManager } from "@itsy-ui/core";
import * as React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { getlocaleText } from "@itsy-ui/utils";

class LinkControl extends React.Component<IWidgetControlProps, {}> {
  getControlSchemaProperties() {
    const registry = getDefaultRegistry();
    const { definitions } = registry;
    const schema = retrieveSchema(this.props.schema, definitions);
    return schema;
  }

  onLinkClick(evt: any) {
   const controlProps = this.getControlSchemaProperties();
    const { fieldSchema } = controlProps;
    const dataLoader = WidgetsFactory.instance.services[
      "DataLoaderFactory"
    ] as DataLoaderFactory;
    const commandManager =
      dataLoader.getLoader<ICommandManager>("commandManager");
    //	return async (_, dispatch: any, transition: any) => {
    let cmd = commandManager.getCommand(fieldSchema.commandName, {});
    try {
      cmd!.execute({}, eventTransition);
    } catch (e) {
      console.log("Command execution error: ", e);
    }
    //}
  }
  onLinkClickData(filedName: any, command: any, formValues: any) {
    // const controlProps = this.getControlSchemaProperties();
    // const { fieldSchema } = controlProps;
    const dataLoader = WidgetsFactory.instance.services[
      "DataLoaderFactory"
    ] as DataLoaderFactory;
    const commandManager =
      dataLoader.getLoader<ICommandManager>("commandManager");
    //	return async (_, dispatch: any, transition: any) => {
    let batchUploadData = {
      id: "",
    };
    if (filedName == "ExecBatchRecfileName") {
      batchUploadData = {
        id: formValues.ExecBatchRecFileDetails.id,
      };
    } else if (filedName == "AnalyticRawDatafileName") {
      batchUploadData = {
        id: formValues.AnalyticRawDataFileDetails.id,
      };
    } else if (filedName == "CoAfileName") {
      batchUploadData = {
        id: formValues.CoAFileDetails.id,
      };
    }
    let cmd = commandManager.getCommand(command, {});
    try {
      cmd!.execute(batchUploadData, eventTransition);
    } catch (e) {
      console.log("Command execution error: ", e);
    }
    //}
  }

  render() {
    const controlProps = this.getControlSchemaProperties();
    const { fieldSchema } = controlProps;
    const { title, displayName, commandName, contextParams, alignText } =
      fieldSchema;
    const alignstyle =
      alignText !== undefined && alignText === "right"
        ? "flex-end"
        : alignText === "center"
        ? "center"
        : "flex-start";
    const customClass = fieldSchema.className ? fieldSchema.className : "";
    const customStyle = fieldSchema.style ? fieldSchema.style : {};
    if (displayName && controlProps.value) {
      //dynamic link
      return (
        <Form.Group
          className={customClass}
          style={customStyle}
          controlId={fieldSchema.id}
        >
          <Form.Label className="text-label">{displayName}</Form.Label>
          <div
            style={{ justifyContent: alignstyle, ...customStyle }}
            className={`input-textbox link-container ${customClass}`}
          >
            <span>
              {fieldSchema.prefixText
                ? getlocaleText(fieldSchema.prefixText)
                : null}
            </span>
            <Card.Link onClick={this.onLinkClick.bind(this)}>
              {controlProps.value ? controlProps.value : null}
            </Card.Link>
          </div>
        </Form.Group>
      );
    } else if (title) {
      //to have some static link click
      return (
        <div
          style={{ justifyContent: alignstyle, ...customStyle }}
          className={`link-container ${customClass}`}
        >
          <span>
            {fieldSchema.prefixText
              ? getlocaleText(fieldSchema.prefixText)
              : null}
          </span>
          <Card.Link onClick={this.onLinkClick.bind(this)}>
            {getlocaleText(title)}
          </Card.Link>
        </div>
      );
    } else if (controlProps.value) {
      return (
        <div
          style={{ justifyContent: alignstyle, ...customStyle }}
          className={`link-container ${customClass}`}
        >
          <span>
            {fieldSchema.prefixText
              ? getlocaleText(fieldSchema.prefixText)
              : null}
          </span>
          <Card.Link
            onClick={() =>
              this.onLinkClickData(
                fieldSchema.id,
                fieldSchema.commandName,
                controlProps.formValues
              )
            }
          >
            {controlProps.value ? controlProps.value : null}
          </Card.Link>
        </div>
      );
    } else return null;
  }
}

LinkControl["displayName"] = "LinkControl";

WidgetsFactory.instance.registerFactory(LinkControl);
WidgetsFactory.instance.registerControls({
  linkText: "LinkControl",
});
