import { getHeaders, getFormatedDateWithTimezoneOffset, getFormatedVisitDate, getFormatedDICOMAvailableDate, getLoginUserRoleName } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { TYPES, UPLOAD_STATUS, Roles } from "../utils/constant"
import { getLoginUserSites, getFilterQuery } from "../utils/helpers"
import { getCurrentUserRole, getServerUrl } from "../utils/utils";
import { getlocaleText } from "@itsy-ui/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class VisitDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        let siteId;
        if (getCurrentUserRole(Roles.QC_USER)) {
            eventTransition({
                type: "FORM_GET_STATE",
                strict: true,
                controlID: "qcDashboard_filters",
                onData: (formData) => {
                    siteId = formData["formValues"]["siteId"];
                },
            });
        } else {
            eventTransition({
                type: "FORM_GET_STATE",
                strict: true,
                controlID: "upload_filter",
                onData: (formData) => {
                    siteId = formData["formValues"]["siteId"];
                },
            });
        }
        await getFilterQuery(parameters, siteId);
        try {
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            if (typeId === TYPES.VISITS) {
                const getItem = data.map((item, i) => {
                    const hasClassification = item && item["classification"] ? true : false;
                    const hasUpload = item.uploads && item.uploads.length > 0 ? true : false;
                    item.weight = item.weight === "-1" || item.weight === null || item.weight === undefined ? getlocaleText("{{common.none}}") : item.weight;
                    item.bmi = item.bmi === "-1" || item.bmi === null || item.bmi === undefined ? getlocaleText("{{common.none}}") : item.bmi;
                    item.mmse = item.mmse === "-1" || item.mmse === null || item.mmse === undefined ? getlocaleText("{{common.none}}") : item.mmse;
                    item.cdr = item.cdr === "-1" || item.cdr === null || item.cdr === undefined ? getlocaleText("{{common.none}}") : item.cdr;
                    item.adas_cog = item.adas_cog === "-1" || item.adas_cog === null || item.adas_cog === undefined ? getlocaleText("{{common.none}}") : item.adas_cog;
                    item.fab = item.fab === "-1" || item.fab === null || item.fab === undefined ? getlocaleText("{{common.none}}") : item.fab;
                    item.psprs = item.psprs === "-1" || item.psprs === null || item.psprs === undefined ? getlocaleText("{{common.none}}") : item.psprs;
                    item.updrs = item.updrs === "-1" || item.updrs === null || item.updrs === undefined ? getlocaleText("{{common.none}}") : item.updrs;
                    item.adverse_events = item.adverseEvent === null || item.adverseEvent === undefined ? getlocaleText("{{common.none}}") : item.adverseEvent ? getlocaleText("{{common.yes}}") : getlocaleText("{{common.no}}");
                    item.rawImage = item.raw === null || item.raw === undefined ? getlocaleText("{{common.none}}") : item.raw ? getlocaleText("{{common.yes}}") : getlocaleText("{{common.no}}");
                    item.compound_name = item.compoundManufactured && item.compoundManufactured.compounds ? item.compoundManufactured.compounds.compound_name : getlocaleText("{{common.none}}");
                    item.batchId = item.compoundManufactured ? item.compoundManufactured.batchId : getlocaleText("{{common.none}}");
                    return {
                        ...item,
                        ...hasClassification && { classification: item["classification"]["name"], classificationId: item["classification"]["id"] },
                        ...item.subject && {
                            subjectId: item.subject["subjectId"], gender: item.subject["gender"],
                            studyName: item.subject.study ? item.subject.study["studyName"] : null,
                            siteName: item.subject.study && item.subject.study.sites && item.subject.study.sites.length > 0 ? item.subject.study.sites[0].siteName : null,
                            "thirdPartySharing": item.subject.sharingConsent ? getlocaleText("{{common.allowed}}") : getlocaleText("{{common.notAllowed}}"),
                            studyNameTooltip: item.subject.study && item.subject.study.principalInvestigator ? `PI : ${item.subject.study.principalInvestigator}` : null
                        },
                        ...item.visitDate && {
                            visitDate: getFormatedVisitDate(item.visitDate)
                        },
                        ...hasUpload && { uploadDate: getFormatedDateWithTimezoneOffset(item.uploadDate) }
                    }
                })
                getItem["totalRecordsCount"] = data["totalRecordsCount"];
                return getItem;
            }
            return data
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async getSignedURL(parameters: any, defaultVisibleColumnsParams): Promise<any> {
        await this.initializeConfig();
        try {
            let siteId;
            if (getCurrentUserRole(Roles.QC_USER)) {
                eventTransition({
                    type: "FORM_GET_STATE",
                    strict: true,
                    controlID: "qcDashboard_filters",
                    onData: (formData) => {
                        siteId = formData["formValues"]["siteId"];
                    },
                });
            } else {
                eventTransition({
                    type: "FORM_GET_STATE",
                    strict: true,
                    controlID: "upload_filter",
                    onData: (formData) => {
                        siteId = formData["formValues"]["siteId"];
                    },
                });
            }

            if (parameters?.filterParams?.siteId === "all") {
                delete parameters.filterParams.siteId
            }
            if (parameters?.filterParams?.studyId === "all") {
                delete parameters.filterParams.studyId
            }
            if (parameters?.filterParams?.compoundId === "all") {
                delete parameters.filterParams.compoundId
            }
            if (parameters?.filterParams?.lastDays === "all") {
                delete parameters.filterParams.lastDays
            }
            let params = { "filter": parameters.filterParams };
            await getFilterQuery(params, siteId);
            let requestBody = {
                "selectedColumns": []
            };
            const selectedColumnsStr = localStorage.getItem(`selectedColumsToShow_uploads`);
            const selectedColumsToShowArry = selectedColumnsStr ? selectedColumnsStr.split(",") : selectedColumnsStr === null || selectedColumnsStr === undefined ? defaultVisibleColumnsParams : [];
            selectedColumsToShowArry.map(item => {
                switch (item) {
                    case "subjectId": requestBody.selectedColumns.push("subject.subjectId"); break;
                    case "studyName": requestBody.selectedColumns.push("subject.study.studyName"); break;
                    case "CRF": requestBody.selectedColumns.push("crf"); break;
                    case "MRI": requestBody.selectedColumns.push("mri"); break;
                    case "PET": requestBody.selectedColumns.push("pet"); break;
                    case "gender": requestBody.selectedColumns.push("subject.gender"); break;
                    case "classification": requestBody.selectedColumns.push("classification.name"); break;
                    case "compound_name": requestBody.selectedColumns.push("compoundManufactured.compounds.compound_name"); break;
                    case "batchId": requestBody.selectedColumns.push("compoundManufactured.batchId"); break;
                    case "adverse_events": requestBody.selectedColumns.push("adverseEvent"); break;
                    case "siteName":
                    case "visitDate":
                    case "uploadDate":
                    case "weight":
                    case "bmi":
                    case "mmse":
                    case "cdr":
                    case "adas_cog":
                        requestBody.selectedColumns.push(item);
                        break;
                    default: break;
                }
            });
            let data = await this.lbDatasource.getAll_export(`${TYPES.VISITS}/export?userRole=${getLoginUserRoleName()}`, { ...params, "orderBy": parameters.filterParams.orderBy, requestBody }, this.dataURL);
            return data;
        } catch (e) {
            throw new Error(`Failed to download the file, Reason: ${e.message}`);
        }
    }

    async downlodFile(url: string): Promise<any> {
        try {
            await this.lbDatasource.downlodFile(url);
        } catch (e) {
            throw new Error(`Failed to download the document`);
        }
    }
}