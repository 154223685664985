import { WidgetsFactory, IDataSourceLake, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { getFormatedDateOfBirth, getFormatedDateWithTimezoneOffset } from "../common/helpers";
import { TYPES } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

function doSubjectDataFormSchemaBeforeLoad(event: any) {
    return async (_, dispatch, transition) => {
        const dataSource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
        const { typeId, formSchemaId, objectData, formSchema, validationSchema, queryParams } = event;
        let items = [];
        try {
            const subjectId = queryParams["subjectId"];
            const subjectData = subjectId ? await dataSource.getObject(TYPES.SUBJECT, subjectId) : null;
            const data = {
                id: subjectId,
                subjectId: subjectData ? subjectData["subjectId"] : null,
                studyName: subjectData && subjectData["study"] ? subjectData["study"]["studyName"] : null,
                siteName: subjectData && subjectData["study"] && subjectData["study"]["sites"] && subjectData["study"]["sites"].length > 0 ? subjectData["study"]["sites"][0].siteName : null,
                birthDate: getFormatedDateOfBirth(subjectData.birthDate),
                gender: subjectData ? subjectData["gender"] : null,
                classification: subjectData && subjectData["visit"] && subjectData["visit"].length > 0 && subjectData["visit"][0].classification.name ? subjectData["visit"][0].classification.name : null,
                createdDate: getFormatedDateWithTimezoneOffset(subjectData.createdDate),
                lastModifiedDate: getFormatedDateWithTimezoneOffset(subjectData.lastModifiedDate),
                thirdPartySharing: (subjectData["sharingConsent"] === true || subjectData["sharingConsent"] == getlocaleText("{{common.allowed}}")) ? getlocaleText("{{common.allowed}}") : getlocaleText("{{common.notAllowed}}"),
            }

            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                objectData: data,
                typeId: typeId,
            });
        } catch (e) {
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: validationSchema,
                typeId: typeId,
            });
        }
    }
}

const subjectDataForm: ICustomStateMachineData = {
    name: "subjectDataForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onSubjectDataFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                }
            }
        }
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSubjectDataFormSchemaBeforeLoad: (event) => dispatch(doSubjectDataFormSchemaBeforeLoad(event)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "subjects",
    "formSchemaId": "viewSubject_data",
    "pageId": "viewSubjectUploadsPage"
}, subjectDataForm);