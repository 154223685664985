import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions, IDataSourceLake } from "@itsy-ui/core";
import { ACTIONS, DateTimeFormat, Roles, USER } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils"
import { getLoginUserCmcSites, getLoginUserSites } from "../utils/helpers";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const moment = require("moment");

function getUserLang(preferredLang) {
    switch (preferredLang) {
        case "en":
            return "English";
        case "zh":
            return "简体中文";
        default:
            return "English";
    }
}


function ProfileEdit(transition, getUser) {
    const drawerData = {
        "title": "{{user.editProfile}}",
        width: "30%",
        "okText": getlocaleText("{{common.Submit}}"),
        "cancelText": getlocaleText("{{user.cancel}}"),
        controlSchema: {
            name: "form",
            properties: {
                "ui:widget": "form",
                "typeId": "userProfile",
                "formSchemaId": "profileEdit",
                "isModal": true,
                controlID: "profileEdit",
                record: getUser
            },
        },
        onOKTransition: {
            type: "FORM_SUBMIT_CLICK",
        },
        onCancelTransition: {
            type: "HIDE_DRAWER"
        },
        customState: {
            contextPath: {
                formSchemaId: "profileEdit",
                typeId: "userProfile",
                controlID: "profileEdit",
            },
        }
    };
    transition({
        type: ACTIONS.SHOW_DRAWER,
        event: drawerData,
    });
}


const profileCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const userdetails = JSON.parse(localStorage.FV_TENANT_INFO);
        const userSelectedRole = localStorage.SELECTED_ROLE ? JSON.parse(localStorage.SELECTED_ROLE) : null;
        const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
        const getUser = await dataSource.getObject(USER.USERS, userdetails["id"]);
        let userInfo = getUser && getUser ? getUser : {};
        let sites = await getLoginUserSites();
        const siteNames = sites && sites.length > 0 ? sites.map((site) => { return site["siteName"] }).join(', ') : null;
        let cmcsites = await getLoginUserCmcSites();
        const cmcsiteNames = cmcsites && cmcsites.length > 0 ? cmcsites.map((msite) => { return msite["cmc_site_name"] }).join(', ') : null;

        let record = {
            "nameKey": getlocaleText("{{User.Name}}"),
            "nameValue": userInfo.name ? userInfo.name : "",
            "emailKey": getlocaleText("{{User.Email}}"),
            "emailValue": userInfo.email ? userInfo.email : "",
            "phoneKey": getlocaleText("{{User.phoneNumber}}"),
            "phoneValue": userInfo.phoneNumber ? userInfo.phoneNumber : "",
            "preferredLangKey": getlocaleText("{{User.preferredLang}}"),
            "preferredLangValue": userInfo.preferredLang ? getUserLang(userInfo.preferredLang) : "",
            "organizationKey": getlocaleText("{{User.Organization}}"),
            "organizationValue": userInfo.organization ? userInfo.organization : "",
            "siteKey": getlocaleText("{{User.siteName}}"),
            "siteValue": siteNames ? siteNames : "",
            "cmcsiteKey": getlocaleText("{{user.cmcsite}}"),
            "cmcsiteValue": cmcsiteNames ? cmcsiteNames : "",
            "roleKey": (userInfo.roles && userInfo.roles === Roles.SUPER_ADMIN) ? getlocaleText("{{User.Role}}") : getlocaleText("{{Common.Role}}"),
            "roleValue": userInfo.roles ? userInfo.roles : "",
            "currentRoleKey": getlocaleText("{{common.currentRole}}"),
            "currentRoleValue": userSelectedRole ? userSelectedRole.role_name : "N/A",
            "lastLoginKey": getlocaleText("{{user.lastLogin}}"),
            "lastLoginValue": userInfo.login && userInfo.login.lastLoginTime && new Date(userInfo.login.lastLoginTime) ? moment(new Date(userInfo.login.lastLoginTime)).format(DateTimeFormat) : ""
        };
        const drawerData = {
            "title": "{{common.profile}}",
            width: "30%",
            okText: getlocaleText("{{Common.Edit}}"),
            cancelText: getlocaleText("{{user.cancel}}"),
            controlSchema: {
                name: "form",
                showTitle: true,
                properties: {
                    "ui:widget": "single_card_control",
                    "fieldSchema": {
                        "typeId": "userProfile",
                        "schemaId": (userInfo.roles && userInfo.roles === Roles.SUPER_ADMIN) ? "adminUserProfile" : "userProfile",
                        "record": record,
                        controlID: "userProfile",
                    }
                },
            },
            onOk: () => {
                ProfileEdit(transition, getUser);
            },
            onCancelTransition: {
                type: "HIDE_DRAWER"
            },
            customState: {
                contextPath: {
                    typeId: "userProfile",
                    formSchemaId: "userProfile",
                    drawer: "context",
                },
            }
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("profile", {}, profileCommand);

//Change password

const ChangePasswordCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{common.changePassword}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "changedPassword",
                    "isModal": true,
                    "formSchemaId": "changedPassword",
                    "validateOnBlur": true,
                    controlID: "changedPassword"
                },
            },
            onOKTransition: {
                type: "FORM_SUBMIT_CLICK",
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "changedPassword",
                    "formSchemaId": "changedPassword",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("changePassword", {}, ChangePasswordCommand);

//switch role
const switchRoleCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        transition({
            type: "NAVIGATE_URL",
            url: "/roles",
            persistCurrentUrl: false,
            shouldReplace: true
        });
    },
};
commandManager.registerCommand("switchRole", {}, switchRoleCommand);

