import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions } from "@itsy-ui/core";
import { ACTIONS } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils"
import { getApiVersion } from "../utils/helpers";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const aboutCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        let apiVersion = await getApiVersion();
        let record = {
            "clientVersionKey": getlocaleText("{{about.clientVersion}}"),
            "clientVersionValue": "2.0.47",
            "serverVersionKey": getlocaleText("{{about.serverVersion}}"),
            "serverVersionValue": apiVersion.toString(),
            "deploymentDateKey": getlocaleText("{{about.deploymentDate}}"),
            "deploymentDateValue": "09-22-2023",
        };
        const drawerData = {
            title: "{{common.about}}",
            width: "30%",
            "showOK": false,
            "showCancel": true,
            cancelText: getlocaleText("{{common.close}}"),
            controlSchema: {
                name: "form",
                showTitle: true,
                properties: {
                    "ui:widget": "single_card_control",
                    "fieldSchema": {
                        "typeId": "about",
                        "schemaId": "about",
                        "record": record,
                        controlID: "about",
                    }
                },
            },
            onOKTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    typeId: "about",
                    formSchemaId: "about",
                    drawer: "context",
                },
            }
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    }
};

commandManager.registerCommand("about", {}, aboutCommand);