import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';

const uploadListName = {
    "title": "{{upload.title}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "upload_add",
            "displayText": "{{upload.newUpload}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": []
        },
        {
            "name": "export_xlsx",
            "displayText": "{{common.export}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "download",
            "className": "export-button",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "uploads",
    "align": "right",
    "className": "upload-button",
    "typeId": "uploads"
};

const filterschema = {
    "widgetType": "bound",
    "schema": {
        "id": "upload_filter",
        "displayName": "Upload filter",
        "propertyDefinitions": {
            "siteId": {
                "id": "siteId",
                "displayName": "{{Site.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{User.SiteName_placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_sites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "siteName"
                    ]
                },
                "datasource": "siteDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "siteName"
            },
            "studyId": {
                "id": "studyId",
                "displayName": "{{Study.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{Study.placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "filter_studies_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "studyName"
                    ]
                },
                "datasource": "studyDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "studyName"
            },
            "compoundId": {
                "id": "compoundId",
                "displayName": "{{compound.displayName}}",
                "propertyType": "dropdown",
                "placeholderText": "{{compound.namedropdown_placeHolder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_cmcsites_compound_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "compound_name"
                    ]
                },
                "datasource": "compoundDataSource",
                "isLocal": false,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "compound_name",
            },
            "lastDays": {
                "id": "lastDays",
                "displayName": "{{filter.displayLastDays}}",
                "propertyType": "dropdown",
                "placeholderText": "{{displayLastDays.placeholder}}",
                "ui:widget": "dropdown",
                "options": [
                    {
                        "key": "all",
                        "value": "{{dropdown.option_all}}"
                    },
                    {
                        "key": "7",
                        "value": "{{common.7Days}}"
                    },
                    {
                        "key": "15",
                        "value": "{{common.15Days}}"
                    },
                    {
                        "key": "30",
                        "value": "{{common.30Days}}"
                    },
                    {
                        "key": "customDate",
                        "value": "{{common.custom}}"
                    }
                ]
            },
            "customDateRange": {
                "id": "customDateRange",
                "displayName": "{{common.custom}}",
                "propertyType": "string",
                "ui:widget": "apn_date_range",
                "dateRange": true,
                "required": true
            },
        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "siteId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "studyId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "compoundId"
                            }
                        ]
                    },
                ],
            },
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "lastDays"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "customDateRange"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {

                            }
                        ]
                    },
                ],
            }
        ]
    },
    "operation": {
        "studyId": "eq",
        "lastDays": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "upload_filter",
    controlID: "upload_filter",
    formSchemaId: "upload_filter",
    "align": "left",
}

const Gridschema = {
    typeId: "visits",
    gridSchemaId: "uploads",
    dataSource: "visitDataSource",
    rowSelectionMode: 1,
    controlID: "uploads",
    primaryColumn: "id"
}

const UploadList = (props) => {
    return (
        <ItsyContainer className="upload-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={uploadListName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="upload_form" value={{ contextPath: { "id": "upload_form" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="upload_filter" value={{ contextPath: { "id": "upload_filter" } }}>
                    <ItsyFilterBar schema={filterschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="uploads" value={{ contextPath: { "id": "uploads" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="uploads-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default UploadList;
