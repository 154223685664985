import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { ItsyButton } from '@itsy-ui/common';
import { getColorForDocType } from "../utils/helpers";
import { getlocaleText } from "@itsy-ui/utils";
import { LOCALSTORAGE_NAME, UPLOAD_STATUS } from "../utils/constant"

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");


function toCamelCase(str) {
    return str.split('_').map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}

function renderUploadType(type, cellValue, commandKey) {
    const data = cellValue.uploads.find(obj => { return obj.documentType === type });
    const bodyData = data && (data.status === UPLOAD_STATUS.REJECTED || data.status === UPLOAD_STATUS.OPEN || data.status === UPLOAD_STATUS.ACCEPTED) ? data : cellValue;
    if (data) {
        const title = toCamelCase(data.status.toString());
        const className = getColorForDocType(data.status);
        return <ItsyButton
            title={`${title}`}
            className={`${className} apn-link-button`}
            commandName={data.status === UPLOAD_STATUS.REJECTED || data.status === UPLOAD_STATUS.OPEN || data.status === UPLOAD_STATUS.ACCEPTED ? "getQcData" : commandKey}
            onContext={() => { return bodyData }}
        />
    } else {
        return <ItsyButton
            title={getlocaleText("{{Document.none}}")}
            className={`apn-link-button`}
            commandName={commandKey}
            onContext={() => { return cellValue }}
        />
    }
}

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    if (col.name === "CRF") {
        const type = "CRF";
        return <div className="apn-custom-action-button">
            {renderUploadType(type, cellValue, 'crf_edit')}
        </div>
    } else if (col.name === "MRI") {
        const type = "MRI";
        return <div className="apn-custom-action-button">
            {renderUploadType(type, cellValue, 'mri_edit')}
        </div>
    } else if (col.name === "PET") {
        const type = "PET";
        return <div className="apn-custom-action-button">
            {renderUploadType(type, cellValue, 'pet_edit')}
        </div>
    } else {
        return null;
    }
}

function doUploadGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        let filterText = {};
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite) {
            filterText["siteId"] = selectedfilter_csite;
        }
        if (selectedfilter_csite && !selectedfilter_csite.includes("all")) {
            let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
            if (selectedfilter_study) {
                filterText["studyId"] = selectedfilter_study;
            }
            let selectedfilter_compound = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_COMPOUND_VALUE);
            if (selectedfilter_compound && selectedfilter_compound !== "all") {
                filterText["compoundId"] = selectedfilter_compound;
            }
            // to remove compoundid in payload when not needed
            if (filterText["compoundId"] && selectedfilter_compound && selectedfilter_compound === "all") {
                delete filterText["compoundId"];
            }
        }
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            filterText: filterText,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const uploadGrid: ICustomStateMachineData = {
    name: "uploadGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onUploadGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onUploadGridInit: (evt) => dispatch(doUploadGridInit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "uploads"
}, uploadGrid);
