import { ItsyButton, ItsyImage } from "@itsy-ui/common";
import { ICustomStateMachineData, ICustomStateMachineProvider, WidgetsFactory } from "@itsy-ui/core";
import { DataLoaderFactory, IAppSchemaProvider } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils"
import { FaCircle } from "react-icons/fa";
import { getCurrentUserRole } from "../utils/utils"
import { Roles } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    const displayName = cellValue["isActive"] === true ? getlocaleText("{{common.active}}") : getlocaleText("{{common.inactive}}")
    const class_active = cellValue["isActive"] === true ? "active-circle" : "inactive-circle"
    const getRole = getCurrentUserRole(Roles.SUPER_ADMIN)
    if (cellValue.hasOwnProperty("isActive") && col.name === "isActive") {
        return <FaCircle className={class_active} title={displayName} />
    } else if (col.name === "customAction") {
        return <div className="apn-custom-action-button">
            <ItsyButton
                title={getlocaleText("{{Common.Edit}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="edit"
                commandName="compound_edit"
                onContext={() => { return cellValue }}
            />
            {getRole && <ItsyButton
                title={getlocaleText("{{Common.Delete}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="delete"
                commandName="DeleteCompound"
                onContext={() => { return cellValue }}
            />}
        </div>
    }
}

function docompoundGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const compoundDataGrid: ICustomStateMachineData = {
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "oncompoundGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            oncompoundGridInit: (evt) => dispatch(docompoundGridInit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", { "id": "compounds" }, compoundDataGrid);
