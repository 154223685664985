import { getHeaders, getCreatedBy, getFormatedDateWithTimezoneOffset } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { DROPDOWN, TYPES, Roles } from "../utils/constant";
import { getAllCMCSiteIds, getFilterQuery } from "../utils/helpers";
import { getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class BatchDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            if (parameters.filter && (!parameters.filter.cmcSiteId || parameters.filter["cmcSiteId"] === "all")) {
                let LallCmcSiteIdsStr = await localStorage.getItem("DROPDOWN_ALL_CMCSITE_IDS");
                // console.log(LallCmcSiteIdsStr+ " ---->localstorage cmcsites");
                let cmcSiteIdsArray = [];
                // allCmcSiteIdsStr = allCmcSiteIdsStr ? allCmcSiteIdsStr : await getAllCMCSiteIds();
                let allCmcSiteIdsStr = await getAllCMCSiteIds();
                if (allCmcSiteIdsStr) {
                    cmcSiteIdsArray = allCmcSiteIdsStr.split(',');
                    cmcSiteIdsArray.splice(cmcSiteIdsArray.indexOf("all"), 1);
                }
                parameters.filter.cmcSiteId = { "inq": cmcSiteIdsArray };
            }
            await getFilterQuery(parameters, "");
            const dropdownId = typeId;
            if (dropdownId === DROPDOWN.BATCHES) {
                typeId = TYPES.BATCHES;
                let compoundId = parameters.compoundId ? parameters.compoundId : "";
                let cmcSiteId = parameters.cmcSiteId ? parameters.cmcSiteId : "";
                parameters.filter = {
                    ...parameters.filter,
                    "order": "batchId ASC",
                    "where": {
                        "compoundId": compoundId,
                        "cmcSiteId": cmcSiteId
                    },
                    fields: { id: true, batchId: true }
                }
            }
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            if (dropdownId === TYPES.BATCHES) {
                data.map((item, i) => {
                    data[i]["createdBy"] = getCreatedBy(item);
                    data[i]["cmc_site_name"] = item.cmcSiteCompound && item.cmcSiteCompound.cmcSites ? item.cmcSiteCompound.cmcSites["cmc_site_name"] : null;
                    data[i]["manufacturingDate"] = getFormatedDateWithTimezoneOffset(item.manufacturingDate);
                    data[i]["compound_name"] = item.cmcSiteCompound && item.cmcSiteCompound.compounds ? item.cmcSiteCompound.compounds["compound_name"] : null;
                    data[i]["uploadDate"] = item.uploadDate ? getFormatedDateWithTimezoneOffset(item.uploadDate) : null;
                    // item.batchesUploads && item.batchesUploads.length > 0 ? getFormatedDateWithTimezoneOffset(item.batchesUploads[0].lastModifiedDate) : null;
                });
                return data;
            }
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async batchUpsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let object = {
                ...record
            }
            return await this.update(object, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

}