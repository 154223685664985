import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory } from "@itsy-ui/core";
import { USER, ACTIONS, RegExp, Roles } from "../utils/constant";
import { getlocaleText } from "@itsy-ui/utils"
import { getValidationError } from "../utils/utils";
import { getAllRoleId } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

const getRenderRule = (propertyDefinitions, allRoleId) => {
    const schema = {};
    let flag;
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("uploadTypes")) {
        schema["uploadTypes"] = (values) => {
            if (allRoleId && values && values["roleId"] && values["roleId"].length > 0) {
                const hasqcRole = values["roleId"].find((roleid) => roleid === allRoleId[Roles.QC_USER]);
                if (hasqcRole) {
                    return false;
                }
            }
            return true;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("sites") || propertyDefinitions.hasOwnProperty("cmcSites")) {
        schema["sites"] = (values) => {
            if (allRoleId && values && values["roleId"] && values["roleId"].length > 0) {
                flag = true;
                const hasRole_upload = values["roleId"].find((roleid) => roleid === allRoleId[Roles.UPLOAD_USER]);
                if (hasRole_upload) {
                    flag = false
                }
                return flag;
            }
            return true;
        };
        schema["cmcSites"] = (values) => {
            if (allRoleId && values && values["roleId"] && values["roleId"].length > 0) {
                flag = true;
                const hasRole_cmcUpload = values["roleId"].find((roleid) => roleid === allRoleId[Roles.CMC_UPLOAD_USER]);
                if (hasRole_cmcUpload) {
                    flag = false
                }
                return flag;

            }
            return true;
        };
    }

    return schema;
};


function doUserFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues, metadata } = getState();
        let updatedValues = { ...value };
        if (value !== undefined && value["roleId"] !== undefined) {
            const allRoleId = await getAllRoleId();
            const hasRole_upload = value["roleId"].find((roleid) => roleid === allRoleId[Roles.UPLOAD_USER]);
            const hasRole_cmcUpload = value["roleId"].find((roleid) => roleid === allRoleId[Roles.CMC_UPLOAD_USER]);
            const hasqcRole = value["roleId"].find((roleid) => roleid === allRoleId[Roles.QC_USER]);
            updatedValues = {
                ...value
            };
            // if no upload user the remove sites
            if (!hasRole_upload) {
                updatedValues["sites"] = [];
            }
            // if no cmc user then remove cmc site
            if (!hasRole_cmcUpload) {
                updatedValues["cmcSites"] = [];
            }
            // if no qcuser then remove uploadtypes
            if (!hasqcRole) {
                updatedValues["uploadTypes"] = [];
            }
        }
        let currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            mode: 0
        });
    }
}

function doCreateFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const allRoleId = await getAllRoleId();
        const userValidationSchema = {
            ...validationSchema,
            "email": Yup.string().matches(RegExp.EMAIL, { message: getlocaleText("{{emailId.errorMessage}}") }).required(getlocaleText("{{emailId.required}}")),
            "organization": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(100, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{organization.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "roleId": Yup.string().required(getlocaleText("{{common.RoleIdInvalidError}}")),
            "preferredLang": Yup.string().required(getlocaleText("{{common.PreferredLangValidError}}")),
            "uploadTypes": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.QC_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.uploadTypesInvalidError}}")),
                otherwise: Yup.array()
            }),
            "sites": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.UPLOAD_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.SiteIdInvalidError}}")),
                otherwise: Yup.array()
            }),
            "cmcSites": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.CMC_UPLOAD_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.CMCSiteIdRequiredError}}")),
                otherwise: Yup.array()
            }),
        };
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: userValidationSchema,
            objectData: objectData,
            typeId: typeId,
            displayRuleSchema: getRenderRule(formSchema.propertyDefinitions, allRoleId),
        });
    }
}

function doUserCreateFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource = dataLoader.getLoader<IDataSourceLake>("datasource");
            if (event.values["roleId"] !== undefined && event.values["preferredLang"] !== undefined) {
                const bodyData = {
                    hostUrl: window.location.origin,
                    ...event.values,
                }
                const data = await datasource.create(bodyData, { id: USER.ADDNEW });  //id:url pathaname
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.UserCreated}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            } else {
                transition({
                    strict: true,
                    type: "FORM_ERROR",
                    errorMessage: getValidationError(event),
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const UsercreateFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onCreateFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onUserFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onUserCreateFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCreateFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doCreateFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onUserCreateFormSubmit: (evt) => dispatch(doUserCreateFormSubmit(evt)),
            onUserFormHandleChange: ({ value, formValues }) => dispatch(doUserFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "users",
    "formSchemaId": "user_create_form",
}, UsercreateFormOnSubmit);


function doEditFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const allRoleId = await getAllRoleId();
        const userEditValidationSchema = {
            ...validationSchema,
            "phoneNumber": Yup.string().matches(RegExp.PHONENUMBER, { message: getlocaleText("{{userPhoneNumber.errormessage}}") }).required(getlocaleText("{{userphonNumber.Required}}")),
            "name": Yup.string().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{name.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "organization": Yup.string().min(2, getlocaleText("{{common.minCharError}}")).max(100, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{name.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "roleId": Yup.string().required(getlocaleText("{{common.RoleIdInvalidError}}")),
            "uploadTypes": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.QC_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.uploadTypesInvalidError}}")),
                otherwise: Yup.array()
            }),
            "sites": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.UPLOAD_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.SiteIdInvalidError}}")),
                otherwise: Yup.array()
            }),
            "cmcSites": Yup.array().when("roleId", {
                is: selected_roleIds => {
                    return (selected_roleIds.length !== 0 && selected_roleIds.includes(allRoleId[Roles.CMC_UPLOAD_USER]))
                },
                then: Yup.array().required(getlocaleText("{{common.CMCSiteIdRequiredError}}")),
                otherwise: Yup.array()
            }),
        };
        if (objectData["phoneNumber"] === null) {
            objectData["phoneNumber"] = ""
        } if (objectData["name"] === null) {
            objectData["name"] = ""
        } if (objectData["sites"] === null) {
            objectData["sites"] = ""
        } if (objectData["cmcSites"] === null) {
            objectData["cmcSites"] = ""
        }
        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: userEditValidationSchema,
            objectData: objectData,
            typeId: typeId,
            displayRuleSchema: getRenderRule(formSchema.propertyDefinitions, allRoleId),
        });
    }
}


function doUserEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource = dataLoader.getLoader<IDataSourceLake>("datasource");
            const data = await datasource.update(event.values, { id: USER.USERS });  //id:url pathaname
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.UserEdited}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const UserEditFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onEditFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onUserEditFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onUserEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onEditFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doEditFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onUserEditFormSubmit: (evt) => dispatch(doUserEditFormSubmit(evt)),
            onUserEditFormHandleChange: ({ value, formValues }) => dispatch(doUserFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "users",
    "formSchemaId": "user_edit_form",
}, UserEditFormOnSubmit);