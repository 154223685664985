import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';
import { useState } from 'react';
import { useEffect } from "react";
import { getCurrentUserRole, getRoleToolbarItem } from "../utils/utils"
import { Roles } from "../utils/constant";

const UserPageName = {
    "title": "{{subject.tittle}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
};

const toolbarSchema = {
    data: [
        {
            "name": "subject_added",
            "displayText": "{{btnDisplayname.newSubject}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": ["Upload User"]
        },
        {
            "name": "batchSubject_create",
            "displayText": "{{btnDisplayname.batchSubjectCreate}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "cloud_upload",
            "roles": ["Upload User"]
        },
        {
            "name": "subjectexport_xlsx",
            "displayText": "{{common.export}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "download",
            "className": "export-button",
            "roles": ["Upload User"]
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "subjects",
    "align": "right",
    "className": "subject-button",
    "typeId": "subjects"
};


const filterSchema = {
    "widgetType": "bound",
    "schema": {
        "id": "subject_filter",
        "displayName": "subject filter",
        "propertyDefinitions": {
            "siteId": {
                "id": "siteId",
                "displayName": "{{Site.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{User.SiteName_placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_sites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "siteName"
                    ]
                },
                "datasource": "siteDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "siteName"
            },
            "studyId": {
                "id": "studyId",
                "displayName": "{{Study.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{Study.placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "filter_studies_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "studyName"
                    ]
                },
                "datasource": "studyDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "studyName"
            },
            "classificationId": {
                "id": "classificationId",
                "displayName": "{{Classification.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{subject.classification_placeHolder}}",
                "ui:widget": "itsy:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_classifications_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "name"
                    ]
                },
                "datasource": "subjectDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "name"
            },
            "sharingConsent": {
                "id": "sharingConsent",
                "displayName": "{{analyst.sharingConsent}}",
                "propertyType": "dropdown",
                "placeholderText": "{{search.sharingConsent}}",
                "ui:widget": "dropdown",
                "options": [
                    {
                        "key": "all",
                        "value": "{{dropdown.option_all}}"
                    },
                    {
                        "key": true,
                        "value": "{{common.allowed}}"
                    },
                    {
                        "key": false,
                        "value": "{{common.notAllowed}}"
                    },
                ]
            }

        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "siteId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "studyId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "classificationId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "sharingConsent"
                            }
                        ]
                    },
                ],
            }
        ]
    },
    "operation": {
        "siteId": "eq",
        "studyId": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "subject_filter",
    formSchemaId: "subject_filter",
    controlID: "subject_filter",
    "align": "left",
};

const Gridschema = {
    typeId: "subjects",
    gridSchemaId: "subjects",
    dataSource: "subjectDataSource",
    rowSelectionMode: 1,
    controlID: "subjects"
};

const SubjectPage = (props) => {
    const toolData = toolbarSchema.data;
    const [toolbarItem, toolbar] = useState();
    useEffect(() => {
        let currentToolbar: any = getRoleToolbarItem(toolData);
        if (currentToolbar.length > 0) {
            currentToolbar = {
                "data": [
                    ...currentToolbar
                ],
                "widgetType": "bound",
                "reflowPageId": "subjects",
                "align": "right",
                "className": "subject-button",
                "typeId": "subjects"
            };
        }
        toolbar(currentToolbar);
    }, []);

    const [filterSchemaItem, filterBar] = useState();
    useEffect(() => {
        let currentFilterSchema: any = filterSchema;
        if (getCurrentUserRole(Roles.SUPER_ADMIN) || getCurrentUserRole(Roles.ANALYST)) {
            currentFilterSchema.schema.propertyDefinitions["siteId"].metadata.typeId = "all_sites_dropdown"
        }
        filterBar(currentFilterSchema);
    }, []);

    const [subjectGridSchema, subjectGrid] = useState();
    useEffect(() => {
        let currentSubjectGridSchema: any = Gridschema;
        if (getCurrentUserRole(Roles.ANALYST)) {
            currentSubjectGridSchema.gridSchemaId = "viewSubjects";
        }
        subjectGrid(currentSubjectGridSchema);
    }, []);
    const [myVariable, setMyVariable] = useState(JSON.parse(localStorage.getItem('myVariable')));
    useEffect(()=>{ },[myVariable])

    return (
        <ItsyContainer className="subject-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={UserPageName} />
                </ItsyColumn>
                {toolbarItem && <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="subjects" value={{ contextPath: { "id": "subject_create" } }}>
                        <ItsyToolbar schema={toolbarItem} />
                    </StateManagerContext.Provider>
                </ItsyColumn>}
            </ItsyRow>
            {filterSchemaItem && <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="subject_filter" value={{ contextPath: { "id": "subject_filter" } }}>
                    <ItsyFilterBar schema={filterSchemaItem} />
                </StateManagerContext.Provider>
            </ItsyRow>}
            {subjectGridSchema && <ItsyRow>
                <StateManagerContext.Provider key="subjects" value={{ contextPath: { "id": "subjects" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="subjects-list-table" schema={subjectGridSchema} />
                </StateManagerContext.Provider>
            </ItsyRow>}
        </ItsyContainer>
    )
}

export default SubjectPage;