import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';
import { useState } from 'react';
import { useEffect } from "react";
import { getCurrentUserRole, getRoleToolbarItem } from "../utils/utils"
import { Roles } from "../utils/constant";

const BatchPageTitle = {
    "title": "{{batchPage.tittle}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
};

const toolbarSchema = {
    data: [
        {
            "name": "batch_added",
            "displayText": "{{batch.newBatch}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": ["CMC Upload User"]
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "batches",
    "align": "right",
    "className": "batches-button",
    "typeId": "batches"
};


const filterSchema = {
    "widgetType": "bound",
    "schema": {
        "id": "batch_filter",
        "propertyDefinitions": {
            "cmcSiteId": {
                "id": "cmcSiteId",
                "displayName": "{{cmcsite.selectTitle}}",
                "propertyType": "dropdown",
                "placeholderText": "{{cmcsite.SiteName_placeholder}}",
                "ui:widget": "itsy:dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "filter_cmcsites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "cmc_site_name"
                    ]
                },
                "datasource": "cmcsiteDataSource",
                "isLocal": false,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "cmc_site_name"
            },
            "lastDays": {
                "id": "lastDays",
                "displayName": "{{filter.displayLastDays}}",
                "propertyType": "dropdown",
                "placeholderText": "{{displayLastDays.placeholder}}",
                "ui:widget": "dropdown",
                "options": [
                    {
                        "key": "all",
                        "value": "{{dropdown.option_all}}"
                    },
                    {
                        "key": "7",
                        "value": "{{common.7Days}}"
                    },
                    {
                        "key": "15",
                        "value": "{{common.15Days}}"
                    },
                    {
                        "key": "30",
                        "value": "{{common.30Days}}"
                    }
                ]
            }

        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "cmcSiteId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "lastDays"
                            }
                        ]
                    },
                ],
            }
        ]
    },
    "operation": {
        "cmcSiteId": "eq",
        "lastDays": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "batch_filter",
    formSchemaId: "batch_filter",
    controlID: "batch_filter",
    "align": "left",
};

const Gridschema = {
    typeId: "batches",
    gridSchemaId: "batches",
    dataSource: "batchDataSource",
    rowSelectionMode: 1,
    controlID: "batches",
    primaryColumn: "id"
};

const BatchesPage = (props) => {
    return (
        <ItsyContainer className="batches-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={BatchPageTitle} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="batch_create" value={{ contextPath: { "id": "batch_create" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="batch_filter" value={{ contextPath: { "id": "batch_filter" } }}>
                    <ItsyFilterBar schema={filterSchema} />
                </StateManagerContext.Provider>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="batches" value={{ contextPath: { "id": "batches" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="batches-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default BatchesPage;