import { getHeaders, getCreatedBy, getFormatedVisitDate } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { DROPDOWN, TYPES, Roles } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils";
import { getCurrentUserRole, getServerUrl } from "../utils/utils"
import { getFilterQuery } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class StudyDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        let data = [];
        await this.initializeConfig();
        let siteId;
        eventTransition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "study_filter",
            onData: (formData) => {
                siteId = formData["formValues"]["siteId"];
            },
        });
        await getFilterQuery(parameters, siteId);
        const dropdownId = typeId;
        if (dropdownId === DROPDOWN.STUDIES || dropdownId === DROPDOWN.FILTER_STUDIES_DROPDOWN) {
            let selectedSiteId;
            if (parameters && parameters.siteId && parameters.siteId.length > 0 && parameters.siteId != "all") {
                selectedSiteId = parameters.siteId;
            } else if (getCurrentUserRole(Roles.UPLOAD_USER)) {
                const userdetails = JSON.parse(localStorage.FV_TENANT_INFO);
                selectedSiteId = userdetails["siteId"];
            }

            // Setting all option here
            if (dropdownId === DROPDOWN.FILTER_STUDIES_DROPDOWN) {
                const allOption = {
                    "studyName": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }
                data.unshift(allOption);
            }

            if (selectedSiteId === undefined) {
                return data;
            }

            parameters.filter = {
                ...parameters.filter,
                "order": "studyName ASC",
                "where": {
                    "siteIds": {
                        "inq": [selectedSiteId]
                    },
                    "isActive": true
                },
                fields: { id: true, studyName: true }
            }
        }
        try {
            const resData = await this.lbDatasource.getAll(TYPES.STUDY, { ...parameters }, this.dataURL);
            data.push(...resData);
            if (typeId === TYPES.STUDY && dropdownId === TYPES.STUDY) {
                data.map((item, i) => {
                    const hasSite = item.sites && item.sites.length > 0 ? true : false;
                    data[i]["siteId"] = hasSite ? item.sites[0]["id"] : null;
                    data[i]["siteName"] = hasSite ? item.sites[0]["siteName"] : null;
                    data[i]["createdBy"] = getCreatedBy(item);
                    data[i]["approvalDate"] = item["approvalDate"] !== null && item["approvalDate"] !== undefined ? getFormatedVisitDate(item["approvalDate"]) : null;
                    data[i]["completionDate"] = item["completionDate"] !== null && item["completionDate"] !== undefined ? getFormatedVisitDate(item["completionDate"]) : null;
                    data[i]["protocolName"] = item["imageProtocolRelation"] && item["imageProtocolRelation"].length > 0 ? item["imageProtocolRelation"][0]["protocolName"] : null;
                    data[i]["imageProtocolComments"] = item["imageProtocolRelation"] && item["imageProtocolRelation"].length > 0 ? item["imageProtocolRelation"][0]["protocolComment"] : null;
                });
            }
            data["totalRecordsCount"] = resData["totalRecordsCount"];
            return data;
        } catch (e) {
            throw new Error(`Failed to get objects, Reason: ${e.message} `);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message} `);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message} `);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            if (typeId === TYPES.STUDY) {
                const hasSite = data.sites && data.sites.length > 0 ? true : false;
                return { ...data, ...hasSite && { siteName: data.sites[0]["siteName"], siteId: data.sites[0]["id"] } };
            }
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message} `);
        }
    }

    async studyUpsert(record: any, formSchema: any): Promise<any> {
        let imageProtocolUploadValues = {};
        if (record["imageProtocolUpload"] && record["imageProtocolUpload"].length > 0) {
            let imageProtocolUploadFile = record["imageProtocolUpload"][0];
            imageProtocolUploadValues = {
                contentStreamFileName: imageProtocolUploadFile.name,
                contentSteamLength: imageProtocolUploadFile.size + "",
                contentStreamMimeType: imageProtocolUploadFile.type,
            }
        }
        let object = {
            "studyName": record["studyName"],
            "principalInvestigator": record["principalInvestigator"],
            "siteId": record["siteId"],
            "isActive": record["isActive"],
            "approvalDate": record["approvalDate"] !== null && record["approvalDate"] !== undefined && record["approvalDate"] !== "" ? record["approvalDate"] : null,
            "completionDate": record["completionDate"] !== null && record["completionDate"] !== undefined && record["completionDate"] !== "" ? record["completionDate"] : null,
            "protocol": {
                "protocolName": record["protocolName"] ? record["protocolName"] : "",
                "protocolComment": record["imageProtocolComments"] !== undefined && record["imageProtocolComments"] ? record["imageProtocolComments"] : "",
                ...imageProtocolUploadValues,
            }
        }
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let editObject = {
                "id": record["id"],
                ...object
            }
            return await this.update(editObject, formSchema);
        } else {
            return await this.create(object, formSchema);
        }
    }
}