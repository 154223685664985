import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { ItsyButton } from '@itsy-ui/common';
import { getlocaleText } from "@itsy-ui/utils";
import { LOCALSTORAGE_NAME } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    if (col.name === "coaUpload" || col.name === "execBatchRecUpload" ||
        col.name === "analyticRawDataUpload") {
        const batchUpladValue = cellValue["batchesUploads"] ?
            cellValue["batchesUploads"].filter(item => { return item.documentType === col.name }) : null;
        if (batchUpladValue && batchUpladValue.length > 0) {

            return <>
                {batchUpladValue.map((batchUpload) => {
                    const title = batchUpload.contentStreamFileName;
                    return <ItsyButton
                        title={title}
                        className="display-block link-primary apn-link-button"
                        commandName="batch_dowload_file"
                        onContext={() => { return batchUpload }}
                    />
                })}

                {col.name === "analyticRawDataUpload" && <ItsyButton
                    title={getlocaleText("{{Document.addMore}}")}
                    className={`apn-link-button display-block addMoreBtn`}
                    commandName={"addMoreBatchUpload"}
                    onContext={() => { return { ...cellValue, editDocumentFor: col.name } }}
                />}
            </>
        } else {
            return <ItsyButton
                title={getlocaleText("{{Document.none}}")}
                className={`apn-link-button display-block`}
                commandName={"editBatchUploaded"}
                onContext={() => { return { ...cellValue, editDocumentFor: col.name } }}
            />
        }
    }
}

function doBatchGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        let filterText = {};
        let selectedfilter_msite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_MSITE_VALUE);
        if (selectedfilter_msite) {
            filterText["cmcSiteId"] = selectedfilter_msite;
        }

        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            filterText: filterText,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const batchGrid: ICustomStateMachineData = {
    name: "batchGrid",
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onBatchGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onBatchGridInit: (evt) => dispatch(doBatchGridInit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", {
    id: "batches"
}, batchGrid);