import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow } from "@itsy-ui/layout";
import { ItsyGrid, ItsyFilterBar } from "@itsy-ui/data";

const filterschema = {
    "widgetType": "bound",
    "schema": {
        "id": "uploadSummary_filters",
        "displayName": "qcDashboard Filters",
        "propertyDefinitions": {
            "siteId": {
                "id": "siteId",
                "displayName": "{{Site.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{User.SiteName_placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_sites_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "siteName"
                    ]
                },
                "datasource": "siteDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "siteName"
            },
            "studyId": {
                "id": "studyId",
                "displayName": "{{Study.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{Study.placeholder}}",
                "ui:widget": "apn:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "filter_studies_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "studyName"
                    ]
                },
                "datasource": "studyDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "studyName"
            },
            "classificationId": {
                "id": "classificationId",
                "displayName": "{{Classification.Dropdown}}",
                "propertyType": "dropdown",
                "placeholderText": "{{subject.classification_placeHolder}}",
                "ui:widget": "itsy:dropdown",
                "controlName": "dropdown",
                "metadata": {
                    "isMulti": false,
                    "isLocal": false,
                    "typeId": "all_classifications_dropdown",
                    "valueKey": "id",
                    "displayKey": [
                        "name"
                    ]
                },
                "datasource": "subjectDataSource",
                "isLocal": true,
                "boundedTypes": "id",
                "valueKey": "id",
                "displayKey": "name"
            },
        },
        "sections": [
            {
                "columns": [
                    {
                        "cell": [
                            {
                                "widget": "siteId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "studyId"
                            }
                        ]
                    },
                    {
                        "cell": [
                            {
                                "widget": "classificationId"
                            }
                        ]
                    },
                ],
            }
        ]
    },
    "operation": {
        "studyId": "eq"
    },
    "applyFilterOnChange": true,
    "isExpanded": true,
    schemaId: "uploadSummary_filters",
    formSchemaId: "uploadSummary_filters",
    controlID: "uploadSummary_filters",
    "align": "left",
}

const Gridschema = {
    typeId: "visits",
    gridSchemaId: "uploadSummary",
    dataSource: "visitDataSource",
    rowSelectionMode: 1,
    controlID: "uploadSummary",
    primaryColumn: "id"
}

const Upload_Summary = (props) => {
    return (
        <ItsyContainer className="user-management-page">
            <ItsyRow className="apn-filter-row">
                <StateManagerContext.Provider key="uploadSummary_filters" value={{ contextPath: { "id": "uploadSummary_filters" } }}>
                    <ItsyFilterBar schema={filterschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
            { }
            <ItsyRow>
                <StateManagerContext.Provider key="uploadSummary" value={{ contextPath: { "id": "uploadSummary" } }}>
                    <ItsyGrid style={{ "width": "100%" }} schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default Upload_Summary;
