import axios from "axios";
import { getHeaders, getCreatedBy, getFormatedDateTimeWithTimezoneOffset, getFormatedDateOfBirth, getFormatedDate, getLoginUserRoleName } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { TYPES, DROPDOWN, Roles } from "../utils/constant";
import { getFilterQuery } from "../utils/helpers";
import { getlocaleText } from '@itsy-ui/utils'
import { getCurrentUserRole, getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class SubjectDataSource {
    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        let dropdownId, siteId = "";
        await this.initializeConfig();
        eventTransition({
            type: "FORM_GET_STATE",
            strict: true,
            controlID: "subject_filter",
            onData: (formData) => {
                siteId = formData["formValues"]["siteId"];
            },
        });
        await getFilterQuery(parameters, siteId);
        if (typeId === DROPDOWN.ALL_ClASSIFICATIONS) {
            typeId = TYPES.CLASSIFICATION;
            dropdownId = DROPDOWN.ALL_ClASSIFICATIONS
        }
        if (typeId === DROPDOWN.SUBJECT) {
            const studyId = parameters.studyId;
            typeId = `${TYPES.SUBJECT}?studyId=${studyId}`;
            parameters.filter = {
                ...parameters.filter,
                "order": "subjectId ASC",
                "where": {
                    "studyId": studyId
                }
            }
        }
        try {
            const data = await this.lbDatasource.getAll(typeId, { ...parameters }, this.dataURL);
            if (typeId === TYPES.SUBJECT) {
                const getItem = data.map((item, i) => {
                    const getClassification = item.visit && item.visit[0].classification.name ? item.visit[0].classification.name : false
                    return {
                        ...item, ...getClassification && { name: getClassification },
                        ...item.createdDate && { createdDate: getFormatedDateTimeWithTimezoneOffset(item.createdDate) },
                        ...item.lastModifiedDate && { lastModifiedDate: getFormatedDateTimeWithTimezoneOffset(item.lastModifiedDate) },
                        ...item.birthDate && { birthDate: getFormatedDateOfBirth(item.birthDate) },
                        ...item.createdBy && { createdBy: getCreatedBy(item) },
                        ...item.createdDate && { createdDate: getFormatedDate(item.createdDate) },
                        ...item.study && { studyName: item.study.studyName, siteName: item.study.sites[0]['siteName'] },
                        "sharingConsent": item.sharingConsent ? getlocaleText("{{common.allowed}}") : getlocaleText("{{common.notAllowed}}"),
                        studyNameTooltip: item.study && item.study.principalInvestigator ? `PI : ${item.study.principalInvestigator}` : null
                    }
                })
                getItem["totalRecordsCount"] = data["totalRecordsCount"];
                return getItem;
            }
            if (dropdownId === DROPDOWN.ALL_ClASSIFICATIONS) {
                const extraOption = [{
                    "name": getlocaleText("{{dropdown.option_all}}"),
                    "id": "all",
                }]
                const item = extraOption.concat(data);
                return item;
            }
            return data
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async subjectUpsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            let object = {
                "id": record["id"],
                "studyId": record["studyId"],
                "subjectId": record["subjectId"],
                "gender": record["gender"],
                "birthDate": record["birthDate"],
                "isActive": record["isActive"],
                "sharingConsent": record["sharingConsent"]
            }
            return await this.update(object, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }

    async subjectUpdateInUpload(record: any, formSchema: any): Promise<any> {
        try {
            const data = await this.getObject(TYPES.SUBJECT, record["subjectId"]);
            const updatedValues = {
                ...data,
                "weight": record["weight"]
            }
            await this.update(updatedValues, formSchema);
            return true;
        } catch (e) {
            throw new Error(`Failed to update subject with ID:, Reason: ${e.message}`);
        }
    }

    async uploadFile(data: {}, metadata: any): Promise<any> {
        try {
            var formData = new FormData();
            formData.append("file", data["file"]);
            formData.append("siteId", data["siteId"]);
            formData.append("studyId", data["studyId"]);
            await this.initializeConfig();
            const headers = this.headers;
            try {
                const resultData = await axios.post(`${this.dataURL}/${metadata.id}/batch`, formData, {
                    headers: {
                        Connection: "keep-alive",
                        "Content-Type": `multipart/form-data; boundary=${formData["_boundary"]}`,
                        ...headers
                    }
                });
                if (resultData && (resultData.status === 204 || resultData.status === 200)) {
                    if (resultData.data && resultData.data.failed && resultData.data.failed.length > 0) {
                        console.log(`batch of subjects creation failed with reason - ${JSON.stringify(resultData.data.failed)}`);
                    }
                    else {
                        console.log(`batch of subjects created successfully with siteId : ${data["siteId"]} and studyId : ${data["studyId"]}`);
                    }
                    return resultData.data;
                }
            } catch (e) {
                throw new Error(e.response.data.error.message);
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    async downlodSubjectBatchTemplate(): Promise<any> {
        try {
            const templateUrl = "/templates/SubjectsBatch_Template.xlsx";
            await this.lbDatasource.downlodFile(templateUrl);
        } catch (e) {
            let reason = e.response.data.error.cause ? e.response.data.error.cause : e.response.data.error.message;
            throw new Error(`Failed to download the subject batch template, Reason: ${reason}`);
        }
    }

    async getSignedURL(parameters: any, defaultVisibleColumnsParams): Promise<any> {
        await this.initializeConfig();
        try {
            let siteId = parameters?.filterParams?.siteId;
            if (parameters?.filterParams?.siteId === "all") {
                delete parameters.filterParams.siteId
            }
            if (parameters?.filterParams?.studyId === "all") {
                delete parameters.filterParams.studyId
            }
            if (parameters?.filterParams?.classificationId === "all") {
                delete parameters.filterParams.classificationId
            }
            if (parameters?.filterParams?.sharingConsent === "all") {
                delete parameters.filterParams.sharingConsent
            }
            let params = { "filter": parameters.filterParams };
            await getFilterQuery(params, siteId);
            let requestBody = {
                "selectedColumns": []
            };
            const selectedColumnsStr = localStorage.getItem(`selectedColumsToShow_subjects`);
            const selectedColumsToShowArry = selectedColumnsStr ? selectedColumnsStr.split(",") : selectedColumnsStr === null || selectedColumnsStr === undefined ? defaultVisibleColumnsParams : [];
            selectedColumsToShowArry.map(item => {
                switch (item) {
                    case "name": requestBody.selectedColumns.push("classification"); break;
                    case "studyName": requestBody.selectedColumns.push("study.studyName");
                        requestBody.selectedColumns.push("study.principalInvestigator");
                        break;
                    case "createdBy": requestBody.selectedColumns.push("createdUser.name"); break;
                    case "subjectId":
                    case "gender":
                    case "birthDate":
                    case "siteName":
                    case "sharingConsent":
                    case "createdDate":
                    case "isActive":
                        requestBody.selectedColumns.push(item);
                        break;
                    default: break;
                }
            });
            let data = await this.lbDatasource.getAll_export(`${TYPES.SUBJECT}/export?userRole=${getLoginUserRoleName()}`, { ...params, "orderBy": parameters.filterParams.orderBy, requestBody }, this.dataURL);
            return data;
        } catch (e) {
            throw new Error(`Failed to download the file, Reason: ${e.message}`);
        }
    }

    async downlodFile(url: string): Promise<any> {
        try {
            await this.lbDatasource.downlodFile(url);
        } catch (e) {
            throw new Error(`Failed to download the document`);
        }
    }
}