import { WidgetsFactory, CommandOptions, DataLoaderFactory, ICommandManager, IDataSourceLake } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { ACTIONS, TYPES } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");

const studyAddCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{study.addprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "studies",
                    "formSchemaId": "study_create_form",
                    "isModal": true,
                    controlID: "study_create_form"
                },
            },
            onOk: () => {
                transition({
                    type: "FORM_SUBMIT_CLICK",
                    controlID: "study_create_form",
                    strict: true,
                });
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "studies",
                    "formSchemaId": "study_create_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("study_add", {}, studyAddCommand);


//STUDY EDIT COMMANDS

const studyEditCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const drawerData = {
            "title": "{{study.editprofileTittle}}",
            "okText": getlocaleText("{{common.Submit}}"),
            "cancelText": getlocaleText("{{user.cancel}}"),
            width: "30%",
            controlSchema: {
                name: "form",
                properties: {
                    "ui:widget": "form",
                    "typeId": "studies",
                    "isModal": true,
                    "formSchemaId": "study_edit_form",
                    "record": data,
                    "validateOnBlur": true,
                    controlID: "study_edit_form"
                },
            },
            onOk: () => {
                transition({
                    type: "FORM_SUBMIT_CLICK",
                    controlID: "study_edit_form",
                    strict: true,
                });
            },
            onCancelTransition: {
                type: ACTIONS.HIDE_DRAWER,
            },
            customState: {
                contextPath: {
                    "typeId": "studies",
                    "formSchemaId": "study_edit_form",
                },
            },
        };

        transition({
            type: ACTIONS.SHOW_DRAWER,
            event: drawerData,
        });
    },
};
commandManager.registerCommand("study_edit", {}, studyEditCommand);


//STUDY DELETE COMMEND
async function deleteStudy(data, transition) {
    const datasource = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
    data["objectTypeId"] = TYPES.STUDY;
    try {
        const deleteData = await datasource.delete(data);
        if (deleteData) {
            transition({
                type: ACTIONS.GRID_REFRESH
            })
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{study.deleteSuccessMsg}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
        }
    } catch (e) {
        const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
        let errorMessage = response ? response.message || response.errorMessage : null;
        if (!errorMessage)
            errorMessage = "Error in network call";
        transition({
            strict: true,
            type: "FORM_ERROR",
            errorMessage: errorMessage,
        });
    }
}

const studyDeleteCommand: CommandOptions<any> = {
    canExecute: (data) => {
        return true;
    },
    execute: async (data, transition) => {
        const popupData = {
            title: getlocaleText("{{study.deleteTitle}}"),
            popupMessage: getlocaleText("{{study.deleteMessage}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                deleteStudy(data, transition)
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
            onCancel: {
                type: ACTIONS.HIDE_POPUP
            },
        };
        transition({
            type: ACTIONS.SHOW_POPUP,
            event: popupData,
        });
    },
};
commandManager.registerCommand("study_delete", {}, studyDeleteCommand);

//download file command
const downloadFile: CommandOptions<any> = {
    canExecute: (imageProtocolUploadData: any) => {
        return true;
    },
    execute: async (imageProtocolUploadData: any, transition: any) => {
        const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
        try {
            if (!imageProtocolUploadData.id) {
                let studyformData;
                transition({
                    type: "FORM_GET_STATE",
                    strict: true,
                    controlID: "study_edit_form",
                    onData: (formData) => {
                        studyformData = formData["formValues"];
                    },
                });
                imageProtocolUploadData = studyformData && studyformData["imageProtocolRelation"] && studyformData["imageProtocolRelation"].length > 0 ? studyformData["imageProtocolRelation"][0] : null;
            }

            const data = await datasource.getSignedURL({ id: imageProtocolUploadData.id }, { id: TYPES.IMAGE_PROTOCOL_UPLOADS });
            if (data && data["signedUrl"]) {
                await datasource.downlodFile(data["signedUrl"]);
            } else {
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": data["message"], "type": " alert-danger drawer-z-index" },
                    metadata: { "delay": 5 * 1000 },
                    action: { "showCloseIcon": false },
                });
            }
        } catch (e) {
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response.errorMessage : null;
            if (!errorMessage) {
                errorMessage = "Error in network call";
            }
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": errorMessage, "type": " alert-danger drawer-z-index" },
                metadata: { "delay": 5 * 1000 },
                action: { "showCloseIcon": false },
            });
        }
    },
};

commandManager.registerCommand("image_protocol_dowload_file", {}, downloadFile);