import { ItsyButton, ItsyImage } from "@itsy-ui/common";
import { ICustomStateMachineData, ICustomStateMachineProvider, WidgetsFactory } from "@itsy-ui/core";
import { DataLoaderFactory, IAppSchemaProvider } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils"
import { FaCircle } from "react-icons/fa";
import { getCurrentUserRole } from "../utils/utils"
import { LOCALSTORAGE_NAME, Roles } from "../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

export function renderCustomCell(col: any, cellValue: any, idx: any) {
    const displayName = cellValue["isActive"] === true ? getlocaleText("{{common.active}}") : getlocaleText("{{common.inactive}}")
    const class_active = cellValue["isActive"] === true ? "active-circle" : "inactive-circle"
    const getRole = getCurrentUserRole(Roles.SUPER_ADMIN)
    if (cellValue.hasOwnProperty("isActive") && col.name === "isActive") {
        return <FaCircle className={class_active} title={displayName} />
    } else if (col.name === "customAction") {
        return <div className="apn-custom-action-button">
            <ItsyButton
                title={getlocaleText("{{Common.Edit}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="edit"
                commandName="study_edit"
                onContext={() => { return cellValue }}
            />
            {getRole && <ItsyButton
                title={getlocaleText("{{Common.Delete}}")}
                alignText="left"
                className="apnuser-edit-button"
                iconPosition="startIcon"
                iconName="delete"
                commandName="study_delete"
                onContext={() => { return cellValue }}
            />}
            {/* {cellValue.hasOwnProperty("isActive") && <ItsyButton
                title={isActive ? getlocaleText("{{User.deActivate}}") : getlocaleText("{{User.Reactive}}")}
                className={isActive ? "deactive-btn-success" : "active-btn-danger"}
                commandName={isActive ? "Delete_User" : "re_active_user"}
                alignText="right"
                onContext={() => { return cellValue }}
            />} */}
        </div>
    }
    else if (col.name === "imageProtocolFile") {
        const imageProtocolFile = cellValue["imageProtocolRelation"] && cellValue["imageProtocolRelation"].length > 0 && cellValue["imageProtocolRelation"][0]["contentStreamFileName"] ? cellValue["imageProtocolRelation"][0] : null;
        if (imageProtocolFile) {
            const title = imageProtocolFile.contentStreamFileName;
            return <ItsyButton
                title={`${title}`}
                className="link-primary apn-link-button"
                commandName="image_protocol_dowload_file"
                onContext={() => { return imageProtocolFile }}
            />
        } else {
            return <span>{getlocaleText("{{common.none}}")}</span>
        }
    }
}

function doProjectsGridInit(data: any) {
    return async (getState, dispatch, transition) => {
        let filterText;
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        if (selectedfilter_csite) {
            filterText = { "siteId": selectedfilter_csite };
        }
        transition({
            type: "GRID_LOAD",
            typeId: data.typeId,
            gridSchemaId: data.gridSchemaId,
            filterText: filterText,
            parentObjectId: data.parentObjectId,
            relationshipName: data.relationshipName,
            customDatasource: data.customDatasource,
            rowSelectionMode: 1,
            renderCustomCell: renderCustomCell,
        });
    };
}

const masterDataGrid: ICustomStateMachineData = {
    stateJSON: {
        "states": {
            "gridInit": {
                "onEntry": [
                    "onProjectsGridInit",
                ],
                "on": {
                    "GRID_LOAD": "gridLoad",
                },
            },
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onProjectsGridInit: (evt) => dispatch(doProjectsGridInit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("GridWidget", { "id": "studies" }, masterDataGrid);
