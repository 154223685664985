import { getDefaultRegistry, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import { Form } from 'react-bootstrap';
import { getlocaleText } from "@itsy-ui/utils";

class ScoringControl extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			error: false
		}
	}


	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}


	checkboxOnClick(firstText, secondText, controlProps, value) {
		const { handleChange } = this.getControlSchemaProperties();
		const defaultValue = {
			"checked": controlProps.value && controlProps.value["checked"] ? !controlProps.value["checked"] : value,
			"min": controlProps.value && controlProps.value["min"] ? controlProps.value["min"] : firstText.min,
			"max": controlProps.value && controlProps.value["max"] ? controlProps.value["max"] : secondText.max,
		}
		handleChange(undefined, defaultValue);
	}

	updateValue(min, max, controlProps, value, column) {
		const { handleChange } = this.getControlSchemaProperties();
		if (column === "firstValue") {
			const checkValue = value !== "" && parseInt(value) >= min && parseInt(value) <= max ? true : false;
			const defaultValue = {
				"checked": true,
				"min": value === "" ? value : checkValue === true ? value : controlProps.value && controlProps.value["min"] ? controlProps.value["min"] : "",
				"max": controlProps.value && controlProps.value["max"] ? controlProps.value["max"] : max,
			}
			handleChange(undefined, defaultValue);
			this.setState({
				error: checkValue ? false : true
			})
		}
		if (column === "secondValue") {
			const checkValue = value !== "" && parseInt(value) >= min && parseInt(value) <= max ? true : false;
			const defaultValue = {
				"checked": true,
				"min": controlProps.value && controlProps.value["min"] ? controlProps.value["min"] : min,
				"max": value === "" ? value : checkValue === true ? value : controlProps.value && controlProps.value["max"] ? controlProps.value["max"] : "",
			}
			handleChange(undefined, defaultValue);
			this.setState({
				error: checkValue ? false : true
			})
		}
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const { firstBox, secondBox } = fieldSchema;
		const defaultValue1 = controlProps.value === undefined && firstBox.min
		const defaultValue2 = controlProps.value === undefined && secondBox.max
		const errorText = controlProps.value !== undefined && (controlProps.value["min"] !== "" || controlProps.value["max"] !== "")
			&& this.state.error ? `${getlocaleText("{{upload.numberError}}")} ${firstBox.min} to ${secondBox.max}` : false
		
			const parts1 = getlocaleText(firstBox.displayName).split(/\s+/);
			const parts2 = getlocaleText(secondBox.displayName).split(/\s+/);
			
			const measurementName1 = parts1[0];
			const operator1 = parts1.slice(1).join(" "); // Join the remaining parts with space
			const measurementName2 = parts2[0];
			const operator2 = parts2.slice(1).join(" "); // Join the remaining parts with space
			const spaces = " ".repeat(20); // Adding 20 spaces
			const formatted1 = measurementName1 + " " + operator1; // No extra space here
			const formatted2 = measurementName2 + " " + operator2; // No extra space here
			
			const style = {
			  fontSize: formatted1 === "ADAS-COG >=" ? "15px !important" : null
			};
			
			return (
			<div>
				<div className={`input-textbox apn-scoring-control ${fieldSchema.customClass}`} style={fieldSchema.customStyle}>
					<Form.Check
						type="checkbox"
						name={fieldSchema.id}
						checked={controlProps.value !== undefined && controlProps.value.hasOwnProperty("checked") ? controlProps.value["checked"] : controlProps.value && (controlProps.value["min"] !== "" || controlProps.value["max"] !== "" || controlProps.value["checked"] === true) ? true : false}
						id={`${fieldSchema.id}`}
						key={fieldSchema.key}
						onChange={e => this.checkboxOnClick && this.checkboxOnClick(firstBox, secondBox, controlProps, e.currentTarget.checked)}
					/>
					
					<div className="apn-scoring-first-textbox">
						<Form.Label className="text-label" style={{fontSize: formatted1 === "ADAS-COG >=" ? "15px !important" : null }}> {formatted1}  &nbsp;&nbsp;  </Form.Label>
						<Form.Control
							id={firstBox.id}
							key={firstBox.id}
							type={firstBox.type}
							required={firstBox.required}
							disabled={firstBox.readOnly}
							min={firstBox.min}
							max={firstBox.max}
							placeholder={firstBox.min}
							value={defaultValue1 !== false ? defaultValue1 : controlProps.value && controlProps.value["min"] !== "" ? controlProps.value["min"] : ""}
							className={controlProps.error ? 'form-control error' : 'form-control'}
							onChange={e => this.updateValue && this.updateValue(firstBox.min, firstBox.max, controlProps, e.target.value, "firstValue")}
						/>
					</div>
					<div className="apn-scoring-second-textbox">
						<Form.Label className="text-label"> {getlocaleText(secondBox.displayName)}&nbsp; </Form.Label>
						<Form.Control
							id={secondBox.id}
							key={secondBox.id}
							type={secondBox.type}
							required={secondBox.required}
							disabled={secondBox.readOnly}
							min={secondBox.min}
							max={secondBox.max}
							placeholder={secondBox.max}
							value={defaultValue2 !== false ? defaultValue2 : controlProps.value && controlProps.value["max"] !== "" ? controlProps.value["max"] : ""}
							className={controlProps.error ? 'form-control error' : 'form-control'}
							onChange={e => this.updateValue && this.updateValue(secondBox.min, secondBox.max, controlProps, e.target.value, "secondValue")}
						/>
					</div>
				</div>
				{this.state.error && <Form.Text key={fieldSchema.id} id="component-error-text">{errorText}</Form.Text>}
			</div>
		);
	}
}

ScoringControl['displayName'] = 'ScoringControl';

WidgetsFactory.instance.registerFactory(ScoringControl);
WidgetsFactory.instance.registerControls({
	"apn_scoring_control": 'ScoringControl',
});