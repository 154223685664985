import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, eventTransition } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { getFormatedDate, getFormatedVisitDate, getISOFormatedDate } from "../common/helpers";
import { ACTIONS, TYPES, UPLOAD_STATUS } from "../utils/constant";
import { getuploadList, getSubjectValue, getAge } from "../utils/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

const getRenderRule = (propertyDefinitions, widget) => {
    const schema = {};
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("CRFfileName")) {
        schema["CRFfileName"] = (values) => {
            if (values && values["CRF"]) {
                return true;
            }
            return false;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("MRIfileName")) {
        schema["MRIfileName"] = (values) => {
            if (values && values["MRI"]) {
                return true;
            }
            return false;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("PETfileName")) {
        schema["PETfileName"] = (values) => {
            if (values && values["PET"]) {
                return true;
            }
            return false;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("CRFComment") && widget === "CRFEditUpload") {
        schema["CRFComment"] = (values) => {
            if (values && values["CRFComment"]) {
                return false;
            }
            return true;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("MRIComment") && widget === "MRIEditUpload") {
        schema["MRIComment"] = (values) => {
            if (values && values["MRIComment"]) {
                return false;
            }
            return true;
        };
    }
    if (propertyDefinitions && propertyDefinitions.hasOwnProperty("PETComment") && widget === "PETEditUpload") {
        schema["PETComment"] = (values) => {
            if (values && values["PETComment"]) {
                return false;
            }
            return true;
        };
    }
    return schema;
};

function doUploadFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
       //first conver the locale time to utc an then to iso
        const CurrentDate = new Date();
        const moment = require("moment")
        const TodayDate = getFormatedDate(CurrentDate);
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        const DateFormat = "MM-DD-YYYY"
        const uploadValidationSchema = {
            ...validationSchema,
            "mmse": Yup.number().max(30, `${getlocaleText("{{upload.numberError}}")} 0 to 30`),
            "cdr": Yup.number().max(3, `${getlocaleText("{{upload.numberError}}")} 0 to 3`),
            "adas_cog": Yup.number().max(70, `${getlocaleText("{{upload.numberError}}")} 0 to 70`),
            "fab": Yup.number().max(18, `${getlocaleText("{{upload.numberError}}")} 0 to 18`),
            "psprs": Yup.number().max(100, `${getlocaleText("{{upload.numberError}}")} 0 to 100`),
            "updrs": Yup.number().max(199, `${getlocaleText("{{upload.numberError}}")} 0 to 199`),
            "weight": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 200`).max(200, `${getlocaleText("{{upload.numberError}}")} 1 to 200`),
            "visitDate": Yup.string().required(getlocaleText("{{visitDate.Required}}")),
            "birthDate": Yup.string().required(getlocaleText("{{subject.DateBirthRequired}}")),
            "onsetAge": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 120`).max(120, `${getlocaleText("{{upload.numberError}}")} 1 to 120`).required(getlocaleText("{{onsetAge.Required}}")),
            "classificationId": Yup.string().required(getlocaleText("{{classification.required}}")),
            "studyId": Yup.string().required(getlocaleText("{{study.Required}}")),
            "subjectId": Yup.string().required(getlocaleText("{{subject.Required}}")),
            "batchId": Yup.string().when("compoundId", {
                is: selected_compoundId => {
                    return (selected_compoundId && selected_compoundId != "")
                },
                then: Yup.string().required(getlocaleText("{{batchId.Required}}")),
                otherwise: Yup.string()
            })
        }
        // if (updateFormSchema.propertyDefinitions["visitDate"]) {
        //     updateFormSchema.propertyDefinitions["visitDate"]["maxDate"] = TodayDate ? TodayDate :  moment(new Date(date)).format(DateFormat);
        // }
        // ? TodayDate :  moment(new Date(CurrentDate)).format(DateFormat);
         updateFormSchema.propertyDefinitions["visitDate"]["maxDate"] = TodayDate 
        updateFormSchema.propertyDefinitions["CRF"]["readOnly"] = updateFormSchema.propertyDefinitions["PET"]["readOnly"] = updateFormSchema.propertyDefinitions["MRI"]["readOnly"] = false;
        updateFormSchema.propertyDefinitions["CRF"]["className"] = updateFormSchema.propertyDefinitions["MRI"]["className"] = updateFormSchema.propertyDefinitions["PET"]["className"] = "btn-enable";

        if (objectData && ((objectData["CRFFileDetails"] && objectData["CRFFileDetails"].status === UPLOAD_STATUS.REJECT_CONFIRMED) || (objectData.MRIFileDetails && objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED) || (objectData.PETFileDetails && objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED))) {
            updateFormSchema.propertyDefinitions["visitDate"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["weight"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["bmi"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["mmse"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["cdr"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["adas_cog"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["fab"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["psprs"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["updrs"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["pet_dicom_available"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["apn_pet_dicom_available"]["readOnly"] = true;
        }

        if (objectData && objectData.CRFFileDetails) {
            if (objectData.CRFFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED) {
                updateFormSchema.propertyDefinitions["CRF"]["readOnly"] = false;
                updateFormSchema.propertyDefinitions["CRF"]["className"] = "btn-enable";
                updateFormSchema.propertyDefinitions["MRI"]["readOnly"] = objectData.MRIFileDetails && objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["MRI"]["className"] = objectData.MRIFileDetails && objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";
                updateFormSchema.propertyDefinitions["PET"]["readOnly"] = objectData.PETFileDetails && objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["PET"]["className"] = objectData.PETFileDetails && objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";
            }
        }
        if (objectData && objectData.MRIFileDetails) {
            if (objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED) {
                updateFormSchema.propertyDefinitions["MRI"]["readOnly"] = false;
                updateFormSchema.propertyDefinitions["MRI"]["className"] = "btn-enable";
                updateFormSchema.propertyDefinitions["CRF"]["readOnly"] = objectData.CRFFileDetails && objectData.CRFFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["CRF"]["className"] = objectData.CRFFileDetails && objectData.CRFFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";
                updateFormSchema.propertyDefinitions["PET"]["readOnly"] = objectData.PETFileDetails && objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["PET"]["className"] = objectData.PETFileDetails && objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";
                //MRI Recject confrim required false changed
                updateFormSchema.propertyDefinitions["visitDate"]["required"] = false;
                updateFormSchema.propertyDefinitions["weight"]["required"] = false;
                updateFormSchema.propertyDefinitions["bmi"]["required"] = false;
                updateFormSchema.propertyDefinitions["mmse"]["required"] = false;
                updateFormSchema.propertyDefinitions["cdr"]["required"] = false;
                updateFormSchema.propertyDefinitions["adas_cog"]["required"] = false;
                updateFormSchema.propertyDefinitions["fab"]["required"] = false;
                updateFormSchema.propertyDefinitions["psprs"]["required"] = false;
                updateFormSchema.propertyDefinitions["updrs"]["required"] = false;
                updateFormSchema.propertyDefinitions["pet_dicom_available"]["required"] = false;
                updateFormSchema.propertyDefinitions["apn_pet_dicom_available"]["required"] = false;
            }
        }
        if (objectData && objectData.PETFileDetails) {
            if (objectData.PETFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED) {
                updateFormSchema.propertyDefinitions["PET"]["readOnly"] = false;
                updateFormSchema.propertyDefinitions["PET"]["className"] = "btn-enable";
                updateFormSchema.propertyDefinitions["CRF"]["readOnly"] = objectData.CRFFileDetails && objectData.CRFFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["CRF"]["className"] = objectData.CRFFileDetails && objectData.CRFFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";
                updateFormSchema.propertyDefinitions["MRI"]["readOnly"] = objectData.MRIFileDetails && objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? false : true;
                updateFormSchema.propertyDefinitions["MRI"]["className"] = objectData.MRIFileDetails && objectData.MRIFileDetails.status === UPLOAD_STATUS.REJECT_CONFIRMED ? "btn-enable" : "btn-disable";

                //PET file Reject confirmed required false changed
                updateFormSchema.propertyDefinitions["visitDate"]["required"] = false;
                updateFormSchema.propertyDefinitions["weight"]["required"] = false;
                updateFormSchema.propertyDefinitions["bmi"]["required"] = false;
                updateFormSchema.propertyDefinitions["mmse"]["required"] = false;
                updateFormSchema.propertyDefinitions["cdr"]["required"] = false;
                updateFormSchema.propertyDefinitions["adas_cog"]["required"] = false;
                updateFormSchema.propertyDefinitions["fab"]["required"] = false;
                updateFormSchema.propertyDefinitions["psprs"]["required"] = false;
                updateFormSchema.propertyDefinitions["updrs"]["required"] = false;
                updateFormSchema.propertyDefinitions["pet_dicom_available"]["required"] = false;
                updateFormSchema.propertyDefinitions["apn_pet_dicom_available"]["required"] = false;
            }
        }
        if (objectData && objectData["CRFFileDetails"] && objectData["CRFFileDetails"].status === UPLOAD_STATUS.REJECT_CONFIRMED && objectData.MRIFileDetails === undefined && objectData.PETFileDetails === undefined) {
            updateFormSchema.propertyDefinitions["visitDate"]["required"] = false;
            updateFormSchema.propertyDefinitions["weight"]["required"] = false;
            updateFormSchema.propertyDefinitions["bmi"]["required"] = false;
            updateFormSchema.propertyDefinitions["mmse"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["cdr"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["adas_cog"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["fab"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["psprs"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["updrs"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["pet_dicom_available"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["apn_pet_dicom_available"]["readOnly"] = false;
        }
        if (updateFormSchema.propertyDefinitions["visitDate"]["readOnly"]) {
            updateFormSchema.propertyDefinitions["visitDate"]["propertyType"] = "string";
            updateFormSchema.propertyDefinitions["visitDate"]["ui:widget"] = "text";
            delete updateFormSchema.propertyDefinitions["visitDate"]["type"]
        }

        if (objectData !== undefined && objectData["siteId"] !== undefined) {
            const studyDatasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const getStudies = await studyDatasource.getAll("studies_dropdown", { siteId: objectData["siteId"] });
            const studyOption = [getStudies]
            updateFormSchema.propertyDefinitions["studyId"].metadata.data = studyOption[0];
            updateFormSchema.propertyDefinitions["studyId"].metadata.isLocal = true;
            updateFormSchema.propertyDefinitions["studyId"].metadata.readOnly = false;
            const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const siteData = await siteDatasource.getObject(TYPES.SITES, objectData["siteId"]);
            const compoundDataSource: any = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
            const getCompounds = await compoundDataSource.getAll("cmcsites_compound_dropdown", { cmcSiteId: siteData["cmcSiteId"] });
            const compoundsOption = [getCompounds];
            updateFormSchema.propertyDefinitions["compoundId"].metadata.data = compoundsOption[0];
            updateFormSchema.propertyDefinitions["compoundId"].metadata.isLocal = true;
            updateFormSchema.propertyDefinitions["compoundId"].metadata.readOnly = false;

         

            if (objectData["studyId"] !== undefined) {
                const subjectDatasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
                const getSUbjectID = await subjectDatasource.getAll("subjects_dropdown", { studyId: objectData["studyId"] });
                const subjectOption = [getSUbjectID]
                updateFormSchema.propertyDefinitions["subjectId"].metadata.data = subjectOption[0];
                updateFormSchema.propertyDefinitions["subjectId"].metadata.isLocal = true;
                updateFormSchema.propertyDefinitions["subjectId"].metadata.readOnly = false;
            }
        }
        if (objectData !== undefined && objectData["compoundId"] !== undefined) {
            const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const siteData = await siteDatasource.getObject(TYPES.SITES, objectData["siteId"]);
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const getBatches = await batchDatasource.getAll("batch_dropdown", { compoundId: objectData["compoundId"] ? objectData["compoundId"] : "", cmcSiteId: siteData["cmcSiteId"] });
            const batchesOption = [getBatches]
             updateFormSchema.propertyDefinitions["batchId"].metadata.data = batchesOption[0];
             updateFormSchema.propertyDefinitions["batchId"].metadata.isLocal = true;
             updateFormSchema.propertyDefinitions["batchId"].metadata.readOnly = false;
        }



        if (!objectData) {
            objectData = {};
        }
        // by default checked
        objectData["rawImage"] = ["rawImage"];

        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            objectData: objectData,
            formSchema: updateFormSchema,
            validationSchema: uploadValidationSchema,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, "newUpload"),
            extraParams: extraParams,
        });
    }
}

function doUploadFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        let localSiteId =localStorage.getItem("siteId")
        
        if(value["siteId"] !== localSiteId  ){
                              //removing after submit 
                           localStorage.removeItem("selectedBatchId")
                           localStorage.removeItem("selectedCompoundId")
                            localStorage.removeItem("compound_manufactured_id")
        }
        const { formValues, metadata } = getState();
       let updatedValues = { ...value };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        // let updatedPropDefs = JSON.parse(JSON.stringify(metadata.propertyDefinitions));
        let subjectOption;
        let prevVisitValues = {};
        let alreadyselectedCompondId
        let alreadyselectedBatch
        let currentFormValues = { ...formValues, ...updatedValues };
        const subjectDatasource: any = dataLoader.getLoader<IDataSourceLake>("subjectDataSource");
        const studyDatasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
        if (value !== undefined && value["siteId"] !== undefined) {
            localStorage.setItem("siteId" , value["siteId"])
            const getStudies = await studyDatasource.getAll("studies_dropdown", { siteId: value["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            updatedPropDefs["studyId"].metadata.readOnly = false;
            //the below line are there if new site is selected already selected compound id and batch id to make them undefined
           alreadyselectedBatch = undefined
            alreadyselectedCompondId = undefined
            currentFormValues.batchId = undefined
            currentFormValues.compoundId = undefined
            // formValues.compoundId = undefined
           updatedPropDefs["subjectId"].metadata.data = [];
            updatedPropDefs["subjectId"].metadata.isLocal = true;
            updatedPropDefs["subjectId"].metadata.readOnly = false;
           const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const siteData = await siteDatasource.getObject(TYPES.SITES, value["siteId"]);
            const compoundDataSource: any = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
            const getCompounds = await compoundDataSource.getAll("cmcsites_compound_dropdown", { cmcSiteId: siteData["cmcSiteId"] });
            const compoundsOption = [getCompounds];
            updatedPropDefs["compoundId"].metadata.data = compoundsOption[0];
            updatedPropDefs["compoundId"].metadata.isLocal = true;
            updatedPropDefs["compoundId"].metadata.readOnly = false;
            updatedPropDefs["batchId"].metadata.data = [];
            updatedPropDefs["batchId"].metadata.isLocal = true;
            updatedPropDefs["batchId"].metadata.readOnly = false;

            updatedValues = {
                ...value,
                studyId: undefined,
                subjectId: undefined,
                gender: undefined,
                birthDate: undefined,
                onsetAge: undefined,
                visitDate: "",
                bmi: undefined,
                adverse_events: [],
                rawImage: ["rawImage"],
            };
       }
        if (value !== undefined && Object.keys(value).toString() === "subjectId" && !Array.isArray(value["subjectId"]) && value["subjectId"].isDisMathc === undefined) {
            const getSubject: any = await getSubjectValue(subjectDatasource, TYPES.SUBJECT, value["subjectId"])
            updatedValues = {
                ...value,
                "gender": getSubject.gender,
                "birthDate": getSubject.birthDate,
                "onsetAge": undefined,
                visitDate: "",
                bmi: undefined,
                adverse_events: [],
                rawImage: ["rawImage"],
            };
        }
        if (value !== undefined && value["studyId"] !== undefined) {
            let secoundUpdateVlaule;
            let prevAlreadyselectedCompondId = alreadyselectedCompondId
           const getSUbjectID = await subjectDatasource.getAll("subjects_dropdown", { studyId: value["studyId"] });
            subjectOption = [getSUbjectID]
            // updatedPropDefs["subjectId"] = metadata.propertyDefinitions["subjectId"];
            updatedPropDefs["subjectId"].metadata.data = subjectOption[0];
            updatedPropDefs["subjectId"].metadata.isLocal = true;
            updatedPropDefs["subjectId"]. metadata.isOldDataEarse =false
            // updatedPropDefs["subjectId"].metadata.filter = { studyId: value["studyId"] }
            updatedPropDefs["subjectId"].metadata.readOnly = false;
            if (value["subjectId"] !== undefined) {
                const selectvalue = getSUbjectID.find(e => e.subjectId === value["subjectId"])
                value["subjectId"] = selectvalue["id"]
                const getSubject: any = await getSubjectValue(subjectDatasource, TYPES.SUBJECT, value["subjectId"])
                secoundUpdateVlaule = {
                    "gender": getSubject.gender,
                    "birthDate": getSubject.birthDate,

                };
            } else {
                secoundUpdateVlaule = {
                    "gender": undefined,
                    "birthDate": undefined,
                };
            }

            updatedValues = {
                visitDate: "",
                onsetAge: undefined,
                bmi: undefined,
                adverse_events: [],
                rawImage: ['rawImage'],
                compoundId :  formValues.compoundId,
                ...value,
                ...secoundUpdateVlaule
            };
        } 
        if (value !== undefined && value["batchId"] !== undefined) {
            updatedValues["compound_manufactured_id"] = value["batchId"]
        }
      

        if(currentFormValues.compoundId !== undefined){
            alreadyselectedCompondId = currentFormValues.compoundId
        }
        if(currentFormValues.batchId !== undefined){
            alreadyselectedBatch = currentFormValues.batchId
        }

        //when the compound id and batch uid is selected and move to ADD NEW SUBJECT Id and come back
        //in that time we need to have previiously selected compound id and batch id so setting it in to 
        //local
       
        let compoundIdBe
        if(alreadyselectedCompondId !== undefined){
          localStorage.setItem("selectedCompoundId" , alreadyselectedCompondId)
        }
        let batchIdBe
        if(alreadyselectedBatch !== undefined){
            localStorage.setItem("compound_manufactured_id" ,currentFormValues?.compound_manufactured_id )
            localStorage.setItem("selectedBatchId" , alreadyselectedBatch)
            localStorage.setItem("batchMetaData",  JSON.stringify(updatedPropDefs["batchId"].metadata.data) )
           }
        
        compoundIdBe =localStorage.getItem("selectedCompoundId")
        batchIdBe =localStorage.getItem("selectedBatchId")
       let compound_manufactured_id_storage = localStorage.getItem("compound_manufactured_id")
       //    ----------------------//
        currentFormValues = { ...formValues, ...updatedValues };
        if(currentFormValues.compoundId !== formValues.compoundId){
            currentFormValues.batchId = undefined
        }
       // check previous visit details for new upload
        if (metadata && metadata.id === "upload_form" && value && (value["visitDate"] || value["studyId"] || value["subjectId"] || value["siteId"])) {
            const todayYear = new Date().getFullYear();
            const minVisitYear = todayYear - 5;
            if (currentFormValues["studyId"] && currentFormValues["subjectId"] && currentFormValues["visitDate"] && currentFormValues["visitDate"] !== "" &&
                (new Date(currentFormValues["visitDate"]).getFullYear() >= minVisitYear && new Date(currentFormValues["visitDate"]).getFullYear() <= todayYear)) {
                const queryParam = {
                    filter: {
                        subjectId: currentFormValues["subjectId"],
                        visitDate: getFormatedDate(currentFormValues["visitDate"])
                    }
                };
                if (queryParam.filter.subjectId && queryParam.filter.visitDate) {
                    transition({
                        type: "SHOW_INDICATOR",
                        loadingMessage: "{{visiDate.find}}",
                    });
                    const visitDatasource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
                    const prevVisitData = await visitDatasource.getAll(TYPES.VISITS, queryParam);

                    prevVisitValues = prevVisitData && prevVisitData.length > 0 && getuploadList(prevVisitData[0]);
                }
            }
            if (prevVisitValues) {
                //crf button
                if (prevVisitValues["CRFfileName"]) {
                    updatedPropDefs["CRF"]["readOnly"] = true;
                    updatedPropDefs["CRF"]["className"] = "btn-disable";
                }
                else {
                    updatedPropDefs["CRF"]["readOnly"] = false;
                    updatedPropDefs["CRF"]["className"] = "btn-enable";
                }
                //mri button
                if (prevVisitValues["MRIfileName"]) {
                    updatedPropDefs["MRI"]["readOnly"] = true;
                    updatedPropDefs["MRI"]["className"] = "btn-disable";
                }
                else {
                    updatedPropDefs["MRI"]["readOnly"] = false;
                    updatedPropDefs["MRI"]["className"] = "btn-enable";
                }
                //pet button
                if (prevVisitValues["PETfileName"]) {
                    updatedPropDefs["PET"]["readOnly"] = true;
                    updatedPropDefs["PET"]["className"] = "btn-disable";
                }
                else {
                    updatedPropDefs["PET"]["readOnly"] = false;
                    updatedPropDefs["PET"]["className"] = "btn-enable";
                }

                updatedPropDefs["CRF"]["showFile"] = false;
                updatedPropDefs["MRI"]["showFile"] = false;
                updatedPropDefs["PET"]["showFile"] = false;

                if (prevVisitValues["CRFComment"]) {
                    updatedPropDefs["CRFComment"]["readOnly"] = true;
                }
                else {
                    updatedPropDefs["CRFComment"]["readOnly"] = false;
                }
                if (prevVisitValues["MRIComment"]) {
                    updatedPropDefs["MRIComment"]["readOnly"] = true;
                }
                else {
                    updatedPropDefs["MRIComment"]["readOnly"] = false;
                }
                if (prevVisitValues["PETComment"]) {
                    updatedPropDefs["PETComment"]["readOnly"] = true;
                }
                else {
                    updatedPropDefs["PETComment"]["readOnly"] = false;
                }
            }
            else {
                updatedPropDefs["CRF"]["readOnly"] = updatedPropDefs["PET"]["readOnly"] = updatedPropDefs["MRI"]["readOnly"] = false;
                updatedPropDefs["CRF"]["className"] = updatedPropDefs["MRI"]["className"] = updatedPropDefs["PET"]["className"] = "btn-enable";
                updatedPropDefs["CRFComment"]["readOnly"] = updatedPropDefs["MRIComment"]["readOnly"] = updatedPropDefs["PETComment"]["readOnly"] = false;
            }

            //set currentFormValues based on search data
            currentFormValues["id"] = prevVisitValues["id"];
            currentFormValues["mmse"] = prevVisitValues["mmse"]; currentFormValues["cdr"] = prevVisitValues["cdr"]; currentFormValues["adas_cog"] = prevVisitValues["adas_cog"];
            currentFormValues["fab"] = prevVisitValues["fab"]; currentFormValues["psprs"] = prevVisitValues["psprs"]; currentFormValues["updrs"] = prevVisitValues["updrs"];
            currentFormValues["weight"] = prevVisitValues["weight"];
            currentFormValues["bmi"] = prevVisitValues["bmi"];
            currentFormValues["adverse_events"] = prevVisitValues["adverse_events"];
            currentFormValues["rawImage"] = prevVisitValues["rawImage"] ? prevVisitValues["rawImage"] : ["rawImage"];
            currentFormValues["classificationId"] = prevVisitValues["classificationId"];
            currentFormValues["pet_dicom_available"] = prevVisitValues["pet_dicom_available"];
            currentFormValues["apn_pet_dicom_available"] = prevVisitValues["apn_pet_dicom_available"];
            currentFormValues["CRFfileName"] = prevVisitValues["CRFfileName"]; currentFormValues["CRFFileDetails"] = prevVisitValues["CRFFileDetails"];
            currentFormValues["MRIfileName"] = prevVisitValues["MRIfileName"]; currentFormValues["MRIFileDetails"] = prevVisitValues["MRIFileDetails"];
            currentFormValues["PETfileName"] = prevVisitValues["PETfileName"]; currentFormValues["PETFileDetails"] = prevVisitValues["PETFileDetails"];
            // reset already uploaded value
            currentFormValues["CRF"] = currentFormValues["MRI"] = currentFormValues["PET"] = null;
            updatedPropDefs["CRF"]["showFile"] = false;
            updatedPropDefs["MRI"]["showFile"] = false;
            updatedPropDefs["PET"]["showFile"] = false;
            currentFormValues["CRFComment"] = prevVisitValues["CRFComment"];
            currentFormValues["MRIComment"] = prevVisitValues["MRIComment"];
            currentFormValues["PETComment"] = prevVisitValues["PETComment"];
            currentFormValues["onsetAge"] = currentFormValues["birthDate"] && currentFormValues["visitDate"] ? getAge(new Date(currentFormValues["visitDate"]), new Date(currentFormValues["birthDate"])) : undefined;
            currentFormValues["compoundId"] = alreadyselectedCompondId ? alreadyselectedCompondId  :prevVisitValues["compoundId"];
            currentFormValues["batchId"] =alreadyselectedBatch ? alreadyselectedBatch : prevVisitValues["batchId"];
       
                //while coming back friom add new subject id w wilkl get all this there value, So setting compound id and batch id which isstored in local 
                if(value["studyId"] && value["siteId"] && value["subjectId"]){
                    currentFormValues["compoundId"] =compoundIdBe
                    currentFormValues["batchId"]  = batchIdBe
                    const jsonString = localStorage.getItem('batchMetaData');
                    const parsedObject = JSON.parse(jsonString);
                    //MetaData to search the selected Id and display the name 
                    updatedPropDefs["batchId"].metadata.data =parsedObject 
                    //when reload happens from Addd New sub,it button we will miss compound_manufactured_id so storing that in local 
                    currentFormValues["compound_manufactured_id"]= compound_manufactured_id_storage
                 }
                transition({
                        type: "HIDE_INDICATOR"
                    })
                }
            // currentFormValues["compoundId"] = prevVisitValues["compoundId"];
     
        if ((value !== undefined && value["compoundId"] !== undefined) || prevVisitValues["compoundId"]) {
        const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const siteData = await siteDatasource.getObject(TYPES.SITES, formValues["siteId"]);
            const getBatches = await batchDatasource.getAll("batch_dropdown", { compoundId: value["compoundId"] ? value["compoundId"] : prevVisitValues["compoundId"], cmcSiteId: siteData["cmcSiteId"] });
            const batchesOption = [getBatches]
            updatedPropDefs["batchId"].metadata.data = batchesOption[0];
            updatedPropDefs["batchId"].metadata.isLocal = true;
            updatedPropDefs["batchId"].metadata.readOnly = false;
        }
        //DOB
        if (value !== undefined && value["onsetAge"] !== undefined && currentFormValues["visitDate"]) {
            const birthYear = (new Date(currentFormValues["visitDate"]).getFullYear() - value["onsetAge"]);
            const birthMonth = new Date(currentFormValues["visitDate"]).getMonth() + 1;
            currentFormValues["birthDate"] = `${birthYear}-${birthMonth}-01`;
        }
        if (value !== undefined && value["birthDate"] !== undefined && currentFormValues["visitDate"]) {
            currentFormValues["onsetAge"] = getAge(new Date(currentFormValues["visitDate"]), new Date(value["birthDate"]));
        }

        if (updatedPropDefs["CRF"]["showFile"] !== true && value["CRF"] !== undefined) {
            updatedPropDefs["CRF"]["showFile"] = true;
        } else if (updatedPropDefs["MRI"]["showFile"] !== true && value["MRI"] !== undefined) {
            updatedPropDefs["MRI"]["showFile"] = true;
        } else if (updatedPropDefs["PET"]["showFile"] !== true && value["PET"] !== undefined) {
            updatedPropDefs["PET"]["showFile"] = true;
        }
        // const finalFormValues = { ...prevVisitValues, ...currentFormValues, }
        if (metadata && metadata.id === "upload_form" && value["studyId"] !== "" && value["studyId"] !== undefined && formValues["studyId"] !== undefined && formValues["studyId"] !== "") {
            updatedPropDefs["CRF"]["showFile"] = false;
            updatedPropDefs["MRI"]["showFile"] = false;
            updatedPropDefs["PET"]["showFile"] = false;
        }
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            controlID: metadata.id === "uploadEdit_form" ? "uploadEdit_form" : "upload_form",
            strict: true,
            mode: 0
        });
  transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            controlID: metadata.id === "uploadEdit_form" ? "uploadEdit_form" : "upload_form",
            strict: true,
            mode: 0
        });
   };
}

async function newUpload(data) {
     try {
        const visitDataSource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
        const commentsdatasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        const visitObject = {
            subjectId: data["subjectId"],
            weight: data["weight"],
            bmi: data["bmi"],
            onsetAge: data["onsetAge"],
            birthDate: data["birthDate"] ? getISOFormatedDate(data["birthDate"]) : undefined,
            visitDate: data["visitDate"] ? getISOFormatedDate(data["visitDate"]) : undefined,
            classificationId: data["classificationId"],
            mmse:parseFloat(data["mmse"]) ,
            cdr:parseFloat(data["cdr"]),
            adas_cog:parseFloat(data["adas_cog"]),
            fab: parseFloat(data["fab"]),
            psprs: parseFloat(data["psprs"]),
            updrs:parseFloat(data["updrs"]) ,
            pet_dicom_available: data["pet_dicom_available"] !== undefined ? (data["pet_dicom_available"] === "true" ? true : false) : undefined,
            apn_pet_dicom_available: data["apn_pet_dicom_available"] !== undefined ? (data["apn_pet_dicom_available"] === "true" ? true : false) : undefined,
            adverseEvent: data["adverse_events"] && Array.isArray(data["adverse_events"]) && data["adverse_events"].length == 1 ? true : false,
            raw: data["rawImage"] && Array.isArray(data["rawImage"]) && data["rawImage"].length == 1 ? true : false,
            compound_manufactured_id: data["compound_manufactured_id"]
        }
        if (data["weight"] === undefined ||data["weight"] === "" || data["weight"] === null) {
            delete visitObject.weight;
        }
        if (data["cdr"] === undefined ||data["cdr"] === "" || data["cdr"] === null) {
            delete visitObject.cdr;
        }
        if (data["mmse"] === undefined ||data["mmse"] === "" || data["mmse"] === null ) {
            delete visitObject.mmse;
        }if (data["adas_cog"] === undefined ||data["adas_cog"] === "" || data["adas_cog"] === null ) {
            delete visitObject.adas_cog;
        }if (data["fab"] === undefined ||data["fab"] === "" || data["fab"] === null ) {
            delete visitObject.fab;
        }if (data["psprs"] === undefined ||data["psprs"] === "" || data["psprs"] === null ) {
            delete visitObject.psprs;
        }if (data["updrs"] === undefined||data["updrs"] === "" || data["updrs"] === null ) {
            delete visitObject.updrs;
        }
        if (data["bmi"] === undefined ||data["bmi"] === "" || data["bmi"] === null ) {
            delete visitObject.bmi;
        }
       
        const visitRes = await visitDataSource.create(visitObject, { id: TYPES.VISITS });
        if (visitRes["id"]) {
            console.log("visit created successfully");
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
            if (data["MRI"] && data["MRI"].length > 0) {
                let mriFile = data["MRI"][0];
                const uploadvalues: any = {
                    visitId: visitRes["id"],
                    documentType: "MRI",
                    contentStreamFileName: mriFile.name,
                    contentSteamLength: mriFile.size + "",
                    contentStreamMimeType: mriFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                let result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                await datasource.uploadFileToCloud(result.signedUrl, mriFile, { id: result.id, documentType: uploadvalues.documentType });
                if (data["MRIComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["MRIComment"],
                        initial: true

                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
            if (data["PET"] && data["PET"].length > 0) {
                let petFile = data["PET"][0];
                const uploadvalues: any = {
                    visitId: visitRes["id"],
                    documentType: "PET",
                    contentStreamFileName: petFile.name,
                    contentSteamLength: petFile.size + "",
                    contentStreamMimeType: petFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                let result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                await datasource.uploadFileToCloud(result.signedUrl, petFile, { id: result.id, documentType: uploadvalues.documentType });
                if (data["PETComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["PETComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
            if (data["CRF"] && data["CRF"].length > 0) {
                let crfFile = data["CRF"][0];
                const uploadvalues: any = {
                    visitId: visitRes["id"],
                    documentType: "CRF",
                    contentStreamFileName: crfFile.name,
                    contentSteamLength: crfFile.size + "",
                    contentStreamMimeType: crfFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                let result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                await datasource.uploadFileToCloud(result.signedUrl, crfFile, { id: result.id, documentType: uploadvalues.documentType });
                if (data["CRFComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["CRFComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
        }
    } catch (e) {
        throw new Error(e);
    }
}

function doUploadFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const record = event.values;
           
            if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
                await editUpload(record);
            }
            else {
                await newUpload(record);
            }
                //removing after submit 
                localStorage.removeItem("selectedBatchId")
                localStorage.removeItem("selectedCompoundId")
                localStorage.removeItem("compound_manufactured_id")
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: ACTIONS.HIDE_DRAWER,
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{upload.successMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: ACTIONS.GRID_REFRESH
            })
        
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const UploadFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onUploadFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onUploadFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onUploadFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onUploadFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(doUploadFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onUploadFormSubmit: (evt) => dispatch(doUploadFormSubmit(evt)),
            onUploadFormHandleChange: ({ value, formValues }) => dispatch(doUploadFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "upload_form",
    typeId: "upload"
}, UploadFormOnSubmit);

//edit upload form

async function editUpload(data) {
    try {
        const visitDataSource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
        const commentsdatasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
        const visitObject = {
            id: data["id"],
            weight: parseFloat(data["weight"]),
            bmi: parseFloat(data["bmi"]),
            onsetAge: data["onsetAge"],
            birthDate: data["birthDate"] ? getISOFormatedDate(data["birthDate"]) : undefined,
            visitDate: data["visitDate"] ? getISOFormatedDate(data["visitDate"]) : undefined,
            classificationId: data["classificationId"],
            mmse: parseFloat(data["mmse"]),
            cdr: parseFloat(data["cdr"]),
            adas_cog: parseFloat(data["adas_cog"]),
            fab: parseFloat(data["fab"]),
            psprs: parseFloat(data["psprs"]),
            updrs: parseFloat(data["updrs"]),
            pet_dicom_available: data["pet_dicom_available"] !== undefined ? (data["pet_dicom_available"] === "true" ? true : false) : undefined,
            apn_pet_dicom_available: data["apn_pet_dicom_available"] !== undefined ? (data["apn_pet_dicom_available"] === "true" ? true : false) : undefined,
            subjectId: data["subject"] && data["subject"]["id"] ? data["subject"]["id"] : data["subjectId"],
            adverseEvent: data["adverse_events"] && Array.isArray(data["adverse_events"]) && data["adverse_events"].length == 1 ? true : false,
            raw: data["rawImage"] && Array.isArray(data["rawImage"]) && data["rawImage"].length == 1 ? true : false,
            compound_manufactured_id: data["compound_manufactured_id"]
        }
        if (data["weight"] === undefined ||data["weight"] === "" || data["weight"] === null) {
            delete visitObject.weight;
        }
        if (data["cdr"] === undefined ||data["cdr"] === "" || data["cdr"] === null) {
            delete visitObject.cdr;
        }
        if (data["mmse"] === undefined ||data["mmse"] === "" || data["mmse"] === null ) {
            delete visitObject.mmse;
        }if (data["adas_cog"] === undefined ||data["adas_cog"] === "" || data["adas_cog"] === null ) {
            delete visitObject.adas_cog;
        }if (data["fab"] === undefined ||data["fab"] === "" || data["fab"] === null ) {
            delete visitObject.fab;
        }if (data["psprs"] === undefined ||data["psprs"] === "" || data["psprs"] === null ) {
            delete visitObject.psprs;
        }if (data["updrs"] === undefined||data["updrs"] === "" || data["updrs"] === null ) {
            delete visitObject.updrs;
        }
        if (data["bmi"] === undefined ||data["bmi"] === "" || data["bmi"] === null ) {
            delete visitObject.bmi;
        }
        const visitRes = await visitDataSource.update(visitObject, { id: TYPES.VISITS });
        if (visitRes) {
            console.log("visit created successfully");
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
            if (data["MRI"] && data["MRI"].length > 0) {
                let mriFile = data["MRI"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "MRI",
                    contentStreamFileName: mriFile.name,
                    contentSteamLength: mriFile.size + "",
                    contentStreamMimeType: mriFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                if (data["MRIFileDetails"]) {
                    uploadvalues["id"] = data["MRIFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                let result;
                if (data["MRIFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, mriFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });

                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, mriFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                if (data["MRIComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["MRIComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
            if (data["PET"] && data["PET"].length > 0) {
                let petFile = data["PET"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "PET",
                    contentStreamFileName: petFile.name,
                    contentSteamLength: petFile.size + "",
                    contentStreamMimeType: petFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                if (data["PETFileDetails"]) {
                    uploadvalues["id"] = data["PETFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                let result;
                if (data["PETFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, petFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });
                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, petFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                if (data["PETComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["PETComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
            if (data["CRF"] && data["CRF"].length > 0) {
                let crfFile = data["CRF"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "CRF",
                    contentStreamFileName: crfFile.name,
                    contentSteamLength: crfFile.size + "",
                    contentStreamMimeType: crfFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                if (data["CRFFileDetails"]) {
                    uploadvalues["id"] = data["CRFFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                let result;
                if (data["CRFFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, crfFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });
                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, crfFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                if (data["CRFComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["CRFComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }
        }
    } catch (e) {
        throw new Error(e);
    }
}

function doEditUploadFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            await editUpload(event.values);
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: ACTIONS.HIDE_DRAWER,
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": "{{upload.successMessage}}", "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: ACTIONS.GRID_REFRESH
            })

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const EditUploadForm: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onEditUploadFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onEditUploadFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onEditUploadFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onEditUploadFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(doUploadFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onEditUploadFormSubmit: (evt) => dispatch(doEditUploadFormSubmit(evt)),
            onEditUploadFormHandleChange: ({ value, formValues }) => dispatch(doUploadFormHandleChange(value, formValues)),
        };
    },
};

customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "uploadEdit_form",
    typeId: "upload"
}, EditUploadForm);

//**************** PET FORM HANDLER **********

function doEditUploadFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues, metadata } = getState();
        let updatedValues = { ...value };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (value !== undefined && value["onsetAge"] !== undefined && formValues["visitDate"]) {
            const birthYear = (new Date(formValues["visitDate"]).getFullYear() - value["onsetAge"]);
            const birthMonth = new Date(formValues["visitDate"]).getMonth() + 1;
            updatedValues = {
                "birthDate": `${birthYear}-${birthMonth}-01`,
                ...value
            };
        }
        if (value !== undefined && value["birthDate"] !== undefined && formValues["visitDate"]) {
            updatedValues = {
                "onsetAge": getAge(new Date(formValues["visitDate"]), new Date(value["birthDate"])),
                ...value
            };
        }
        if (value !== undefined && value["compoundId"] !== undefined) {
            const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
            const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const siteData = await siteDatasource.getObject(TYPES.SITES, formValues["siteId"]);
            const getBatches = await batchDatasource.getAll("batch_dropdown", { compoundId: value["compoundId"], cmcSiteId: siteData["cmcSiteId"] });
            const batchesOption = [getBatches]
            updatedPropDefs["batchId"].metadata.data = batchesOption[0];
            updatedPropDefs["batchId"].metadata.isLocal = true;
            updatedPropDefs["batchId"].metadata.readOnly = false;
        }
        let currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
            mode: 0
        });
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
            mode: 0
        });
    }
}
function onPETUploadFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
        //first conver the locale time to utc an then to iso
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        if (objectData && objectData["PETFileDetails"] && objectData["PETFileDetails"].status === UPLOAD_STATUS.REJECT_CONFIRMED) {
            updateFormSchema.propertyDefinitions["PET"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["PET"]["className"] = "btn-enable";
            updateFormSchema.propertyDefinitions["weight"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["bmi"]["readOnly"] = false;
        }

        const uploadValidationSchema = {
            ...validationSchema,
            "weight": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 200`).max(200, `${getlocaleText("{{upload.numberError}}")} 1 to 200`),
        };

        if (formSchema.propertyDefinitions.studyId.propertyType && formSchema.propertyDefinitions.studyId.propertyType == "string") {
            objectData["studyId"] = objectData.subject && objectData.subject.study ? objectData.subject.study.studyName : "";
        }

        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            objectData: objectData,
            formSchema: updateFormSchema,
            validationSchema: uploadValidationSchema,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, "PETEditUpload"),
        });
    }
}

function doPETUploadFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        const data = event.values
        try {

            let displayError = true;
            if (data["PETfileName"] !== undefined && data["PETfileName"] !== null && data["PETfileName"] !== "") {
                displayError = false;
            }

            if (data["PET"] && data["PET"].length > 0) {
                let petFile = data["PET"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "PET",
                    contentStreamFileName: petFile.name,
                    contentSteamLength: petFile.size + "",
                    contentStreamMimeType: petFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                if (data["PETFileDetails"]) {
                    uploadvalues["id"] = data["PETFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                const commentsdatasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
                let result;
                if (data["PETFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, petFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });
                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, petFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                displayError = false;
                if (data["PETComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["PETComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }

            if (displayError) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                let errorMessage = "PET file is required, please select file and submit.";
                transition({
                    strict: true,
                    type: "FORM_ERROR",
                    errorMessage: errorMessage,
                });
            } else {
                const visitDataSource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
                const visitObject = {
                    id: data["id"],
                    bmi: (data["bmi"] !== undefined || data["bmi"] !== null) ? parseFloat(data["bmi"]) : undefined,
                    weight: (data["weight"] !== undefined || data["weight"] !== null) ? parseFloat(data["weight"]) : undefined,
                    classificationId: data["classificationId"],
                    onsetAge: data["onsetAge"],
                    birthDate: data["birthDate"] ? getISOFormatedDate(data["birthDate"]) : undefined,
                }
                await visitDataSource.update(visitObject, { id: TYPES.VISITS });

                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{upload.successMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const PETFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onPETUploadFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onPETUploadFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onPETUploadFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onPETUploadFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) => dispatch(onPETUploadFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onPETUploadFormSubmit: (evt) => dispatch(doPETUploadFormSubmit(evt)),
            onPETUploadFormHandleChange: ({ value, formValues }) => dispatch(doEditUploadFormHandleChange(value, formValues)),
        };
    },
};

customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "petUpload_form",
    typeId: "upload"
}, PETFormHandler);


// *** MRI FORM HANDLER *** //

function doMRIUploadFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
        //first conver the locale time to utc an then to iso
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        if (objectData && objectData["MRIFileDetails"] && objectData["MRIFileDetails"].status === UPLOAD_STATUS.REJECT_CONFIRMED) {
            updateFormSchema.propertyDefinitions["MRI"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["MRI"]["className"] = "btn-enable";
            updateFormSchema.propertyDefinitions["weight"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["bmi"]["readOnly"] = false;
        }

        const uploadValidationSchema = {
            ...validationSchema,
            "weight": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 200`).max(200, `${getlocaleText("{{upload.numberError}}")} 1 to 200`),
        };

        if (formSchema.propertyDefinitions.studyId.propertyType && formSchema.propertyDefinitions.studyId.propertyType == "string") {
            objectData["studyId"] = objectData.subject && objectData.subject.study ? objectData.subject.study.studyName : "";
        }
        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            objectData: objectData,
            formSchema: updateFormSchema,
            validationSchema: uploadValidationSchema,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, "MRIEditUpload"),
        });
    }
}

function doMRIUploadFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        const data = event.values
        try {

            let displayError = true;
            if (data["MRIfileName"] !== undefined && data["MRIfileName"] !== null && data["MRIfileName"] !== "") {
                displayError = false;
            }

            if (data["MRI"] && data["MRI"].length > 0) {
                let mriFile = data["MRI"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "MRI",
                    contentStreamFileName: mriFile.name,
                    contentSteamLength: mriFile.size + "",
                    contentStreamMimeType: mriFile.type,
                    status: UPLOAD_STATUS.NEW
                }
                if (data["MRIFileDetails"]) {
                    uploadvalues["id"] = data["MRIFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                const commentsdatasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
                let result;
                if (data["MRIFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, mriFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });
                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, mriFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                displayError = false;
                if (data["MRIComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["MRIComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }

            if (displayError) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                let errorMessage = "MRI file is required, please select file and submit.";
                transition({
                    strict: true,
                    type: "FORM_ERROR",
                    errorMessage: errorMessage,
                });
            } else {
                const visitDataSource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
                const visitObject = {
                    id: data["id"],
                    bmi: (data["bmi"] !== undefined || data["bmi"] !== null) ? parseFloat(data["bmi"]) : undefined,
                    weight: (data["weight"] !== undefined || data["weight"] !== null) ? parseFloat(data["weight"]) : undefined,
                    classificationId: data["classificationId"],
                    onsetAge: data["onsetAge"],
                    birthDate: data["birthDate"] ? getISOFormatedDate(data["birthDate"]) : undefined,
                }
                await visitDataSource.update(visitObject, { id: TYPES.VISITS });

                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{upload.successMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const MRIFormHandler: ICustomStateMachineData = {
    name: "MRIForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onMRIUploadFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onMRIUploadFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onMRIUploadFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onMRIUploadFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) =>
                dispatch(doMRIUploadFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onMRIUploadFormSubmit: (evt) => dispatch(doMRIUploadFormSubmit(evt)),
            onMRIUploadFormHandleChange: ({ value, formValues }) => dispatch(doEditUploadFormHandleChange(value, formValues)),
        };
    },
};

customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "mriUpload_form",
    typeId: "upload"
}, MRIFormHandler);

// *** CRF FORM HANDLER *** //
function doCRFUploadFormSchemaBeforeLoad(typeId: string, formSchemaId: string, objectData: any, formSchema: any, validationSchema: any, extraParams: {}) {
    return async (_, dispatch, transition) => {
        //first conver the locale time to utc an then to iso
        let updateFormSchema = JSON.parse(JSON.stringify(formSchema));
        if (objectData && objectData["CRFFileDetails"] && objectData["CRFFileDetails"].status === UPLOAD_STATUS.REJECT_CONFIRMED) {
            updateFormSchema.propertyDefinitions["CRF"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["CRF"]["className"] = "btn-enable";
            updateFormSchema.propertyDefinitions["weight"]["readOnly"] = false;
            updateFormSchema.propertyDefinitions["bmi"]["required"] = false;
            updateFormSchema.propertyDefinitions["visitDate"]["readOnly"] = true;
            updateFormSchema.propertyDefinitions["visitDate"]["required"] = false;
        }

        const uploadValidationSchema = {
            ...validationSchema,
            "mmse": Yup.number().max(30, `${getlocaleText("{{upload.numberError}}")} 0 to 30`),
            "cdr": Yup.number().max(3, `${getlocaleText("{{upload.numberError}}")} 0 to 3`),
            "adas_cog": Yup.number().max(70, `${getlocaleText("{{upload.numberError}}")} 0 to 70`),
            "fab": Yup.number().max(18, `${getlocaleText("{{upload.numberError}}")} 0 to 18`),
            "psprs": Yup.number().max(100, `${getlocaleText("{{upload.numberError}}")} 0 to 100`),
            "updrs": Yup.number().max(199, `${getlocaleText("{{upload.numberError}}")} 0 to 199`),
            "weight": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 200`).max(200, `${getlocaleText("{{upload.numberError}}")} 1 to 200`),
            "birthDate": Yup.string().required(getlocaleText("{{subject.DateBirthRequired}}")),
            "onsetAge": Yup.number().min(1, `${getlocaleText("{{upload.numberError}}")} 1 to 120`).max(120, `${getlocaleText("{{upload.numberError}}")} 1 to 120`).required(getlocaleText("{{onsetAge.Required}}"))
        };

        if (formSchema.propertyDefinitions.studyId.propertyType && formSchema.propertyDefinitions.studyId.propertyType == "string") {
            objectData["studyId"] = objectData.subject && objectData.subject.study ? objectData.subject.study.studyName : "";
        }

        if (updateFormSchema.propertyDefinitions["visitDate"]["readOnly"]) {
            updateFormSchema.propertyDefinitions["visitDate"]["propertyType"] = "string";
            updateFormSchema.propertyDefinitions["visitDate"]["ui:widget"] = "text";
            delete updateFormSchema.propertyDefinitions["visitDate"]["type"]
        }
//this below line is commented since we wanted to show already selected compound and batch 
        // update compound and batchId dropdown
        // const siteDatasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
        // const siteData = await siteDatasource.getObject(TYPES.SITES, objectData["siteId"]);
        // const compoundDataSource: any = dataLoader.getLoader<IDataSourceLake>("compoundDataSource");
        // const getCompounds = await compoundDataSource.getAll("cmcsites_compound_dropdown", { cmcSiteId: siteData["cmcSiteId"] });
        // const compoundsOption = [getCompounds];
        // updateFormSchema.propertyDefinitions["compoundId"].metadata.data = compoundsOption[0];
        // updateFormSchema.propertyDefinitions["compoundId"].metadata.isLocal = true;
        // updateFormSchema.propertyDefinitions["compoundId"].metadata.readOnly = false;
        // if (objectData["compoundId"]) {
        //     const batchDatasource: any = dataLoader.getLoader<IDataSourceLake>("batchDataSource");
        //     const getBatches = await batchDatasource.getAll("batch_dropdown", { compoundId: objectData["compoundId"] ? objectData["compoundId"] : "", cmcSiteId: siteData["cmcSiteId"] });
        //     const batchesOption = [getBatches]
        //     updateFormSchema.propertyDefinitions["batchId"].metadata.data = batchesOption[0];
        //     updateFormSchema.propertyDefinitions["batchId"].metadata.isLocal = true;
        //     updateFormSchema.propertyDefinitions["batchId"].metadata.readOnly = false;
        // }
        transition({
            type: "FORM_SCHEMA_LOADED",
            typeId: typeId,
            formSchemaId: formSchemaId,
            objectData: objectData,
            formSchema: updateFormSchema,
            validationSchema: uploadValidationSchema,
            displayRuleSchema: getRenderRule(updateFormSchema.propertyDefinitions, "CRFEditUpload"),
        });
    }
}

function showErrormsgforDateRequired(transition, msg) {
    transition({
        type: ACTIONS.HIDE_INDICATOR,
    });
    let errorMessage = msg;
    transition({
        strict: true,
        type: "FORM_ERROR",
        errorMessage: errorMessage,
    });
}

function doCRFUploadFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        const data = event.values
        try {
            let displayError = true;
            if (data["CRFfileName"] !== undefined && data["CRFfileName"] !== null && data["CRFfileName"] !== "") {
                displayError = false;
            }

            if (data["CRF"] && data["CRF"].length > 0) {
                let crfFile = data["CRF"][0];
                const uploadvalues: any = {
                    visitId: data["id"],
                    documentType: "CRF",
                    contentStreamFileName: crfFile.name,
                    contentSteamLength: crfFile.size + "",
                    contentStreamMimeType: crfFile.type,
                    status: UPLOAD_STATUS.NEW

                }
                if (data["CRFFileDetails"]) {
                    uploadvalues["id"] = data["CRFFileDetails"]["id"];
                    uploadvalues["status"] = UPLOAD_STATUS.NEW;
                }
                const datasource: any = dataLoader.getLoader<IDataSourceLake>("uploadDataSource");
                const commentsdatasource = dataLoader.getLoader<IDataSourceLake>("qcDashboardDataSource");
                let result;
                if (data["CRFFileDetails"]) {
                    result = await datasource.update(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, crfFile, { id: uploadvalues.id, documentType: uploadvalues.documentType });
                } else {
                    result = await datasource.postData(uploadvalues, { id: TYPES.UPLOADS });
                    await datasource.uploadFileToCloud(result.signedUrl, crfFile, { id: result.id, documentType: uploadvalues.documentType });
                }
                displayError = false;
                if (data["CRFComment"]) {
                    let commentsData = {
                        uploadId: result.id,
                        comments: data["CRFComment"],
                        initial: true
                    };
                    const createcomment = await commentsdatasource.create(commentsData, { id: TYPES.COMMENTS });
                }
            }

            if (displayError) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                let errorMessage = "CRF file is required, please select file and submit.";
                transition({
                    strict: true,
                    type: "FORM_ERROR",
                    errorMessage: errorMessage,
                });
            } else {
                const visitDataSource: any = dataLoader.getLoader<IDataSourceLake>("visitDataSource");
                const visitObject = {
                    id: data["id"],
                    bmi: (data["bmi"] !== undefined || data["bmi"] !== null) ? parseFloat(data["bmi"]) : undefined,
                    onsetAge: data["onsetAge"],
                    birthDate: data["birthDate"] ? getISOFormatedDate(data["birthDate"]) : undefined,
                    weight: (data["weight"] !== undefined || data["weight"] !== null) ? parseFloat(data["weight"]) : undefined,
                    mmse: (data["mmse"] !== undefined || data["mmse"] !== null) ? parseFloat(data["mmse"]) : undefined,
                    cdr: (data["cdr"] !== undefined || data["cdr"] !== null) ? parseFloat(data["cdr"]) : undefined,
                    adas_cog: (data["adas_cog"] !== undefined || data["adas_cog"] !== null) ? parseFloat(data["adas_cog"]) : undefined,
                    fab: (data["fab"] !== undefined || data["fab"] !== null) ? parseFloat(data["fab"]) : undefined,
                    psprs: (data["psprs"] !== undefined || data["psprs"] !== null) ? parseFloat(data["psprs"]) : undefined,
                    updrs: (data["updrs"] !== undefined || data["updrs"] !== null) ? parseFloat(data["updrs"]) : undefined,
                    pet_dicom_available: data["pet_dicom_available"] !== undefined ? (data["pet_dicom_available"] === "true" ? true : false) : undefined,
                    apn_pet_dicom_available: data["apn_pet_dicom_available"] !== undefined ? (data["apn_pet_dicom_available"] === "true" ? true : false) : undefined,
                    classificationId: data["classificationId"],
                    compound_manufactured_id: data["batchId"]
                }
                await visitDataSource.update(visitObject, { id: TYPES.VISITS });

                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{upload.successMessage}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                });
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}


const CRFFormHandler: ICustomStateMachineData = {
    name: "CRFForm",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onCRFUploadFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onCRFUploadFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onCRFUploadFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onCRFUploadFormSchemaBeforeLoad: ({ typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams }) =>
                dispatch(doCRFUploadFormSchemaBeforeLoad(typeId, formSchemaId, objectData, formSchema, validationSchema, extraParams)),
            onCRFUploadFormSubmit: (evt) => dispatch(doCRFUploadFormSubmit(evt)),
            onCRFUploadFormHandleChange: ({ value, formValues }) => dispatch(doEditUploadFormHandleChange(value, formValues)),
        };
    },
};

customStateProvider.registerCustomStateMachine("FormWidget", {
    formSchemaId: "crfUpload_form",
    typeId: "upload"
}, CRFFormHandler);