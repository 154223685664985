import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory, IDataSourceLake } from "@itsy-ui/core";
import { DROPDOWN, LOCALSTORAGE_NAME } from "../../utils/constant";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doDashboardFilterFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        let selectedfilter_csite = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE);
        let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
        let selectedfilter_classification = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_CLASSIFICATION_VALUE);
        const customeobjectData = {
            ...objectData,
            "siteId": selectedfilter_csite ? selectedfilter_csite : "all",
            "studyId": selectedfilter_study ? selectedfilter_study : "all",
            "classificationId": selectedfilter_classification ? selectedfilter_classification : "all",
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: validationSchema,
            objectData: customeobjectData,
            typeId: typeId,
        });
    }
}

function doDashboardFilterFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues, metadata } = getState();
        let currentFormValues;
        const updatedValues = {
            ...value,
        };
        let updatedPropDefs = { ...metadata.propertyDefinitions };
        if (Object.keys(value).toString() === "siteId") {
            let selectedfilter_study = localStorage.getItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE);
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("studyDataSource");
            const getStudies = await datasource.getAll(DROPDOWN.FILTER_STUDIES_DROPDOWN, { siteId: value["siteId"] });
            const studyOption = [getStudies]
            updatedPropDefs["studyId"].metadata.data = studyOption[0];
            updatedPropDefs["studyId"].metadata.isLocal = true;
            const studyId = selectedfilter_study && studyOption[0].map(item => { return item.id }).includes(selectedfilter_study) ? selectedfilter_study : "all";
            currentFormValues = { ...formValues, ...updatedValues, studyId };
        } else {
            currentFormValues = { ...formValues, ...updatedValues };
        }
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
        });
        transition({
            type: "FORM_UPDATE_PROPERTY_DEFINITIONS",
            propertyDefinitions: updatedPropDefs,
        })
        transition({
            controlID: "uploadSummary",
            strict: true,
            type: "GRID_FILTER",
            searchValue: {
                studyId: currentFormValues["studyId"], siteId: currentFormValues["siteId"],
                classificationId: currentFormValues["classificationId"]
            }
        });
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CSITE_VALUE, currentFormValues["siteId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_STUDY_VALUE, currentFormValues["studyId"]);
        localStorage.setItem(LOCALSTORAGE_NAME.FILTER_CLASSIFICATION_VALUE, currentFormValues["classificationId"]);
    };
};


const subjectFormHandler: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onDashboardFilterFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formBeforeHandleChange": {
                "onEntry": [
                    "onDashboardFilterFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onDashboardFilterFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doDashboardFilterFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onDashboardFilterFormHandleChange: ({ value, formValues }) => dispatch(doDashboardFilterFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "uploadSummary_filters"
}, subjectFormHandler);