import { getDefaultRegistry, IWidgetControlProps, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import Form from 'react-bootstrap/Form';
import { Row, Col } from "react-bootstrap";
import { getlocaleText } from "@itsy-ui/utils"
import {getFormLabelClassName} from "../../helper"
class PhoneNumberTextBox extends React.Component {

	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}


	customeHandleblur(event, value) {
		const controlProps = this.getControlSchemaProperties();
		const getvalue = value === "" ? value : value.replace(/[^a-zA-Z]/g, '') === '' && value.replace(/^\+(?:[0-9] ?){12,20}[0-9]$/g, '') !== '' ? value : controlProps.value;
		event.target.value = getvalue;
		controlProps.handleBlur(event, getvalue)
	}

	customeOnChange(event, value) {
		const controlProps = this.getControlSchemaProperties();
		const getvalue = value === "" ? value : value.replace(/[^a-zA-Z]/g, '') === '' && value.replace(/^\+(?:[0-9] ?){12,20}[0-9]$/g, '') !== '' ? value : controlProps.value;
		event.target.value = getvalue;
		controlProps.handleChange(event, getvalue)
	}

	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const disabledClass = fieldSchema.readOnly ? "borderDisable" : "";
		const customClass = fieldSchema.className ? fieldSchema.className : "";
		const customStyle = fieldSchema.style ? fieldSchema.style : {};
		const placeHolder = fieldSchema.placeholderText ? fieldSchema.placeholderText : fieldSchema.placeholder ? fieldSchema.placeholder : "";
		if (controlProps.isReadonly) {
			return (
				<Form.Group className={customClass} style={customStyle}>
					<Row>
						<Col xs={6} sm={6} md={6} lg={6}>
							<Form.Label className="read-only-label">{`${fieldSchema.displayName}:`}</Form.Label>
						</Col>
						<Col xs={6} md={6} lg={6}>
							<Form.Label>{controlProps.value}</Form.Label>
						</Col>
					</Row>
				</Form.Group>
			);
		}
		return <Form.Group className={customClass} style={customStyle} controlId={fieldSchema.id}>
			<Form.Label className={getFormLabelClassName(fieldSchema)}>{fieldSchema.displayName}</Form.Label>
			<div className="input-textbox">
				<Form.Control
					type="text"
					disabled={fieldSchema.readOnly}
					placeholder={placeHolder}
					value={controlProps.value !== undefined ? controlProps.value : ""}
					className={controlProps.error ? 'form-control error' : 'form-control'}
					onBlur={(v) => {
						controlProps.handleBlur && this.customeHandleblur(v, v.target.value)
					}}
					onChange={e => controlProps.handleChange && this.customeOnChange(e, e.target.value)}
				/>
				{fieldSchema.helptext && <Form.Text className="helping-text">{getlocaleText(fieldSchema.helptext)}</Form.Text>}
				{controlProps.error &&
					<Form.Text id="component-error-text">{controlProps.error}</Form.Text>
				}
			</div>
		</Form.Group>
	}
}

PhoneNumberTextBox['displayName'] = 'PhoneNumberTextBox';

WidgetsFactory.instance.registerFactory(PhoneNumberTextBox);
WidgetsFactory.instance.registerControls({
	phonenumber_textBox: "PhoneNumberTextBox"
});