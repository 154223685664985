import { StateManagerContext } from "@itsy-ui/core";
import { ItsyContainer, ItsyRow, ItsyColumn } from "@itsy-ui/layout";
import { ItsyGrid } from "@itsy-ui/data";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from '@itsy-ui/navigation';


const SiteListName = {
    "title": "{{site.siteList}}",
    "headerSize": "h6",
    "alignText": "left",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "site_add",
            "displayText": "{{site.addSite}}",
            "isPrimary": true,
            "iconPosition": "startIcon",
            "iconName": "add_circle",
            "roles": []
        }
    ],
    "widgetType": "bound",
    "reflowPageId": "sites",
    "align": "right",
    "className": "site-list-added",
    "typeId": "site_add"
};

const Gridschema = {
    typeId: "sites",
    gridSchemaId: "sites",
    dataSource: "siteDataSource",
    rowSelectionMode: 1,
    controlID: "sites"
}

const SiteList = (props) => {
    return (
        <ItsyContainer className="site-management-page">
            <ItsyRow>
                <ItsyColumn span={6}>
                    <ItsyLabel schema={SiteListName} />
                </ItsyColumn>
                <ItsyColumn span={6}>
                    <StateManagerContext.Provider key="site" value={{ contextPath: { "id": "site_create_form" } }}>
                        <ItsyToolbar schema={toolbarSchema} />
                    </StateManagerContext.Provider>
                </ItsyColumn>
            </ItsyRow>
            <ItsyRow>
                <StateManagerContext.Provider key="sites" value={{ contextPath: { "id": "sites" } }}>
                    <ItsyGrid style={{ "width": "100%" }} className="site-list-table" schema={Gridschema} />
                </StateManagerContext.Provider>
            </ItsyRow>
        </ItsyContainer>
    )
}

export default SiteList;