import { StateManagerContext } from "@itsy-ui/core";
import { ItsyColumn, ItsyContainer } from "@itsy-ui/layout";
import { ItsyForm } from "@itsy-ui/form";
import { ItsyLabel } from '@itsy-ui/common';
import { ItsyToolbar } from "@itsy-ui/navigation";

const Formschema = {
    typeId: "forgotpassword",
    formSchemaId: "forgotpassword",
    controlID: "forgotpassword",
    showSubmitButton: false
}

const ForgotTitle = {
    "title": "{{forgetYourPasswordTitle}}",
    "headerSize": "h6",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const forgotSubTitle = {
    "title": "{{forgot.helpText}}",
    "alignText": "center",
    "style": { "text-align": "center" }
}

const toolbarSchema = {
    data: [
        {
            "name": "back_login",
            "displayText": "{{common.back}}",
            "isPrimary": true,
            "enabled": true
        },
        {
            "name": "forgot_submit",
            "displayText": "{{common.send}}",
            "isPrimary": true,
            "enabled": true
        },
    ],
    "widgetType": "bound",
    "reflowPageId": "forgotpassword",
    "align": "right",
    "className": "forgotpassword-button",
    "typeId": "forgotpassword"
};

const ForgotPage = (props) => {
    return (<ItsyContainer className="apn-forgotpassword-container w-50">
        <ItsyColumn className="apn-forgotpassword-title">
            <ItsyLabel schema={ForgotTitle} />
        </ItsyColumn>
        <ItsyColumn>
            <ItsyLabel schema={forgotSubTitle} />
        </ItsyColumn>
        <ItsyColumn className="apn-forgotpassword-form">
            <StateManagerContext.Provider key="forgotpassword" value={{ contextPath: { "id": "forgotpassword" } }}>
                <ItsyForm type="form" schema={Formschema} />
                <ItsyToolbar className="toolbar" schema={toolbarSchema} />
            </StateManagerContext.Provider>
        </ItsyColumn>
    </ItsyContainer>)
}

export default ForgotPage;