import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, IDataSourceLake, DataLoaderFactory, IAuthService } from "@itsy-ui/core";
import { ACTIONS, TYPES, RegExp } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");
const Yup = require("yup");

function doSiteFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const checkyValidationSchema = {
            ...validationSchema,
            "siteName": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{siteName.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}")),
            "siteManagerName": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{siteManagerName.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}"))
        };

        transition({
            type: "FORM_SCHEMA_LOADED",
            formSchema: formSchema,
            validationSchema: checkyValidationSchema,
            objectData: objectData,
            typeId: typeId,
        });
    }
}

function doSiteCreateOrUpdateFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const data = await datasource.siteUpsert(event.values, { id: TYPES.SITES });  //id:url pathaname            
            let textMessage = (event.values["id"] !== undefined && event.values["id"] !== null && event.values["id"] !== "") ? "{{site.successUpdateMessage}}" : "{{site.successCreateMessage}}";
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            transition({
                type: "FORM_AFTER_SUBMIT",
            });
            transition({
                type: ACTIONS.HIDE_DRAWER,
            });
            transition({
                type: "SHOW_NOTIFICATION",
                message: { "text": textMessage, "type": " alert-success" },
                metadata: { "delay": 1500 },
                action: { "showCloseIcon": false },
            });
            transition({
                type: ACTIONS.GRID_REFRESH
            })

        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            let errorMessage = e.message ? e.message : "Invalid input data.";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const SiteCreateOrUpdateFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onSiteFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onSiteCreateOrUpdateFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSiteFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doSiteFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onSiteCreateOrUpdateFormSubmit: (evt) => dispatch(doSiteCreateOrUpdateFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "sites",
    "formSchemaId": "site_create_form",
}, SiteCreateOrUpdateFormOnSubmit);



function doSiteEditFormSubmit(event) {
    return async (getState: any, dispatch: any, transition: any) => {
        try {
            const datasource: any = dataLoader.getLoader<IDataSourceLake>("siteDataSource");
            const data = await datasource.siteUpsert(event.values, { id: TYPES.SITES });   //id:url pathaname
            if (data) {
                transition({
                    type: ACTIONS.HIDE_INDICATOR,
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: ACTIONS.HIDE_DRAWER,
                });
                transition({
                    type: "SHOW_NOTIFICATION",
                    message: { "text": "{{common.UserEdited}}", "type": " alert-success" },
                    metadata: { "delay": 1500 },
                    action: { "showCloseIcon": false },
                });
                transition({
                    type: ACTIONS.GRID_REFRESH
                })
            }
        } catch (e) {
            transition({
                type: ACTIONS.HIDE_INDICATOR,
            });
            const response = e && e.response && e.response.data ? e.response.data : e.message ? e.message : null;
            let errorMessage = response ? response.message || response : null;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    };
}

const SiteEditFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSubmit": {
                "onEntry": [
                    "onSiteEditFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onSiteEditFormSubmit: (evt) => dispatch(doSiteEditFormSubmit(evt)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    "typeId": "sites",
    "formSchemaId": "site_edit_form",
}, SiteEditFormOnSubmit);