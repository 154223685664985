import { getDefaultRegistry, retrieveSchema, WidgetsFactory, withReducer } from "@itsy-ui/core";
import * as React from 'react';
import { getlocaleText } from "@itsy-ui/utils"
import Form from 'react-bootstrap/Form';
import { getFormLabelClassName } from "../../helper";
const moment = require("moment")

const initialState = {
	selectOptions: [],
};

const Actions = {
	LoadOptions: "Selectbox.LoadOptions",
	UpdateSelectValue: "Selectbox.UpdateSelectValue",
};

function reducer(state, action) {
	switch (action.type) {
		case Actions.LoadOptions:
			return {
				...state,
				selectOptions: action.options,
			};
		default:
			return state === undefined ? initialState :
				Object.keys(state).length === 0 ? initialState : state;
	}
}

function updateOptions(options, updatedValue) {
	return {
		type: Actions.LoadOptions,
		options,
		updatedValue
	};
}

export function doLoadOptions(controlProps) {
	return async (_getState, dispatch, transition) => {
		const { fieldSchema, handleChange, value, queryParams } = controlProps;
		const { options, datasource, metadata, defaultValue } = fieldSchema;
		let currentYear = new Date().getFullYear(), selectOptions = [];
		let startYear = currentYear - 100;
		while (startYear <= currentYear) {
			const item = {
				key: startYear,
				value: startYear,
			}
			startYear++;
			selectOptions.push(item);
		}
		const updatedValue = value ? value : defaultValue;
		dispatch(updateOptions(selectOptions, updatedValue));
		updatedValue && handleChange && handleChange(undefined, updatedValue);
	};
}

class CustomYearMonth extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			yearDate: ""
		}
	}
	componentWillMount() {
		const controlProps = this.getControlSchemaProperties();
		this.props.onLoadOptions(controlProps);
	}

	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}

	getSelectOptions(options) {
		return Array.isArray(options) && options.map((t, index) => {
			if (t) {
				if (Object.prototype.toString.call(t) === "[object String]") {
					return <option key={index} value={t}>{getlocaleText(t)}</option>;
				} else {
					// expecting as key value object pair
					return <option key={index} value={t.key}>{t.value}</option>;
				}
			}
		});
	}

	getSelectMonthOptions(options) {
		return Array.isArray(options) && options.map((t, index) => {
			if (t) {
				if (Object.prototype.toString.call(t) === "[object String]") {
					return <option key={index} value={t}>{getlocaleText(t)}</option>;
				} else {
					// expecting as key value object pair
					return <option key={index} value={t.key}>{getlocaleText(t.value)}</option>;
				}
			}
		});
	}

	handleChange(_event, value, item) {
		const { handleChange, } = this.getControlSchemaProperties();
		if (item === "year") {
			const date = this.props.schema && this.props.schema.value ? moment(this.props.schema.value).format("YYYY-MM-DD").split('-')[1] : "01"
			this.setState({
				yearDate: value
			})
			const dateValue = `${value}-${date}-${'01'}`
			handleChange(undefined, dateValue);
		} else if (item === "month") {
			const year = this.state.yearDate !== "" ? this.state.yearDate : this.props.schema && this.props.schema.value ? moment(this.props.schema.value).format("YYYY-MM-DD").split('-')[0] : ""
			const dateValue = `${year}-${value}-${'01'}`
			handleChange(undefined, dateValue);
		}
	}


	render() {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const setValue = controlProps.value !== null && controlProps.value !== undefined && controlProps.value !== "" ? moment(controlProps.value).format("YYYY-MM-DD").split('-') : false;
		const customClass = fieldSchema.className ? fieldSchema.className : "";
		const customStyle = fieldSchema.style ? fieldSchema.style : {};
		const fieldClass = controlProps.className ? `select-box ${controlProps.className} ${customClass}` : `select-box ${customClass}`
		if (controlProps.value) {
			delete controlProps.error;
		}
		return (fieldSchema.visibility === undefined || fieldSchema.visibility) &&
			<Form.Group className={fieldClass}
				controlId={fieldSchema.id}>
				<Form.Label className={getFormLabelClassName(fieldSchema)}>{fieldSchema.displayName}</Form.Label>
				<div className="custom-date-dropdown">
					<Form.Control as="select"
						className="select-option"
						value={setValue ? setValue[0] : "none"}
						disabled={fieldSchema.readOnly}
						onChange={e => { this.handleChange(e, e.target.value, "year") }}
					>
						<option key="Empty_value_dropdown" value="none" disabled hidden >{getlocaleText(fieldSchema.placeholderText)}</option>
						{this.props.selectOptions !== undefined &&
							this.getSelectOptions(this.props.selectOptions)
						}
					</Form.Control>
					<Form.Control as="select"

						className="select-option month"
						value={setValue ? setValue[1] : "none"}
						disabled={fieldSchema.readOnly ? fieldSchema.readOnly : this.state.yearDate === "" && !setValue ? true : false}
						onChange={e => { this.handleChange(e, e.target.value, 'month') }}
					>
						<option key="Empty_value_dropdown" value="none" disabled hidden >{getlocaleText(fieldSchema.monthSchema.placeholderText)}</option>
						{fieldSchema.monthSchema["option"] !== undefined &&
							this.getSelectMonthOptions(fieldSchema.monthSchema["option"])
						}
					</Form.Control>
				</div>
				{fieldSchema.helptext && <Form.Text>{getlocaleText(fieldSchema.helptext)}</Form.Text>}
				{controlProps.error && <Form.Text id="component-error-text">{controlProps.error}</Form.Text>}
			</Form.Group>;
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLoadOptions: (schema, queryParams) => dispatch(doLoadOptions(schema, queryParams))
	};
};
const CustomYearMonthComponent = withReducer("CustomYearMonth", reducer, mapDispatchToProps)(CustomYearMonth);
CustomYearMonthComponent['displayName'] = 'CustomYearMonth';

WidgetsFactory.instance.registerFactory(CustomYearMonthComponent);
WidgetsFactory.instance.registerControls({
	custom_year_and_month: "CustomYearMonth"
});