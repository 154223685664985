import { getDefaultRegistry, retrieveSchema, WidgetsFactory } from "@itsy-ui/core";
import * as React from "react";
import { Form } from 'react-bootstrap';
import { Row, Col } from "react-bootstrap";
import { getlocaleText } from "@itsy-ui/utils";

class CustomeSwitchControl extends React.Component {

    getControlSchemaProperties() {
        const registry = getDefaultRegistry();
        const { definitions } = registry;
        const schema = retrieveSchema(this.props.schema, definitions);
        return schema;
    }

    render() {
        const controlProps = this.getControlSchemaProperties();
        const { fieldSchema } = controlProps;
        const customClass = fieldSchema.className ? fieldSchema.className : "";
        const customStyle = fieldSchema.style ? fieldSchema.style : {};
        const labelSpan = fieldSchema.labelSpan ? fieldSchema.labelSpan : undefined;
        const switchSpan = fieldSchema.switchSpan ? fieldSchema.switchSpan : undefined;
        const displayOption = fieldSchema.value.find(e => e.key === controlProps.value);
        const id = `switchControl_${fieldSchema.id}`;
        if (controlProps.isReadonly) {
            return (
                <Form.Group className={customClass} style={customStyle}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <Form.Label >{`${fieldSchema.displayName}:`}</Form.Label>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                            <Form.Label>{controlProps.value}</Form.Label>
                        </Col>
                    </Row>
                </Form.Group>
            );
        }
        return (
            <div className={`Switch-button ${customClass}`} style={customStyle}>
                <div className="swithc-label-container">
                    <Form.Label className="radio_label" column sm={labelSpan}>
                        {fieldSchema.displayName}
                    </Form.Label>
                </div>
                <Form.Check
                    id={id}
                    type="switch"
                    disabled={fieldSchema.readOnly ? true : false}
                    checked={controlProps.value !== undefined ? controlProps.value : fieldSchema.checked ? fieldSchema.checked : false}
                    value={controlProps.value}
                    color="secondary"
                    onChange={e => controlProps.handleChange && controlProps.handleChange(e, e.target.checked)}
                    label={displayOption !== undefined && displayOption.value ? getlocaleText(displayOption.value) : fieldSchema.displayName}
                />
                {fieldSchema.helptext && <Form.Text>{getlocaleText(fieldSchema.helptext)}</Form.Text>}
            </div>
        );
    }
}

CustomeSwitchControl['displayName'] = 'CustomeSwitchControl';

WidgetsFactory.instance.registerFactory(CustomeSwitchControl);
WidgetsFactory.instance.registerControls({
    "custom_switch": 'CustomeSwitchControl',
});