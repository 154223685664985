import { getDefaultRegistry, retrieveSchema, WidgetsFactory, withReducer } from "@itsy-ui/core";
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Form, InputGroup } from 'react-bootstrap';
import { getlocaleText, setItemInLocalStorage } from "@itsy-ui/utils"
import { getUpdatedFilter, getFormLabelClassName } from "../../helper";
import { ItsyButton } from "@itsy-ui/common"
import { ItsyColumn, ItsyRow } from "@itsy-ui/layout"
import { BsCaretDown, BsCaretUp } from "react-icons/bs"

const initialState = {
	lookupOptions: [],
};

const Actions = {
	LoadDataSource: "AutoComplete.LoadDataSource",
	ChildWidget: {
		CHILD_FORM_INIT: "CHILD_FORM_INIT"
	},
};


function reducer(state, action) {
	switch (action.type) {
		case Actions.LoadDataSource:
			return {
				...state,
				lookupOptions: action.data
			};
		default:
			return state === undefined ? initialState :
				Object.keys(state).length === 0 ? initialState : state;
	}
}

function doUpdateData(id, data) {
	return {
		type: Actions.LoadDataSource,
		id,
		data
	};
}




export function doLoadData(schema, queryParams) {
	return async (getState, dispatch, transition) => {
		const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"];
		let data = [];
		let datasource = schema.datasource ? (schema.datasource.getAll ? schema.datasource : dataLoader.getLoader(schema.datasource)) : dataLoader.getLoader("datasource");
		if (datasource && !schema.metadata.isLocal) {
			const parameters = {
				propertyDefinitions: [schema.metadata.valueKey, ...(Array.isArray(schema.metadata.displayKey) ? schema.metadata.displayKey : [])].reduce((propDefs, key) => { return { ...propDefs, ...{ [key]: {} } } }, {}),
				filter: schema.metadata.filter ? (typeof schema.metadata.filter === "string" ? JSON.parse(schema.metadata.filter) : schema.metadata.filter) : {},
			};
			parameters.filter = await getUpdatedFilter(parameters.filter, queryParams, transition);

			if (schema.metadata && schema.metadata.isLocalFetch === undefined) {
				const typeId = schema.metadata.typeId ? schema.metadata.typeId.toString() : "";
				data = await datasource.getAll(typeId, parameters);
			}
		} else if (schema.metadata.isLocal && schema.metadata.isLocalFetch) { // for updating local options
			if (typeof (schema.metadata.data) === "string") {
				data.push(...JSON.parse(schema.metadata.data));
			} else {
				data.push(...schema.metadata.data);
			}
		}
		dispatch(doUpdateData(schema.id, data));
	};
}





class DropDownButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			arrowMark: false,
			inValidValue: ""
		}
		this.lookupFiled = React.createRef();
		this.inputBoxFocuse = this.inputBoxFocuse.bind(this);
		this.hide = this.hide.bind(this);
	}

	componentWillMount() {
		const schema = this.props.schema ? (this.props.schema.fieldSchema ? this.props.schema.fieldSchema : this.props.schema) : null;
		if (schema) {
			this.props.onLoadData(schema, this.props.schema.queryParams);
		}
	}

	componentDidUpdate() {
		if (this.props.schema && this.props.schema.fieldSchema) {
			this.props.onLoadData(this.props.schema.fieldSchema);
		}
	}
	getControlSchemaProperties() {
		const registry = getDefaultRegistry();
		const { definitions } = registry;
		const schema = retrieveSchema(this.props.schema, definitions);
		return schema;
	}

	getLabel = (option, metadata) => {
		if (Array.isArray(metadata.displayKey) && option) {
			if (metadata.displayKey.length === 1 && option[metadata.displayKey[0]]) {
				return Array.isArray(option[metadata.displayKey[0]]) ? option[metadata.displayKey[0]].toString() : option[metadata.displayKey[0]];
			} else if (metadata.displayKey.length === 2 && option[metadata.displayKey[0]] && option[metadata.displayKey[1]]) {
				return `${option[metadata.displayKey[0]]}, ${option[metadata.displayKey[1]]}`;
			}
		}
		return option && metadata.displayKey && option[metadata.displayKey] ? option[metadata.displayKey] : "";
	}

	updateValue(controlProps, isMultiSelect, option, metadata) {
		metadata.isOldDataEarse = true
		let values;
		if (Array.isArray(option)) {
			values = option.map(t => t[metadata.valueKey]);
		} else {
			values = option ? option[metadata.valueKey] : "";
		}
		values = !isMultiSelect && Array.isArray(values) && values.length > 0 ? values[0] : values
		controlProps.handleChange && controlProps.handleChange(undefined, values)
	}

	inputBoxFocuse() {
		this.lookupFiled.current.toggleMenu();
		this.lookupFiled.current.isMenuShown = !this.state.arrowMark
		this.setState({
			arrowMark: !this.state.arrowMark,
		})
	}
	// componentDidMount() {
	// 	document.addEventListener("mousedown", this.hide);
	// }

	hide(e) {
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const { metadata } = fieldSchema;
		if (metadata.isShowDropdown && this.lookupFiled && this.lookupFiled.current && this.lookupFiled.current.isMenuShown) {
			this.setState({
				arrowMark: false,
			})
		}
		if (metadata.isUnmatchValue === true && e !== undefined && (controlProps.value === undefined || controlProps.value.length === 0) && e.target.value) {
			const setInValid = {
				invalidSubjectId: e.target.value,
				isDisMathc: true
			}
			controlProps.handleChange && controlProps.handleChange(undefined, setInValid)
			this.setState({
				inValidValue: e.target.value
			})
		}
	}

	render() {
		this.props.schema.fieldSchema["lookupOptions"] = this.props.lookupOptions && this.props.lookupOptions;
		const controlProps = this.getControlSchemaProperties();
		const { fieldSchema } = controlProps;
		const { metadata, lookupOptions, ButtonSchema } = fieldSchema;
		const customClass = fieldSchema.className ? fieldSchema.className : "";
		const customStyle = fieldSchema.style ? fieldSchema.style : {};
		const isMultiSelect = (metadata.isMulti === null || metadata.isMulti === undefined) ? false : metadata.isMulti;
		const placeHolder = fieldSchema.placeholderText ? fieldSchema.placeholderText : fieldSchema.placeholder ? fieldSchema.placeholder : "";
		let defaultValues;
		if (lookupOptions && lookupOptions.length > 0 && controlProps.value) {
		if (!isMultiSelect ) {
		defaultValues = lookupOptions.filter((x) => x[metadata.valueKey] === controlProps.value
				
				);
			} else {
				if (Array.isArray(controlProps.value)) {
					defaultValues = [];
					lookupOptions.forEach(obj => {
						controlProps.value.forEach(function (val) {
							if (obj[metadata.valueKey] === val) {
								defaultValues.push(obj);
							}
						});
					});
				}
			}
		}
		if ((!defaultValues || defaultValues.length == 0) && lookupOptions && lookupOptions.length > 0) {
			if (lookupOptions[0][metadata.valueKey].includes("all")) {
				defaultValues = []; // incase of undefined set empty array and then push
				defaultValues.push(lookupOptions[0]);
				this.updateValue(controlProps, isMultiSelect, defaultValues, metadata);
			}
		}

		return <div className="apn-dropdown-button">
			<ItsyRow>
				<ItsyColumn span={fieldSchema.span ? fieldSchema.span : 12}>
					<Form.Group className={customClass} style={customStyle} controlId={fieldSchema.id}>
						<Form.Label className={getFormLabelClassName(fieldSchema)}>{fieldSchema.displayName}</Form.Label>
						<div className="input-textbox">
							<InputGroup>
								<Typeahead     
									className="lookup-component"
									id={fieldSchema.id}
									ref={this.lookupFiled}
									disabled={metadata.readOnly ? metadata.readOnly : false}
									options={lookupOptions}
									multiple={isMultiSelect ? isMultiSelect : false}
									selected={defaultValues ? defaultValues : []}
									labelKey={(option) => { return !Array.isArray(option) ? this.getLabel(option, metadata) : controlProps.value }}
									onChange={(option) => {
										this.updateValue(controlProps, isMultiSelect, option, metadata)
									}}
									onSelect={(e, option) => {
										this.updateValue(controlProps, isMultiSelect, option, metadata)
									}}
									placeholder={placeHolder}
									renderMenuItemChildren={(option, props, index) =>
										this.getLabel(option, metadata)
									}
									onBlur={this.hide}
								/>
								{metadata.isShowDropdown && <InputGroup.Append className="right-side-lookup-contianer">
									<div className="right-side-arrow-mark"  >
								{this.state.arrowMark ? <BsCaretUp onClick={this.inputBoxFocuse} /> : <BsCaretDown onClick={this.inputBoxFocuse} />}
									</div>
								</InputGroup.Append>
								}
							</InputGroup>
							{fieldSchema.helptext && <Form.Text className="helping-text">{getlocaleText(fieldSchema.helptext)}</Form.Text>}
							{controlProps.error &&
								<Form.Text id="component-error-text">{controlProps.error}</Form.Text>
							}
						</div>
					</Form.Group>
				</ItsyColumn>
				{metadata.isShowButton && <ItsyColumn className="icon-button-colum" span={ButtonSchema.span ? ButtonSchema.span : 12} justifyContent="left" alignItems="center">
					<ItsyButton
						title={getlocaleText(ButtonSchema.title)}
						className={ButtonSchema.className ? ButtonSchema.className : "dropdown-button-algin"}
						iconPosition={ButtonSchema.iconPosition}
						iconName={ButtonSchema.iconName}
						commandName={ButtonSchema.commandName}
						alignText={ButtonSchema.alignText}
						style={ButtonSchema.style}
					/>
				</ItsyColumn>}
			</ItsyRow>
		</div >
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		onLoadData: (schema, queryParams) => dispatch(doLoadData(schema, queryParams))
	};
};
const DropDownButtonComponent = withReducer("DropDownButton", reducer, mapDispatchToProps)(DropDownButton);
DropDownButtonComponent['displayName'] = 'DropDownButton';

WidgetsFactory.instance.registerFactory(DropDownButtonComponent);
WidgetsFactory.instance.registerControls({
	custom_dropdown_button: "DropDownButton"
});