import { WidgetsFactory, DataLoaderFactory, ICommandManager, CommandOptions } from "@itsy-ui/core";
import { ACTIONS } from "../utils/constant"
import { getlocaleText } from "@itsy-ui/utils"
import { cleanLocalStorage } from "../utils/utils";
const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const commandManager = dataLoader.getLoader<ICommandManager>("commandManager");


const logoutCommand: CommandOptions<any> = {
    canExecute: (data: any) => {
        return true;
    },
    execute: async (data: any, transition: any) => {
        const popupData = {
            title: getlocaleText("{{logoutPopup.title}}"),
            popupMessage: getlocaleText("{{logout.message}}"),
            showCloseButton: true,
            popupType: 1,
            onOk: () => {
                cleanLocalStorage();
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
            onCancel: () => {
                transition({
                    type: ACTIONS.HIDE_POPUP,
                });
            },
        };
        transition({
            type: ACTIONS.SHOW_POPUP,
            event: popupData,
        });
    }
};

commandManager.registerCommand("logout", {}, logoutCommand);