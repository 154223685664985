import { WidgetsFactory, IDataSourceLake, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { getlocaleText } from "@itsy-ui/utils";
import { getUrlParamValue } from "../utils/helpers";
import { SuccessType, ErrorType, RegExp } from "../utils/constant";
import { UserDataSource } from "../common/UserDataSource";
const Yup = require("yup");

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider");

async function initializeDatasource() {
    dataLoader.registerLoader({
        datasource: new UserDataSource(),
    });
    return true;
}

function doEnrollFormSchemaBeforeLoad(typeId: string, objectData: any, formSchema: any, validationSchema: any) {
    return async (_, dispatch, transition) => {
        const formValidationSchema = {
            ...validationSchema,
            "username": Yup.string().matches(RegExp.USERNAME, { message: getlocaleText("{{userName.errorMessage}}") }).required(getlocaleText("{{userName.required}}")),
            "password": Yup.string().matches(RegExp.PASSWORD, { message: getlocaleText("{{password.errorMessage}}") }).required(getlocaleText("{{password.required}}")),
            "confirmPassword": Yup.string().required(getlocaleText("{{password.required}}"))
                .oneOf([Yup.ref('password'), null], getlocaleText("{{password.mustmatch}}")),
            "phoneNumber": Yup.string().matches(RegExp.PHONENUMBER, { message: getlocaleText("{{userPhoneNumber.errormessage}}") }).required(getlocaleText("{{userphonNumber.Required}}")),
            "name": Yup.string().trim().min(2, getlocaleText("{{common.minCharError}}")).max(50, getlocaleText("{{common.maxCharError}}")).required(getlocaleText("{{name.required}}")).matches(RegExp.NOTEMAIL, getlocaleText("{{common.invalidFormat_email}}"))
        };
        const userId = getUrlParamValue("id");
        let dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
        if (!dataSource) {
            await initializeDatasource();
            dataSource = dataLoader.getLoader<IDataSourceLake>("datasource");
        }
        let userdetails;
        try {
            userdetails = userId ? await dataSource.getObject("users", userId) : null;
            if (userdetails === null) {
                throw new Error("User.InvalidUserId");
            }
            else if (userdetails && userdetails.status && userdetails.status !== "NEW") {
                throw new Error("User.AlreadyEnrolled");
            }

            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: formValidationSchema,
                objectData: userdetails,
                typeId: typeId,
            });
        } catch (e) {
            transition({
                type: "FORM_SCHEMA_LOADED",
                formSchema: formSchema,
                validationSchema: formValidationSchema,
                objectData: userdetails,
                typeId: typeId,
            });
            transition({
                type: "NAVIGATE_URL",
                url: `/error?type=${ErrorType.INVALID_URL}&msg=${e.message}`
            });
        }
    }
}

function doEnrollFormSubmit(evt) {
    return async (_, dispatch, transition) => {
        try {
            const dataSource: any = dataLoader.getLoader<IDataSourceLake>("datasource");
            const enrollData = {
                id: evt.values.id,
                username: evt.values.username,
                password: evt.values.password,
                name: evt.values.name,
                phoneNumber: evt.values.phoneNumber,
                preferredLang: evt.values.preferredLang
            }
            const userdetails = await dataSource.post(enrollData, { id: "users/enroll" });
            if (userdetails) {
                transition({
                    type: "HIDE_INDICATOR",
                });
                transition({
                    type: "FORM_AFTER_SUBMIT",
                });
                transition({
                    type: "HIDE_DRAWER",
                });
                transition({
                    type: "NAVIGATE_URL",
                    url: `/success?type=${SuccessType.ENROLL}`,
                });
            }
        } catch (e) {
            transition({
                type: "HIDE_INDICATOR",
            });
            transition({
                type: "HIDE_DRAWER",
            });
            const errorMsg = e.message.split(",");
            let errorMessage = errorMsg[2] ? errorMsg[2] : e.message;
            if (!errorMessage)
                errorMessage = "Error in network call";
            transition({
                strict: true,
                type: "FORM_ERROR",
                errorMessage: errorMessage,
            });
        }
    }
}

const createFormOnSubmit: ICustomStateMachineData = {
    name: "createFormOnSubmit",
    stateJSON: {
        "states": {
            "formSchemaBeforeLoad": {
                "onEntry": [
                    "onEnrollFormSchemaBeforeLoad",
                ],
                "on": {
                    "FORM_SCHEMA_LOADED": "formSchemaLoaded",
                    "FORM_ERROR": "formError",
                },
            },
            "formSubmit": {
                "onEntry": [
                    "onEnrollFormSubmit"
                ],
                "on": {
                    "FORM_AFTER_SUBMIT": "formAfterSubmit",
                    "FORM_ERROR": "formError"
                }
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onEnrollFormSchemaBeforeLoad: ({ typeId, objectData, formSchema, validationSchema }) => dispatch(doEnrollFormSchemaBeforeLoad(typeId, objectData, formSchema, validationSchema)),
            onEnrollFormSubmit: (evt) => dispatch(doEnrollFormSubmit(evt))
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    id: "enroll"
}, createFormOnSubmit);