import { WidgetsFactory, ICustomStateMachineProvider, ICustomStateMachineData, DataLoaderFactory } from "@itsy-ui/core";
import { getFormatedDateWithTimezoneOffset } from "../../common/helpers";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const customStateProvider = dataLoader.getLoader<ICustomStateMachineProvider>("customStateProvider")

function doActivitiesFilterFormHandleChange(value: {}, formValues: {}) {
    return async (getState, dispatch: any, transition: any) => {
        const { formValues } = getState();
        const updatedValues = {
            ...value,
        };
        const currentFormValues = { ...formValues, ...updatedValues };
        transition({
            type: "FORM_VALUE_UPDATE",
            values: { ...currentFormValues },
        });
        transition({
            controlID: "activities",
            strict: true,
            type: "GRID_FILTER",
            searchValue: { from_date: getFormatedDateWithTimezoneOffset(currentFormValues["from_date"]), to_date: getFormatedDateWithTimezoneOffset(currentFormValues["to_date"]) }
        });
    };
}

const subjectFormHandler: ICustomStateMachineData = {
    name: "activitiesFormFilter",
    stateJSON: {
        "states": {
            "formBeforeHandleChange": {
                "onEntry": [
                    "onActivitiesFilterFormHandleChange",
                ],
                "on": {
                    "FORM_VALUE_UPDATE": "formValueUpdate",
                },
            }
        },
    },
    mapDispatchToAction: (dispatch) => {
        return {
            onActivitiesFilterFormHandleChange: ({ value, formValues }) => dispatch(doActivitiesFilterFormHandleChange(value, formValues)),
        };
    },
};
customStateProvider.registerCustomStateMachine("FormWidget", {
    schemaId: "activities_filter"
}, subjectFormHandler);