import axios from "axios";
import { getHeaders, getCreatedBy, getFormatedDateTimeWithTimezoneOffset } from "./helpers";
import { WidgetsFactory, IConfigLoader, DataLoaderFactory } from "@itsy-ui/core";
import { LBDataSource } from "./lbDataSource";
import { Roles, USER } from "../utils/constant";
import { getServerUrl } from "../utils/utils";

const dataLoader = WidgetsFactory.instance.services["DataLoaderFactory"] as DataLoaderFactory;
const configData = dataLoader.getLoader<IConfigLoader>("config");

export class UserDataSource {

    private headers: any
    private config: any
    private configFunc: any
    private dataURL: any;
    private lbDatasource: any;

    constructor() {
        this.configFunc = configData.getConfig;
        this.lbDatasource = new LBDataSource();
    }

    async initializeConfig() {
        this.dataURL = await getServerUrl();
        this.headers = this.headers ? this.headers : getHeaders();
        return this.config
    }

    async getAll(typeId: string, parameters: any): Promise<any[]> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getAll(typeId, parameters, this.dataURL);
            data.map((item, i) => {
                // Site Name
                data[i]["sites"] = (item.site && item.site.length > 0) ? item.site.map((ele, index) => { return ele.id }) : null;
                data[i]["siteName"] = (item.site && item.site.length > 0) ? item.site.map((site) => {
                    return site.siteName;
                }).join(', ') : null;
                // Role
                data[i]["roleId"] = (item.roles && item.roles.length > 0) ? item.roles.map((ele, index) => { return ele.id }) : null;
                data[i]["role_name"] = (item.roles && item.roles.length > 0) ? item.roles.map((role) => {
                    if (role.role_name === Roles.QC_USER) {
                        const userUploadTypes = (item.usersUploadTypes && item.usersUploadTypes.length > 0) ? item.usersUploadTypes.map((ele, index) => { return ele.type }) : "";
                        return userUploadTypes ? `${role.role_name} [${userUploadTypes.toString()}]` : role.role_name;
                    }
                    return role.role_name;
                }).join(', ') : null;
                // Last login time
                data[i]["lastLoginTime"] = item.login ? getFormatedDateTimeWithTimezoneOffset(item.login["lastLoginTime"]) : null;
                // Created By
                data[i]["createdBy"] = getCreatedBy(item);
                data[i]["uploadTypes"] = (item.usersUploadTypes && item.usersUploadTypes.length > 0) ? item.usersUploadTypes.map((ele, index) => { return ele.id }) : "";
                data[i]["cmcSites"] = (item.cmcSiteList && item.cmcSiteList.length > 0) ? item.cmcSiteList.map((ele, index) => { return ele.id }) : null;
                data[i]["cmcSitesName"] = (item.cmcSiteList && item.cmcSiteList.length > 0) ? item.cmcSiteList.map((msite) => {
                    return msite.cmc_site_name;
                }).join(', ') : null;
            });

            return data;
        } catch (e) {
            throw new Error(`Failed to get objects , Reason: ${e.message}`);
        }
    }

    async create(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.create(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e);
        }
    }
    async upsert(record: any, formSchema: any): Promise<any> {
        if (record["id"] !== undefined && record["id"] !== null && record["id"] !== "") {
            return await this.update(record, formSchema);
        } else {
            return await this.create(record, formSchema);
        }
    }
    async update(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();

        try {
            const updateData = {
                id: data["id"],
                email: data["email"],
                name: data["name"],
                phoneNumber: data["phoneNumber"],
                adminComments: data["adminComments"],
                preferredLang: data["preferredLang"],
                isActive: data["isActive"],
                sites: data["sites"],
                cmcSites: data["cmcSites"],
                organization: data["organization"],
                roleId: data["roleId"],
                uploadTypes: data["uploadTypes"]
            }
            return await this.lbDatasource.update(updateData, metadata, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to update object with ID, Reason: ${e.message}`);
        }
    }

    async delete(data: any): Promise<any> {
        await this.initializeConfig();

        try {
            return await this.lbDatasource.delete(data, this.dataURL);
        } catch (e) {
            throw new Error(`Failed to delete object with ID:, Reason: ${e.message}`);
        }
    }

    async getObject(typeId: string, objectId: string, _parameters?: {}): Promise<any> {
        await this.initializeConfig();
        try {
            const data = await this.lbDatasource.getObject(typeId, objectId, this.dataURL, _parameters);
            if (typeId === USER.USERS) {
                const getRole = data.roles && data.roles.length > 0 ? data.roles.map((role) => {
                    if (role.role_name === Roles.QC_USER) {
                        const userUploadTypes = (data.usersUploadTypes && data.usersUploadTypes.length > 0) ? data.usersUploadTypes.map((ele, index) => { return ele.type }) : "";
                        return userUploadTypes ? `${role.role_name} [${userUploadTypes.toString()}]` : role.role_name;
                    }
                    return role.role_name;
                }).join(', ') : false

                const getsiteName = data.site && data.site.length > 0 ? data.site.map((site) => {
                    return site.siteName;
                }).join(', ') : false

                const getcmcsiteName = data.cmcSiteList && data.cmcSiteList.length > 0 ? data.cmcSiteList.map((msite) => {
                    return msite.cmc_site_name;
                }).join(', ') : false

                return {
                    ...data, ...data.login && { lastLoginTime: data.login["lastLoginTime"] },
                    ...getRole && { roles: getRole }, ...getsiteName && { sites: getsiteName },
                    roleObj: data.roles, ...getcmcsiteName && { cmcSites: getcmcsiteName }
                }
            }
            return data
        } catch (e) {
            throw new Error(`Failed to get object with ID: ${objectId}, Reason: ${e.message}`);
        }
    }

    async post(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        const headers = this.headers;
        const objectId = data["id"];
        try {
            const resultData = await axios.post(`${this.dataURL}/${metadata.id}`, data, { headers });
            if (resultData.status === 204 || resultData.status === 200) {
                return resultData.data;
            }
        } catch (e) {
            let reason = e.response.data.error.cause ? e.response.data.error.cause : e.response.data.error.message;
            if (objectId === undefined || objectId === "") {
                throw new Error(`Reason: ${reason}`);
            }
            else {
                throw new Error(`Failed to update object with ID: ${objectId}, Reason: ${reason}`);
            }
        }
    }

    //Re-activating the user account
    async reActivate(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            data = {
                id: data["id"],
                isActive: true
            }
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e.message);
        }
    }

    //Accept terms of use
    async acceptTermsofuse(data: {}, metadata: any): Promise<any> {
        await this.initializeConfig();
        try {
            return await this.lbDatasource.update(data, metadata, this.dataURL);
        } catch (e) {
            throw new Error(e.message);
        }
    }

}